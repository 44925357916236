import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
}

const registerMedico = (This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL()),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		const productos = res.data.appInfo.productos

		let hombros = productos.filter( el => el.nombre === 'HOMBROS ')[0]

		// console.log('res', hombros)
		console.log('res', hombros.idTipoClienteDefault, hombros.precios_TipoCliente)

        This.setState({ productos })

		// return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default registerMedico