let consulta = (user) => {

    let { nombre, apellidos, usuario, correo, fecha_de_nacimiento, direccion, nacionalidad, sexo, telefono, DPI, tipo, password } = user
  
    const string =
      `mutation {
        userMutation {
          registerUser (registerUser: {
            nombre: "${nombre}"
            apellidos: "${apellidos}"
            usuario: "${usuario}"
            correo: "${correo}"
            fecha_de_nacimiento: "${fecha_de_nacimiento}"
            direccion: "${direccion}"
            nacionalidad: "${nacionalidad}"
            sexo: "${sexo}"
            telefono: ${telefono}
            DPI: "${DPI}"
            tipo: "${tipo}"
            password: "${password}"
            permisos: {
              AdminUser: {
                ViewUser: false
                CreateUser: false
                EditUser: false
                DeleteUser: false
                UpdateStateMedic: false
              }
            }
          }) {
            usuario {
              id
              usuario
              nombre
              apellidos
              correo
              fecha_de_nacimiento
              direccion
              nacionalidad
              sexo
              telefono
              tipo
              DPI
              permisos {
                AdminUser {
                  ViewUser
                  CreateUser
                  EditUser
                  DeleteUser
                  UpdateStateMedic
                }
              }
              suscription {
                id
                firma_afiliado
                fecha_inicio
                fecha_expiracion
                auto_renovacion
                tipo
                DPI_frontal
                DPI_posterior
                principalUser
                FK_Empresa
              }
            }
            conexion
            mensaje
          }
        }
    }`
  
      return {
        "query": string
      }
  
  }
  
  export default consulta