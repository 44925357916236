const initialState = {
    conexion: false,
    usuario: null
  }
  
  let logIn = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN': {
  
        //Obtener datos almacenados Res
        let logIn = action.payload.logIn;
  
        //console.log(conexionSession)
  
        if(logIn === null){ //|| conexionSession.conexion === false
          return state
            } else {
          return logIn
        }
  
      }
      case 'ADD_AFILIACION': {

        //console.log(action.payload.suscription)

        let newState = {
          ...state,
          usuario: {
            ...state.usuario,
            suscription: action.payload.suscription
          }
        }

        return newState

      }
      case 'UPLOAD_MEDIC_DOCUMENT': {
        let newState = {
          ...state,
          usuario: {
            ...state.usuario,
            medico: {
              ...state.usuario.medico,
              financiero: {
                ...state.usuario.medico.financiero,
                documentos: {
                  ...state.usuario.medico.financiero.documentos,
                  [action.payload.tipo]: action.payload.documento
                }
              }
            }
          }
        }

        return newState

      }
      case 'AUTO_MEDIC_EDIT': {
        let newState = {
          ...state,
          usuario: action.payload.user
        }

        return newState

      }
      case 'AUTO_EDIT_PROCEDIMIENTO': {
        let newState = {
          ...state,
          usuario: {
            ...state.usuario,
            medico: {
              ...state.usuario.medico,
              data_procedimientos: action.payload.data.data_procedimientos
            }
          }
        }

        return newState

      }
        //break;
      default:
        return state
    }
  }
  
  export default logIn