let consulta = (producto) => {

  let { id, nombre, clasificacion, tipo, descFamGT, conOSinMedioDeContraste, observaciones, idTipoClienteDefault, precios_TipoCliente } = producto

  let string = `
    mutation {
      productosMutation {
        editProducto (
          producto: {
            id: "${id}"
            tipo: "${tipo}"
            clasificacion: "${clasificacion}"
            descFamGT: ${descFamGT}
            nombre: "${nombre}"
            conOSinMedioDeContraste: "${conOSinMedioDeContraste}"
            observaciones: "${observaciones}"
            idTipoClienteDefault: "${idTipoClienteDefault}"
            precios_TipoCliente: [ ${precios_TipoCliente.map( el => `{ idTipoCliente: "${el.idTipoCliente}", precio: ${el.precio} }`)} ]
          }
        ) {
          producto {
            id
            tipo
            clasificacion
            precios_TipoCliente {
              idTipoCliente
              precio
            }
            descFamGT
            nombre
            conOSinMedioDeContraste
            observaciones
            idTipoClienteDefault
          }
          mensaje
        }
      }
    }
  `

  return {
    "query": string
  }

}

export default consulta;