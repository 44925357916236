let consulta = (panel) => {
  console.log(panel)
    let string = `
      mutation {
        productosMutation {
          deletePanel (
            id: "${panel.id}"
          )
        }
      }
    `
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;