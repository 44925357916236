let consulta = () => {
    let string = `
      {
        appInfo {
          usuarios {
            id
            nombre
            apellidos
            telefono
            correo
            foto_perfil
            tipo
            permisos {
                AdminUser {
                  ViewUser
                  CreateUser
                  EditUser
                  DeleteUser
                  UpdateStateMedic
              } 
            }
            medico {
              estado
              id
              numero_colegiado
              NIT
              nombre_clinica
              departamento_clinica
              municipalidad_clinica
              direccion_clinica
              telefono_clinica
              correo_clinica
              especialidad
              sub_especialidad
              atencion_emergencias
              observaciones
              observaciones_emergencias
              social_media {
                fb_link
                ins_link
                web_link
              }
              data_consultas {
                precioNormal
                descuento
                precioFinal
                precioFinalPaciente
                seguro
              }
              data_procedimientos {
                html
              }
              horario_atencion {
                dia
                ingreso
                egreso
              }
            }
            FK_Afiliado
          }
        }
      }
    `
  
    return string
  
  }
  
  export default consulta;