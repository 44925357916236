let consulta = (afiliacion) => {

  const { idsAfiliados, empresaId } = afiliacion

  let string = `
    mutation {
      empresaMutation {
        afiliateToEmpresa (
          idEmpresa: "${empresaId}"
          idsAfiliaciones: [${ idsAfiliados.map( id => `"${id}"` ) }]
        )
      }
    }
  `

  return {
    "query": string
  }

}

export default consulta;