import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
	This.setState({ loaderClassname: 'd-none', errorResponseMessage: 'text-danger font-weight-bold' })
	setTimeout(() => { This.setState({ errorResponseMessage: 'd-none'}) }, 3000);
}

const deleteAnalysis = (This, analysisId) => {

	
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(analysisId)),
		credentials: 'include'
	}
	
	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		
		let filteredAnalysis = This.state.analysis.filter( analysis => analysis.id !== analysisId )
		This.setState({ ...This.state, analysis: filteredAnalysis, DeleteAnalysisValidation: false })

		// return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default deleteAnalysis