import React, { Component } from 'react'
import DasboardComponent from '../../Components/DashboardComponent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope, faArrowCircleLeft, faUserCircle, faColumns } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';

import famgt from '../../../assets/famgt.png'

export default class AdminPerfilView extends Component {

    state = {
        documentModal: false,
        currentDocument: ''
    }
    
    render() {

        let { nombre, apellidos, telefono, correo, foto_perfil, medico } = this.props.usuario
        let clinica_nombre, clinica_departamento, clinica_municipalidad, direccion_clinica, telefono_clinica, correo_clinica, direccion_clinica2, telefono_clinica2, correo_clinica2, direccion_clinica3, telefono_clinica3, correo_clinica3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, horario_atencion
        let precioNormal, descuento, precioFinalPaciente
        let html
        let fb_link, ins_link, web_link
        
        if (medico !== null) {
            clinica_nombre = medico.nombre_clinica
            clinica_departamento = medico.departamento_clinica
            clinica_municipalidad = medico.municipalidad_clinica
            direccion_clinica = medico.direccion_clinica
            telefono_clinica = medico.telefono_clinica
            correo_clinica = medico.correo_clinica
            direccion_clinica2 = medico.direccion_clinica2
            telefono_clinica2 = medico.telefono_clinica2
            correo_clinica2 = medico.correo_clinica2
            direccion_clinica3 = medico.direccion_clinica3
            telefono_clinica3 = medico.telefono_clinica3
            correo_clinica3 = medico.correo_clinica3
            especialidad = medico.especialidad
            sub_especialidad = medico.sub_especialidad
            atencion_emergencias = medico.atencion_emergencias
            observaciones = medico.observaciones
            observaciones_emergencias = medico.observaciones_emergencias
            horario_atencion = medico.horario_atencion
        }

        if (medico.social_media !== null ) {
            fb_link = medico.social_media.fb_link
            ins_link = medico.social_media.ins_link
            web_link = medico.social_media.web_link
        } else {
            fb_link = ''
            ins_link = ''
            web_link = ''
        }

        if (medico.data_consultas !== null) {
            precioNormal = medico.data_consultas.precioNormal
            descuento = medico.data_consultas.descuento
            precioFinalPaciente = medico.data_consultas.precioFinalPaciente
        } else {
            precioNormal = ''
            descuento = ''
            precioFinalPaciente = ''
        }

        if (medico.data_procedimientos !== null) {
            html = medico.data_procedimientos.html
        } else {
            html = ''
        }

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
        
                    <div id="showProfileSelect" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('') }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                    { (foto_perfil !== null && foto_perfil !== undefined) ? <img  src={foto_perfil} alt='medico' style={{ height: '150px', borderRadius: '50%' }}  /> : <FontAwesomeIcon icon={faUserCircle} style={{color: '#5DACD4', fontSize: '100px'}} className='mt-3' /> }
                        <h1>{ nombre } { apellidos }</h1>
                    </div>

                    <div className='mx-auto text-center mt-5 pt-5' >

                        <div className='row' >
                            <div className='col-12 col-md-6 col-lg-3' >
                                <p><span className='font-weight-bold' >Nombres:</span> { nombre } </p>
                            </div>
                            <div className='col-12 col-md-6 col-lg-3' >
                                <p><span className='font-weight-bold' >Apellidos:</span> { apellidos } </p>
                            </div>
                            <div className='col-12 col-md-6 col-lg-3' >
                                <p><span className='font-weight-bold' >Teléfono:</span> { telefono } </p>
                            </div>
                            <div className='col-12 col-md-6 col-lg-3' >
                                <p><span className='font-weight-bold' >Correo:</span> { correo } </p>
                            </div>
                        </div>

                        {
                            medico !== null ?
                                <div>
                                    
                                    <h4 className='py-5 mt-2' >Información de Médico</h4>
                                    
                                    <div className='row' >
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Nombre de la Clínica:</span> { clinica_nombre } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Departamento:</span> { clinica_departamento } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Municipalidad:</span> { clinica_municipalidad } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Especialidad:</span> { especialidad } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Sub-Especialidad:</span> { sub_especialidad } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Atención a Emergencias:</span> { atencion_emergencias ? 'Sí' : 'No' } </p>
                                        </div>
                                    </div>

                                    {
                                        direccion_clinica === null && telefono_clinica === null && correo_clinica === null ? null :
                                        <div>
                                            <h5 className='mt-5 py-4' >Información de la Clínica No. 1</h5>
                                            
                                            <div className='row mt-5' >

                                                {
                                                    direccion_clinica === null ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Dirección de la Clínica:</span> { direccion_clinica } </p>
                                                    </div>
                                                }
                                                {
                                                    telefono_clinica === null ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Teléfono de la Clínica:</span> { telefono_clinica } </p>
                                                    </div>
                                                }
                                                {
                                                    correo_clinica === null ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Correo Electrónico de la Clínica:</span> { correo_clinica } </p>
                                                    </div>
                                                }
                                                
                                            </div>
                                        </div>
                                    }


                                    {
                                        direccion_clinica2 === '' && telefono_clinica2 === '' && correo_clinica2 === '' ? null :
                                        <div>
                                            <h5 className='mt-5 py-4' >Información de la Clínica No. 2</h5>
                                            
                                            <div className='row mt-5' >

                                                {
                                                    direccion_clinica2 === '' ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Dirección de la Clínica:</span> { direccion_clinica2 } </p>
                                                    </div>
                                                }
                                                {
                                                    telefono_clinica2 === '' ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Teléfono de la Clínica:</span> { telefono_clinica2 } </p>
                                                    </div>
                                                }
                                                {
                                                    correo_clinica2 === '' ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Correo Electrónico de la Clínica:</span> { correo_clinica2 } </p>
                                                    </div>
                                                }
                                                
                                            </div>
                                        </div>
                                    }

                                    {
                                        direccion_clinica3 === '' && telefono_clinica3 === '' && correo_clinica3 === '' ? null :
                                        <div>
                                            <h5 className='mt-5 py-4' >Información de la Clínica No. 3</h5>
                                            
                                            <div className='row mt-5' >

                                                {
                                                    direccion_clinica3 === '' ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Dirección de la Clínica:</span> { direccion_clinica3 } </p>
                                                    </div>
                                                }
                                                {
                                                    telefono_clinica3 === '' ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Teléfono de la Clínica:</span> { telefono_clinica3 } </p>
                                                    </div>
                                                }
                                                {
                                                    correo_clinica3 === '' ? null :
                                                    <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                        <p><span className='font-weight-bold' >Correo Electrónico de la Clínica:</span> { correo_clinica3 } </p>
                                                    </div>
                                                }
                                                
                                            </div>
                                        </div>
                                    }
                                    
                                    <div className='row my-5' >
                                        <div className='col-12 col-md-6' >
                                            <p className='font-weight-bold' >Observaciones:</p>
                                            <p>{ observaciones }</p>
                                        </div>
                                        <div className='col-12 col-md-6' >
                                            <p className='font-weight-bold' >Obervaciones de Emergencias:</p>
                                            <p>{ observaciones_emergencias }</p>
                                        </div>
                                    </div>

                                    <h4 className='py-5 mt-2' >Horario de Atención</h4>
                                    
                                    <div className='table-responsive' >
                                        <table className='table text-center table-bordered table-hover table-sm' >
                                            <thead>
                                                <tr>
                                                    {
                                                        horario_atencion !== undefined ? horario_atencion.map( (horario, i) => <td key={i} className='text-capitalize' >{horario.dia}</td> ) : null
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {
                                                        horario_atencion !== undefined ? horario_atencion.map( (horario, i) => 
                                                            (horario.ingreso === '' || horario.egreso === '') ? <td key={i} className='' >-</td> : <td key={i} className='' >{horario.ingreso} - {horario.egreso}</td>
                                                        ) : null
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Contacto */}
                                    {
                                        telefono_clinica || correo_clinica ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Formas de Contacto</h4>

                                            <div className='row col-12 col-md-9 col-lg-6 mx-auto py-5' >
                                                {
                                                    telefono_clinica ?
                                                    <div className='col-6 mx-auto' >
                                                        <a href={`tel:${telefono_clinica}`} title={telefono_clinica} ><FontAwesomeIcon icon={faPhoneAlt} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                                {
                                                    correo_clinica ?
                                                    <div className='col-6 mx-auto' >
                                                        <a href={`mailto:${correo_clinica}`} ><FontAwesomeIcon icon={faEnvelope} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                            </div>
                                        </div> : null
                                    }

                                    {/* Consulta Médica */}
                                    {
                                        medico.data_consultas !== null  ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Consulta Médica</h4>

                                            <div className='py-4 text-center mx-auto' >

                                                <div className='col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3 border rounded shadow-sm p-5 mx-auto' >
                                                    <img src={famgt} style={{ height: '80px' }} alt='familia guatemala' />
                                                    <p>Precio de consulta médica preferencial para afiliados</p>
                                                    <p className='font-weight-bold mb-0' style={{ fontSize: '2em', color: '#21a5d7' }} >Q.{parseFloat(precioFinalPaciente).toFixed(2)} <span className='font-weight-normal text-dark' style={{ fontSize: '.4em' }} >/ consulta*</span></p>
                                                    <p style={{ fontSize: '0.8em' }} >Normalmente <del className='text-danger' >Q.{parseFloat(precioNormal).toFixed(2)}</del></p>
                                                    <p className='text-white bg-success font-weight-bold border border-success py-1 shadow' style={{ borderRadius: '15px' }} >{descuento.toFixed(2) * 100}% <span style={{ fontSize: '.8em' }} >de descuento</span></p>
                                                    <button className='grow btn btn-info mt-3 text-white text-decoration-none' href={`tel:${telefono_clinica}`} title={telefono_clinica} type="button" >Llamar</button>
                                                </div>

                                            </div>

                                        </div> : null
                                    }

                                    {/* Procedimiento Médico */}
                                    {
                                        medico.data_procedimientos !== null  ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Procedimientos</h4>

                                            <div className='col-12 col-md-6 mx-auto  py-4 text-justify border rounded shadow-sm' >
                                                
                                                { parse(html) }
                                                
                                            </div>

                                        </div> : null
                                    }

                                    {/* Medios Sociales */}
                                    {
                                        fb_link || ins_link || web_link ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Sitios de Contacto</h4>

                                            <div className='row col-12 col-md-9 col-lg-6 mx-auto' >
                                                {
                                                    fb_link ?
                                                    <div className='col-4 mx-auto' >
                                                        <a href={fb_link} target="_blank" rel="noopener noreferrer" title='Facebook' ><FontAwesomeIcon icon={['fab', 'facebook']} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                                {
                                                    ins_link ?
                                                    <div className='col-4 mx-auto' >
                                                        <a href={ins_link} target="_blank" rel="noopener noreferrer" title='Instagram' ><FontAwesomeIcon icon={['fab', 'instagram']} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                                {
                                                    web_link ?
                                                    <div className='col-4 mx-auto' >
                                                        <a href={web_link} target="_blank" rel="noopener noreferrer" title='Sitio Oficial' ><FontAwesomeIcon icon={faColumns} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                            </div>
                                        </div> : null
                                    }

                                    
                                </div>
                            : null

                        }


                    </div>
                    


                </div>
            </DasboardComponent>
        )
    }
}
