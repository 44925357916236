import React from 'react';

import './css/MenuCMR.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'mfb/src/mfb.css';

import 'mfb/src/mfb.js';

let MenuCMR = props => {
    return(
        <ul className="mfb-component--br mfb-zoomin" data-mfb-toggle="hover" data-mfb-state="closed">
            <li className="mfb-component__wrap">
            {/*<!-- the main menu button -->*/}
            <span data-mfb-label="Menu" className="mfb-component__button--main">
              {/*<!-- the main button icon visibile by default -->*/}
                    <FontAwesomeIcon className="mfb-component__main-icon--resting" icon={"bars"} style={{'left' : '20px', 'top' : '20px'}} />
              {/*<!-- the main button icon visibile when the user is hovering/interacting with the menu -->*/}
                    <FontAwesomeIcon className="mfb-component__main-icon--active" icon={"times"} style={{'left' : '22px', 'top' : '20px'}} />
            </span>
            <ul className="mfb-component__list">
              {/*<!-- a child button, repeat as many times as needed -->*/}
              <li>
                <a href="tel:50225096969" data-mfb-label="Realizarnos una llamada" className="mfb-component__button--child">
                    <FontAwesomeIcon className="mfb-component__child-icon" icon={"phone"} style={{'left' : '18px', 'top' : '20px'}} />
                </a>
              </li>
            </ul>
          </li>
        </ul>
    )
}

export default MenuCMR;