import React, { Component } from 'react';

import DasboardComponent from '../../Components/DashboardComponent'

import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle, faPhoneAlt, faEnvelope, faColumns } from '@fortawesome/free-solid-svg-icons';

import famgt from '../../../assets/famgt.png'

export default class UserView extends Component {
    
    render() {

        let { nombre, apellidos, correo, foto_perfil, medico } = this.props.currentUser
        let clinica_nombre, clinica_departamento, clinica_municipalidad, clinica_direccion, clinica_telefono, clinica_correo, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, horario_atencion
        let html
        let precioNormal, descuento, precioFinalPaciente
        let fb_link, ins_link, web_link
        
        if (medico !== null) {
            clinica_nombre = medico.nombre_clinica
            clinica_departamento = medico.departamento_clinica
            clinica_municipalidad = medico.municipalidad_clinica
            clinica_direccion = medico.direccion_clinica
            clinica_telefono = medico.telefono_clinica
            clinica_correo = medico.correo_clinica
            especialidad = medico.especialidad
            sub_especialidad = medico.sub_especialidad
            atencion_emergencias = medico.atencion_emergencias
            observaciones = medico.observaciones
            observaciones_emergencias = medico.observaciones_emergencias
            horario_atencion = medico.horario_atencion
        }

        if (medico.social_media !== null ) {
            fb_link = medico.social_media.fb_link
            ins_link = medico.social_media.ins_link
            web_link = medico.social_media.web_link
        } else {
            fb_link = ''
            ins_link = ''
            web_link = ''
        }

        if (medico.data_consultas !== null) {
            precioNormal = medico.data_consultas.precioNormal
            descuento = medico.data_consultas.descuento
            precioFinalPaciente = medico.data_consultas.precioFinalPaciente
        } else {
            precioNormal = ''
            descuento = ''
            precioFinalPaciente = ''
        }

        if (medico.data_procedimientos !== null) {
            html = medico.data_procedimientos.html
        } else {
            html = ''
        }

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
        
                    <div id="showTableButton" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('', {
                        id: '',
                        usuario: '',
                        nombre: '',
                        apellidos: '',
                        correo: '',
                        fecha_de_nacimiento: new Date(),
                        password: '',
                        direccion: '',
                        nacionalidad: 'Guatemala',
                        sexo: '',
                        telefono: 0,
                        tipo: '',
                        dpi: '',
                        foto_perfil: '',
                        permisos: {
                            AdminUser: {
                                ViewUser: false,
                                CreateUser: false,
                                EditUser: false,
                                DeleteUser: false,
                                UpdateStateMedic: false
                            }
                        },
                        colaborador: {
                            id_colaborador: '',
                            nit_colaborador: '',
                            puesto: '',
                            departamento: ''
                        },
                        medico: {
                            estado: 'No Validado',
                            id_medico: '',
                            nit_medico: '',
                            numero_colegiado: '',
                            clinica_nombre: '',
                            clinica_departamento: '',
                            clinica_municipalidad: '',
                            clinica_direccion: '',
                            clinica_telefono: '',
                            clinica_correo: '',
                            especialidad: '',
                            sub_especialidad: '',
                            atencion_emergencias: '',
                            financiero: {
                                cuenta_numero: '',
                                cuenta_nombre: '',
                                cuenta_tipo: '',
                                banco_nombre: '',
                                factura_nombre: '',
                                documentos: {
                                    dpi_front: '',
                                    dpi_back: '',
                                    licencia_sanitaria: '',
                                    patente: '',
                                    recibo_colegiado: '',
                                    titulo_academico: '',
                                    constancia_desechos_solidos: '',
                                    rtu: '',
                                    factura: '',
                                }
                            },
                            horario_atencion: [
                                {
                                    dia: 'lunes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'martes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'miercoles',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'jueves',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'viernes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'sabado',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'domingo',
                                    ingreso: '',
                                    egreso: ''
                                }
                            ]
                            
                        }
                    }) }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                    { (foto_perfil !== null && foto_perfil !== undefined) ? <img  src={foto_perfil} alt='medico' style={{ height: '150px', borderRadius: '50%' }}  /> : <FontAwesomeIcon icon={faUserCircle} style={{color: '#5DACD4', fontSize: '100px'}} className='mt-3' /> }
                        <h1>{ nombre } { apellidos }</h1>
                    </div>

                    <div className='mx-auto text-center mt-5 pt-5' >

                        <div className='row' >
                            <div className='col-12 col-md-6 col-lg-4' >
                                <p><span className='font-weight-bold' >Nombres:</span> { nombre } </p>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4' >
                                <p><span className='font-weight-bold' >Apellidos:</span> { apellidos } </p>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4' >
                                <p><span className='font-weight-bold' >Correo:</span> { correo } </p>
                            </div>
                        </div>

                        {
                            medico !== null ?
                                <div>
                                    
                                    <h4 className='py-5 mt-2' >Información de Médico</h4>
                                    
                                    <div className='row' >
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Nombre de la Clínica:</span> { clinica_nombre } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Dirección de la Clínica:</span> { clinica_direccion } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Departamento:</span> { clinica_departamento } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Municipalidad:</span> { clinica_municipalidad } </p>
                                        </div>
                                        {/* <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Número Telefónico de Clínica:</span> { clinica_telefono } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Correo Electrónico de Clínica:</span> { clinica_correo } </p>
                                        </div> */}
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Especialidad:</span> { especialidad } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Sub-Especialidad:</span> { sub_especialidad } </p>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-3' >
                                            <p><span className='font-weight-bold' >Atención a Emergencias:</span> { atencion_emergencias ? 'Sí' : 'No' } </p>
                                        </div>
                                    </div>
                                    
                                    <div className='row my-4' >
                                        <div className='col-12 col-md-6' >
                                            <p className='font-weight-bold' >Observaciones:</p>
                                            <p>{ observaciones }</p>
                                        </div>
                                        <div className='col-12 col-md-6' >
                                            <p className='font-weight-bold' >Obervaciones de Emergencias:</p>
                                            <p>{ observaciones_emergencias }</p>
                                        </div>
                                    </div>

                                    <h4 className='py-5 mt-2' >Horario de Atención</h4>
                                    
                                    <div className='table-responsive' >
                                        <table className='table text-center table-bordered table-hover table-sm' >
                                            <thead>
                                                <tr>
                                                    {
                                                        horario_atencion !== undefined ? horario_atencion.map( (horario, i) => <td key={i} className='text-capitalize' >{horario.dia}</td> ) : null
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {
                                                        horario_atencion !== undefined ? horario_atencion.map( (horario, i) => 
                                                            (horario.ingreso === '' || horario.egreso === '') ? <td key={i} className='' >-</td> : <td key={i} className='' >{horario.ingreso} - {horario.egreso}</td>
                                                        ) : null
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Contacto */}
                                    {
                                        clinica_telefono || clinica_correo ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Formas de Contacto</h4>

                                            <div className='row col-12 col-md-9 col-lg-6 mx-auto' >
                                                {
                                                    clinica_telefono ?
                                                    <div className='col-6 mx-auto' >
                                                        <a href={`tel:${clinica_telefono}`} title={clinica_telefono} ><FontAwesomeIcon icon={faPhoneAlt} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                                {
                                                    clinica_correo ?
                                                    <div className='col-6 mx-auto' >
                                                        <a href={`mailto:${clinica_correo}`} ><FontAwesomeIcon icon={faEnvelope} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                            </div>
                                        </div> : null
                                    }

                                    {/* Consulta Medica */}
                                    {
                                        medico.data_consultas !== null  ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Consulta Médica</h4>

                                            <div className='py-4 text-center mx-auto' >

                                                <div className='col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3 border rounded shadow-sm p-5 mx-auto' >
                                                    <img src={famgt} style={{ height: '80px' }} alt='familia guatemala' className='img-fluid' />
                                                    <p>Precio de consulta médica preferencial para afiliados</p>
                                                    <p className='font-weight-bold mb-0' style={{ fontSize: '2em', color: '#21a5d7' }} >Q.{parseFloat(precioFinalPaciente).toFixed(2)} <span className='font-weight-normal text-dark' style={{ fontSize: '.4em' }} >/ consulta*</span></p>
                                                    <p style={{ fontSize: '0.8em' }} >Normalmente <del className='text-danger' >Q.{parseFloat(precioNormal).toFixed(2)}</del></p>
                                                    <p className='text-white bg-success font-weight-bold border border-success py-1 shadow' style={{ borderRadius: '15px' }} >{descuento.toFixed(2) * 100}% <span style={{ fontSize: '.8em' }} >de descuento</span></p>
                                                    <button className='grow btn btn-info mt-3' type="button" ><a href={`tel:${clinica_telefono}`} title={clinica_telefono} className='text-white text-decoration-none' >Llamar</a></button>
                                                </div>

                                            </div>

                                        </div> : null
                                    }

                                    {/* Procedimiento Médico */}
                                    {
                                        medico.data_procedimientos !== null  ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Procedimientos</h4>

                                            <div className='col-12 col-md-6 mx-auto  py-4 text-justify border rounded shadow-sm' >
                                                
                                                { parse(html) }

                                            </div>

                                        </div> : null
                                    }

                                    {/* Medios Sociales */}
                                    {
                                        fb_link || ins_link || web_link ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Medios Sociales</h4>

                                            <div className='row col-12 col-md-9 col-lg-6 mx-auto' >
                                                {
                                                    fb_link ?
                                                    <div className='col-4 mx-auto' >
                                                        <a href={fb_link} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={['fab', 'facebook']} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                                {
                                                    ins_link ?
                                                    <div className='col-4 mx-auto' >
                                                        <a href={ins_link} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={['fab', 'instagram']} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                                {
                                                    web_link ?
                                                    <div className='col-4 mx-auto' >
                                                        <a href={web_link} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faColumns} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                    </div> : null
                                                }
                                            </div>
                                        </div> : null
                                    }

                                    
                                </div>
                            : null

                        }


                    </div>
                    


                </div>
            </DasboardComponent>
        )
    }
} 