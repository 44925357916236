import React, { Component } from 'react'
import { connect } from 'react-redux';
import actions from '../../../../../actions';
import DasboardComponent from '../../../Components/DashboardComponent'
import FetchEditClientType from '../Containers/fetchEditTipoCliente/FetchGraphQLEditTipoCliente'
import Loader from '../../../../Components/Loader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


export class ProductEdit extends Component {

    state = {
        fetchEditResponse: 'd-none',
        fetchEditMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        clientType: {
            id: '',
            nombre: ''
        }
    }

    componentDidMount() {

        let { id, nombre } = this.props.currentClientType

        this.setState({ ...this.state, clientType: { ...this.state.clientType, id, nombre } })

    }

    editFormHandler = (e) => {
        
        e.preventDefault()

        FetchEditClientType(this, this.state.clientType)

    }

    onInputChange = (event) => {

        this.setState({ ...this.state, clientType: { ...this.state.clientType, [event.target.id]: event.target.value } })
        
    }

    render() {

        let { id, nombre } = this.state.clientType

        return (
            <DasboardComponent>

                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', { id: '', nombre: '' }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center pb-5' >
                        <h1>Edita este Tipo de Cliente</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <h5 className='text-center' >{id}</h5>

                        <form id='editForm' onSubmit={this.editFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className="form-row">
                                <div className="form-group col-md-6 mx-auto">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input value={nombre} onChange={ this.onInputChange } type="text" className="form-control" id="nombre" required />
                                </div>
                            </div>
                            
                            <button type="submit" className="btn btn-success">Editar</button>

                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchEditResponse} role="alert">
                                {this.state.fetchEditMessage}
                            </div>

                        </form>

                    </div>

                </div>

            </DasboardComponent>
        )
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        editClientType: (clientType) => dispatch (actions.clientTypes.editClientType({ clientType }))
    }
}

export default connect(null, mapDispatchToProps) (ProductEdit)
