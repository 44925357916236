import GraphQLLogIn from './ConsultaGraphQLLogIn.js';
import GraphQLGetLogIn from './ConsultaGraphQLGetLogIn.js';
import GraphQLLogOut from './ConsultaGraphQLLogOut.js';

import actions from '../actions';

/* istanbul ignore next */
async function sessionService(){}

function handleError(err){
	//console.log(`Request failed: ${err}`);
	return err
}

sessionService.logIn = async (url, usuarioData, This, callback) => {

	/* istanbul ignore next */ 
	let promise = new Promise((resolve, reject) => {
		const options = {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(GraphQLLogIn(usuarioData.usuarioOEmail, usuarioData.pass)),
			credentials: 'include'
			//mode: 'cors',
		}

		return fetch(url, options)
		.then(res => res.json())
		.then( (res) => {

			This.props.dispatch(actions.logIn.logIn({ logIn: res.data.auth.logIn }))
			/* istanbul ignore next */ 
			resolve(res)
			
		})
		.catch(err => {
			handleError(err)
		});

	}).then(res => { 
		/* istanbul ignore next */ 
		callback(res)
	})

	let result = await promise; // wait till the promise resolves (*)

    //console.log(result); // "done!"
	/* istanbul ignore next */ 
	return result

	//termina fetch
}

sessionService.getLogIn = (url, This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQLGetLogIn()),
		credentials: 'include'
		//mode: 'cors',
	}

	return fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.props.dispatch(actions.logIn.logIn({ logIn: res.data.auth.getLogIn }))

		return res

	})
	.catch(err => handleError(err));

	//termina fetch
}

sessionService.logOut = async (url, This, callback) => {

	/* istanbul ignore next */ 
	let promise = new Promise((resolve, reject) => {
		const options = {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(GraphQLLogOut()),
			credentials: 'include'
			//mode: 'cors',
		}
		return fetch(url, options)
		.then(res => res.json())
		.then( (res) => {

			This.props.dispatch(actions.logIn.logIn({ logIn: res.data.auth.logOut }))
			/* istanbul ignore next */ 
			resolve(res)

		})
		.catch(err => handleError(err));
		//termina fetch
	}).then(res => {
		/* istanbul ignore next */ 
		callback(res)
	})

	let result = await promise; // wait till the promise resolves (*)

    //console.log(result); // "done!"
	/* istanbul ignore next */ 
	return result

}

export default sessionService;