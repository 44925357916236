import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import actions from '../../../../../actions';

import PanelsTable from '../Components/PanelsTable'
import PanelCreate from '../Components/PanelCreate'
import PanelView from '../Components/PanelView'
import PanelEdit from '../Components/PanelEdit'

import FetchTiposCliente from './fetchGetTiposCliente/FetchGraphQLTipoCliente'
import fetchGraphQLPanels from './fetchGetPanels/FetchGraphQLPanels'
import FetchDeletePanel from './fetchDeletePanel/FetchGraphQLPanels'

export class AdminPaneles extends Component {

    state = {
        display: '',
        currentPanel: '',
        tiposCliente: [],
        fetchedProducts: []
    }

    componentDidMount() {

        FetchTiposCliente(this)
        fetchGraphQLPanels(this)

    }

    handleDisplay = (display, panel) => {
        this.setState({ display, currentPanel: panel })
    }

    handleDeletePanel = (panel) => {
        FetchDeletePanel(this, panel)
    }

    render() {

        if ( this.props.conexion === false ) {
			return <Redirect to='/login' />
		} else {

            if (this.state.display === '') {
                return <PanelsTable usuario={this.props.usuario} panels={this.props.panels} products={this.state.products} handleDisplay={this.handleDisplay} handleDeletePanel={this.handleDeletePanel}/>
            } else if (this.state.display === 'PanelCreate') {
                return <PanelCreate usuario={this.props.usuario} productos={this.state.fetchedProducts} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'PanelView') {
                return <PanelView usuario={this.props.usuario} currentPanel={this.state.currentPanel} tiposCliente={this.state.tiposCliente} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'PanelEdit') {
                return <PanelEdit usuario={this.props.usuario} currentPanel={this.state.currentPanel} productos={this.state.fetchedProducts} handleDisplay={this.handleDisplay} />
            } else {
                return null
            }

        }
    }
}

const mapStateToProps = (state) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        panels: state.appInfo.panelsData.panels
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPanels: (panels) => dispatch (actions.panels.setPanels({ panels }) ),
        deletePanel: (panelId) => dispatch (actions.panels.deletePanel({ panelId }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (AdminPaneles)
