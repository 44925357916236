// JavaScript Document
import GraphQL from './ConsultaGraphQLPanel';
import ProductsGQL from '../fetchGetProducts/ConsultaGraphQLProducts';

import url from '../../../../../../apiService/ApiUrl';

function handleError(err){
	// console.log(`Request failed: ${err}`);
}

const FetchCreatePanel = (This, panel) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(panel)),
		credentials: 'include'
	}

	This.setState({ loaderClassName: 'd-block' })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( async (res) => {

		let panel = res.data.productosMutation.insertPanel
		let message = 'Se ha hecho la inserción de un nuevo tipo de cliente.'

		
		const productsOptions = {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(ProductsGQL()),
			credentials: 'include'
		}
		
		let fetchedProducts = await fetch(url, productsOptions)
		.then(res => res.json())
		.then( (res) => res.data.appInfo.productos )
		.catch(err => console.log(err))
	
		let products = panel.idsProductos.map( productId => { 
			return fetchedProducts.find( product => product.id === productId )
		})

		let newPanel = {
			id: panel.id,
			nombre: panel.nombre,
			productos: products
		}
	
		This.setState({ fetchedProducts })

		This.props.createPanel(newPanel)
		This.setState({ fetchCreateResponse: 'mt-4 col-8 mx-auto alert alert-success', fetchCreateMessage: message, loaderClassName: 'd-none' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchCreateResponse: 'd-none', fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.' })
		}, 1500);

	})
	.catch(err => {
		handleError(err)
		This.setState({ fetchCreateResponse: 'mt-4 col-8 mx-auto alert alert-danger', loaderClassName: 'd-none' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchCreateResponse: 'd-none' })
		}, 1500);
	});

	//termina fetch
}

export default FetchCreatePanel;