let consulta = (data) => {

  let { medicoId, dataConsultas } = data
  let { precioNormal, seguro, precioFinal } = dataConsultas

  const string =
    `mutation {
      userMutation {
        insertDataMedicosConsultas (
          medicoId: "${medicoId}",
          dataConsultas: {
            precioNormal: ${precioNormal}
            seguro: ${seguro}
            precioFinal: ${precioFinal}
          }
        ) {
          precioNormal
          descuento
          precioFinal
          precioFinalPaciente
          seguro
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta