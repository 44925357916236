import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// import actions from '../../../../../actions';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
}

const fetchEspecialidadesMedico = (This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL()),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		const getEspecialidades = res.data.appInfo.medicoInfo.especialidades_medico
		const especialidades = getEspecialidades.map( especialidad => {
			let { id, nombre, tipo } = especialidad
			let newObject = { id, value: nombre, label: nombre, tipo }
			return newObject
		} )
		
		This.setState({ especialidades })

		return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default fetchEspecialidadesMedico