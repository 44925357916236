let consulta = (paneles) => {

  let { nombre, idsProductos } = paneles

  let string = `
    mutation {
      productosMutation {
        insertPanel (
          nombre: "${nombre}"
          idsProductos: [${idsProductos.map(id => `"${id}"`)}]
        ) {
          id
          nombre
          idsProductos
        }
      }
    }
  `

  return {
    "query": string
  }

}

export default consulta;