import React, { Component } from 'react'
import { connect } from 'react-redux';

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'

import Modal from 'react-bootstrap4-modal';
import PDFViewer from '../../../Components/PDFViewer'

import loader from './assets/loader.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faEye, faFileDownload, faFileUpload, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import FetchEditDocuments from '../Containers/FetchEditDocuments/FetchGraphQLEditDocuments';

class AdminPerfilDocs extends Component {

    state = {
        documentModal: false,
        currentDocument: '',
        dpi_front_loaderClassName: 'd-none',
        dpi_back_loaderClassName: 'd-none',
        licencia_sanitaria_loaderClassName: 'd-none',
        patente_loaderClassName: 'd-none',
        recibo_colegiado_loaderClassName: 'd-none',
        titulo_academico_loaderClassName: 'd-none',
        constancia_desechos_solidos_loaderClassName: 'd-none',
        rtu_loaderClassName: 'd-none',
        factura_loaderClassName: 'd-none',
        dpi_front_fileUploader: 'mx-2 text-warning',
        dpi_back_fileUploader: 'mx-2 text-warning',
        licencia_sanitaria_fileUploader: 'mx-2 text-warning',
        patente_fileUploader: 'mx-2 text-warning',
        recibo_colegiado_fileUploader: 'mx-2 text-warning',
        titulo_academico_fileUploader: 'mx-2 text-warning',
        constancia_desechos_solidos_fileUploader: 'mx-2 text-warning',
        rtu_fileUploader: 'mx-2 text-warning',
        factura_fileUploader: 'mx-2 text-warning',
        dpi_front_success: 'd-none',
        dpi_back_success: 'd-none',
        licencia_sanitaria_success: 'd-none',
        patente_success: 'd-none',
        recibo_colegiado_success: 'd-none',
        titulo_academico_success: 'd-none',
        constancia_desechos_solidos_success: 'd-none',
        rtu_success: 'd-none',
        factura_success: 'd-none',
        dpi_front_failure: 'd-none',
        dpi_back_failure: 'd-none',
        licencia_sanitaria_failure: 'd-none',
        patente_failure: 'd-none',
        recibo_colegiado_failure: 'd-none',
        titulo_academico_failure: 'd-none',
        constancia_desechos_solidos_failure: 'd-none',
        rtu_failure: 'd-none',
        factura_failure: 'd-none',
        dpi_front: '',
        dpi_back: '',
        licencia_sanitaria: '',
        patente: '',
        recibo_colegiado: '',
        titulo_academico: '',
        constancia_desechos_solidos: '',
        rtu: '',
        factura: '',
        userDocuments: {
            dpi_front: '',
            dpi_back: '',
            licencia_sanitaria: '',
            patente: '',
            recibo_colegiado: '',
            titulo_academico: '',
            constancia_desechos_solidos: '',
            rtu: '',
            factura: ''
        }
    }

    componentDidMount() {

        let { dpi_front, dpi_back, licencia_sanitaria, patente, recibo_colegiado, titulo_academico, constancia_desechos_solidos, rtu, factura } = this.props.usuario.medico.financiero.documentos
        
        this.setState({
            ...this.state,
            userDocuments: {
                ...this.state.userDocuments,
                dpi_front,
                dpi_back,
                licencia_sanitaria,
                patente,
                recibo_colegiado,
                titulo_academico,
                constancia_desechos_solidos,
                rtu,
                factura
            }
        })
        
    }

    onFileLoad = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        if (event.target.files[0] !== undefined) {
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                files.push(base64)
            };
            // Convert data to base64
            fileReader.readAsDataURL(event.target.files[0]);
            // Onload of file read the file content
            this.setState({ [event.target.id]: files })
        } else {
            this.setState({ [event.target.id]: '' })
        }



    }

    onFileUpload = (tipo, documento) => {

        let idMedico = this.props.usuario.medico.id

        FetchEditDocuments(this, idMedico, tipo, documento)

    }

    render() {

        let usuario = this.props.usuario.usuario

        return (
            <DasboardComponent>
                
                <div className='col-11 mx-auto d-flex flex-column justify-content-center align-items-center py-5' style={{minHeight: '80vh'}} >

                    <div id="showProfileSelect" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('') }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <h4 className='text-center mb-5' >Documentos del Médico</h4>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Documento</th>
                                <th scope="col"></th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                            <tbody>
                                <tr>
                                    <td className='align-middle' >Fotografía de DPI Frontal</td>
                                    <td className='align-middle text-center' ><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="dpi_front" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.dpi_front === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.dpi_front }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.dpi_front === null ? null : <a href={this.state.userDocuments.dpi_front} download={`${usuario}_dpi_front.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.dpi_front === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('dpi_front', this.state.dpi_front) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.dpi_front_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.dpi_front_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.dpi_front_success} title='Carga Exitosa del Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.dpi_front_failure} title='Error al Cargar el Documento' />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Fotografía de DPI Trasero</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="dpi_back" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.dpi_back === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.dpi_back }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.dpi_back === null ? null : <a href={this.state.userDocuments.dpi_back} download={`${usuario}_dpi_back.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.dpi_back === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('dpi_back', this.state.dpi_back) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.dpi_back_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.dpi_back_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.dpi_back_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.dpi_back_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Licencia Sanitaria</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="licencia_sanitaria" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.licencia_sanitaria === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.licencia_sanitaria }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.licencia_sanitaria === null ? null : <a href={this.state.userDocuments.licencia_sanitaria} download={`${usuario}_licencia_sanitaria.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.licencia_sanitaria === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('licencia_sanitaria', this.state.licencia_sanitaria) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.licencia_sanitaria_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.licencia_sanitaria_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.licencia_sanitaria_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.licencia_sanitaria_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Patente de Comercio</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="patente" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.patente === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.patente }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.patente === null ? null : <a href={this.state.userDocuments.patente} download={`${usuario}_patente.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.patente === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('patente', this.state.patente) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.patente_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.patente_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.patente_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.patente_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Recibo de Colegiado</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="recibo_colegiado" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.recibo_colegiado === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.recibo_colegiado }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.recibo_colegiado === null ? null : <a href={this.state.userDocuments.recibo_colegiado} download={`${usuario}_recibo_colegiado.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.recibo_colegiado === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('recibo_colegiado', this.state.recibo_colegiado) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.recibo_colegiado_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.recibo_colegiado_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.recibo_colegiado_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.recibo_colegiado_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Título Académico</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="titulo_academico" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.titulo_academico === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.titulo_academico }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.titulo_academico === null ? null : <a href={this.state.userDocuments.titulo_academico} download={`${usuario}_titulo_academico.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.titulo_academico === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('titulo_academico', this.state.titulo_academico) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.titulo_academico_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.titulo_academico_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.titulo_academico_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.titulo_academico_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Constancia de Desechos Sólidos</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="constancia_desechos_solidos" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.constancia_desechos_solidos === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.constancia_desechos_solidos }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.constancia_desechos_solidos === null ? null : <a href={this.state.userDocuments.constancia_desechos_solidos} download={`${usuario}_constancia_desechos_solidos.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.constancia_desechos_solidos === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('constancia_desechos_solidos', this.state.constancia_desechos_solidos) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.constancia_desechos_solidos_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.constancia_desechos_solidos_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.constancia_desechos_solidos_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.constancia_desechos_solidos_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >RTU</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="rtu" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.rtu === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.rtu }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.rtu === null ? null : <a href={this.state.userDocuments.rtu} download={`${usuario}_rtu.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.rtu === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('rtu', this.state.rtu) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.rtu_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.rtu_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.rtu_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.rtu_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Factura</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="factura" /></td>
                                    <td className='align-middle text-center'>
                                        { this.state.userDocuments.factura === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: this.state.userDocuments.factura }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { this.state.userDocuments.factura === null ? null : <a href={this.state.userDocuments.factura} download={`${usuario}_factura.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.factura === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('factura', this.state.factura) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.factura_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.factura_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.factura_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.factura_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {
                            this.state.currentDocument.length !== 0 ?
                            <Modal id='documentModal' visible={this.state.documentModal} fade onClickBackdrop={ () => this.setState({ documentModal: false, currentDocument: '' })} className='modal' dialogClassName='modal-dialog modal-lg' >
                        
                                <div className="modal-body text-center py-4">
                                    <PDFViewer file={this.state.currentDocument} />
                                </div>

                            </Modal> : null
                        }

                </div>

            </DasboardComponent>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        autoUploadMedicDocument: ( idMedico, tipo, documento ) => dispatch ( actions.users.autoUploadMedicDocument({ idMedico, tipo, documento }) )
    }
}

export default connect(null, mapDispatchToProps) (AdminPerfilDocs)