// JavaScript Document
import GraphQL from './ConsultaGraphQLEditUser';

import url from '../../../../../apiService/ApiUrl';

function handleError(err){
	// console.log(`Request failed: ${err}`);
}

const FetchEditUser = (This, userJSON) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(userJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		//console.log(res)

		let data = res.data.userMutation.editUser

		let usuario = data.usuario
		let message = data.mensaje

		usuario.fecha_de_nacimiento = new Date(parseInt(usuario.fecha_de_nacimiento))

		This.props.editUser(usuario)
		This.setState({ fetchEditResponse: 'mt-4 col-8 mx-auto alert alert-success', fetchEditMessage: message })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchEditResponse: 'd-none', fetchEditMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.' })
		}, 1500);

		return message

	})
	.catch(err => {
		handleError(err)
		This.setState({ fetchEditResponse: 'mt-4 col-8 mx-auto alert alert-danger' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchEditResponse: 'd-none' })
		}, 1500);
	});

	//termina fetch
}

export default FetchEditUser;