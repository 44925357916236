let consulta = () => {

    const string =`
      query {
        appInfo {
          productos {
            id
            tipo
            clasificacion
            precios_TipoCliente {
              idTipoCliente
              precio
            }
            descFamGT
            nombre
            conOSinMedioDeContraste
            observaciones
            idTipoClienteDefault
          }
        }
      }
    `
  
      return {
        "query": string
      }
  
  }
  
  export default consulta