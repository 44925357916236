import React, { Component } from 'react';

import Modal from 'react-bootstrap4-modal';
import PDFViewer from '../../../Components/PDFViewer'

import loader from './assets/loader.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faEye, faFileDownload, faFileUpload, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import DasboardComponent from '../../Components/DashboardComponent'

import fetchUser from '../Containers/FetchGetUser/FetchGraphQLUsuario'
import FetchEditMedicDocuments from '../Containers/FetchEditMedicDocuments/FetchGraphQLEditMedicDocuments';

export default class AddMedicDocuments extends Component {

    state = {
        documentModal: false,
        currentDocument: '',
        dpi_front_loaderClassName: 'd-none',
        dpi_back_loaderClassName: 'd-none',
        licencia_sanitaria_loaderClassName: 'd-none',
        patente_loaderClassName: 'd-none',
        recibo_colegiado_loaderClassName: 'd-none',
        titulo_academico_loaderClassName: 'd-none',
        constancia_desechos_solidos_loaderClassName: 'd-none',
        rtu_loaderClassName: 'd-none',
        factura_loaderClassName: 'd-none',
        dpi_front_fileUploader: 'mx-2 text-warning',
        dpi_back_fileUploader: 'mx-2 text-warning',
        licencia_sanitaria_fileUploader: 'mx-2 text-warning',
        patente_fileUploader: 'mx-2 text-warning',
        recibo_colegiado_fileUploader: 'mx-2 text-warning',
        titulo_academico_fileUploader: 'mx-2 text-warning',
        constancia_desechos_solidos_fileUploader: 'mx-2 text-warning',
        rtu_fileUploader: 'mx-2 text-warning',
        factura_fileUploader: 'mx-2 text-warning',
        dpi_front_success: 'd-none',
        dpi_back_success: 'd-none',
        licencia_sanitaria_success: 'd-none',
        patente_success: 'd-none',
        recibo_colegiado_success: 'd-none',
        titulo_academico_success: 'd-none',
        constancia_desechos_solidos_success: 'd-none',
        rtu_success: 'd-none',
        factura_success: 'd-none',
        dpi_front_failure: 'd-none',
        dpi_back_failure: 'd-none',
        licencia_sanitaria_failure: 'd-none',
        patente_failure: 'd-none',
        recibo_colegiado_failure: 'd-none',
        titulo_academico_failure: 'd-none',
        constancia_desechos_solidos_failure: 'd-none',
        rtu_failure: 'd-none',
        factura_failure: 'd-none',
        dpi_front: '',
        dpi_back: '',
        licencia_sanitaria: '',
        patente: '',
        recibo_colegiado: '',
        titulo_academico: '',
        constancia_desechos_solidos: '',
        rtu: '',
        factura: '',
        usuario: {
            id: '',
            usuario: '',
            nombre: '',
            apellidos: '',
            correo: '',
            fecha_de_nacimiento: '',
            direccion: '',
            nacionalidad: 'Guatemala',
            sexo: '',
            telefono: 0,
            tipo: '',
            DPI: '',
            foto_perfil: '',
            permisos: {
                AdminUser: {
                    ViewUser: false,
                    CreateUser: false,
                    EditUser: false,
                    DeleteUser: false,
                    UpdateStateMedic: false
                }
            },
            colaborador: {
                id_colaborador: '',
                nit_colaborador: '',
                puesto: '',
                departamento: ''
            },
            medico: {
                id_medico: '',
                estado: 'Validado',
                nit_medico: '',
                numero_colegiado: '',
                clinica_nombre: '',
                clinica_departamento: '',
                clinica_municipalidad: '',
                clinica_direccion: '',
                clinica_telefono: '',
                clinica_correo: '',
                clinica_direccion2: '',
                clinica_telefono2: '',
                clinica_correo2: '',
                clinica_direccion3: '',
                clinica_telefono3: '',
                clinica_correo3: '',
                especialidad: '',
                sub_especialidad: '',
                observaciones: '',
                observaciones_emergencias: '',
                atencion_emergencias: false,
                social_media: {
                    fb_link: '',
                    ins_link: '',
                    web_link: ''
                },
                financiero: {
                    cuenta_numero: '',
                    cuenta_nombre: '',
                    cuenta_tipo: '',
                    banco_nombre: '',
                    factura_nombre: '',
                    documentos: {
                        dpi_front: null,
                        dpi_back: null,
                        licencia_sanitaria: null,
                        patente: null,
                        recibo_colegiado: null,
                        titulo_academico: null,
                        constancia_desechos_solidos: null,
                        rtu: null,
                        factura: null
                    }
                },
                horario_atencion: [
                    {
                        dia: 'lunes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'martes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'miercoles',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'jueves',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'viernes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'sabado',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'domingo',
                        ingreso: '',
                        egreso: ''
                    }
                ]
            }
        }
    }

    componentDidMount() {

        fetchUser(this, this.props.currentUser.id)

    }

    onFileLoad = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        if (event.target.files[0] !== undefined) {
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                files.push(base64)
            };
            // Convert data to base64
            fileReader.readAsDataURL(event.target.files[0]);
            // Onload of file read the file content
            this.setState({ [event.target.id]: files })
        } else {
            this.setState({ [event.target.id]: '' })
        }



    }

    onFileUpload = (tipo, documento) => {

        let idMedico = this.state.usuario.medico.id

        FetchEditMedicDocuments(this, idMedico, tipo, documento)

    }
    
    render() {

        let usuario = this.state.usuario.usuario
        let userDocuments = this.state.usuario.medico.financiero.documentos

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
        
                    <div id="showTableButton" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('', {
                        id: '',
                        usuario: '',
                        nombre: '',
                        apellidos: '',
                        correo: '',
                        fecha_de_nacimiento: new Date(),
                        password: '',
                        direccion: '',
                        nacionalidad: 'Guatemala',
                        sexo: '',
                        telefono: 0,
                        tipo: '',
                        dpi: '',
                        foto_perfil: '',
                        permisos: {
                            AdminUser: {
                                ViewUser: false,
                                CreateUser: false,
                                EditUser: false,
                                DeleteUser: false,
                                UpdateStateMedic: false
                            }
                        },
                        colaborador: {
                            id_colaborador: '',
                            nit_colaborador: '',
                            puesto: '',
                            departamento: ''
                        },
                        medico: {
                            estado: 'No Validado',
                            id_medico: '',
                            nit_medico: '',
                            numero_colegiado: '',
                            clinica_nombre: '',
                            clinica_departamento: '',
                            clinica_municipalidad: '',
                            clinica_direccion: '',
                            clinica_telefono: '',
                            clinica_correo: '',
                            clinica_direccion2: '',
                            clinica_telefono2: '',
                            clinica_correo2: '',
                            clinica_direccion3: '',
                            clinica_telefono3: '',
                            clinica_correo3: '',
                            especialidad: '',
                            sub_especialidad: '',
                            observaciones: '',
                            observaciones_emergencias: '',
                            social_media: {
                                fb_link: '',
                                ins_link: '',
                                web_link: ''
                            },
                            atencion_emergencias: '',
                            financiero: {
                                cuenta_numero: '',
                                cuenta_nombre: '',
                                cuenta_tipo: '',
                                banco_nombre: '',
                                factura_nombre: '',
                                documentos: {
                                    dpi_front: '',
                                    dpi_back: '',
                                    licencia_sanitaria: '',
                                    patente: '',
                                    recibo_colegiado: '',
                                    titulo_academico: '',
                                    constancia_desechos_solidos: '',
                                    rtu: '',
                                    factura: '',
                                }
                            },
                            horario_atencion: [
                                {
                                    dia: 'lunes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'martes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'miercoles',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'jueves',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'viernes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'sabado',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'domingo',
                                    ingreso: '',
                                    egreso: ''
                                }
                            ]
                            
                        }
                    }) }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>
        
                    <h4 className='text-center mb-5' >Documentos del Médico</h4>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Documento</th>
                                <th scope="col"></th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                            <tbody>
                                <tr>
                                    <td className='align-middle' >Fotografía de DPI Frontal</td>
                                    <td className='align-middle text-center' ><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="dpi_front" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.dpi_front === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.dpi_front }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.dpi_front === null ? null : <a href={userDocuments.dpi_front} download={`${usuario}_dpi_front.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.dpi_front === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('dpi_front', this.state.dpi_front) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.dpi_front_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.dpi_front_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.dpi_front_success} title='Carga Exitosa del Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.dpi_front_failure} title='Error al Cargar el Documento' />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Fotografía de DPI Trasero</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="dpi_back" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.dpi_back === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.dpi_back }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.dpi_back === null ? null : <a href={userDocuments.dpi_back} download={`${usuario}_dpi_back.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.dpi_back === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('dpi_back', this.state.dpi_back) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.dpi_back_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.dpi_back_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.dpi_back_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.dpi_back_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Licencia Sanitaria</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="licencia_sanitaria" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.licencia_sanitaria === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.licencia_sanitaria }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.licencia_sanitaria === null ? null : <a href={userDocuments.licencia_sanitaria} download={`${usuario}_licencia_sanitaria.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.licencia_sanitaria === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('licencia_sanitaria', this.state.licencia_sanitaria) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.licencia_sanitaria_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.licencia_sanitaria_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.licencia_sanitaria_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.licencia_sanitaria_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Patente de Comercio</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="patente" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.patente === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.patente }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.patente === null ? null : <a href={userDocuments.patente} download={`${usuario}_patente.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.patente === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('patente', this.state.patente) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.patente_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.patente_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.patente_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.patente_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Recibo de Colegiado</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="recibo_colegiado" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.recibo_colegiado === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.recibo_colegiado }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.recibo_colegiado === null ? null : <a href={userDocuments.recibo_colegiado} download={`${usuario}_recibo_colegiado.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.recibo_colegiado === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('recibo_colegiado', this.state.recibo_colegiado) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.recibo_colegiado_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.recibo_colegiado_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.recibo_colegiado_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.recibo_colegiado_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Título Académico</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="titulo_academico" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.titulo_academico === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.titulo_academico }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.titulo_academico === null ? null : <a href={userDocuments.titulo_academico} download={`${usuario}_titulo_academico.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.titulo_academico === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('titulo_academico', this.state.titulo_academico) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.titulo_academico_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.titulo_academico_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.titulo_academico_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.titulo_academico_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Constancia de Desechos Sólidos</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="constancia_desechos_solidos" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.constancia_desechos_solidos === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.constancia_desechos_solidos }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.constancia_desechos_solidos === null ? null : <a href={userDocuments.constancia_desechos_solidos} download={`${usuario}_constancia_desechos_solidos.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.constancia_desechos_solidos === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('constancia_desechos_solidos', this.state.constancia_desechos_solidos) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.constancia_desechos_solidos_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.constancia_desechos_solidos_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.constancia_desechos_solidos_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.constancia_desechos_solidos_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >RTU</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="rtu" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.rtu === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.rtu }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.rtu === null ? null : <a href={userDocuments.rtu} download={`${usuario}_rtu.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.rtu === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('rtu', this.state.rtu) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.rtu_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.rtu_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.rtu_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.rtu_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle' >Factura</td>
                                    <td className='align-middle text-center'><input onChange={ this.onFileLoad } accept=".pdf" data-test-type='input-file' type="file" className="form-control p-1" id="factura" /></td>
                                    <td className='align-middle text-center'>
                                        { userDocuments.factura === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: userDocuments.factura }) } icon={faEye} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Visualizar Documento' /> }
                                        { userDocuments.factura === null ? null : <a href={userDocuments.factura} download={`${usuario}_factura.pdf`} ><FontAwesomeIcon onClick={this.onFileDownload} icon={faFileDownload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-2' title='Descargar Documento' /></a> }
                                        { this.state.factura === '' ? null : <FontAwesomeIcon onClick={ () => this.onFileUpload('factura', this.state.factura) } icon={faFileUpload} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer' }} className={this.state.factura_fileUploader} title='Subir Documento' /> }
                                        <img src={loader} alt='cdm' className={this.state.factura_loaderClassName} style={{ height: '20px' }} />
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'green' }} className={this.state.factura_success} title='Visualizar Documento' />
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '20px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: 'red' }} className={this.state.factura_failure} title='Error al Cargar el Documento' />

                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {
                            this.state.currentDocument.length !== 0 ?
                            <Modal id='documentModal' visible={this.state.documentModal} fade onClickBackdrop={ () => this.setState({ documentModal: false, currentDocument: '' })} className='modal' dialogClassName='modal-dialog modal-lg' >
                        
                                <div className="modal-body text-center py-4">
                                    <PDFViewer file={this.state.currentDocument} />
                                </div>

                            </Modal> : null
                        }
        
                </div>
            </DasboardComponent>
        )
    }
} 