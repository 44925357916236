let consulta = () => {
    let string = `
        {
          appInfo {
            usuarios {
              id
              nombre
              apellidos
              usuario
              correo
              fecha_de_nacimiento
              direccion
              nacionalidad
              sexo
              telefono
              DPI
              tipo
              foto_perfil
              permisos {
                  AdminUser {
                    ViewUser
                    CreateUser
                    EditUser
                    DeleteUser
                    UpdateStateMedic
                } 
              }
              colaborador {
                id
                puesto
                departamento
                NIT
              }
              medico {
                estado
                id
                numero_colegiado
                NIT
                nombre_clinica
                departamento_clinica
                municipalidad_clinica
                direccion_clinica
                telefono_clinica
                correo_clinica
                direccion_clinica2
                telefono_clinica2
                correo_clinica2
                direccion_clinica3
                telefono_clinica3
                correo_clinica3
                especialidad
                sub_especialidad
                atencion_emergencias
                observaciones
                observaciones_emergencias
                social_media {
                  fb_link
                  ins_link
                  web_link
                }
                financiero {
                  cuenta_numero
                  cuenta_nombre
                  cuenta_tipo
                  banco_nombre
                  factura_nombre
                }
                horario_atencion {
                  dia
                  ingreso
                  egreso
                }
                data_consultas {
                  precioNormal
                  descuento
                  precioFinal
                  precioFinalPaciente
                  seguro
                }
                data_procedimientos {
                  html
                }
              }
              FK_Afiliado
              afiliacion {
                id
                fecha_inicio
                fecha_expiracion
                auto_renovacion
                tipo
                principalUser
                FK_Empresa
              }
            }
          }
        }
    `
  
    return string
  
  }
  
  export default consulta;