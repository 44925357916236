let consulta = () => {
  
    const string =`
      query {
        appInfo {
          medicoInfo {
            especialidades_medico {
              id
              nombre
              tipo
            }
          }
        }
      }
    `

    // console.log(string)
  
      return {
        "query": string
      }
  
  }
  
  export default consulta