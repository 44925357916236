import React, { Component } from 'react';

import '../Components/css/Navbar.css';

import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import Modal from 'react-bootstrap4-modal';
import famgt from '../../../assets/famgt.png'

class Navbar extends Component {

    state = {
        registerModal: false
    }

    render(){
        return(
            <nav id="Navbar-container" className="navbar navbar-expand-md navbar-light bg-light fixed-top">
                <NavHashLink exact to="/#inicio" activeClassName="active" className="nav-link">
					<img src="/img/Logo.png" style={{height: '2.5em', paddingLeft: '25px'}} alt="logoimg"/>
				</NavHashLink>

                <button className="navbar-toggler x collapsed ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="sr-only">Toggle navigation</span>
				</button>

                {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>*/}

                <div className="collapse navbar-collapse justify-content-center justify-content-md-end" id="navbarSupportedContent">
                    <ul className="navbar-nav justify-content-center align-items-center">
                        <li className="nav-item text-center text-md-right">
                            <NavHashLink exact to="/#inicio" activeClassName="active" className="nav-link">Inicio</NavHashLink>
                        </li>
                        {/*<li className="nav-item text-center text-md-right">
                            <NavHashLink exact to="/#bienvenido" activeClassName="active" className="nav-link">Bienvenido a CDM</NavHashLink>
                        </li>*/}
                        <li className="nav-item text-center text-md-right">
                            <NavHashLink exact to="/#familia-guatemala" activeClassName="active" className="nav-link grow"><img src={famgt} alt='familia guatemala' style={{ height: '38px' }} /></NavHashLink>
                        </li>
                        <li className="nav-item text-center text-md-right">
                            <NavLink exact to="/examenes" activeClassName="active" className="nav-link">Exámenes</NavLink>
                        </li>
                        <li className="nav-item text-center text-md-right">
                            <NavLink exact to="/medicos" activeClassName="active" className="nav-link" >Médicos Afiliados</NavLink>
                        </li>
                        {/* <li className="nav-item text-center text-md-right">
                            <NavLink exact to="/farmaceutica" activeClassName="active" className="nav-link">Farmaceutica</NavLink>
                        </li> */}
                        <li className="nav-item text-center text-md-right">
                            <span id='registerModal' onClick={ () => this.setState({ registerModal: true }) } className="nav-link" style={{ cursor: 'pointer' }}>Registrarse</span>
                        </li>
                        <li className="nav-item text-center text-md-right">
                            <NavLink exact to="/login" activeClassName="active" className="nav-link">Login</NavLink>
                        </li>
                        {/*<li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Dropdown
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Something else here</a>
                            </div>
                        </li>*/}
                    </ul>
                </div>

                <Modal id='registerModal' visible={this.state.registerModal} fade onClickBackdrop={ () => this.setState({ registerModal: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >
                    
                    <div className="modal-body text-center py-4">
                    <button id='registerClose' type="button" className="close" onClick={ () => this.setState({ registerModal: false }) }>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className='mx-auto col-10 mb-4' >
                        <h5 className='mb-5' >¿Qué tipo de registro deseas realizar?</h5>
                        <NavLink exact to="/register/user" ><button type="button" className="btn btn-info px-5">Usuario</button></NavLink><br/><br/>
                        <NavLink exact to="/register/medico" ><button type="button" className="btn btn-info px-5">Médico</button></NavLink>
                    </div>
                    </div>
    
                </Modal>
                
            </nav>
        )
    }
}

export default Navbar