
import logIn from './logIn';
import appInfo from './appInfo'
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  logIn,
  appInfo
})

export default rootReducer