

let consulta = (idMedico, tipo, documento) => {

    let string = `
      mutation {
        userMutation {
          addDocumentMedico (
            idMedico: "${idMedico}"
            tipo: ${tipo}
            documento: "${documento}"
          ) {
            mensaje
            documento
          }
        }
      }
    `

    return {
      "query": string
    }
  
  }
  
  export default consulta;