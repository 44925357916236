import React, { Component } from 'react'

import DashboardComponent from '../../../Components/DashboardComponent'
import ProductFilter from './ProductFilter'

import Modal from 'react-bootstrap4-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loader from '../../../../Components/Loader'

export class ProductsTable extends Component {

    state = {
        DeleteProductValidation: false,
        nombre: '',
        tipo: '',
        clasificacion: '',
        productId: ''
    }

    setFilter = (type, event) => {
        if ( type === 'set' ) {
            this.setState({ [event.target.id]: event.target.value })
        } else {
            this.setState({ nombre: '', tipo: '', clasificacion: '' })
        }
    }

    render() {

        const productFilter = this.props.products.filter( producto =>
            producto.nombre.toLowerCase().indexOf(this.state.nombre.toLowerCase()) !== -1 &&
            producto.tipo.toLowerCase().indexOf(this.state.tipo.toLowerCase()) !== -1 &&
            producto.clasificacion.toLowerCase().indexOf(this.state.clasificacion.toLowerCase()) !== -1 
        )

        return (

            <DashboardComponent>
                
                <div className="container" style={{marginTop: '50px', minHeight: '75vh'}}>

                    <div className='mb-5' >
                        <ProductFilter setFilter={this.setFilter} products={this.props.products} />
                    </div>

                    <div className="table-responsive"> 
                    {
                        this.props.showLoader !== 'd-none' ? null :
                        this.props.usuario.permisos.AdminUser.CreateUser === true ? (
                        <div className="justify-content-end">
                            <button id="createButton"
                                onClick={() => {
                                let display = 'ProductCreate';
                                this.props.handleDisplay(display)
                                }}
                                type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color' : '#5DACD4'}}>
                                Nuevo Producto <FontAwesomeIcon icon="box" />
                            </button>
                        </div>
                        ) : null
                    }
                    
                    {

                        this.props.showLoader !== 'd-none' ? <div className={this.props.showLoader} ><Loader /></div> :
                        ((this.props.usuario.permisos.AdminUser.ViewUser === true) || (this.props.usuario.permisos.AdminUser.EditUser === true) || (this.props.usuario.permisos.AdminUser.DeleteUser === true)) ? (
                        <table className="table text-center table-bordered table-hover table-sm">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col" className="d-none d-md-table-cell" >Tipo</th>
                                <th scope="col" className="d-none d-lg-table-cell" >Clasificación</th>
                                <th scope="col">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    productFilter.length === 0 ? 
                                    <tr key={1} ><td colSpan="5" className='text-center mx-auto' ><p>No existe ningún elemento en el listado.</p></td></tr> :
                                    productFilter.map( producto => (
                                        <tr key={producto.id}>
                                        <td>{producto.nombre}</td>
                                        <td className="d-none d-md-table-cell" >{producto.tipo}</td>
                                        <td className="d-none d-lg-table-cell" >{producto.clasificacion}</td>
                                        <td>
                                            {
                                            // this.props.producto.permisos.AdminUser.ViewUser === true ? (
                                                <button id="viewButton" type="button" onClick={() => {
                                                    let display = 'ProductView';
                                                    this.props.handleDisplay(display, producto)
                                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}>
                                                <FontAwesomeIcon icon="eye" title='Visualizar Producto' />
                                                </button>
                                            // ) : null
                                            }

                                            {
                                            // this.props.usuario.permisos.AdminUser.EditUser === true ? (
                                                <button id="editButton" type="button" onClick={() => {
                                                    let display = 'ProductEdit';
                                                    this.props.handleDisplay(display, producto);
                                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}>
                                                <FontAwesomeIcon icon="edit" title='Editar Producto' />
                                                </button>
                                            // ) : null
                                            }

                                            {
                                            // this.props.usuario.permisos.AdminUser.DeleteUser === true ? (
                                                <button id="deleteButton" 
                                                    // onClick={ () => { this.props.handleDeleteProduct(producto) }} 
                                                    onClick={ () => { this.setState({ productId: producto.id, DeleteProductValidation: true }) }} 
                                                    type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}
                                                >
                                                <FontAwesomeIcon icon="trash-alt" title='Eliminar Producto' />
                                                </button>
                                                
                                            // ) : null
                                            }

                                        </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        ) : null
                    }
                    </div>

                    <Modal id='DeleteProductValidation' visible={this.state.DeleteProductValidation} fade onClickBackdrop={ () => this.setState({ DeleteProductValidation: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >

                        <div className="modal-body text-center py-4">
                            <button id='registerClose' type="button" className="close" onClick={ () => this.setState({ DeleteProductValidation: false }) }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='mx-auto col-10 mb-4 py-4' >
                                <h5 className='mb-5' >¿En verdad deseas eliminar este producto?</h5>
                                <div className='d-flex justify-content-center align-items-center flex-column' >
                                    <button onClick={ () => { this.props.handleDeleteProduct(this.state.productId); this.setState({ DeleteProductValidation: false }) } } className='btn btn-danger mx-auto mb-4' >Eliminar</button>
                                    <button onClick={ () => { this.setState({ DeleteProductValidation: false }) } } className='btn btn-secondary mx-auto' >Cancelar</button>
                                </div>
                            </div>  
                        </div>
                        
                    </Modal>

                </div>

            </DashboardComponent>
        )
    }
}

export default ProductsTable
