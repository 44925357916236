import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState()

  useEffect(() => {
      setFile(props.file)
  }, [props.file])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div>
        <Document
            className='d-flex justify-content-center'
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
        >
            <Page pageNumber={pageNumber} />
        </Document>

        <div className='d-flex justify-content-center mt-5' >

            <button 
                className='btn btn-light shadow-lg rounded-0'
                style={{ width: '100px' }}
                type="button" 
                disabled={pageNumber <= 1} 
                onClick={previousPage}
            >
                Anterior
            </button>

            <button className='btn btn-light border-0 shadow-lg rounded-0' style={{ cursor: 'auto' }} disabled >{pageNumber} / {numPages}</button>
            <button
                className='btn btn-light shadow-lg rounded-right'
                style={{ width: '100px' }}
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
            >
                Siguiente
            </button>

        </div>

    </div>
  );
};

export default PDFViewer;
