import React, { Component } from 'react'

import DashboardComponent from '../../../Components/DashboardComponent'

import printEl from "print-el";


export class OrdenesVenta extends Component {

    onPrint = () => {
        var content = `
            <div class='py-3' >
                <div class="col-11 col-md-8 col-lg-6 mx-auto text-center py-5 border rounded shadow-lg" >
                    <img src='/img/Logo.png' class='my-5' style="height:75px;" alt='CDM' />
                    <h2>Cotizacion Express</h2>
                    <h3>Tipo de Cliente</h3>
                    <div class='my-5' >
                        <h4>Nombre y Apellido</h4>
                        <h5>Correo</h5>
                        <h5>Telefono</h5>
                    </div>
                    <div class='my-5' >
                        <p>ESTA ES LA COTIZACION</p>
                    </div>
                </div>
            </div>
        `
            
        printEl(content, {
            useGlobalStyle: true,
        });
        return true
    }

    render() {
        return (
            <DashboardComponent>
                
                <div className="container" style={{marginTop: '50px', minHeight: '75vh'}}>

                    <div className='text-center' >
                    
                        <h1>Órdenes de Compra</h1>

                        <div className='mb-5' >
                            
                            <div className='py-4' >
                                Tipo de Usuario
                            </div>
                            
                            <div className='py-4' >
                                Productos Modulo
                            </div>

                            <div className='py-4' >
                                Paneles Modulo
                            </div>

                        </div>
                        
                        <div className='mb-5' >

                            <h3>Método de Pago</h3>
                            

                        </div>

                        <div>

                            <div className='' onClick={ this.onPrint } >
                                Imprimir Cotización Express
                            </div>
                            
                        </div>

                    </div>


                </div>

            </DashboardComponent>
        )
    }
}

export default OrdenesVenta
