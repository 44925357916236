const initialState = {
    products: []
}

let products = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_PRODUCTS':
            return {
                ...state,
                products: action.payload.products
            }
        case 'CREATE_PRODUCT': 
            let newProduct = [ action.payload.product ]
            let stateProducts = [...state.products]
            let newProductsArr = stateProducts.concat(newProduct)
            return {
                ...state,
                products: newProductsArr
            }
        case 'EDIT_PRODUCT': 
            let productIndex = state.products.findIndex(product => product.id === action.payload.product.id)
            let editedArray = [ ...state.products ]
            editedArray[productIndex] = action.payload.product
            return {
                ...state,
                products: editedArray
            }  
        case 'DELETE_PRODUCT': 
            let filteredArray = state.products.filter((product) => product.id !== action.payload.productId)
            return {
                ...state,
                products: filteredArray
            }
        case 'CHANGE_PRODUCT_PRICES': 
            let productoIndex = state.products.findIndex(product => product.id === action.payload.productoId)
            let editedArray1 = [ ...state.products ]
            let pricesEditedProduct = {...editedArray1[productoIndex], precios_TipoCliente: action.payload.precios}
            editedArray1[productoIndex] = pricesEditedProduct 
            return {
                ...state,
                products: editedArray1
            }  
            // break;
        default:
            return state

    }
}
  
  export default products