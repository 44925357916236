import React, { Component } from 'react'
import { connect } from 'react-redux'

import SignaturePad from 'react-signature-canvas';

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'
import Loader from '../../../Components/Loader'

import FetchAffiliateEmpresa from '../containers/FetchAffiliateEmpresa/FetchGQLAffiliateEmpresa'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


class EmpresaAffiliate extends Component {

    state = {
        
        fetchCreateResponse: 'd-none',
        fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        afiliacion: {
            metodo_pago: '',
            cuota: '',
            tipo: '',
            inicio_contrato: '',
            firma_contrato: '',
            texto_contrato: '',
            vencimiento_contrato: '',
            cantidad_afiliados_permitidos: '',
            visitas_medicas_maximas_mes: '',
            FK_Empresa: ''
        }
        
    }

    componentDidMount() {

        this.setState({
            afiliacion: {
                ...this.state.afiliacion,
                metodo_pago: '',
                cuota: '',
                tipo: '',
                inicio_contrato: '',
                firma_contrato: '',
                texto_contrato: '',
                vencimiento_contrato: '',
                cantidad_afiliados_permitidos: '',
                visitas_medicas_maximas_mes: '',
                FK_Empresa: this.props.currentCompany.id
            }
        })

    }

    affiliateFormHandler = (e) => {
        
        e.preventDefault()

        let { metodo_pago, cuota, tipo, inicio_contrato, firma_contrato, texto_contrato, vencimiento_contrato, cantidad_afiliados_permitidos, visitas_medicas_maximas_mes, FK_Empresa } = this.state.afiliacion

        let afiliacion = {
            ...this.state.afiliacion,
            metodo_pago: metodo_pago,
            cuota: tipo === 'Paga_Empresa' ? cuota : null,
            tipo: tipo,
            inicio_contrato: moment(inicio_contrato).format('YYYY-MM-DD'),
            firma_contrato: firma_contrato,
            texto_contrato: texto_contrato,
            vencimiento_contrato: moment(vencimiento_contrato).format('YYYY-MM-DD'),
            cantidad_afiliados_permitidos: cantidad_afiliados_permitidos,
            visitas_medicas_maximas_mes: visitas_medicas_maximas_mes,
            FK_Empresa: FK_Empresa,
        }

        FetchAffiliateEmpresa(this, afiliacion)

    }

    onInputChange = (event) => {

        this.setState({ 
            afiliacion: {
                ...this.state.afiliacion,
                [event.target.id]: event.target.value 
            }
        })
        
    }

    onSignatureEndStroke = () => {

        let firma_contrato = this.firmaEmpresaContrato.getTrimmedCanvas().toDataURL("image/png")
    
        this.setState({ ...this.state, afiliacion: { ...this.state.afiliacion, firma_contrato } })
    
    }

    clearSign = () => {

        this.firmaEmpresaContrato.clear();
    
        this.setState({ ...this.state, afiliacion: { ...this.state.afiliacion, firma_contrato: '' } })
    
    }

    render() {

        let { logo, nombre } = this.props.currentCompany

        return (
            <DasboardComponent >
            
                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {
                            id: '',
                            logo: '',
                            nombre: '',
                            direccion: '',
                            telefono: '',
                            correo: '',
                            NIT: '',
                            FK_Afiliacion: '',
                            metodo_pago: '',
                            tipo: '',
                            inicio_contrato: '',
                            firma_contrato: '',
                            texto_contrato: '',
                            vencimiento_contrato: '',
                            cantidad_afiliados_permitidos: '',
                            visitas_medicas_maximas_mes: ''
                        }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        <div className='mx-auto text-center' >
                            { (logo !== null && logo !== undefined) ? <img  src={logo} alt='medico' style={{ height: '150px' }}  /> : <FontAwesomeIcon icon={'building'} style={{color: '#5DACD4', fontSize: '100px'}} className='mt-3' /> }
                            <h3>{ nombre }</h3>
                        </div>
                        <h1>Afiliar Empresa</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >
                        
                        <form id='createForm' onSubmit={this.affiliateFormHandler} className='mx-auto text-center mt-5' >
                            <div className='form-row' >
                                <div className="form-group col-md-6">
                                    <label htmlFor="metodo_pago">Método de Pago</label>
                                    <select onChange={ this.onInputChange } className="form-control" id="metodo_pago" data-test-type='select' required >
                                        <option value='' >Selecciona una Opción</option>
                                        <option value='Deposito' >Deposito</option>
                                        <option value='Cheque' >Cheque</option>
                                        <option value='Tarjeta' >Tarjeta</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="tipo">Tipo de Afiliación</label>
                                    <select onChange={ this.onInputChange } className="form-control" id="tipo" data-test-type='select' required >
                                        <option value='' >Selecciona una Opción</option>
                                        <option value='Paga_Empresa' >Paga la Empresa</option>
                                        <option value='Paga_Usuario' >Paga el Usuario</option>
                                    </select>
                                </div>
                            </div>

                            {
                                this.state.afiliacion.tipo !== 'Paga_Empresa' ? null : 
                                <div className="form-group col-12 px-0">
                                    <label htmlFor="texto_contrato">Cuota</label>
                                    <input onChange={ this.onInputChange } type="number" value={this.state.afiliacion.cuota} className="form-control" id="cuota" data-test-type='input-number' required />
                                </div>
                            }
                            
                            <div className='form-row' >
                                <div className="form-group col-md-6">
                                    <label htmlFor="cantidad_afiliados_permitidos">Límite de Afiliados</label>
                                    <input onChange={ this.onInputChange } type="number" className="form-control" id="cantidad_afiliados_permitidos" data-test-type='input-number' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="visitas_medicas_maximas_mes">Límite de Visitas Médicas al Mes</label>
                                    <input onChange={ this.onInputChange } type="number" className="form-control" id="visitas_medicas_maximas_mes" data-test-type='input-number' required />
                                </div>
                            </div>

                            <div className='form-row' >
                                <div className="form-group col-md-6">
                                    <label htmlFor="inicio_contrato">Fecha de Inicio de Contrato</label>
                                    <input onChange={ this.onInputChange } type="date" className="form-control" id="inicio_contrato" data-test-type='input-date' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="vencimiento_contrato">Fecha de Vencimiento de Contrato</label>
                                    <input onChange={ this.onInputChange } type="date" className="form-control" id="vencimiento_contrato" data-test-type='input-date' required />
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <SignaturePad 
                                    ref={ ref => this.firmaEmpresaContrato = ref }
                                    canvasProps={{className: "signatureCanvas"}}
                                    onEnd={this.onSignatureEndStroke} 
                                />
                                <p className="text-center">f)__________________________</p>
                                <button id="clearSignButton" type="button" className="btn btn-info" onClick={this.clearSign}>Limpiar Firma</button>
                                <div id="alert-signature" className="alert alert-danger mt-2" role="alert" style={{display: this.state.hideAlertMessage ? 'block' : 'none'}}>
                                    Hace falta la firma. Por favor firma en el recuadro.
                                </div>
                            </div>
                            
                            <div className="form-group col-12">
                                <label htmlFor="texto_contrato">Contenido del Contrato</label>
                                <textarea onChange={ this.onInputChange } type="text" className="form-control" id="texto_contrato" data-test-type='textarea' />
                            </div>
                            
                            <button type="submit" className="btn btn-success mt-3">Crear</button>
                            
                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchCreateResponse} role="alert">
                                {this.state.fetchCreateMessage}
                            </div>

                        </form>

                    </div>

                </div>
            
            </DasboardComponent>
        )
    }
}


export const mapDispatchToProps = dispatch => {
    return {
        insertAfiliacion: ( afiliacion ) => dispatch ( actions.companies.insertAfiliacion({ afiliacion }) )
    }
}

export default connect(null, mapDispatchToProps) (EmpresaAffiliate)