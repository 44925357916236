// JavaScript Document
import GraphQL from './ConsultaGraphQLClientTypes';

import url from '../../../../../../apiService/ApiUrl';

function handleError(err){
	//console.log(`Request failed: ${err}`);
}

const fetchClientTypes = (This) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${GraphQL()}`, options)
	.then(res => res.json())
	.then( (res) => {

        let tiposCliente = res.data.appInfo.tiposCliente

		This.setState({ tiposCliente })

		// return res

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchClientTypes;