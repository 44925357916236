import React, { Component } from 'react'
import { connect } from 'react-redux';

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'

import FetchEditMedic from '../Containers/FetchEditMedic/FetchGraphQLEditMedic'
import fetchCountries from '../../../Dashboard/Containers/FetchCountries/fetchCountries'
import fetchEspecialidadesMedico from '../Containers/FetchEspecialidades/FetchEspecialidadesMedico'
import fetchInsertEspecialidadesMedico from '../Containers/FetchInsertEspecialidades/FetchInsertEspecialidadesMedico'

import CreatableSelect from 'react-select/creatable'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';

class AdminPerfilEdit extends Component {

    state = {
        countries: [],
        guatemala: [],
        currentDepartment: '',
        formMunicipalities: [],
        especialidades: [],
        fetchEditResponse: 'd-none',
        fetchEditMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        usuario: {
            id: '',
            usuario: '',
            nombre: '',
            apellidos: '',
            correo: '',
            fecha_de_nacimiento: '',
            direccion: '',
            nacionalidad: 'Guatemala',
            sexo: '',
            telefono: 0,
            tipo: '',
            DPI: '',
            foto_perfil: '',
            permisos: {
                AdminUser: {
                    ViewUser: false,
                    CreateUser: false,
                    EditUser: false,
                    DeleteUser: false,
                    UpdateStateMedic: false
                }
            },
            medico: {
                id: '',
                estado: '',
                nit_medico: '',
                numero_colegiado: '',
                clinica_nombre: '',
                clinica_departamento: '',
                clinica_municipalidad: '',
                direccion_clinica: '',
                telefono_clinica: '',
                correo_clinica: '',
                direccion_clinica2: '',
                telefono_clinica2: '',
                correo_clinica2: '',
                direccion_clinica3: '',
                telefono_clinica3: '',
                correo_clinica3: '',
                especialidad: '',
                sub_especialidad: '',
                observaciones: '',
                observaciones_emergencias: '',
                atencion_emergencias: false,
                social_media: {
                    fb_link: '',
                    ins_link: '',
                    web_link: ''
                },
                financiero: {
                    cuenta_numero: '',
                    cuenta_nombre: '',
                    cuenta_tipo: '',
                    banco_nombre: '',
                    factura_nombre: '',
                },
                horario_atencion: [
                    {
                        dia: 'lunes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'martes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'miercoles',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'jueves',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'viernes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'sabado',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'domingo',
                        ingreso: '',
                        egreso: ''
                    }
                ]
            }
        }
    }

    async componentDidMount() {

        let { id, usuario, nombre, apellidos, telefono, correo, DPI, fecha_de_nacimiento, sexo, direccion, tipo, nacionalidad, foto_perfil, permisos, medico, suscription } = this.props.usuario
        let ViewUser, CreateUser, EditUser, DeleteUser, UpdateStateMedic
        let estado, id_medico, nit_medico, numero_colegiado, clinica_nombre, clinica_departamento, clinica_municipalidad, direccion_clinica, telefono_clinica, correo_clinica, direccion_clinica2, telefono_clinica2, correo_clinica2, direccion_clinica3, telefono_clinica3, correo_clinica3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, horario_atencion
        let fb_link, ins_link, web_link
        let cuenta_numero, cuenta_nombre, cuenta_tipo, banco_nombre, factura_nombre

        /* istanbul ignore next */
        if (permisos.AdminUser !== null) {
            ViewUser = permisos.AdminUser.ViewUser
            CreateUser = permisos.AdminUser.CreateUser
            EditUser = permisos.AdminUser.EditUser
            DeleteUser = permisos.AdminUser.DeleteUser
            UpdateStateMedic = permisos.AdminUser.UpdateStateMedic
        } else {
            ViewUser = false
            CreateUser = false
            EditUser = false
            DeleteUser = false
            UpdateStateMedic = false
        }

        /* istanbul ignore next */
        if (suscription !== null) {
            suscription = suscription.id
        } else {
            suscription = null
        }

        /* istanbul ignore next */
        if (medico !== null) {
            estado = medico.estado
            id_medico = medico.id
            nit_medico = medico.NIT
            numero_colegiado = medico.numero_colegiado
            clinica_nombre = medico.nombre_clinica
            clinica_departamento = medico.departamento_clinica
            clinica_municipalidad = medico.municipalidad_clinica
            direccion_clinica = medico.direccion_clinica
            telefono_clinica = medico.telefono_clinica
            correo_clinica = medico.correo_clinica
            medico.direccion_clinica2 === '' ? direccion_clinica2 = '' : direccion_clinica2 = medico.direccion_clinica2
            medico.telefono_clinica2 === '' ? telefono_clinica2 = '' : telefono_clinica2 = medico.telefono_clinica2
            medico.correo_clinica2 === '' ? correo_clinica2 = '' : correo_clinica2 = medico.correo_clinica2
            medico.direccion_clinica3 === '' ? direccion_clinica3 = '' : direccion_clinica3 = medico.direccion_clinica3
            medico.telefono_clinica3 === '' ? telefono_clinica3 = '' : telefono_clinica3 = medico.telefono_clinica3
            medico.correo_clinica3 === '' ? correo_clinica3 = '' : correo_clinica3 = medico.correo_clinica3
            especialidad = medico.especialidad
            sub_especialidad = medico.sub_especialidad
            atencion_emergencias = medico.atencion_emergencias
            observaciones = medico.observaciones
            observaciones_emergencias = medico.observaciones_emergencias
            fb_link = medico.social_media.fb_link
            ins_link = medico.social_media.ins_link
            web_link = medico.social_media.web_link
            horario_atencion = medico.horario_atencion
            cuenta_numero = medico.financiero.cuenta_numero
            cuenta_nombre = medico.financiero.cuenta_numero
            cuenta_tipo = medico.financiero.cuenta_tipo
            banco_nombre = medico.financiero.banco_nombre
            factura_nombre = medico.financiero.factura_nombre
        } else {
            id_medico = ''
            nit_medico = ''
            numero_colegiado = ''
            clinica_nombre = ''
            clinica_departamento = ''
            clinica_municipalidad = ''
            direccion_clinica = ''
            telefono_clinica = ''
            correo_clinica = ''
            direccion_clinica2 = ''
            telefono_clinica2 = ''
            correo_clinica2 = ''
            direccion_clinica3 = ''
            telefono_clinica3 = ''
            correo_clinica3 = ''
            especialidad = ''
            sub_especialidad = ''
            atencion_emergencias = ''
            observaciones = ''
            observaciones_emergencias = ''
            fb_link = ''
            ins_link = ''
            web_link = ''
            horario_atencion = [
                {
                    dia: 'lunes',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'martes',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'miercoles',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'jueves',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'viernes',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'sabado',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'domingo',
                    ingreso: '',
                    egreso: ''
                }
            ]
            cuenta_numero = ''
            cuenta_nombre = ''
            cuenta_tipo = ''
            banco_nombre = ''
            factura_nombre = ''
        }

        this.setState({
            ...this.state,
            currentDepartment: clinica_departamento,
            usuario: {
                ...this.state.usuario,
                id: id,
                usuario: usuario,
                nombre: nombre,
                apellidos: apellidos,
                correo: correo,
                fecha_de_nacimiento: fecha_de_nacimiento,
                direccion: direccion,
                nacionalidad: nacionalidad,
                sexo: sexo,
                telefono: telefono,
                tipo: tipo,
                DPI: DPI,
                foto_perfil: foto_perfil,
                FK_Afiliado: suscription,
                permisos: {
                    AdminUser: {
                        ViewUser: ViewUser,
                        CreateUser: CreateUser,
                        EditUser: EditUser,
                        DeleteUser: DeleteUser,
                        UpdateStateMedic: UpdateStateMedic
                    }
                },
                medico: {
                    estado: estado,
                    id: id_medico,
                    nit_medico: nit_medico,
                    numero_colegiado: numero_colegiado,
                    clinica_nombre: clinica_nombre,
                    clinica_departamento: clinica_departamento,
                    clinica_municipalidad: clinica_municipalidad,
                    direccion_clinica: direccion_clinica,
                    telefono_clinica: telefono_clinica,
                    correo_clinica: correo_clinica,
                    direccion_clinica2: direccion_clinica2,
                    telefono_clinica2: telefono_clinica2,
                    correo_clinica2: correo_clinica2,
                    direccion_clinica3: direccion_clinica3,
                    telefono_clinica3: telefono_clinica3,
                    correo_clinica3: correo_clinica3,
                    especialidad: especialidad,
                    sub_especialidad: sub_especialidad,
                    atencion_emergencias: atencion_emergencias,
                    observaciones: observaciones,
                    observaciones_emergencias: observaciones_emergencias,
                    social_media: {
                        fb_link: fb_link,
                        ins_link: ins_link,
                        web_link: web_link
                    },
                    horario_atencion: horario_atencion,
                    financiero: {
                        cuenta_numero: cuenta_numero,
                        cuenta_nombre: cuenta_nombre,
                        cuenta_tipo: cuenta_tipo,
                        banco_nombre: banco_nombre,
                        factura_nombre: factura_nombre,
                    }
                }
            }
        })
        
        let countries = await fetchCountries()
        
        this.setState({ 
            countries
        })
        
        fetchEspecialidadesMedico(this)

        if ( this.state.currentDepartment !== '' ) { this.setState({ formMunicipalities: this.props.guatemala[this.state.currentDepartment] }) }

    }

    creatableSelect = (event, action, tipo) => {

        if (action.action === 'clear') {

            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    medico: {
                        ...this.state.usuario.medico,
                        [tipo]: ''
                    }
                }
            
            })

        } else if (action.action === 'create-option') {

           fetchInsertEspecialidadesMedico(this, event, tipo)

           this.setState({ 
               usuario: {
                   ...this.state.usuario,
                   medico: {
                       ...this.state.usuario.medico,
                       [tipo]: event.label
                   }
               }
            })

        } else {

           this.setState({ 
               usuario: {
                   ...this.state.usuario,
                   medico: {
                       ...this.state.usuario.medico,
                       [tipo]: event.label
                   }
               }
            })

        }

   }

    editFormHandler = (e) => {
        
        e.preventDefault()

        let usuario = {
            ...this.state.usuario,
            fecha_de_nacimiento: moment(this.state.usuario.fecha_de_nacimiento).format('YYYY-MM-DD'),
        }

        let data_consultas = this.props.usuario.medico.data_consultas
        let data_procedimientos = this.props.usuario.medico.data_procedimientos

        // Idealmente, al responder el servidor, debería de enviar el usuario con los nuevos campos de data_consultas y data_procedimientos
        FetchEditMedic(this, usuario, data_consultas, data_procedimientos)

    }

    onInputChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                [event.target.id]: event.target.value 
            }
        })
        
    }

    onInputChangeTipo = (event) => {
        if (event.target.value === 'cliente') {
            this.setState({
                ...this.state,
                usuario: {
                    ...this.state.usuario,
                    tipo: event.target.value,
                    colaborador: {
                        id_colaborador: '',
                        puesto: '',
                        departamento: '',
                        nit_colaborador: ''
                    },
                    medico: {
                        id_medico: '',
                        estado: 'No Validado',
                        nit_medico: '',
                        numero_colegiado: '',
                        clinica_nombre: '',
                        clinica_departamento: '',
                        clinica_municipalidad: '',
                        clinica_direccion: '',
                        clinica_telefono: '',
                        clinica_correo: '',
                        especialidad: '',
                        sub_especialidad: '',
                        atencion_emergencias: false,
                        financiero: {
                            cuenta_numero: '',
                            cuenta_nombre: '',
                            cuenta_tipo: '',
                            banco_nombre: '',
                            factura_nombre: '',
                            documentos: {
                                dpi_front: '',
                                dpi_back: '',
                                licencia_sanitaria: '',
                                patente: '',
                                recibo_colegiado: '',
                                titulo_academico: '',
                                constancia_desechos_solidos: '',
                                rtu: '',
                                factura: '',
                            }
                        },
                        horario_atencion: [
                            {
                                dia: 'lunes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'martes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'miercoles',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'jueves',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'viernes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'sabado',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'domingo',
                                ingreso: '',
                                egreso: ''
                            }
                        ]
                    }
                }
            })
        } else if (event.target.value === 'colaborador') {
            this.setState({
                ...this.state,
                usuario: {
                    ...this.state.usuario,
                    tipo: event.target.value,
                    medico: {
                        id_medico: '',
                        estado: 'No Validado',
                        nit_medico: '',
                        numero_colegiado: '',
                        clinica_nombre: '',
                        clinica_departamento: '',
                        clinica_municipalidad: '',
                        clinica_direccion: '',
                        clinica_telefono: '',
                        clinica_correo: '',
                        especialidad: '',
                        sub_especialidad: '',
                        atencion_emergencias: false,
                        financiero: {
                            cuenta_numero: '',
                            cuenta_nombre: '',
                            cuenta_tipo: '',
                            banco_nombre: '',
                            factura_nombre: '',
                            documentos: {
                                dpi_front: '',
                                dpi_back: '',
                                licencia_sanitaria: '',
                                patente: '',
                                recibo_colegiado: '',
                                titulo_academico: '',
                                constancia_desechos_solidos: '',
                                rtu: '',
                                factura: '',
                            }
                        },
                        horario_atencion: [
                            {
                                dia: 'lunes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'martes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'miercoles',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'jueves',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'viernes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'sabado',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'domingo',
                                ingreso: '',
                                egreso: ''
                            }
                        ]
                    }
                }
            })
        } else if (event.target.value === 'medico') {
            this.setState({
                ...this.state,
                usuario: {
                    ...this.state.usuario,
                    tipo: event.target.value,
                    colaborador: {
                        id_colaborador: '',
                        puesto: '',
                        departamento: '',
                        nit_colaborador: ''
                    }
                }
            })
        } else {
            this.setState({
                ...this.state,
                usuario: {
                    ...this.state.usuario,
                    [event.target.id]: event.target.value,
                }
            })
        }
    }

    onMedicoChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    [event.target.id]: event.target.value
                }
            }
        })
        
    }

    onMedicoSocialMediaChange = (event) => {

        this.setState({ 
            ...this.state,
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    social_media: {
                        ...this.state.usuario.medico.social_media,
                        [event.target.id]: event.target.value
                    }
                }

            }
        })

    }

    onFinancieroChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    financiero: {
                        ...this.state.usuario.medico.financiero,
                        [event.target.id]: event.target.value
                    }
                }
            }
        })

    }

    onFileUpload = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Print data in console
            files.push(base64)
        };
        // Convert data to base64
        fileReader.readAsDataURL(event.target.files[0]);

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                [event.target.id]: files
            }
        })
        
    }

    onHorarioChange = (event, time, i) => {

        let setTime = () => {
            
        let nuevo_horario_atencion = this.state.usuario.medico.horario_atencion.map( 
            
            (horario, index) => { 
                
                let new_horario

                if ( index === i ) {
                    new_horario = {
                        ...horario,
                        [time]: event.target.value
                    }
                } else {
                    new_horario = horario
                }

                return new_horario

            })
            
            return nuevo_horario_atencion

        }

        this.setState({ 
                usuario: {
                ...this.state.usuario,
                medico: {
                        ...this.state.usuario.medico,
                    horario_atencion: setTime()
                }
            }
        })

    }

    onPermisoChange = (event) => {

        /* istanbul ignore next */
        if ( event.target.id === 'CreateUser' ) {
            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.CreateUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'ViewUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.ViewUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'EditUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.EditUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'DeleteUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.DeleteUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'UpdateStateMedic' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.UpdateStateMedic
                        }
                    }
                }
            })

        }

    }

    render() {

        let { usuario, nombre, apellidos, telefono, correo, DPI, fecha_de_nacimiento, sexo, direccion, tipo, nacionalidad, foto_perfil, medico } = this.state.usuario
        let { nit_medico, numero_colegiado, clinica_nombre, clinica_departamento, clinica_municipalidad, direccion_clinica, telefono_clinica, correo_clinica, direccion_clinica2, telefono_clinica2, correo_clinica2, direccion_clinica3, telefono_clinica3, correo_clinica3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, social_media, financiero, horario_atencion } = medico
        let { fb_link, ins_link, web_link } = social_media
        let { cuenta_numero, cuenta_nombre, cuenta_tipo, banco_nombre, factura_nombre } = financiero

        return (
            <DasboardComponent>
                
                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {
                            id: '',
                            usuario: '',
                            nombre: '',
                            apellidos: '',
                            correo: '',
                            fecha_de_nacimiento: new Date(),
                            direccion: '',
                            nacionalidad: '',
                            sexo: '',
                            telefono: 0,
                            tipo: '',
                            dpi: '',
                            foto_perfil: '',
                            permisos: {
                                AdminUser: {
                                    ViewUser: false,
                                    CreateUser: false,
                                    EditUser: false,
                                    DeleteUser: false,
                                },
                                AdminMedic: {
                                    ViewMedic: false,
                                    CreateMedic: false,
                                    EditMedic: false,
                                    UpdateStateMedic: false
                                }
                            },
                            colaborador: {
                                id_colaborador: '',
                                nit_colaborador: '',
                                puesto: '',
                                departamento: ''
                            },
                            medico: {
                                id_medico: '',
                                estado: 'No Validado',
                                nit_medico: '',
                                numero_colegiado: '',
                                clinica_nombre: '',
                                clinica_departamento: '',
                                clinica_municipalidad: '',
                                clinica_direccion: '',
                                clinica_telefono: '',
                                clinica_correo: '',
                                especialidad: '',
                                sub_especialidad: '',
                                atencion_emergencias: '',
                                financiero: {
                                    cuenta_numero: '',
                                    cuenta_nombre: '',
                                    cuenta_tipo: '',
                                    banco_nombre: '',
                                    factura_nombre: '',
                                    
                                },
                                horario_atencion: [
                                    {
                                        dia: 'lunes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'martes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'miercoles',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'jueves',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'viernes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'sabado',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'domingo',
                                        ingreso: '',
                                        egreso: ''
                                    }
                                ]
                            }
                        }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        { foto_perfil !== null ? <img src={foto_perfil} alt={nombre} style={{ height: '150px', borderRadius: '50%' }} /> : <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '80px' }} className='mt-3' /> }<br/><br/>
                        <div className='form-group mx-auto custom-file col-10 col-md-6 col-lg-4 mb-4' >
                            <label htmlFor="foto_perfil" className='custom-file-label'>Fotografía de Perfil</label>
                            <input 
                                onChange={ this.onFileUpload }
                                accept=".png, .jpg, .jpeg"
                                data-test-type='input-file'
                                type="file" className="form-control custom-file-input" id="foto_perfil"
                            />
                        </div>
                        <h1>{ this.props.usuario.nombre } { this.props.usuario.apellidos }</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='editForm' onSubmit={this.editFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="usuario">Usuario</label>
                                    <input value={usuario} onChange={ this.onInputChange } type="text" className="form-control" id="usuario" required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input value={nombre} onChange={ this.onInputChange } type="text" className="form-control" id="nombre" required />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="apellidos">Apellidos</label>
                                    <input value={apellidos} onChange={ this.onInputChange } type="text" className="form-control" id="apellidos" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <input value={telefono} onChange={ this.onInputChange } type="number" className="form-control" id="telefono" data-test-type='input-number' required />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="correo">Correo</label>
                                    <input value={correo} onChange={ this.onInputChange } type="emial" className="form-control" id="correo" data-test-type='input-email' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="dpi">DPI</label>
                                    <input value={DPI} onChange={ this.onInputChange } type="text" className="form-control" id="DPI" data-test-type='input-text' />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="fecha_de_nacimiento">Fecha de Nacimiento</label>
                                    <input value={moment(fecha_de_nacimiento).format('YYYY-MM-DD')} onChange={ this.onInputChange } type="date" className="form-control" id="fecha_de_nacimiento" data-test-type='input-date' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="sexo">Sexo</label>
                                    <select value={sexo} onChange={ this.onInputChange } type="text" className="form-control" id="sexo" data-test-type='input-text' required >
                                        <option value='' >Selecciona un Sexo</option>
                                        <option value='Masculino' >Masculino</option>
                                        <option value='Femenino' >Femenino</option>
                                    </select>

                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="tipo">Tipo de Usuario</label>
                                    <select id="tipo" value={tipo} onChange={ this.onInputChangeTipo } type="text" className="form-control" data-test-type='input-text' required >
                                        <option value='' >Selecciona un Tipo</option>
                                        <option value='cliente' >Cliente</option>
                                        <option value='colaborador' >Colaborador</option>
                                        <option value='medico' >Médico</option>
                                    </select>
                                    
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="nacionalidad">Nacionalidad</label>
                                    {/* <input value={nacionalidad} onChange={ this.onInputChange } type="text" className="form-control" id="nacionalidad" /> */}
                                    { 
                                        this.state.countries.length > 0 ? (
                                            <select id="nacionalidad" className="form-control" placeholder="Escoje tu Nacionalidad" onChange={ this.onInputChange } value={nacionalidad} >
                                                { this.state.countries.map( (country, i) => <option value={country.name} key={i}>{country.name}</option>) }
                                            </select>
                                        ) : (
                                            <select id="nacionalidad" className="form-control" placeholder="Escoje tu Nacionalidad" onChange={ this.onInputChange } value={nacionalidad} >
                                                <option value="Guatemala">Guatemala</option>
                                            </select>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="direccion">Dirección</label>
                                <input value={direccion} onChange={ this.onInputChange } type="text" className="form-control" id="direccion" data-test-type='input-text' />
                            </div>
                            
                            {/* Medico */}
                            <div>
                                {
                                    this.state.usuario.tipo === 'medico' ?
                                        <div className='mt-4' >
                                            <h3>Médico</h3>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="numero_colegiado">Número de Colegiado</label>
                                                    <input 
                                                        value={ numero_colegiado } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="numero_colegiado" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="medicoNIT">Nombre de la Clínica</label>
                                                    <input 
                                                        value={ clinica_nombre } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="clinica_nombre" required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_departamento">Departamento</label>
                                                    <select value={clinica_departamento} onChange={ (e) => { this.onMedicoChange(e); this.setState({ currentDepartment: e.target.value }) } } type="text" className="form-control" id="clinica_departamento" data-test-type='select' required >
                                                        <option value='' >Selecciona un Departamento</option>
                                                        {Object.keys(this.props.guatemala).map( (department, i) => { return <option key={i} value={department} >{department}</option> })}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_municipalidad">Municipalidad</label>
                                                    <select value={clinica_municipalidad} onChange={ this.onMedicoChange } type="text" className="form-control" id="clinica_municipalidad" data-test-type='select' required >
                                                        <option value='' >Selecciona un Municipio</option>
                                                        { this.state.usuario.medico.clinica_departamento !== '' ? this.state.formMunicipalities.map( (municipalidad, i) => <option key={i} value={municipalidad} >{municipalidad}</option> ) : null }
                                                    </select>
                                                </div>
                                            </div>

                                            
                                            <h5 className='text-center mt-4' >Información de Clínica No. 01</h5>

                                            <div className="form-group">
                                                <label htmlFor="direccion_clinica">Dirección de la Clínica</label>
                                                <input 
                                                    value={ direccion_clinica } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="direccion_clinica" required
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="telefono_clinica">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ telefono_clinica } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="telefono_clinica" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="correo_clinica">Correo de la Clínica</label>
                                                    <input 
                                                        value={ correo_clinica } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="correo_clinica" required
                                                    />
                                                </div>
                                            </div>

                                            <h5 className='text-center mt-4' >Información de Clínica No. 02</h5>

                                            <div className="form-group">
                                                <label htmlFor="direccion_clinica2">Dirección de la Clínica</label>
                                                <input 
                                                    value={ direccion_clinica2 } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="direccion_clinica2"
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="telefono_clinica2">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ telefono_clinica2 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="telefono_clinica2"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="correo_clinica2">Correo de la Clínica</label>
                                                    <input 
                                                        value={ correo_clinica2 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="correo_clinica2"
                                                    />
                                                </div>
                                            </div>

                                            <h5 className='text-center mt-4' >Información de Clínica No. 03</h5>

                                            <div className="form-group">
                                                <label htmlFor="direccion_clinica3">Dirección de la Clínica</label>
                                                <input 
                                                    value={ direccion_clinica3 } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="direccion_clinica3"
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="telefono_clinica3">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ telefono_clinica3 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="telefono_clinica3"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="correo_clinica3">Correo de la Clínica</label>
                                                    <input 
                                                        value={ correo_clinica3 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="correo_clinica3"
                                                    />
                                                </div>
                                            </div>

                                            
                                            <div className="form-row mt-4">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="especialidad">Especialidad</label>
                                                    <CreatableSelect
                                                        isClearable
                                                        value={ this.state.especialidades.find(specialty => specialty.label === especialidad )  }
                                                        onChange={ (event, action) => this.creatableSelect(event, action, 'especialidad') }
                                                        options={this.state.especialidades.filter( especialidad => especialidad.tipo === 'especialidad' )}
                                                    />
                                                    
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="sub_especialidad">Sub-Especialidad</label>
                                                    <CreatableSelect
                                                        isClearable
                                                        value={ this.state.especialidades.find(specialty => specialty.label === sub_especialidad )  }
                                                        onChange={ (event, action) => this.creatableSelect(event, action, 'sub_especialidad') }
                                                        options={this.state.especialidades.filter( especialidad => especialidad.tipo === 'sub_especialidad' )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="atencion_emergencias">Atención a Emergencias</label>
                                                    <select value={atencion_emergencias} onChange={ this.onMedicoChange } type="text" className="form-control" id="atencion_emergencias" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='true' >Sí</option>
                                                        <option value='false' >No</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="nit_medico">NIT</label>
                                                    <input 
                                                        value={ nit_medico } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="nit_medico" required
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="fb_link">URL Perfil de Facebook</label>
                                                    <input 
                                                        value={ fb_link } 
                                                        onChange={ this.onMedicoSocialMediaChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="fb_link"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ins_link">URL Perfil de Instagram</label>
                                                    <input 
                                                        value={ ins_link } 
                                                        onChange={ this.onMedicoSocialMediaChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="ins_link"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="web_link">URL del Sitio Oficial del Médico</label>
                                                <input 
                                                    value={ web_link } 
                                                    onChange={ this.onMedicoSocialMediaChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="web_link" required
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="observaciones">Observaciones</label>
                                                <input 
                                                    value={ observaciones } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="observaciones"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="observaciones_emergencias">Observaciones de Emergencias</label>
                                                <input 
                                                    value={ observaciones_emergencias } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="observaciones_emergencias"
                                                />
                                            </div>

                                            <h4>Horarios de Atención</h4>

                                            <div className="form-row mx-auto">

                                                {
                                                    horario_atencion.map( (horario, i) => {

                                                            return (
                                                                <div className="form-group col-md-4 p-3 mx-auto" key={i}>
                                                                    <label htmlFor={`ingreso-${horario.dia}`} className='text-capitalize'>{horario.dia}</label>
                                                                    <select value={horario.ingreso} onChange={ (e) => this.onHorarioChange(e, 'ingreso', i) } type="text" className="form-control" id={`ingreso-${horario.dia}`} data-test-type='select' >
                                                                        <option value='' >Ingreso</option>
                                                                        { this.props.horarios.map( (horario, i) => <option key={i} value={horario} >{horario}</option> ) }
                                                                    </select>
                                                                    <select value={horario.egreso} onChange={ (e) => this.onHorarioChange(e, 'egreso', i) } type="text" className="form-control" id={`egreso-${horario.dia}`} data-test-type='select' >
                                                                        <option value='' >Egreso</option>
                                                                        { this.props.horarios.map( (horario, i) => <option key={i} value={horario} >{horario}</option> ) }
                                                                    </select>
                                                                </div>
                                                            ) 
                                                        }
                                                    )
                                                }
                                                
                                            </div>

                                            <h4>Información Financiera</h4>
                                            
                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="cuenta_numero">Número de Cuenta</label>
                                                    <input 
                                                        value={ cuenta_numero } 
                                                        onChange={ this.onFinancieroChange }
                                                        data-test-type='input-number'
                                                        type="number" className="form-control" id="cuenta_numero" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="cuenta_nombre">Nombre de la Cuenta</label>
                                                    <input 
                                                        value={ cuenta_nombre } 
                                                        onChange={ this.onFinancieroChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="cuenta_nombre" required
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="cuenta_tipo">Tipo de Cuenta</label>
                                                    <select value={cuenta_tipo} onChange={ this.onFinancieroChange } type="text" className="form-control" id="cuenta_tipo" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='Monetario' >Monetario</option>
                                                        <option value='Ahorro' >Ahorro</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="banco_nombre">Nombre del Banco</label>
                                                    <select value={banco_nombre} onChange={ this.onFinancieroChange } type="text" className="form-control" id="banco_nombre" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='Banrural' >Banrural</option>
                                                        <option value='Banco Agromercantil' >Banco Agromercantil</option>
                                                        <option value='BAC' >BAC</option>
                                                        <option value='Banco Industrial' >Banco Industrial</option>
                                                        <option value='GyT Continental' >GyT Continental</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group">
                                                <label htmlFor="factura_nombre">Nombre de Facturación</label>
                                                <input 
                                                    value={ factura_nombre } 
                                                    onChange={ this.onFinancieroChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="factura_nombre" required
                                                />
                                            </div>

                                        </div>
                                    : null
                                    
                                }
                            </div>
                            
                            <button type="submit" className="btn btn-success">Editar</button>

                            <div className={this.state.fetchEditResponse} role="alert">
                                {this.state.fetchEditMessage}
                            </div>

                        </form>

                    </div>

                </div>

            </DasboardComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        guatemala: state.appInfo.publicData.guatemala,
        horarios: state.appInfo.publicData.horarios
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        autoMedicEdit: ( user ) => dispatch ( actions.users.autoMedicEdit({ user }) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (AdminPerfilEdit)