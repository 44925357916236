import * as logIn from './LogIn';
import * as users from './Users';
import * as companies from './Companies'
import * as products from './Products'
import * as panels from './Panels'
import * as clientTypes from './ClientTypes'

const actions = {
    logIn,
    users,
    companies,
    products,
    panels,
    clientTypes
}

export default actions