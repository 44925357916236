import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import actions from '../../../../../actions';

import TiposClienteTable from '../Components/TipoClienteTable'
import TipoClienteCreate from '../Components/TipoClienteCreate'
import TipoClienteEdit from '../Components/TipoClienteEdit'

import fetchGraphQLClientTypes from './fetchGetTiposCliente/FetchGraphQLTipoCliente'
import FetchDeleteClientType from './fetchDeleteTipoCliente/FetchGraphQLDeleteTipoCliente'

export class AdminTiposCliente extends Component {

    state = {
        display: '',
        currentClientType: {
            id: '',
            nombre: ''
        }
    }

    componentDidMount() {

        fetchGraphQLClientTypes(this)

    }

    handleDisplay = (display, clientType) => {
        this.setState({ display, currentClientType: clientType })
    }

    handleDeleteClientType = (clientType) => {
        FetchDeleteClientType(this, clientType)
    }

    render() {

        if ( this.props.conexion === false ) {
			return <Redirect to='/login' />
		} else {

            if (this.state.display === '') {
                return <TiposClienteTable usuario={this.props.usuario} clientTypes={this.props.clientTypes} handleDisplay={this.handleDisplay} handleDeleteClientType={this.handleDeleteClientType}/>
            } else if (this.state.display === 'ClientTypeCreate') {
                return <TipoClienteCreate handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ClientTypeEdit') {
                return <TipoClienteEdit usuario={this.props.usuario} currentClientType={this.state.currentClientType} handleDisplay={this.handleDisplay} />
            } else {
                return null
            }

        }
    }
}

const mapStateToProps = (state) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        clientTypes: state.appInfo.clientTypesData.clientTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setClientTypes: (tiposCliente) => dispatch (actions.clientTypes.setClientTypes({ tiposCliente }) ),
        deleteClientType: (clientTypeId) => dispatch (actions.clientTypes.deleteClientType({ clientTypeId }) ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (AdminTiposCliente)
