import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import DashboardComponent from '../../../Components/DashboardComponent'

export class PanelView extends Component {

    state = {
        defaultClientType: '611d818c875d7d003625bc80',
        tiposClienteDisponibles: []
    }

    componentDidMount() {
        this.setAvailableClientTypes()
    }

    setAvailableClientTypes = () => {

        const tiposClienteDisponibles = this.props.tiposCliente.filter( tipo => {
            let id = tipo.id
            const productos = this.props.currentPanel.productos.map( producto => { 
                let precios_TipoCliente = producto.precios_TipoCliente.filter( el => el.idTipoCliente === id )
                return precios_TipoCliente
            })

            let validation = productos.every(el => el.length > 0)

            return validation === true

        })
        
        this.setState({ defaultClientType: tiposClienteDisponibles[0].id, tiposClienteDisponibles })
    }

    render() {

        let { id, nombre, productos } = this.props.currentPanel

        return (
            <DashboardComponent>

                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
                        
                        <div id="showProfileSelect" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('') }>
                            <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                        </div>

                        <div className='mx-auto text-center mt-5' >
                            <h1>Visualización de Panel</h1>
                        </div>

                        <div className='mx-auto text-center mt-5 pt-5' >

                            <p><span className='font-weight-bold' >ID:</span> { id }</p>
                            <p><span className='font-weight-bold' >Nombre:</span> { nombre }</p>

                            <div className='py-5 col-10 col-md-8 col-lg-6 mx-auto' >
                                <p><span className='font-weight-bold' >Precios Según Tipo de Cliente</span></p>
                                <select onChange={ (event) => this.setState({ defaultClientType: event.target.value }) } className="form-control col-md-6 mx-auto mb-5" id="idTipoClienteDefault" required >
                                        { this.state.tiposClienteDisponibles.map( tipo => <option key={tipo.id} value={tipo.id} >{tipo.nombre}</option> ) }
                                </select>
                                <table className='table text-center table-bordered table-hover table-sm' >
                                    <thead>
                                        <tr>
                                            <th>Producto</th>
                                            <th>Precio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            productos.map( producto => 
                                                <tr key={producto.id} >
                                                    <td>{producto.nombre}</td>
                                                    <td>Q.{ producto.precios_TipoCliente.filter( el => el.idTipoCliente === this.state.defaultClientType )[0].precio.toFixed(2) }</td>
                                                </tr> 
                                            )
                                        }
                                        <tr>
                                            <td className='font-weight-bold' >Total:</td>
                                            <td className='font-weight-bold' >
                                                Q.{ productos.map( product => {
                                                        return product.precios_TipoCliente
                                                        .filter( el => el.idTipoCliente === this.state.defaultClientType )
                                                        .map( el => el.precio )[0]
                                                    }).reduce((partial_sum, a) => partial_sum + a,0).toFixed(2) 
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                </div>

            </DashboardComponent>
        )
    }
}

export default PanelView
