import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Redirect } from 'react-router'
import moment from 'moment'

import CreatableSelect from 'react-select/creatable'
import Loader from '../../../Components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import fetchRegisterMedico from './RegisterMedicoFetch/FetchRegisterMedico'
import fetchCountries from '../../Containers/FetchCountries/fetchCountries'
import fetchEspecialidadesMedico from '../../AdminUser/Containers/FetchEspecialidades/FetchEspecialidadesMedico'
import fetchInsertEspecialidadesMedico from '../../AdminUser/Containers/FetchInsertEspecialidades/FetchInsertEspecialidadesMedico'

class RegisterMedico extends Component {

    state = {
        registerButton: 'btn btn-outline-info mx-auto',
        fetchCreateResponse: 'd-none',
        fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        countries: [],
        guatemala: [],
        formMunicipalities: [],
        especialidades: [],
        formData: {
            guatemala: '',
            horarios: '',
            formMunicipalities: [],
            fetchCreateResponse: 'd-none',
            fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
            usuario: {
                usuario: '',
                nombre: '',
                apellidos: '',
                correo: '',
                fecha_de_nacimiento: '',
                password: '',
                passwordValidator: '',
                direccion: '',
                nacionalidad: 'Guatemala',
                sexo: '',
                telefono: 0,
                tipo: 'medico',
                dpi: '',
                foto_perfil: '',
                permisos: {
                    AdminUser: {
                        ViewUser: false,
                        CreateUser: false,
                        EditUser: false,
                        DeleteUser: false,
                        UpdateStateMedic: false
                    }
                },
                colaborador: {
                    nit_colaborador: '',
                    puesto: '',
                    departamento: ''
                },
                medico: {
                    estado: 'No Validado',
                    nit_medico: '',
                    numero_colegiado: '',
                    clinica_nombre: '',
                    clinica_departamento: '',
                    clinica_municipalidad: '',
                    clinica_direccion1: '',
                    clinica_telefono1: '',
                    clinica_correo1: '',
                    clinica_direccion2: '',
                    clinica_telefono2: '',
                    clinica_correo2: '',
                    clinica_direccion3: '',
                    clinica_telefono3: '',
                    clinica_correo3: '',
                    especialidad: '',
                    sub_especialidad: '',
                    atencion_emergencias: '',
                    observaciones: '',
                    observaciones_emergencias: '',
                    social_media: {
                        fb_link: '',
                        ins_link: '',
                        web_link: ''
                    },
                    financiero: {
                        cuenta_numero: '',
                        cuenta_nombre: '',
                        cuenta_tipo: '',
                        banco_nombre: '',
                        factura_nombre: '',
                        documentos: {
                            dpi_front: '',
                            dpi_back: '',
                            licencia_sanitaria: '',
                            patente: '',
                            recibo_colegiado: '',
                            titulo_academico: '',
                            constancia_desechos_solidos: '',
                            rtu: '',
                            factura: '',
                        }
                    },
                    horario_atencion: [
                        {
                            dia: 'lunes',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'martes',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'miercoles',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'jueves',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'viernes',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'sabado',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'domingo',
                            ingreso: '',
                            egreso: ''
                        }
                    ]
                    
                }
            }
        }
    }

    async componentDidMount() {

        if(this.props.conexion === true){
                return <Redirect to='/dashboard' />
        }

        let countries = await fetchCountries()

        this.setState({ countries, guatemala: this.props.guatemala, horarios: this.props.horarios })

        fetchEspecialidadesMedico(this)
  
    }

    creatableSelect = (event, action, tipo) => {

         if (action.action === 'clear') {
             this.setState({ 
                formData: {
                    ...this.state.formData,
                    usuario: {
                        ...this.state.formData.usuario,
                        medico: {
                            ...this.state.formData.usuario.medico,
                            [tipo]: ''
                        }
                    }
                }
             })
         } else if (action.action === 'create-option') {

            fetchInsertEspecialidadesMedico(this, event, tipo)

            this.setState({ 
                formData: {
                    ...this.state.formData,
                    usuario: {
                        ...this.state.formData.usuario,
                        medico: {
                            ...this.state.formData.usuario.medico,
                            [tipo]: event.label
                        }
                    }
                }
             })

         } else {
            this.setState({ 
                formData: {
                    ...this.state.formData,
                    usuario: {
                        ...this.state.formData.usuario,
                        medico: {
                            ...this.state.formData.usuario.medico,
                            [tipo]: event.label
                        }
                    }
                }
             })
         }

    }

    creatableSelectNew = (event, tipo) => {

        fetchInsertEspecialidadesMedico(this, event, tipo)

    }

    onFileUpload = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Print data in console
            files.push(base64)
        };
        // Convert data to base64
        fileReader.readAsDataURL(event.target.files[0]);

        this.setState({ 
            formData: {
                ...this.state.formData,
                usuario: {
                    ...this.state.formData.usuario,
                    [event.target.id]: files
                }
            }
        })
        
    }

    onInputChange = (event) => {
        this.setState({ 
            ...this.state,
            formData: {
                ...this.state.formData,
                usuario: {
                    ...this.state.formData.usuario,
                    [event.target.id]: event.target.value 
                }
            }
        })
    }

    onMedicoChange = (event) => {

        this.setState({ 
            ...this.state,
            formData: {
                ...this.state.formData,
                usuario: {
                    ...this.state.formData.usuario,
                    medico: {
                        ...this.state.formData.usuario.medico,
                        [event.target.id]: event.target.value
                    }

                }
            }
        })
        
    }

    onMedicoSocialMediaChange = (event) => {

        this.setState({ 
            ...this.state,
            formData: {
                ...this.state.formData,
                usuario: {
                    ...this.state.formData.usuario,
                    medico: {
                        ...this.state.formData.usuario.medico,
                        social_media: {
                            ...this.state.formData.usuario.medico.social_media,
                            [event.target.id]: event.target.value
                        }
                    }

                }
            }
        })

    }

    onHorarioChange = (event, time, i) => {

        let setTime = () => {
            
        let nuevo_horario_atencion = this.state.formData.usuario.medico.horario_atencion.map( 
            
            (horario, index) => { 
                
                let new_horario

                if ( index === i ) {
                    new_horario = {
                        ...horario,
                        [time]: event.target.value
                    }
                } else {
                    new_horario = horario
                }

                return new_horario

            })
            
            return nuevo_horario_atencion

        }

        this.setState({ 
            ...this.state,
            formData: {
                ...this.state.formData,
                usuario: {
                    ...this.state.formData.usuario,
                    medico: {
                        ...this.state.formData.usuario.medico,
                        horario_atencion: setTime()
                    }

                }
            }
        })

    }

    onFinancieroChange = (event) => {

        this.setState({ 
            ...this.state,
            formData: {
                ...this.state.formData,
                usuario: {
                    ...this.state.formData.usuario,
                    medico: {
                        ...this.state.formData.usuario.medico,
                        financiero: {
                            ...this.state.formData.usuario.medico.financiero,
                            [event.target.id]: event.target.value
                        }
                    }
                }
            }
        })

    }

    OnRegisterMedico = (event) => {

        event.preventDefault();

        if(this.state.formData.usuario.password === this.state.formData.usuario.passwordValidator){

            let newMedico = {
                ...this.state.formData.usuario,
                fecha_de_nacimiento: moment(this.state.formData.fecha_de_nacimiento).format('YYYY-MM-DD')
                
            }

            fetchRegisterMedico(this, newMedico)
            
        }
        

    }

    render() {

        let { usuario, nombre, apellidos, telefono, correo, dpi, fecha_de_nacimiento, sexo, password, passwordValidator, direccion, nacionalidad, medico } = this.state.formData.usuario
        let { nit_medico, numero_colegiado, clinica_nombre, clinica_departamento, clinica_municipalidad, clinica_direccion1, clinica_telefono1, clinica_correo1, clinica_direccion2, clinica_telefono2, clinica_correo2, clinica_direccion3, clinica_telefono3, clinica_correo3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, social_media, financiero, horario_atencion } = medico
        let {  fb_link, ins_link, web_link } = social_media
        let { cuenta_numero, cuenta_nombre, cuenta_tipo, banco_nombre, factura_nombre } = financiero

        if (this.props.conexion === true) {
            return <Redirect to='/dashboard' />
        } else {
            return (
                <div>
                    <div id="RegisterMedicoContainer">
                        <nav id="navLogIn" className="navbar navbar-expand fixed-top shadow-sm">
        
                            <NavLink exact to="/#home" activeClassName="active" className="nav-link">
                                <img src="/img/Logo.png" style={{height: '2.5em', paddingLeft: '25px'}} alt="logoimg"/>
                            </NavLink>
        
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
    
                            <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
                                <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <NavLink exact to="/" activeClassName="active" className="nav-link" >Inicio</NavLink>
                                </li>
                                </ul>
                            </div>
    
                        </nav>
    
                        <div className="d-flex align-items-center">
                            <div className="container"  style={{marginTop: '50px'}}>
                                <div className="row">
                                    <div className="col-12 mx-auto">
                                        <div className="card card-register-user mx-auto mt-5">
                                            <div className="card-header text-center">
                                                <h3>Registro de Médico</h3>
                                            </div>
                                            <div className='mx-auto text-center' >
                                                <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '80px' }} className='mt-3' /><br /><br />
                                                <div className='form-group mx-auto col-10' >
                                                    <label htmlFor="foto_perfil" className='' >Fotografía de Perfil</label>
                                                    <input 
                                                        onChange={ this.onFileUpload }
                                                        accept=".png, .jpg, .jpeg"
                                                        data-test-type='input-file'
                                                        type="file" className="form-control p-1" id="foto_perfil"
                                                    />
                                                </div>
                                            </div>

                                            <div className="card-body">
                                            
                                                <form onSubmit={this.OnRegisterMedico}>
                                                    <div className='mt-4' >
    
                                                        <h4 className='text-center' >Datos Generales</h4>
    
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="nombre">Nombre</label>
                                                                <input 
                                                                    value={nombre} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="nombre" 
                                                                    placeholder='Escribe tu Nombre' 
                                                                    data-test-type='input-text'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="nombre">Apellidos</label>
                                                                <input 
                                                                    value={apellidos} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="apellidos" 
                                                                    placeholder='Escribe tus Apellidos' 
                                                                    data-test-type='input-text'
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="usuario">Usuario</label>
                                                                <input 
                                                                    value={usuario} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="usuario" 
                                                                    placeholder='Escribe tu Usuario' 
                                                                    data-test-type='input-text'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="correo">Correo Electrónico</label>
                                                                <input 
                                                                    value={correo} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="email" 
                                                                    className="form-control" 
                                                                    id="correo" 
                                                                    placeholder='Escribe tu Dirección de Correo Electrónico' 
                                                                    data-test-type='input-text'
                                                                    required
                                                                />
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="fecha_de_nacimiento">Fecha de Nacimiento</label>
                                                                <input 
                                                                    value={fecha_de_nacimiento} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="date" 
                                                                    className="form-control" 
                                                                    id="fecha_de_nacimiento"          
                                                                    data-test-type='input-date'                                                           
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                            <label htmlFor="direccion">Dirección</label>
                                                                <input 
                                                                    value={direccion} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="direccion" 
                                                                    placeholder='Escribe tu Dirección' 
                                                                    data-test-type='input-text'
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="nacionalidad">Nacionalidad</label>
                                                                { 
                                                                    this.state.countries.length > 0 ? (
                                                                        <select id="nacionalidad" className="form-control" placeholder="Escoje tu Nacionalidad" onChange={ this.onInputChange } value={nacionalidad} data-test-type='select' >
                                                                            { this.state.countries.map( (country, i) => <option value={country.name} key={i}>{country.name}</option>) }
                                                                        </select>
                                                                    ) : (
                                                                        <select id="nacionalidad" className="form-control" placeholder="Escoje tu Nacionalidad" onChange={ this.onInputChange } value={nacionalidad} data-test-type='select' >
                                                                            <option value="Guatemala">Guatemala</option>
                                                                        </select>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="sexo">Sexo</label>    
                                                                <select value={sexo} onChange={ this.onInputChange } type="text" className="form-control" id="sexo" data-test-type='select' required >
                                                                    <option value='' >Selecciona un Sexo</option>
                                                                    <option value='Masculino' >Masculino</option>
                                                                    <option value='Femenino' >Femenino</option>
                                                                </select>
                                                            </div>
    
                                                        </div>
                                                        
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="telefono">Teléfono</label>
                                                                <input 
                                                                    value={telefono} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    id="telefono" 
                                                                    placeholder='Escribe tu Teléfono' 
                                                                    data-test-type='input-number'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="dpi">DPI</label>
                                                                <input 
                                                                    value={dpi} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="dpi" 
                                                                    placeholder='Escribe tu DPI' 
                                                                    data-test-type='input-text'
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="password">Contraseña</label>
                                                                <input 
                                                                    value={password} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="password" 
                                                                    className="form-control" 
                                                                    id="password" 
                                                                    placeholder='Escribe tu Contraseña' 
                                                                    data-test-type='input-password'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="repiteTuPassword"> Repite tu Contraseña</label>
                                                                <input 
                                                                    value={passwordValidator} 
                                                                    onChange={ this.onInputChange } 
                                                                    type="password" 
                                                                    className="form-control" 
                                                                    id="passwordValidator" 
                                                                    placeholder='Repite tu Contraseña' 
                                                                    data-test-type='input-text'
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className='mt-4' >
                                                
                                                        <h4 className='text-center' >Datos de Médico</h4>
                                                        
                                                        <div className="form-row">
                                                            <div className='form-group col-md-6' >
                                                                <label htmlFor="numero_colegiado">Número de Colegiado</label>
                                                                <input 
                                                                    value={ numero_colegiado } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="text" className="form-control" id="numero_colegiado" required
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="medicoNIT">Nombre de la Clínica</label>
                                                                <input 
                                                                    value={ clinica_nombre } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-number'
                                                                    type="text" className="form-control" id="clinica_nombre" required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-row">
                                                            <div className='form-group col-md-6' >
                                                                <label htmlFor="clinica_departamento">Departamento</label>
                                                                <select value={clinica_departamento} onChange={ (e) => { this.onMedicoChange(e); this.setState({ formMunicipalities: this.props.guatemala[e.target.value] }) } } type="text" className="form-control" id="clinica_departamento" data-test-type='select' required >
                                                                    <option value='' >Selecciona un Departamento</option>
                                                                    {Object.keys(this.props.guatemala).map( (department, i) => { return <option key={i} value={department} >{department}</option> })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="clinica_municipalidad">Municipalidad</label>
                                                                <select value={clinica_municipalidad} onChange={ this.onMedicoChange } type="text" className="form-control" id="clinica_municipalidad" data-test-type='select' required >
                                                                    <option value='' >Selecciona un Municipio</option>
                                                                    { this.state.formData.usuario.medico.clinica_departamento !== '' ? this.state.formMunicipalities.map( (municipalidad, i) => <option key={i} value={municipalidad} >{municipalidad}</option> ) : null }
                                                                </select>
                                                            </div>
                                                        </div>


                                                        <h5 className='text-center mt-4' >Información de Clínica No. 01</h5>

                                                        <div className="form-group">
                                                            <label htmlFor="clinica_direccion1">Dirección de la Clínica</label>
                                                            <input 
                                                                value={ clinica_direccion1 } 
                                                                onChange={ this.onMedicoChange }
                                                                data-test-type='input-text'
                                                                type="text" className="form-control" id="clinica_direccion1" required
                                                            />
                                                        </div>

                                                        <div className="form-row">
                                                            <div className='form-group col-md-6' >
                                                                <label htmlFor="clinica_telefono1">Número Telefónico de la Clinica</label>
                                                                <input 
                                                                    value={ clinica_telefono1 } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="number" className="form-control" id="clinica_telefono1" required
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="clinica_correo1">Correo de la Clínica</label>
                                                                <input 
                                                                    value={ clinica_correo1 } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="email" className="form-control" id="clinica_correo1" required
                                                                />
                                                            </div>
                                                        </div>

                                                        <h5 className='text-center mt-4' >Información de Clínica No. 02</h5>

                                                        <div className="form-group">
                                                            <label htmlFor="clinica_direccion2">Dirección de la Clínica</label>
                                                            <input 
                                                                value={ clinica_direccion2 } 
                                                                onChange={ this.onMedicoChange }
                                                                data-test-type='input-text'
                                                                type="text" className="form-control" id="clinica_direccion2"
                                                            />
                                                        </div>

                                                        <div className="form-row">
                                                            <div className='form-group col-md-6' >
                                                                <label htmlFor="clinica_telefono2">Número Telefónico de la Clinica</label>
                                                                <input 
                                                                    value={ clinica_telefono2 } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="number" className="form-control" id="clinica_telefono2"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="clinica_correo2">Correo de la Clínica</label>
                                                                <input 
                                                                    value={ clinica_correo2 } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="email" className="form-control" id="clinica_correo2"
                                                                />
                                                            </div>
                                                        </div>

                                                        <h5 className='text-center mt-4' >Información de Clínica No. 03</h5>

                                                        <div className="form-group">
                                                            <label htmlFor="clinica_direccion3">Dirección de la Clínica</label>
                                                            <input 
                                                                value={ clinica_direccion3 } 
                                                                onChange={ this.onMedicoChange }
                                                                data-test-type='input-text'
                                                                type="text" className="form-control" id="clinica_direccion3"
                                                            />
                                                        </div>

                                                        <div className="form-row">
                                                            <div className='form-group col-md-6' >
                                                                <label htmlFor="clinica_telefono3">Número Telefónico de la Clinica</label>
                                                                <input 
                                                                    value={ clinica_telefono3 } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="number" className="form-control" id="clinica_telefono3"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="clinica_correo3">Correo de la Clínica</label>
                                                                <input 
                                                                    value={ clinica_correo3 } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="email" className="form-control" id="clinica_correo3"
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="form-row mt-4">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="especialidad">Especialidad</label>
                                                                {/* <input 
                                                                    value={ especialidad } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="text" className="form-control" id="especialidad" required
                                                                /> */}
                                                                <CreatableSelect
                                                                    isClearable
                                                                    value={ this.state.especialidades.find(specialty => specialty.label === especialidad )  }
                                                                    onChange={ (event, action) => this.creatableSelect(event, action, 'especialidad') }
                                                                    options={this.state.especialidades.filter( especialidad => especialidad.tipo === 'especialidad' )}
                                                                />
                                                                
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="sub_especialidad">Sub-Especialidad</label>
                                                                {/* <input 
                                                                    value={ sub_especialidad } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="text" className="form-control" id="sub_especialidad" required
                                                                /> */}
                                                                <CreatableSelect
                                                                    isClearable
                                                                    value={ this.state.especialidades.find(specialty => specialty.label === sub_especialidad )  }
                                                                    onChange={ (event, action) => this.creatableSelect(event, action, 'sub_especialidad') }
                                                                    options={this.state.especialidades.filter( especialidad => especialidad.tipo === 'sub_especialidad' )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="atencion_emergencias">Atención a Emergencias</label>
                                                                <select value={atencion_emergencias} onChange={ this.onMedicoChange } type="text" className="form-control" id="atencion_emergencias" data-test-type='select' required >
                                                                    <option value='' >Selecciona una Respuesta</option>
                                                                    <option value='true' >Sí</option>
                                                                    <option value='false' >No</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="nit_medico">NIT</label>
                                                                <input 
                                                                    value={ nit_medico } 
                                                                    onChange={ this.onMedicoChange }
                                                                    data-test-type='input-text'
                                                                    type="text" className="form-control" id="nit_medico" required
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="fb_link">URL Perfil de Facebook</label>
                                                                <input 
                                                                    value={ fb_link } 
                                                                    onChange={ this.onMedicoSocialMediaChange }
                                                                    data-test-type='input-text'
                                                                    type="text" className="form-control" id="fb_link"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="ins_link">URL Perfil de Instagram</label>
                                                                <input 
                                                                    value={ ins_link } 
                                                                    onChange={ this.onMedicoSocialMediaChange }
                                                                    data-test-type='input-text'
                                                                    type="text" className="form-control" id="ins_link"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="web_link">URL del Sitio Oficial del Médico</label>
                                                            <input 
                                                                value={ web_link } 
                                                                onChange={ this.onMedicoSocialMediaChange }
                                                                data-test-type='input-text'
                                                                type="text" className="form-control" id="web_link"
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="observaciones">Observaciones</label>
                                                            <input 
                                                                value={ observaciones } 
                                                                onChange={ this.onMedicoChange }
                                                                data-test-type='input-text'
                                                                type="text" className="form-control" id="observaciones"
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="observaciones_emergencias">Observaciones de Emergencias</label>
                                                            <input 
                                                                value={ observaciones_emergencias } 
                                                                onChange={ this.onMedicoChange }
                                                                data-test-type='input-text'
                                                                type="text" className="form-control" id="observaciones_emergencias"
                                                            />
                                                        </div>
                                                        
                                                        <h4>Horarios de Atención</h4>

                                                        <div className="form-row mx-auto">

                                                            {
                                                                horario_atencion.map( (horario, i) => {

                                                                        return (
                                                                            <div className="form-group col-md-4 p-3 mx-auto" key={i}>
                                                                                <label htmlFor={`ingreso-${horario.dia}`} className='text-capitalize'>{horario.dia}</label>
                                                                                <select value={horario.ingreso} onChange={ (e) => this.onHorarioChange(e, 'ingreso', i) } type="text" className="form-control" id={`ingreso-${horario.dia}`} data-test-type='select' >
                                                                                    <option value='' >Ingreso</option>
                                                                                    { this.props.horarios.map( (horario, i) => <option key={i} value={horario} >{horario}</option> ) }
                                                                                </select>
                                                                                <select value={horario.egreso} onChange={ (e) => this.onHorarioChange(e, 'egreso', i) } type="text" className="form-control" id={`egreso-${horario.dia}`} data-test-type='select' >
                                                                                    <option value='' >Egreso</option>
                                                                                    { this.props.horarios.map( (horario, i) => <option key={i} value={horario} >{horario}</option> ) }
                                                                                </select>
                                                                            </div>
                                                                        ) 
                                                                    }
                                                                )
                                                            }
                                                            
                                                        </div>

                                                        <h4 className='text-center' >Información Financiera</h4>
                                                        
                                                        <div className="form-row">
                                                            <div className='form-group col-md-6' >
                                                                <label htmlFor="cuenta_numero">Número de Cuenta</label>
                                                                <input 
                                                                    value={ cuenta_numero } 
                                                                    onChange={ this.onFinancieroChange }
                                                                    data-test-type='input-text'
                                                                    type="text" className="form-control" id="cuenta_numero" required
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="cuenta_nombre">Nombre de la Cuenta</label>
                                                                <input 
                                                                    value={ cuenta_nombre } 
                                                                    onChange={ this.onFinancieroChange }
                                                                    data-test-type='input-number'
                                                                    type="text" className="form-control" id="cuenta_nombre" required
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-row">
                                                            <div className='form-group col-md-6' >
                                                                <label htmlFor="cuenta_tipo">Tipo de Cuenta</label>
                                                                <select value={cuenta_tipo} onChange={ this.onFinancieroChange } type="text" className="form-control" id="cuenta_tipo" data-test-type='select' required >
                                                                    <option value='' >Selecciona una Respuesta</option>
                                                                    <option value='Monetario' >Monetario</option>
                                                                    <option value='Ahorro' >Ahorro</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="banco_nombre">Nombre del Banco</label>
                                                                <select value={banco_nombre} onChange={ this.onFinancieroChange } type="text" className="form-control" id="banco_nombre" data-test-type='select' required >
                                                                    <option value='' >Selecciona una Respuesta</option>
                                                                    <option value='BAC' >BAC</option>
                                                                    <option value='Banrural' >Banrural</option>
                                                                    <option value='Banco Promérica' >Banco Promérica</option>
                                                                    <option value='Banco Agromercantil' >Banco Agromercantil</option>
                                                                    <option value='Banco Industrial' >Banco Industrial</option>
                                                                    <option value='GyT Continental' >GyT Continental</option>
                                                                    <option value='Interbanco' >Interbanco</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-group">
                                                            <label htmlFor="factura_nombre">Nombre de Facturación</label>
                                                            <input 
                                                                value={ factura_nombre } 
                                                                onChange={ this.onFinancieroChange }
                                                                data-test-type='input-text'
                                                                type="text" className="form-control" id="factura_nombre" required
                                                            />
                                                        </div>

                                                    </div>
    
                                                    <div className="form-group col-12">
                                                        <div className="row">
                                                            <button id="RegisterMedicoButton" className={this.state.registerButton} type="submit">Registrar</button>
                                                        </div>
                                                    </div> 

                                                    {
                                                        (this.state.formData.password !== this.state.formData.passwordValidator) ? <div id="incorrect-data" style={{'marginTop': '15px', 'transition': 'all 0.5s ease-in-out'}} className="alert alert-danger mx-auto" role="alert">Las contraseñas no coinciden.</div> : null

                                                    }

                                                    <div className={this.state.fetchCreateResponse} role="alert">
                                                        {this.state.fetchCreateMessage}
                                                    </div>

                                                    <div className={this.state.loaderClassName} >
                                                        <Loader />
                                                    </div>
    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }
}

let mapStateToProps = (state) => {
    return {
        conexion: state.logIn.conexion,
        guatemala: state.appInfo.publicData.guatemala,
        horarios: state.appInfo.publicData.horarios
    }
}

export default connect(mapStateToProps)(RegisterMedico)