// JavaScript Document
import GraphQL from './ConsultaGraphQLMedicState';

import url from '../../../../../apiService/ApiUrl';

function handleError(err){
	// console.log(`Request failed: ${err}`);
}

const FetchUpdateStateMedic = (This, id, update) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(id, update)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let data = res.data.userMutation.updateMedicoState
		
		let medicoId = data.medicoId
		let newState = data.newState
		let message = data.mensaje

		This.props.setMedicoState(medicoId, newState, message)
		This.setState({ fetchUpdateStateMedic: 'mt-4 col-8 mx-auto alert alert-success' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchUpdateStateMedic: 'd-none' })
		}, 1500);

		return message

	})
	.catch(err => {
		handleError(err)
		This.setState({ fetchUpdateStateMedicFail: 'mt-4 col-8 mx-auto alert alert-danger' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchUpdateStateMedicFail: 'd-none' })
		}, 1500);
	});

	// termina fetch
}

export default FetchUpdateStateMedic;