import React, { Component } from 'react'
import Select from 'react-select'
import printEl from "print-el";

import DashboardComponent from '../../../Components/DashboardComponent'
import FetchProductos from './fetchGetProducts/FetchGraphQLProducts'
import FetchPanels from './fetchGetPanels/FetchGraphQLPanels'
import FetchTiposCliente from './fetchGetTiposCliente/FetchGraphQLTipoCliente'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class Cotizaciones extends Component {

    state = {
        nombre: '',
        precioTotal: 0,
        productos: [],
        tiposCliente: [],
        currentProduct: {
            id: '',
            cantidad: '0',
            nombre: '',
            tiposClienteProducto: [], 
            tiposClienteDisponibles: [],
            precioCorrespondiente: 0
        },
        currentPanel: {
            id: '',
            cantidad: '0',
            nombre: '',
            precioCorrespondiente: 0,
            tiposClienteDisponibles: [],
            productos: []
        },
        listedProducts: [],
        listedPanels: []
    }

    componentDidMount() {
        FetchProductos(this)
        FetchPanels(this)
        FetchTiposCliente(this)
    }

    onPrint = () => {
        let nombrePaciente = this.state.nombre
        let listedProducts = this.state.listedProducts
        let productsPricesArray = listedProducts.map( product => product.precio * product.cantidad )
        let productsTotalPrice = productsPricesArray.length > 0 ? productsPricesArray.reduce( (accumulator, current) => accumulator + current ) : [0]

        let listedPanels = this.state.listedPanels
        let panelsPricesArray = listedPanels.map( product => product.precio * product.cantidad )
        let panelsTotalPrice = panelsPricesArray.length > 0 ? panelsPricesArray.reduce( (accumulator, current) => accumulator + current ) : [0]
        
        let content = `
            <div style="size: 7in 9.25in; margin: 27mm 16mm 27mm 16mm;" >
                <div class="mx-auto text-center" >
                    <img src='/img/Logo.png' class='mb-5' style="height:75px;" alt='CDM' />
                    <h2>Cotización Express</h2>
                    <div class='my-4 mb-5' >
                        <h4>${nombrePaciente}</h4>
                    </div>
                    ${ listedProducts.length > 0 ?
                        `<div class='my-5 mx-auto text-center' >
                            <h5  >Listado de Productos</h5>
                            <table class="table text-center table-bordered table-hover table-sm mt-3 mb-5 col-8 mx-auto">
                                <thead class="thead-light bg-info">
                                <tr>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col" >Nombre</th>
                                    <th scope="col" >Tipo</th>
                                    <th scope="col" >Precio</th>
                                </tr>
                                </thead>
                                <tbody>
                                    ${ 
                                        listedProducts.map( (producto, i) => (
                                            `<tr key=${i}>
                                                <td>${producto.cantidad}</td>
                                                <td>${producto.nombre}</td>
                                                <td>${producto.tipo}</td>
                                                <td>Q.${(parseInt(producto.cantidad) * producto.precio).toFixed(2)}</td>
                                            </tr>`
                                        )).join('')
                                    }
                                    <tr class='table-info' >
                                        <td class='text-center align-middle' >Total</td>
                                        <td colSpan='2'></td>
                                        <td class='mx-auto align-middle text-center font-weight-bold' >Q.${productsTotalPrice.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>` : ``
                    }
                    ${ listedPanels.length > 0 ?
                        `<div class='my-5 mx-auto text-center' >
                            <h5  >Listado de Paneles</h5>
                            <table class="table text-center table-bordered table-hover table-sm mt-3 mb-5 col-8 mx-auto">
                                <thead class="thead-light bg-info">
                                <tr>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col" >Nombre</th>
                                    <th scope="col" >Produtos</th>
                                    <th scope="col" >Precio</th>
                                </tr>
                                </thead>
                                <tbody>
                                    ${ 
                                        listedPanels.map( (panel, i) => (
                                            `<tr key=${i}>
                                                <td class='align-middle text-center' >${panel.cantidad}</td>
                                                <td class='align-middle text-center' >${panel.nombre}</td>
                                                <td class='align-middle text-center' >
                                                    ${panel.productos.map( producto => (
                                                        `<p class='mb-1' >${producto.nombre}</p>`
                                                    )).join('')}
                                                </td>
                                                <td class='align-middle text-center' >Q.${(parseInt(panel.cantidad) * panel.precio).toFixed(2)}</td>
                                            </tr>`
                                        )).join('')
                                    }
                                    <tr class='table-info' >
                                        <td class='text-center align-middle' >Total</td>
                                        <td colSpan='2'></td>
                                        <td class='mx-auto align-middle text-center font-weight-bold' >Q.${panelsTotalPrice.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>` : ``
                    }
                </div>
                <div id='footer' style='position: fixed; left: 0; bottom: 0; width: 100%; background-color: #17a2b8; color: white; text-align: center;' >
                    <p >Dirección: 9 Calle "A" 00-75 zona 1 9 Calle "A" 00-75 zona 1-Guatemala, Guatemala.</p>
                    <p >Teléfono: +502 2509-6969</p>
                </div>
            </div>
        `
            
        printEl(content, {
            useGlobalStyle: true,
        });
        return true
    }

    addProductHandler = () => {
        let { id, cantidad, nombre, tipo } = this.state.currentProduct
        let precio = this.state.currentProduct.precioCorrespondiente
        let listedProducts = this.state.listedProducts
        let newProduct = { id, cantidad, nombre, precio, tipo }
        if (cantidad !== '0' && nombre.length > 0 && precio > 0) {
            let isItemListed = listedProducts.map( product => product.id ).indexOf(id) !== -1
            if (!isItemListed) {
                listedProducts.push(newProduct)
                return this.setState({ listedProducts })
            }
        }
    }

    removeProductHandler = (element, productId) => {
        let list = this.state.listedProducts
        let listedProducts = list.filter( el => el.id !== productId )
        this.setState({ listedProducts })
    }

    addPanelHandler = () => {
        let { id, cantidad, nombre, precioCorrespondiente, productos } = this.state.currentPanel
        let precio = precioCorrespondiente
        let listedPanels = this.state.listedPanels
        let newPanel = { id, cantidad, nombre, precio, productos }
        if (cantidad !== '0' && nombre.length > 0 && precio > 0) {
            let isItemListed = listedPanels.map( panel => panel.id ).indexOf(id) !== -1
            if (!isItemListed) {
                listedPanels.push(newPanel)
                return this.setState({ listedPanels })
            }
        }
    }

    removePanelHandler = (element, panelId) => {
        let list = this.state.listedPanels
        let listedPanels = list.filter( el => el.id !== panelId )
        this.setState({ listedPanels })
    }

    render() {

        return (
            <DashboardComponent>
                
                <div className="container" style={{marginTop: '50px', minHeight: '75vh'}}>

                    <div className='text-center col-12 mx-auto' >
                    
                        <h1>Cotización Express</h1>

                        <div className='mb-5' >
                            
                            <div className="form-row py-5">
                                <div className="form-group col-12 col-md-6 mx-auto">
                                    <label htmlFor="nombre">Nombre del Paciente</label>
                                    <input onChange={ (e) => this.setState({ nombre: e.target.value }) } type="text" className="form-control" id="nombre" />
                                </div>
                            </div>
                            
                            <div className='py-4' >
                                
                                <h5>Listado de Productos</h5>

                                <form onSubmit={this.addProductHandler}>

                                    <div className='d-none d-md-block' >
                                        <table className='table text-center table-bordered table-hover table-sm py-5 col-8 mx-auto' >
                                            <tbody>
                                                <tr>    
                                                    <td style={{ maxWidth: '60px' }} >
                                                        <select onChange={ (event) => this.setState({ currentProduct: {...this.state.currentProduct, cantidad: event.target.value } }) } className='form-control' >
                                                            <option value='0' >Cantidad</option>
                                                            <option value='1' >1</option>
                                                            <option value='2' >2</option>
                                                            <option value='3' >3</option>
                                                            <option value='4' >4</option>
                                                            <option value='5' >5</option>
                                                            <option value='6' >6</option>
                                                            <option value='7' >7</option>
                                                            <option value='8' >8</option>
                                                            <option value='9' >9</option>
                                                            <option value='10' >10</option>   
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <Select 
                                                            options={this.state.productos} 
                                                            onChange={ (element) => {
                                                                let id = element.id
                                                                let nombre = element.label
                                                                let tipo = element.tipo
                                                                let tiposClienteProducto = element.precios_TipoCliente
                                                                let tiposClienteDisponibles = this.state.tiposCliente.filter( tipo => tiposClienteProducto.map( el => el.idTipoCliente ).includes(tipo.id) )
                                                                let precioCorrespondiente = 0
                                                                let currentProduct = { ...this.state.currentProduct, id, nombre, tipo, tiposClienteProducto, tiposClienteDisponibles, precioCorrespondiente }
                                                                this.tipoClienteHandlerRef.value = ''
                                                                this.setState({ currentProduct }) 
                                                            }}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <select 
                                                            ref={ ref => this.tipoClienteHandlerRef = ref }
                                                            onChange={ (event) => {
                                                                let idTipoCliente = event.target.value
                                                                let tiposClienteCorrespondiente = this.state.currentProduct.tiposClienteProducto.filter( el => el.idTipoCliente === idTipoCliente )[0]
                                                                if (tiposClienteCorrespondiente !== undefined) {
                                                                    let precioCorrespondiente = tiposClienteCorrespondiente.precio
                                                                    this.setState({ currentProduct: { ...this.state.currentProduct, precioCorrespondiente} })
                                                                } else {
                                                                    this.setState({ currentProduct: { ...this.state.currentProduct, precioCorrespondiente: 0} })
                                                                }
                                                            }} 
                                                            className='form-control' 
                                                            required
                                                        >
                                                            <option value='' >Tipo</option>
                                                            { this.state.currentProduct.tiposClienteDisponibles.length === 0 ? null : this.state.currentProduct.tiposClienteDisponibles.map( (tipo, i) => <option key={i} value={tipo.id} >{tipo.nombre}</option> ) }
                                                        </select>
                                                    </td>
                                                    <td className='align-middle text-center' >
                                                        { (this.state.currentProduct.precioCorrespondiente === 0 || this.state.currentProduct.precioCorrespondiente === undefined) ? 'Precio' : `Q.${this.state.currentProduct.precioCorrespondiente.toFixed(2)}` }
                                                    </td>
                                                    <td className='align-middle text-center' ><FontAwesomeIcon type='submit' onClick={ this.addProductHandler } icon="plus-circle" className='text-info' style={{ cursor: 'pointer', fontSize: '1.2em' }} title='Agregar' /></td>
                                                </tr>

                                            </tbody>
                                        </table> 
                                    </div>

                                    <div className='d-block d-md-none' >
                                        <div className="form-row">
                                            <div className="form-group col-md-6 mx-auto">
                                                <label htmlFor="cantidad">Cantidad</label>
                                                <select onChange={ (event) => this.setState({ currentProduct: {...this.state.currentProduct, cantidad: event.target.value } }) } className='form-control' id='cantidad' >
                                                    <option value='0' >Cantidad</option>
                                                    <option value='1' >1</option>
                                                    <option value='2' >2</option>
                                                    <option value='3' >3</option>
                                                    <option value='4' >4</option>
                                                    <option value='5' >5</option>
                                                    <option value='6' >6</option>
                                                    <option value='7' >7</option>
                                                    <option value='8' >8</option>
                                                    <option value='9' >9</option>
                                                    <option value='10' >10</option>
                                                    
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6 mx-auto">
                                                <label htmlFor="nombre">Nombre</label>
                                                <Select 
                                                    id='nombre'
                                                    options={this.state.productos} 
                                                    onChange={ (element) => {
                                                        let id = element.id
                                                        let nombre = element.label
                                                        let tiposClienteProducto = element.precios_TipoCliente
                                                        let tiposClienteDisponibles = this.state.tiposCliente.filter( tipo => tiposClienteProducto.map( el => el.idTipoCliente ).includes(tipo.id) )
                                                        let precioCorrespondiente = 0
                                                        let currentProduct = { ...this.state.currentProduct, id, nombre, tiposClienteProducto, tiposClienteDisponibles, precioCorrespondiente }
                                                        this.tipoClienteHandlerRef1.value = ''
                                                        this.setState({ currentProduct }) 
                                                    }}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-6 mx-auto">
                                                <label htmlFor="tipo">Tipo de Cliente</label>
                                                <select 
                                                    id='tipo'
                                                    ref={ ref => this.tipoClienteHandlerRef1 = ref }
                                                    onChange={ (event) => {
                                                        let idTipoCliente = event.target.value
                                                        let tiposClienteCorrespondiente = this.state.currentProduct.tiposClienteProducto.filter( el => el.idTipoCliente === idTipoCliente )[0]
                                                        if (tiposClienteCorrespondiente !== undefined) {
                                                            let precioCorrespondiente = tiposClienteCorrespondiente.precio
                                                            this.setState({ currentProduct: { ...this.state.currentProduct, precioCorrespondiente} })
                                                        } else {
                                                            this.setState({ currentProduct: { ...this.state.currentProduct, precioCorrespondiente: 0} })
                                                        }
                                                    }} 
                                                    className='form-control' 
                                                    required
                                                >
                                                    <option value='' >Tipo</option>
                                                    { this.state.currentProduct.tiposClienteDisponibles.length === 0 ? null : this.state.currentProduct.tiposClienteDisponibles.map( (tipo, i) => <option key={i} value={tipo.id} >{tipo.nombre}</option> ) }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6 mx-auto">
                                                <label htmlFor="cantidad">Precio Unitario</label>
                                                { (this.state.currentProduct.precioCorrespondiente === 0 || this.state.currentProduct.precioCorrespondiente === undefined) ? <p>Q.0.00</p> : <p>Q.{this.state.currentProduct.precioCorrespondiente.toFixed(2)}</p> }
                                            </div>
                                            <div className="form-group col-md-6 mx-auto">
                                                <FontAwesomeIcon type='submit' onClick={ this.addProductHandler } icon="plus-circle" className='text-info' style={{ cursor: 'pointer', fontSize: '2em' }} title='Agregar' />
                                            </div>
                                        </div>
                                    </div>

                                </form>

                                <table className="table text-center table-bordered table-hover table-sm mt-4">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col" className="" >Nombre</th>
                                        <th scope="col" className="" >Precio</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.listedProducts.length === 0 ? 
                                            <tr ><td colSpan="5" className='text-center mx-auto align-middle text-center' >No existe ningún elemento en el listado.</td></tr> :
                                            this.state.listedProducts.map( producto => (
                                                <tr key={producto.id}>
                                                <td>{producto.cantidad}</td>
                                                <td>{producto.nombre}</td>
                                                <td>{(producto.precio * producto.cantidad).toFixed(2)}</td>
                                                <td>{ <button id="deleteButton" onClick={ (e) => this.removeProductHandler(e, producto.id) } type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}} > <FontAwesomeIcon icon="trash-alt" title='Eliminar Producto' /> </button> } </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                            </div>

                            <div className='py-4' >
                                
                                <h5 className='pt-5' >Listado de Paneles</h5>

                                <form onSubmit={this.addProductHandler} >

                                    <div className='d-none d-md-block' >
                                        <table className='table text-center table-bordered table-hover table-sm py-5 col-8 mx-auto' >
                                            <tbody>
                                                <tr>    
                                                    <td style={{ maxWidth: '60px' }} >
                                                        <select onChange={ (event) => this.setState({ currentPanel: {...this.state.currentPanel, cantidad: event.target.value } }) } className='form-control' >
                                                            <option value='0' >Cantidad</option>
                                                            <option value='1' >1</option>
                                                            <option value='2' >2</option>
                                                            <option value='3' >3</option>
                                                            <option value='4' >4</option>
                                                            <option value='5' >5</option>
                                                            <option value='6' >6</option>
                                                            <option value='7' >7</option>
                                                            <option value='8' >8</option>
                                                            <option value='9' >9</option>
                                                            <option value='10' >10</option>   
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <Select 
                                                            options={this.state.panels} 
                                                            onChange={ (element) => {
                                                                let id = element.id
                                                                let nombre = element.label
                                                                let precioCorrespondiente = 0
                                                                let productos = element.productos
                                                                let tiposClienteDisponibles = this.state.tiposCliente.filter( tipo => {
                                                                    let id = tipo.id
                                                                    const productos = element.productos.map( producto => { 
                                                                        let precios_TipoCliente = producto.precios_TipoCliente.filter( el => el.idTipoCliente === id )
                                                                        return precios_TipoCliente
                                                                    })
                                                        
                                                                    let validation = productos.every(el => el.length > 0)
                                                        
                                                                    return validation === true
                                                        
                                                                })
                                                                let currentPanel = { ...this.state.currentPanel, id, nombre, tiposClienteDisponibles, precioCorrespondiente, productos }
                                                                this.panelTipoClienteHandlerRef.value = ''
                                                                this.setState({ currentPanel }) 
                                                            }}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <select 
                                                            ref={ ref => this.panelTipoClienteHandlerRef = ref }
                                                            onChange={ (event) => {
                                                                let idTipoCliente = event.target.value
                                                                let tiposClienteCorrespondiente = this.state.currentPanel.productos.map( product => {
                                                                    return product.precios_TipoCliente
                                                                    .filter( el => el.idTipoCliente === idTipoCliente )
                                                                    .map( el => el.precio )[0]
                                                                }).reduce((partial_sum, a) => partial_sum + a,0)
                                                                let precioCorrespondiente = 0
                                                                if ( tiposClienteCorrespondiente !== undefined && !isNaN(tiposClienteCorrespondiente) ) {
                                                                    precioCorrespondiente = tiposClienteCorrespondiente
                                                                    this.setState({ currentPanel: { ...this.state.currentPanel, precioCorrespondiente} })
                                                                } else {
                                                                    this.setState({ currentPanel: { ...this.state.currentPanel, precioCorrespondiente} })
                                                                }
                                                            }} 
                                                            className='form-control' 
                                                            required
                                                        >
                                                            <option value='' >Tipo</option>
                                                            { this.state.currentPanel.tiposClienteDisponibles.length === 0 ? null : this.state.currentPanel.tiposClienteDisponibles.map( (tipo, i) => <option key={i} value={tipo.id} >{tipo.nombre}</option> ) }
                                                        </select>
                                                    </td>
                                                    <td className='align-middle text-center' >
                                                        { (this.state.currentPanel.precioCorrespondiente === 0 || this.state.currentPanel.precioCorrespondiente === undefined || typeof this.state.currentPanel.precioCorrespondiente !== 'number' ) ? 'Precio' : `Q.${this.state.currentPanel.precioCorrespondiente.toFixed(2)}` }
                                                    </td>
                                                    <td className='align-middle text-center' ><FontAwesomeIcon type='submit' onClick={ this.addPanelHandler } icon="plus-circle" className='text-info' style={{ cursor: 'pointer', fontSize: '1.2em' }} title='Agregar' /></td>
                                                </tr>

                                            </tbody>
                                        </table> 
                                    </div>

                                    <div className='d-block d-md-none' >
                                        <div className="form-row">
                                            <div className="form-group col-md-6 mx-auto">
                                                <label htmlFor="cantidad">Cantidad</label>
                                                <select onChange={ (event) => this.setState({ currentPanel: {...this.state.currentPanel, cantidad: event.target.value } }) } className='form-control' id='cantidad' >
                                                    <option value='0' >Cantidad</option>
                                                    <option value='1' >1</option>
                                                    <option value='2' >2</option>
                                                    <option value='3' >3</option>
                                                    <option value='4' >4</option>
                                                    <option value='5' >5</option>
                                                    <option value='6' >6</option>
                                                    <option value='7' >7</option>
                                                    <option value='8' >8</option>
                                                    <option value='9' >9</option>
                                                    <option value='10' >10</option>
                                                    
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6 mx-auto">
                                                <label htmlFor="nombre">Nombre</label>
                                                <Select 
                                                    id='nombre'
                                                    options={this.state.panels} 
                                                    onChange={ (element) => {
                                                        let id = element.id
                                                        let nombre = element.label
                                                        let precioCorrespondiente = 0
                                                        let productos = element.productos
                                                        let tiposClienteDisponibles = this.state.tiposCliente.filter( tipo => {
                                                            let id = tipo.id
                                                            const productos = element.productos.map( producto => { 
                                                                let precios_TipoCliente = producto.precios_TipoCliente.filter( el => el.idTipoCliente === id )
                                                                return precios_TipoCliente
                                                            })
                                                
                                                            let validation = productos.every(el => el.length > 0)
                                                
                                                            return validation === true
                                                
                                                        })
                                                        let currentPanel = { ...this.state.currentPanel, id, nombre, tiposClienteDisponibles, precioCorrespondiente, productos }
                                                        this.panelTipoClienteHandlerRef1.value = ''
                                                        this.setState({ currentPanel }) 
                                                    }}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-6 mx-auto">
                                                <label htmlFor="tipo">Tipo de Cliente</label>
                                                <select 
                                                    ref={ ref => this.panelTipoClienteHandlerRef1 = ref }
                                                    onChange={ (event) => {
                                                        let idTipoCliente = event.target.value
                                                        let tiposClienteCorrespondiente = this.state.currentPanel.productos.map( product => {
                                                            return product.precios_TipoCliente
                                                            .filter( el => el.idTipoCliente === idTipoCliente )
                                                            .map( el => el.precio )[0]
                                                        }).reduce((partial_sum, a) => partial_sum + a,0)
                                                        if (tiposClienteCorrespondiente !== undefined) {
                                                            let precioCorrespondiente = tiposClienteCorrespondiente
                                                            this.setState({ currentPanel: { ...this.state.currentPanel, precioCorrespondiente} })
                                                        } else {
                                                            this.setState({ currentPanel: { ...this.state.currentPanel, precioCorrespondiente: 0} })
                                                        }
                                                    }} 
                                                    className='form-control' 
                                                    required
                                                >
                                                    <option value='' >Tipo</option>
                                                    { this.state.currentPanel.tiposClienteDisponibles.length === 0 ? null : this.state.currentPanel.tiposClienteDisponibles.map( (tipo, i) => <option key={i} value={tipo.id} >{tipo.nombre}</option> ) }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6 mx-auto">
                                                <label htmlFor="cantidad">Precio Unitario</label>
                                                { (this.state.currentPanel.precioCorrespondiente === 0 || this.state.currentPanel.precioCorrespondiente === undefined) ? <p>Q.0.00</p> : <p>Q.{this.state.currentPanel.precioCorrespondiente.toFixed(2)}</p> }
                                            </div>
                                            <div className="form-group col-md-6 mx-auto">
                                                <FontAwesomeIcon type='submit' onClick={ this.addPanelHandler } icon="plus-circle" className='text-info' style={{ cursor: 'pointer', fontSize: '2em' }} title='Agregar' />
                                            </div>
                                        </div>
                                    </div>

                                </form>

                                <table className="table text-center table-bordered table-hover table-sm mt-4">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col" className="" >Nombre</th>
                                        <th scope="col" className="" >Precio</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.listedPanels.length === 0 ? 
                                            <tr ><td colSpan="5" className='text-center mx-auto align-middle text-center' >No existe ningún elemento en el listado.</td></tr> :
                                            this.state.listedPanels.map( panel => (
                                                <tr key={panel.id}>
                                                <td>{panel.cantidad}</td>
                                                <td>{panel.nombre}</td>
                                                <td>{(panel.precio * panel.cantidad).toFixed(2)}</td>
                                                <td>{ <button id="deleteButton" onClick={ (e) => this.removePanelHandler(e, panel.id) } type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}} > <FontAwesomeIcon icon="trash-alt" title='Eliminar Panel' /> </button> } </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>


                            </div>

                        </div>

                        <div>

                            { ( this.state.listedProducts.length > 0 || this.state.listedPanels.length > 0) ? 
                            <div className='' >
                                <button onClick={ this.onPrint } className="btn btn-info my-5">Imprimir Cotización Express</button>
                            </div> : null
                            } 
                            
                        </div>

                    </div>


                </div>

            </DashboardComponent>
        )
    }
}

export default Cotizaciones
