import React, { Component } from 'react';
import { connect } from 'react-redux'

import actions from '../../../../actions';

// import loader from './assets/loader.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import FetchEditConsulta from '../Containers/FetchEditConsulta/FetchGQLEditConsulta'
import DasboardComponent from '../../Components/DashboardComponent'

class ConsultaMedico extends Component {

    state = {
        fetchEditConsultaResponse: 'd-none',
        fetchEditConsultaMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        original: '',
        descuento: '',
        final: '',
        data_consultas: {
            precioNormal: '', 
            seguro: '', 
            precioFinal: ''
        }
    }

    componentDidMount() {

        let data_consultas = this.props.currentUser.medico.data_consultas
        let precioNormal, seguro, precioFinal

        if (data_consultas !== null) {
            precioNormal = data_consultas.precioNormal
            seguro = data_consultas.seguro
            precioFinal = data_consultas.precioFinal
        } else {
            precioNormal = ''
            seguro = ''
            precioFinal = ''
        }

        this.setState({ data_consultas: { ...this.state.data_consultas, precioNormal, seguro, precioFinal } })

    }

    onFormSubmit = (event) => {
        event.preventDefault()

        let { precioNormal, seguro, precioFinal } = this.state.data_consultas
        let medicoId = this.props.currentUser.medico.id
        
        const data = {
            medicoId, dataConsultas: { precioNormal, seguro, precioFinal }
        }

        FetchEditConsulta(this, data, medicoId)

    }

    onInputChange = (event) => {
        this.setState({
            ...this.state,
            data_consultas: {
                ...this.state.data_consultas,
                [event.target.id]: event.target.value
            }
        })
    }

    render() {

        let { precioNormal, seguro, precioFinal } = this.state.data_consultas

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
        
                    <div id="showTableButton" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('', {
                        id: '',
                        usuario: '',
                        nombre: '',
                        apellidos: '',
                        correo: '',
                        fecha_de_nacimiento: new Date(),
                        password: '',
                        direccion: '',
                        nacionalidad: 'Guatemala',
                        sexo: '',
                        telefono: 0,
                        tipo: '',
                        dpi: '',
                        foto_perfil: '',
                        permisos: {
                            AdminUser: {
                                ViewUser: false,
                                CreateUser: false,
                                EditUser: false,
                                DeleteUser: false,
                                UpdateStateMedic: false
                            }
                        },
                        colaborador: {
                            id_colaborador: '',
                            nit_colaborador: '',
                            puesto: '',
                            departamento: ''
                        },
                        medico: {
                            estado: 'No Validado',
                            id_medico: '',
                            nit_medico: '',
                            numero_colegiado: '',
                            clinica_nombre: '',
                            clinica_departamento: '',
                            clinica_municipalidad: '',
                            clinica_direccion: '',
                            clinica_telefono: '',
                            clinica_correo: '',
                            clinica_direccion2: '',
                            clinica_telefono2: '',
                            clinica_correo2: '',
                            clinica_direccion3: '',
                            clinica_telefono3: '',
                            clinica_correo3: '',
                            especialidad: '',
                            sub_especialidad: '',
                            observaciones: '',
                            observaciones_emergencias: '',
                            social_media: {
                                fb_link: '',
                                ins_link: '',
                                web_link: ''
                            },
                            atencion_emergencias: '',
                            financiero: {
                                cuenta_numero: '',
                                cuenta_nombre: '',
                                cuenta_tipo: '',
                                banco_nombre: '',
                                factura_nombre: '',
                                documentos: {
                                    dpi_front: '',
                                    dpi_back: '',
                                    licencia_sanitaria: '',
                                    patente: '',
                                    recibo_colegiado: '',
                                    titulo_academico: '',
                                    constancia_desechos_solidos: '',
                                    rtu: '',
                                    factura: '',
                                }
                            },
                            horario_atencion: [
                                {
                                    dia: 'lunes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'martes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'miercoles',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'jueves',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'viernes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'sabado',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'domingo',
                                    ingreso: '',
                                    egreso: ''
                                }
                            ],
                            data_consultas: {
                                nombre: '',
                                descripcion: '',
                                precioNormal: '',
                                descuento: '',
                                precioFinal: ''
                            },
                            data_procedimientos: {
                                html: ''
                            }
                            
                        }
                    }) }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>
        
                    <h4 className='text-center mb-5' >Consulta Médica</h4>

                    <form onSubmit={this.onFormSubmit} >

                        <div className='row py-5' >

                            <div className='col-12 col-sm-6 col-md-4 col-lg-3 mx-auto px-3 my-4 ' >
                                <div className='bg-info rounded px-3 py-5 text-center text-white shadow' >
                                    <h4 className='mb-4' >Precio Original</h4>
                                    <input value={precioNormal} onChange={ this.onInputChange } className='rounded border-0 w-100' id='precioNormal' placeholder='Ejemplo: 100' type='number' required />
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3 mx-auto px-3 my-4 ' >
                                <div className='bg-info rounded px-3 py-5 text-center text-white shadow' >
                                    <h4 className='mb-4' >Seguro</h4>
                                    <input value={seguro} onChange={ this.onInputChange } className='rounded border-0 w-100' id='seguro' placeholder='Ejemplo: 10' type='number' required />
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3 mx-auto px-3 my-4 ' >
                                <div className='bg-info rounded px-3 py-5 text-center text-white shadow' >
                                    <h4 className='mb-4' >Precio Especial</h4>
                                    <input value={precioFinal} onChange={ this.onInputChange } className='rounded border-0 w-100' id='precioFinal' placeholder='Ejemplo: 90' type='number' required />
                                </div>
                            </div>

                        </div>

                        <div className='mx-auto text-center my-3'>
                            <input className='bg-info rounded p-2 text-center text-white shadow border-0' type='submit' value='Guardar' />
                            <p className={this.state.fetchEditConsultaResponse} >{this.state.fetchEditConsultaMessage}</p>
                        </div>

                    </form>


                </div>
            </DasboardComponent>
        )
    }
} 

export const mapDispatchToProps = dispatch => {
    return {
        editConsulta: ( data ) => dispatch ( actions.users.editConsulta({ data }) )
    }
}

export default connect(null, mapDispatchToProps) (ConsultaMedico)