import React from 'react';

// Toggle the side navigation
let toggleSide = (e) => {
  e.preventDefault();

  document.querySelector("body").classList.toggle("sidenav-toggled");
  document.querySelector(".body").classList.toggle("sidenav-toggled");
  document.querySelector(".navbar-sidenav").classList.add("collapsed");

  //document.querySelector(".nav-link-collapse").classList.add("collapsed"); //Agregar cuando exista un submenu

  let secondLevel = document.querySelectorAll(".sidenav-second-level"),
      thirdLevel = document.querySelectorAll(".sidenav-third-level");

  /* istanbul ignore next */
  secondLevel.forEach((item) => {
    /* istanbul ignore next */ 
    item.classList.remove("show");
  });

  /* istanbul ignore next */
  thirdLevel.forEach((item) => {
    /* istanbul ignore next */ 
    item.classList.remove("show");
  });

}

let LeftSideToggler = props => (
  <ul className="navbar-nav sidenav-toggler d-sm-none d-md-block">
    <li className="nav-item">
      <a href="#link" className="nav-link text-center" id="sidenavToggler" onClick={toggleSide} >
        <i className="fa fa-fw fa-angle-left"></i>
      </a>
    </li>
  </ul>
)

export default LeftSideToggler