import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import actions from '../../../../actions';

import FetchGraphQLUsers from './FetchGetUsers/FetchGraphQLUsuarios'
import FetchDeleteUser from './FetchDeleteUser/FetchGraphQLDeleteUser'
// import FetchUpdateStateMedic from './FetchUpdateStateMedic/FetchUpdateStateMedic'

import UserTable from '../Components/UserTable.jsx';
import UserView from '../Components/UserView';
import UserEdit from '../Components/UserEdit.jsx';
import UserCreate from '../Components/UserCreate';
import AddMedicDocuments from '../Components/AddMedicDocuments';
import ConsultaMedico from '../Components/ConsultaMedico';
import ProcedimientosMedico from '../Components/ProcedimientosMedico';


interface IProps {
    conexion: boolean
    usuario: Usuario
    usuarios: Usuarios[]
}

interface IState {
    display: String
    currentUser: Usuarios
    // fetchMedicStateUpdate: Object
}

interface Usuario {
    id: string
    nombre: string
    apellidos: string
    telefono: string
    correo: string
    tipo: string
    permisos: Permisos
}

interface Usuarios {
    id: string
    usuario: string
    nombre: string
    apellidos: string
    correo: string
    fecha_de_nacimiento: Date
    direccion: string
    nacionalidad: string
    sexo: string
    telefono: number
    tipo: string
    dpi: string
    foto_perfil: string
    permisos: Permisos
    colaborador: Colaborador
    medico: Medico
}

interface Colaborador {
    id_colaborador: string
    puesto: string
    departamento: string
    nit_colaborador: string
}

interface Medico {
    estado: string
    id_medico: string,
    nit_medico: string,
    numero_colegiado: string,
    clinica_nombre: string,
    clinica_departamento: string,
    clinica_municipalidad: string,
    clinica_direccion: string,
    clinica_telefono: string,
    clinica_correo: string,
    especialidad: string,
    sub_especialidad: string,
    atencion_emergencias: Boolean,
    financiero: Financiero,
    horario_atencion: any,
    data_consultas: any,
    data_procedimientos: any
}

interface Financiero {
    cuenta_numero: string,
    cuenta_nombre: string,
    cuenta_tipo: string,
    banco_nombre: string,
    factura_nombre: string,
    documentos: Documentos
}

interface Documentos {
    dpi_front: string,
    dpi_back: string,
    licencia_sanitaria: string,
    patente: string,
    recibo_colegiado: string,
    titulo_academico: string,
    constancia_desechos_solidos: string,
    rtu: string,
    factura: string,
}

interface Permisos {
    AdminUser: AdminUser
}

interface AdminUser {
    ViewUser: Boolean
    CreateUser: Boolean
    EditUser: Boolean
    DeleteUser: Boolean
    UpdateStateMedic: Boolean
}


class AdminUserComponent extends Component <IProps, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {
            display: '',
            // fetchMedicStateUpdate: {
            //     success: 'd-none',
            //     failure: 'd-none'
            // },
            currentUser: {
                id: '',
                usuario: '',
                nombre: '',
                apellidos: '',
                correo: '',
                fecha_de_nacimiento: new Date(),
                direccion: '',
                nacionalidad: '',
                sexo: '',
                telefono: 0,
                tipo: '',
                dpi: '',
                foto_perfil: '',
                permisos: {
                    AdminUser: {
                        ViewUser: false,
                        CreateUser: false,
                        EditUser: false,
                        DeleteUser: false,
                        UpdateStateMedic: false
                    }
                },
                colaborador: {
                    id_colaborador: '',
                    nit_colaborador: '',
                    puesto: '',
                    departamento: ''
                },
                medico: {
                    estado: 'No Validado',
                    id_medico: '',
                    nit_medico: '',
                    numero_colegiado: '',
                    clinica_nombre: '',
                    clinica_departamento: '',
                    clinica_municipalidad: '',
                    clinica_direccion: '',
                    clinica_telefono: '',
                    clinica_correo: '',
                    especialidad: '',
                    sub_especialidad: '',
                    atencion_emergencias: false,
                    financiero: {
                        cuenta_numero: '',
                        cuenta_nombre: '',
                        cuenta_tipo: '',
                        banco_nombre: '',
                        factura_nombre: '',
                        documentos: {
                            dpi_front: '',
                            dpi_back: '',
                            licencia_sanitaria: '',
                            patente: '',
                            recibo_colegiado: '',
                            titulo_academico: '',
                            constancia_desechos_solidos: '',
                            rtu: '',
                            factura: '',
                        }
                    },
                    horario_atencion: [
                        {
                            dia: 'lunes',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'martes',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'miercoles',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'jueves',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'viernes',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'sabado',
                            ingreso: '',
                            egreso: ''
                        },
                        {
                            dia: 'domingo',
                            ingreso: '',
                            egreso: ''
                        },

                    ],
                    data_consultas: {
                        nombre: '',
                        descripcion: '',
                        precioNormal: '',
                        descuento: '',
                        precioFinal: ''
                    },
                    data_procedimientos: {
                        html: ''
                    }
                    
                }
            }
        }

        this.handleDisplay = this.handleDisplay.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);

    }

    componentDidMount(){

        if((this.props.conexion === false) || (this.props.usuario.permisos.AdminUser === null)){
			return <Redirect to='/login' />
        }
        
        FetchGraphQLUsers(this)

    }

    handleDisplay = (display: string, usuario: Usuarios) => {
        this.setState({ display, currentUser: usuario })
    }

    handleDeleteUser = (usuario: Usuarios) => {
        FetchDeleteUser(this, usuario)
    }

    // handleUpdateStateMedic = (id:any, update:any) => {
    //     FetchUpdateStateMedic(this, id, update)
    // }

    render(){

        if ((this.props.conexion === false) || (this.props.usuario.permisos.AdminUser === null)) {
			return <Redirect to='/login' />
		} else {

            if (this.state.display === '') {
                return <UserTable usuario={this.props.usuario} usuarios={this.props.usuarios} handleDisplay={this.handleDisplay} handleDeleteUser={this.handleDeleteUser} />
            } else if (this.state.display === 'UserView') {
                return <UserView currentUser={this.state.currentUser} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'UserCreate') {
                return <UserCreate handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'UserEdit') {
                return <UserEdit usuario={this.props.usuario} currentUser={this.state.currentUser} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'MedicDocumentsEdit') {
                return <AddMedicDocuments currentUser={this.state.currentUser} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ConsultaMedico') {
                return <ConsultaMedico currentUser={this.state.currentUser} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ProcedimientosMedico') {
                return <ProcedimientosMedico currentUser={this.state.currentUser} handleDisplay={this.handleDisplay} />
            } else {
                return null
            }

        }

    }
}

const mapStateToProps = (state: any, props: any) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        usuarios: state.appInfo.usersData.users
    }
}

export const mapDispatchToProps = (dispatch: any) => {
    return {
        dispatch,
        setUsuarios: (usuarios: Usuarios) => dispatch (actions.users.fetchUsers({ usuarios })),
        removeUser: (userId: any) => dispatch (actions.users.deleteUser({ userId }))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserComponent)