const initialState = {
    clientTypes: []
}

let clientTypes = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_CLIENT_TYPES':
            return {
                ...state,
                clientTypes: action.payload.tiposCliente
            }
        case 'CREATE_CLIENT_TYPE': 
            let newClientType = [ action.payload.clientTypeName ]
            let stateClientTypes = [...state.clientTypes]
            let newClientTypesArr = stateClientTypes.concat(newClientType)
            return {
                ...state,
                clientTypes: newClientTypesArr
            }
        case 'EDIT_CLIENT_TYPE': 
            let clientTypeIndex = state.clientTypes.findIndex(clientType => clientType.id === action.payload.clientType.id)
            let editedArray = [ ...state.clientTypes ]
            editedArray[clientTypeIndex] = action.payload.clientType
            return {
                ...state,
                clientTypes: editedArray
            }  
        case 'DELETE_CLIENT_TYPE': 
            console.log(action.payload)
            let filteredArray = state.clientTypes.filter((clientType) => clientType.id !== action.payload.clientTypeId)
            return {
                ...state,
                clientTypes: filteredArray
            }
            //break;
        default:
            return state

    }
}
  
  export default clientTypes