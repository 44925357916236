import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import actions from '../../../../actions'

import FetchGraphQLUsers from '../../AdminUser/Containers/FetchGetUsers/FetchGraphQLUsuarios'
import FetchGraphQLCompanies from './FetchGetCompanies/FetchGraphQLCompanies'
import FetchDeleteCompany from '../containers/FetchDeleteCompany/FetchGraphQLDeleteEmpresa'

import EmpresasTable from '../components/EmpresasTable'
import EmpresaView from '../components/EmpresaView'
import EmpresaCreate from '../components/EmpresaCreate'
import EmpresaEdit from '../components/EmpresaEdit'
import EmpresaAffiliateEdit from '../components/EmpresaAffiliateEdit'
import EmpresaAffiliate from '../components/EmpresaAffiliate'
import EmpresaAfiliados from '../components/EmpresaAfiliados'

class Empresas extends Component {

    state = {
        display: '',
        currentCompany: {
            id: '',
            logo: '',
            nombre: '',
            direccion: '',
            telefono: '',
            correo: '',
            NIT: '',
            FK_Afiliacion: '',
            metodo_de_pago: '',
            tipo: '',
            inicio_contrato: '',
            firma_contrato: '',
            texto_contrato: '',
            vencimiento_contrato: '',
            cantidad_afiliados_permitidos: '',
            visitas_medicas_maximas_mes: ''
        },
        
    }

    componentDidMount(){

        if((this.props.conexion === false) || (this.props.usuario.permisos.AdminUser === null)){
			return <Redirect to='/login' />
        }
        
        FetchGraphQLUsers(this)
        FetchGraphQLCompanies(this)

    }

    handleDisplay = (display, company) => {
        this.setState({ display, currentCompany: company })
    }

    handleDeleteCompany = (company) => {
        FetchDeleteCompany(this, company)
    }

    render() {

        if (this.props.conexion === false) {
			return <Redirect to='/login' />
		} else {

            if (this.state.display === '') {
                return <EmpresasTable usuario={this.props.usuario} empresas={this.props.companies} handleDisplay={this.handleDisplay} handleDeleteCompany={this.handleDeleteCompany}/>
            } else if (this.state.display === 'EmpresaView') {
                return <EmpresaView usuario={this.props.usuario} currentCompany={this.state.currentCompany} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'EmpresaCreate') {
                return <EmpresaCreate usuario={this.props.usuario} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'EmpresaEdit') {
                return <EmpresaEdit usuario={this.props.usuario} currentCompany={this.state.currentCompany} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'EmpresaAffiliate') {
                return <EmpresaAffiliate usuario={this.props.usuario} currentCompany={this.state.currentCompany} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'EmpresaAffiliateEdit') {
                return <EmpresaAffiliateEdit usuario={this.props.usuario} currentCompany={this.state.currentCompany} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ManageAffiliates') {
                return <EmpresaAfiliados usuario={this.props.usuario} usuarios={this.props.usuarios} currentCompany={this.state.currentCompany} handleDisplay={this.handleDisplay} />
            } else {
                return null
            }

        }

    }
}

const mapStateToProps = (state) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        usuarios: state.appInfo.usersData.users,
        companies: state.appInfo.companiesData.companies
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUsuarios: (usuarios) => dispatch (actions.users.fetchUsers({ usuarios })),
        setCompanies: (empresas) => dispatch (actions.companies.fetchCompanies({ empresas })),
        removeCompany: (idEmpresa) => dispatch (actions.companies.deleteCompany({ idEmpresa }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Empresas)