import React, { Component } from 'react'
import { connect } from 'react-redux' 

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'
import Loader from '../../../Components/Loader'

import FetchAffiliateToEmpresa from '../containers/FetchAffiliateToEmpresa/FetchGQLAffiliateToEmpresa'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

class EmpresaAfiliados extends Component {

    state = {
        
        fetchSendResponse: 'd-none',
        fetchSendMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        initialIdsAfiliados: [],
        afiliados: {
            idsAfiliados: [],
            empresaId: ''
        }
        
    }

    componentDidMount() {

        let currentCompanyId = this.props.currentCompany.id

        let usuarios = this.props.usuarios.filter( usuario => usuario.afiliacion !== null && usuario.afiliacion.FK_Empresa !== null )
        let usuariosConFK_Empresa = usuarios.filter( usuario => usuario.afiliacion.FK_Empresa === currentCompanyId )
        let usuariosFK_Afiliaciones = usuariosConFK_Empresa.map( usuario => usuario.FK_Afiliado )

        this.setState({ afiliados: { ...this.state.afiliados, idsAfiliados: usuariosFK_Afiliaciones, empresaId: currentCompanyId }, initialIdsAfiliados: usuariosFK_Afiliaciones })

    }

    handleAddAffiliate = (usuario) => {

        let newIdIndex = this.state.afiliados.idsAfiliados.length
        let newIdsAfiliados = [ ...this.state.afiliados.idsAfiliados ]
        newIdsAfiliados[newIdIndex] = usuario.FK_Afiliado

        this.setState({ 
            ...this.state,
            afiliados: {
                ...this.state.afiliados,
                idsAfiliados: newIdsAfiliados
            }
        })

    }    

    handleDeleteAffiliate = (usuario) => {

        let idsAfiliados = [ ...this.state.afiliados.idsAfiliados ]
        let newIdsAfiliados = idsAfiliados.filter( FK_Afiliado => FK_Afiliado !== usuario.FK_Afiliado )

        this.setState({ 
            ...this.state,
            afiliados: {
                ...this.state.afiliados,
                idsAfiliados: newIdsAfiliados
            }
        })

    }

    saveAffiliates = () => {

        let afiliados = this.state.afiliados
        let initialAfiliados = this.state.initialIdsAfiliados
        let currentCompanyId = this.props.currentCompany.id
        FetchAffiliateToEmpresa(this, afiliados, initialAfiliados, currentCompanyId)

    }

    render() {

        let { logo, nombre, afiliacion } = this.props.currentCompany
        let usuarios = this.props.usuarios.filter( usuario => usuario.afiliacion !== null )
        let idsAfiliados = this.state.afiliados.idsAfiliados
        let affiliated = usuarios.filter( usuario => idsAfiliados.includes(usuario.FK_Afiliado) )
        let notAffiliated = usuarios.filter( usuario => !idsAfiliados.includes(usuario.FK_Afiliado) && (usuario.afiliacion.FK_Empresa === null || usuario.afiliacion.FK_Empresa === afiliacion.FK_Empresa) )

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto text-center" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
                        
                    <div id="showTableButton" className='col-12 mr-auto text-left' onClick={ () => this.props.handleDisplay('', {
                        id: '',
                        logo: '',
                        nombre: '',
                        direccion: '',
                        telefono: '',
                        correo: '',
                        NIT: '',
                        FK_Afiliacion: ''
                    }) }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div>

                        <div className='mx-auto text-center mb-5' >
                            { (logo !== null && logo !== undefined) ? <img  src={logo} alt='medico' style={{ height: '150px' }}  /> : <FontAwesomeIcon icon={faUserCircle} style={{color: '#5DACD4', fontSize: '100px'}} className='mt-3' /> }
                            <h3>{ nombre }</h3>
                            <h2 className='mt-4' >Administración de Afiliaciones</h2>
                        </div>

                        <div className='col-12 mx-auto text-center mb-5' >
                            <h2 className='mt-4' >Usarios Afilidos</h2>
                            {
                                
                                affiliated.length !== 0 ?
                                <table className="table text-center table-bordered table-hover table-sm mt-5">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Nombres</th>
                                            <th scope="col">Apellidos</th>
                                            <th scope="col" className="d-none d-md-table-cell" >DPI</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        affiliated.map( usuario => (
                                            <tr key={usuario.id}>
                                                {/*<th scope="row">{usuario.nombre}</th>*/}
                                                <td className="align-middle text-center" >{usuario.nombre}</td>
                                                <td className='align-middle text-center' >{usuario.apellidos}</td>
                                                <td className="align-middle text-center" >{usuario.DPI}</td>
                                                <td className='align-middle text-center' >
                                                    
                                                    {
                                                        //this.props.usuario.permisos.AdminUser.DeleteUser === true ? (
                                                            <button id="deleteButton" 
                                                            onClick={ () => { this.handleDeleteAffiliate(usuario) }} 
                                                            type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}
                                                            >
                                                            <FontAwesomeIcon icon={faMinus} title='Eliminar Usuario' />
                                                            </button>
                                                            
                                                        //) : null
                                                    }

                                                </td>
                                            </tr>
                                        )
                                        
                                        )
                                    }
                                    </tbody>
                                </table> :
                                <h5 className='pt-5' >No hay ningún usuario afiliado a la empresa aún.</h5>
                                
                            }
                        </div>

                        <div className='col-12 mx-auto text-center mb-5' >
                            <h2 className='mt-4' >Usarios No Afiliados</h2>

                            {
                                notAffiliated.length !== 0 ?
                                <table className="table text-center table-bordered table-hover table-sm mt-5">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Nombres</th>
                                            <th scope="col">Apellidos</th>
                                            <th scope="col" className="d-none d-md-table-cell" >DPI</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        notAffiliated.map( usuario => (
                                            <tr key={usuario.id}>
                                                <td className="align-middle text-center" >{usuario.nombre}</td>
                                                <td className='align-middle text-center' >{usuario.apellidos}</td>
                                                <td className="align-middle text-center" >{usuario.DPI}</td>
                                                <td className='align-middle text-center' >
                                                    
                                                    {
                                                        //this.props.usuario.permisos.AdminUser.DeleteUser === true ? (
                                                            <button id="deleteButton" 
                                                            onClick={ () => { this.handleAddAffiliate(usuario) }} 
                                                            type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}
                                                            >
                                                            <FontAwesomeIcon icon={faPlus} title='Agregar Usuario' />
                                                            </button>
                                                            
                                                        //) : null
                                                    }

                                                </td>
                                            </tr>
                                        )
                                        
                                        )
                                    }
                                    </tbody>
                                </table> : 
                                <h5 className='pt-5' >No hay ningún usuario por afiliar a la empresa.</h5>
                            }
                        </div>

                    </div>
                    
                    { this.state.afiliados.idsAfiliados.length !== 0 ? <button type="button" className="btn btn-info mt-5 " onClick={ this.saveAffiliates } >Guardar Afiliaciones</button> : null }

                        <div className={this.state.loaderClassName} >
                            <Loader />
                        </div>

                        <div className={this.state.fetchSendResponse} role="alert">
                            {this.state.fetchSendMessage}
                        </div>
                    
                </div>
            </DasboardComponent>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editAfiliacionToEmpresa: ( newAfiliados, initialAfiliados ) => dispatch ( actions.users.editAffiliatesToEmpresa( newAfiliados, initialAfiliados ) )
    }
}

export default connect(null, mapDispatchToProps) (EmpresaAfiliados)