import React from 'react';

import './css/Footer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let Footer = props => (
    <footer className="container-fluid">
        <div className="row">
            <div className="col-lg-offset-0 col-lg-4 col-md-6 mx-auto" style={{marginTop: '15px'}}>
                <ul className="list-inline social-buttons">
                    <li>
                    	<a href="https://www.facebook.com/CDMGT"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                    </li>
                </ul>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto" style={{marginTop: '15px'}}><span><b>Dirección:</b><br/> 9 Calle "A" 00-75 zona 1 9 Calle "A" 00-75 zona 1-Guatemala, Guatemala</span><br/><span><b>Teléfono:</b> <a href="tel:50225096969">2509-6969</a></span></div>
            <div className="col-lg-4 col-md-6 mx-auto" style={{marginTop: '15px'}}><span className="copyright">Copyright &copy; <img src="/img/png/LogoP-laoBlanco.png" width="85px" alt="LogoP-lao"/> 2020</span></div>
        </div>
    </footer>
)

export default Footer