import React, { Component } from 'react'

import InicioContainer from '../../Components/Inicio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import MedicFilter from './MedicFilter'
import Loader from '../../../Components/Loader'


export default class medicTable extends Component {

    state = {
        nombre: '',
        apellidos: '',
        especialidad: '',
        sub_especialidad: ''
    }

    setFilter = (type, event) => {
        if ( type === 'set' ) {
            this.setState({ [event.target.id]: event.target.value })
        } else {
            this.setState({ nombre: '', apellidos: '', especialidad: '', sub_especialidad: '' })
        }
    }

    render() {
        
        const medicFilter = this.props.usuarios.filter( usuario =>
            usuario.tipo.indexOf('medico') !== -1  &&
            usuario.nombre.toLowerCase().indexOf(this.state.nombre.toLowerCase()) !== -1 &&
            usuario.apellidos.toLowerCase().indexOf(this.state.apellidos.toLowerCase()) !== -1 &&
            usuario.medico.especialidad.toLowerCase().indexOf(this.state.especialidad.toLowerCase()) !== -1 &&
            usuario.medico.sub_especialidad.toLowerCase().indexOf(this.state.sub_especialidad.toLowerCase()) !== -1
        )

        return (
            <div>
    
                <InicioContainer >
                    <div className="container" style={{marginTop: '50px', minHeight: '75vh'}}>

                        <div className='my-5' >
                            <MedicFilter setFilter={this.setFilter} medicos={this.props.usuarios.filter( usuario => usuario.tipo === 'medico' )} />
                        </div>

                        { 
                            this.props.usuarios.length === 0 ? 
                            <div className='justify-content-center align-items-center ' >
                                <Loader />
                            </div> :
                            <div className="table-responsive"> 
            
                                <table className="table text-center table-bordered table-hover table-sm">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col" className="d-none d-md-table-cell" >Fotografía</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Apellidos</th>
                                        <th scope="col" className="d-none d-md-table-cell" >Especialidad</th>
                                        <th scope="col" className="d-none d-md-table-cell" >Sub-Especialidad</th>
                                        {/* <th scope="col" className="d-none d-md-table-cell" >Teléfono</th>
                                        <th scope="col" className="d-none d-lg-table-cell" >Correo</th> */}
                                        <th scope="col">Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            //props.usuarios.filter( usuario => usuario.tipo === 'medico' ).map( usuario => (
                                            medicFilter.map( usuario => (
                                                <tr key={usuario.id}>
                                                <td className="d-none d-md-table-cell align-middle text-center" >{ (usuario.foto_perfil !== null) ? <img  src={usuario.foto_perfil} alt='medico' style={{ height: '100px', borderRadius: '50%' }}  /> : <FontAwesomeIcon icon={faUserCircle} style={{color: '#5DACD4', fontSize: '100px'}} className='mt-3' /> }</td>
                                                <td className="align-middle text-center" >{usuario.nombre}</td>
                                                <td className="align-middle text-center" >{usuario.apellidos}</td>
                                                <td className="d-none d-md-table-cell align-middle text-center" >{usuario.medico.especialidad}</td>
                                                <td className="d-none d-md-table-cell align-middle text-center" >{usuario.medico.sub_especialidad}</td>
                                                {/* <td className="d-none d-md-table-cell" >{usuario.telefono}</td>
                                                <td className="d-none d-lg-table-cell" >{usuario.correo}</td> */}
                                                <td className='align-middle text-center' >

                                                    
                                                    <button id="viewButton" type="button" onClick={() => {
                                                        let display = 'UserView';
                                                        this.props.handleDisplay(display, usuario)
                                                    }} 
                                                        style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}>
                                                        <FontAwesomeIcon icon="eye" />
                                                    </button>

                                                </td>
                                                </tr>
                                            )
                                            
                                            )
                                        }
                                    </tbody>
                                </table>

                            </div>
                            
                        }

    
                        
    
                    </div>
                </InicioContainer >
                
            </div>
        )

    }
}