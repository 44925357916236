import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'
import Loader from '../../../Components/Loader'
import SignaturePad from 'react-signature-canvas';
import moment from 'moment'

import FetchEditCompanyAffiliation from '../containers/FetchAffiliateEdit/FetchGQLAffiliateEmpresa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';

class EmpresaAffiliateEdit extends Component {

    state = {
        fetchSendResponse: 'd-none',
        fetchSendMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        companyId: '',
        companyName: '',
        companyLogo: '',
        afiliacion: {
            id: '',
            metodo_pago: '',
            cuota: '',
            tipo: '',
            inicio_contrato: '',
            firma_contrato: '',
            texto_contrato: '',
            vencimiento_contrato: '',
            cantidad_afiliados_permitidos: '',
            visitas_medicas_maximas_mes: '',
            FK_Empresa: ''
        }
    }

    async componentDidMount() {

        let { id, nombre, logo, afiliacion } = this.props.currentCompany
        let idAfiliacion, firma_contrato, inicio_contrato, metodo_pago, cuota, texto_contrato, tipo, vencimiento_contrato, cantidad_afiliados_permitidos, visitas_medicas_maximas_mes, FK_Empresa
        if ( afiliacion !== null ) {
            idAfiliacion = afiliacion.id
            firma_contrato = afiliacion.firma_contrato
            inicio_contrato = afiliacion.inicio_contrato
            metodo_pago = afiliacion.metodo_pago
            texto_contrato = afiliacion.texto_contrato
            tipo = afiliacion.tipo
            cuota = afiliacion.cuota
            vencimiento_contrato = afiliacion.vencimiento_contrato
            cantidad_afiliados_permitidos = afiliacion.cantidad_afiliados_permitidos
            visitas_medicas_maximas_mes= afiliacion.visitas_medicas_maximas_mes
            FK_Empresa=afiliacion.FK_Empresa
        }

        this.setState({
            ...this.state,
            companyId: id,
            companyName: nombre,
            companyLogo: logo,
            afiliacion: {
                id: idAfiliacion,
                metodo_pago: metodo_pago,
                tipo: tipo,
                cuota: cuota,
                inicio_contrato: inicio_contrato,
                firma_contrato: firma_contrato,
                texto_contrato: texto_contrato,
                vencimiento_contrato: vencimiento_contrato,
                cantidad_afiliados_permitidos: cantidad_afiliados_permitidos,
                visitas_medicas_maximas_mes: visitas_medicas_maximas_mes,
                FK_Empresa: FK_Empresa
            }
        })

        this.firmaEmpresaContrato.fromDataURL(firma_contrato)
        
    }

    editAffiliationFormHandler = (e) => {
        
        e.preventDefault()
        
        let { metodo_pago, cuota, tipo, inicio_contrato, firma_contrato, texto_contrato, vencimiento_contrato, cantidad_afiliados_permitidos, visitas_medicas_maximas_mes, FK_Empresa } = this.state.afiliacion

        let afiliacion = {
            ...this.state.afiliacion,
            metodo_pago: metodo_pago,
            cuota: tipo === 'Paga_Empresa' ? cuota : null,
            tipo: tipo,
            inicio_contrato: moment(inicio_contrato).format('YYYY-MM-DD'),
            firma_contrato: firma_contrato,
            texto_contrato: texto_contrato,
            vencimiento_contrato: moment(vencimiento_contrato).format('YYYY-MM-DD'),
            cantidad_afiliados_permitidos: cantidad_afiliados_permitidos,
            visitas_medicas_maximas_mes: visitas_medicas_maximas_mes,
            FK_Empresa: FK_Empresa,
        }
        
        FetchEditCompanyAffiliation(this, afiliacion)

    }

    onInputChange = (event) => {

        this.setState({ 
            afiliacion: {
                ...this.state.afiliacion,
                [event.target.id]: event.target.value 
            }
        })
        
    }

    onSignatureEndStroke = () => {

        let firma_contrato = this.firmaEmpresaContrato.getTrimmedCanvas().toDataURL("image/png")
    
        this.setState({ ...this.state, afiliacion: { ...this.state.afiliacion, firma_contrato } })
    
    }

    clearSign = () => {

        this.firmaEmpresaContrato.clear();
    
        this.setState({ ...this.state, afiliacion: { ...this.state.afiliacion, firma_contrato: '' } })
    
    }

    render() {

        let { companyLogo, companyName } = this.state
        let { inicio_contrato, metodo_pago, texto_contrato, tipo, cuota, vencimiento_contrato, cantidad_afiliados_permitidos, visitas_medicas_maximas_mes } = this.state.afiliacion

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {
                            id: '',
                            logo: '',
                            nombre: '',
                            direccion: '',
                            telefono: '',
                            correo: '',
                            NIT: '',
                            afiliacion: ''
                        }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        { companyLogo !== null ? <img src={companyLogo} alt={companyName} style={{ height: '150px' }} /> : <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '80px' }} className='mt-3' /> }<br/><br/>
                        <h1>{ companyName }</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='editaffiliationForm' onSubmit={this.editAffiliationFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div>
                                    
                                <h3>Información de la Afiliación</h3>

                                <div className='form-row' >
                                    <div className="form-group col-md-6">
                                        <label htmlFor="metodo_pago">Método de Pago</label>
                                        <select onChange={ this.onInputChange } value={metodo_pago} className="form-control" id="metodo_pago" data-test-type='select' required >
                                            <option value='' >Selecciona una Opción</option>
                                            <option value='Deposito' >Deposito</option>
                                            <option value='Cheque' >Cheque</option>
                                            <option value='Tarjeta' >Tarjeta</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="tipo">Tipo de Afiliación</label>
                                        <select onChange={ this.onInputChange } value={tipo} className="form-control" id="tipo" data-test-type='select' required >
                                            <option value='' >Selecciona una Opción</option>
                                            <option value='Paga_Empresa' >Paga la Empresa</option>
                                            <option value='Paga_Usuario' >Paga el Usuario</option>
                                        </select>
                                    </div>
                                </div>

                                {
                                    this.state.afiliacion.tipo !== 'Paga_Empresa' ? null : 
                                    <div className="form-group col-12 px-0">
                                        <label htmlFor="cuota">Cuota</label>
                                        <input onChange={ this.onInputChange } value={cuota} type="number" className="form-control" id="cuota" data-test-type='input-number' required />
                                    </div>
                                }

                                <div className='form-row' >
                                    <div className="form-group col-md-6">
                                        <label htmlFor="cantidad_afiliados_permitidos">Límite de Afiliados</label>
                                        <input onChange={ this.onInputChange } value={cantidad_afiliados_permitidos} type="number" className="form-control" id="cantidad_afiliados_permitidos" data-test-type='input-number' required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="visitas_medicas_maximas_mes">Límite de Visitas Médicas al Mes</label>
                                        <input onChange={ this.onInputChange } value={visitas_medicas_maximas_mes} type="number" className="form-control" id="visitas_medicas_maximas_mes" data-test-type='input-number' required />
                                    </div>
                                </div>

                                <div className='form-row' >
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inicio_contrato">Fecha de Inicio de Contrato</label>
                                        <input onChange={ this.onInputChange } value={inicio_contrato} type="date" className="form-control" id="inicio_contrato" data-test-type='input-date' required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="vencimiento_contrato">Fecha de Vencimiento de Contrato</label>
                                        <input onChange={ this.onInputChange } value={vencimiento_contrato} type="date" className="form-control" id="vencimiento_contrato" data-test-type='input-date' required />
                                    </div>
                                </div>

                                <div className="form-group col-12">
                                    <SignaturePad 
                                        ref={ ref => this.firmaEmpresaContrato = ref }
                                        canvasProps={{className: "signatureCanvas"}}
                                        onEnd={this.onSignatureEndStroke} 
                                        
                                    />
                                    <p className="text-center">f)__________________________</p>
                                    <button id="clearSignButton" type="button" className="btn btn-info" onClick={this.clearSign}>Limpiar Firma</button>
                                    <div id="alert-signature" className="alert alert-danger mt-2" role="alert" style={{display: this.state.hideAlertMessage ? 'block' : 'none'}}>
                                        Hace falta la firma. Por favor firma en el recuadro.
                                    </div>
                                </div>

                                <div className="form-group col-12">
                                    <label htmlFor="text_contrato">Contenido de Contrato</label>
                                    <textarea onChange={ this.onInputChange } value={texto_contrato}  className="form-control" id="texto_contrato" data-test-type='textarea' />
                                </div>

                            </div>
                            
                            <button type="submit" className="btn btn-success">Editar Afiliación</button>

                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchSendResponse} role="alert">
                                {this.state.fetchSendMessage}
                            </div>

                        </form>

                    </div>

                </div>
            </DasboardComponent>
        )
    }

}

export const mapDispatchToProps = dispatch => {
    return {
        editAfiliacion: ( afiliacion ) => dispatch ( actions.companies.editAfiliacion({ afiliacion }) )
    }
}

export default connect(null, mapDispatchToProps) (EmpresaAffiliateEdit)