import React, { Component } from 'react'

import { NavLink } from 'react-router-dom';

import DasboardComponent from '../../Components/DashboardComponent'
import FetchGetAllAnalysys from '../containers/fetchGetAllAnalysis/FetchGetAllAnalysis'
import FetchDeleteAnalysis from '../containers/fetchDeleteAnalysis/FetchDeleteAnalysis'
import FetchGetAnalysisNames from '../containers/fetchGetAnalysisNames/FetchAnalysisNames'

import AnalysisFilter from './AnalysisFilter'
import Loader from '../../../Components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap4-modal';

export default class AnalysisList extends Component {

    state = {
        cursor: 0,
        limit: 15,
        currentPage: 1,
        totalResultados: 0,
        loaderClassName: 'd-none',
        errorResponseMessage: 'd-none',
        nombre: '',
        apellidos: '',
        analysisName: '',
        DeleteAnalysisValidation: '',
        analysisIdToDelete: '',
        analysisNameList: [],
        analysis: []
    }

    componentDidMount() {

        let {cursor, limit, nombre, apellidos, analysisName} = this.state
        let getAllAnalysisData = {cursor, limit, nombre, apellidos, analysisName}

        FetchGetAnalysisNames(this)
        FetchGetAllAnalysys(this, getAllAnalysisData)
    }

    handleDeleteAnalysis = () => FetchDeleteAnalysis(this, this.state.analysisIdToDelete)

    setFilter = (type, event) => {
        if ( type === 'set' ) {
            this.setState({ [event.target.id]: event.target.value })
        } else if ( type === 'search' ) {
            let {cursor, limit, nombre, apellidos, analysisName} = this.state
            let getAllAnalysisData = {cursor, limit, nombre, apellidos, analysisName}
            FetchGetAllAnalysys(this, getAllAnalysisData)
        } else if ( type === 'select' ) {
            this.setState({ analysisName: event.label })
        } else if ( type === 'reset' ) {
            this.setState({ cursor: 0, limit: 15, currentPage: 1, nombre: '', apellidos: '', analysisName: '' })
            setTimeout(() => {
                let {cursor, limit, nombre, apellidos, analysisName} = this.state
                let getAllAnalysisData = {cursor, limit, nombre, apellidos, analysisName}
                FetchGetAllAnalysys(this, getAllAnalysisData)    
            }, 300);
        }
    }

    pagination = () => {
        let analysis = this.state.analysis
        let limit = this.state.limit
        let pages = []
        for( var i = 0; i < Math.ceil(analysis.length / limit); i++ ) { pages.push(i) }
        return pages.map( page => <button key={page} className='rounded btn btn-info mx-2' >{page + 1}</button> )
    }

    currentPageHandler = (value) => {

        let {nombre, apellidos, analysisName} = this.state

        if ( value === '+' ) {
            if ((nombre !== '' || apellidos !== '' || analysisName !== '')) {
                let nextCursor = this.state.cursor + this.state.limit
                let getAllAnalysisData = { cursor: nextCursor, limit: this.state.limit, nombre, apellidos, analysisName }
                FetchGetAllAnalysys(this, getAllAnalysisData)
                this.setState({ ...this.state, currentPage: this.state.currentPage + 1, cursor: nextCursor })
            } else {
                let nextCursor = this.state.cursor + this.state.limit
                let getAllAnalysisData = { cursor: nextCursor, limit: this.state.limit, nombre: '', apellidos: '', analysisName: '' }
                FetchGetAllAnalysys(this, getAllAnalysisData)
                this.setState({ ...this.state, currentPage: this.state.currentPage + 1, cursor: nextCursor })
            }
        } else if ( value === '-' ) {
            if ( this.state.currentPage > 1 ) {
                let previousCursor = this.state.cursor - this.state.limit
                let getAllAnalysisData = { cursor: previousCursor, limit: this.state.limit, nombre: '', apellidos: '', analysisName: '' }
                FetchGetAllAnalysys(this, getAllAnalysisData)
                this.setState({ ...this.state, currentPage: this.state.currentPage - 1, cursor: previousCursor })
            }
        }
    }

    render() {

        // const analysisFilter = this.state.analysis.resultados.filter( analysis =>
        //     analysis.nombre.toLowerCase().indexOf(this.state.nombre.toLowerCase()) !== -1 &&
        //     analysis.apellidos.toLowerCase().indexOf(this.state.apellidos.toLowerCase()) !== -1 &&
        //     analysis.nombreAnalisis.toLowerCase().indexOf(this.state.analysisName.toLowerCase()) !== -1 
        // )

        return (

            <DasboardComponent>

                <div className="col-11 mx-auto py-5" style={{marginTop: '50px', minHeight: '75vh'}}>

                    <h1 className='mx-auto text-center' >Listado de Análisis</h1>

                    {/* <div className='mx-auto text-center  col-12 col-md-10 col-lg-8' ></div> */}
                    <div className='mb-5' >
                        <AnalysisFilter setFilter={this.setFilter} analysis={this.state.analysisNameList} />
                    </div>

                    <div className='my-5 py-5' >
                        {
                            this.state.analysis.length === 0 ? 
                            <Loader /> :
                            <div className="table-responsive"> 
                                <table className="table text-center table-bordered table-hover table-sm">
                                
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Apellidos</th>
                                        <th scope="col" className="d-none d-md-table-cell" >Teléfono</th>
                                        <th scope="col" className="d-none d-lg-table-cell" >Correo</th>
                                        <th scope="col" className="d-none d-lg-table-cell" >Análisis</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        //props.usuarios.map( usuario => (
                                        this.state.analysis.map( analysis => (
                                            <tr key={analysis.id}>
                                            {/*<th scope="row">{usuario.nombre}</th>*/}
                                            <td>{analysis.nombre}</td>
                                            <td>{analysis.apellidos}</td>
                                            <td className="d-none d-md-table-cell" >{analysis.telefono}</td>
                                            <td className="d-none d-lg-table-cell" >{analysis.correo}</td>
                                            <td className="d-none d-lg-table-cell" >{analysis.nombreAnalisis}</td>
                                            <td className='align-middle text-center' >
                                                <NavLink exact to={`/analisis/${analysis.id}`} className="text-decoration-none" >
                                                    <button id="viewButton" type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}>
                                                        <FontAwesomeIcon icon="eye" title='Visualizar Usuario' />
                                                    </button>
                                                </NavLink>
                                                <button id="deleteButton" onClick={ () => { this.setState({ DeleteAnalysisValidation: true, analysisIdToDelete: analysis.id }) }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}} >
                                                    <FontAwesomeIcon icon="trash-alt" title='Eliminar Usuario' />
                                                </button>

                                            </td>
                                            </tr>
                                        )
                                        
                                        )
                                    }
                                    </tbody>
                                
                                </table>
                            </div>
                        }
                    </div>

                    <div className='my-5 text-center' >
                        
                        { this.state.currentPage === 1 ? null : <button className='rounded btn btn-info mx-2' onClick={ () => this.currentPageHandler('-') } >Anterior</button> }
                        {/* { this.state.totalAnalysisNumber === 0 ? null : this.pagination() } */}
                        <button className='rounded btn btn-info mx-2' style={{ cursor: 'auto' }} >{this.state.currentPage}</button>
                        { ( (this.state.currentPage * this.state.limit) + 1 ) > this.state.totalResultados ? null : <button className='rounded btn btn-info mx-2' onClick={ () => this.currentPageHandler('+') } >Siguiente</button> }
                        

                    </div>


                    <Modal id='DeleteAnalysisValidation' visible={this.state.DeleteAnalysisValidation} fade onClickBackdrop={ () => this.setState({ DeleteAnalysisValidation: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >

                        <div className="modal-body text-center py-4">
                        <button id='registerClose' type="button" className="close" onClick={ () => this.setState({ UpdateMedicStateModal: false }) }>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className='mx-auto col-10 mb-4 py-4' >
                            <h5 className='mb-5' >¿En verdad deseas eliminar este análisis?</h5>
                            <div className='d-flex justify-content-center align-items-center flex-column' >
                                <button onClick={ this.handleDeleteAnalysis } className='btn btn-danger mx-auto mb-4' >Eliminar</button>
                                <button onClick={ () => { this.setState({ DeleteAnalysisValidation: false }) } } className='btn btn-secondary mx-auto' >Cancelar</button>
                            </div>
                            <div className={this.state.loaderClassName} ><Loader /></div>
                            <p className={this.state.errorResponseMessage} >Ha ocurrido un problema en el servidor. Por favor, vuelve a intentarlo más tarde.</p>
                        </div>
                        </div>
                        
                    </Modal>

                    

                </div>
           

            </DasboardComponent>
        )
    }
}