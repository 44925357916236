// JavaScript Document
import GraphQL from './ConsultaGraphQLProducts';

import url from '../../../../../../apiService/ApiUrl';

function handleError(err){
	//console.log(`Request failed: ${err}`);
}

const fetchProducts = (This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL()),
		credentials: 'include'
	}

	This.setState({ showLoader: 'd-block' })
	
	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		
		This.setState({ showLoader: 'd-none' })

        let productos = res.data.appInfo.productos
		let productsData = productos.map( product => {
			return {
				label: product.nombre,
				id: product.id,
				tipo: product.tipo,
				precios_TipoCliente: product.precios_TipoCliente
			}
		})

		This.setState({ productos: productsData })

		// return res

	})
	.catch(err => {
		handleError(err)
		This.setState({ showLoader: 'd-none' })
	});

	//termina fetch
}

export default fetchProducts;