import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import actions from '../../../../actions'
// import FetchGraphQLMedics from './FetchGetMedics/FetchGraphQLMedics'

import AdminPerfilSelect from '../Components/AdminPerfilSelect'
import AdminPerfilView from '../Components/AdminPerfilView'
import AdminPerfilEdit from '../Components/AdminPerfilEdit'
import AdminPerfilDocs from '../Components/AdminPerfilDocs'
import AdminPerfilProcedures from '../Components/AdminPerfilProcedures'
// import DasboardComponent from '../../Components/DashboardComponent'

class AdminPerfil extends Component {

    state = {
        display: ''
    }

    componentDidMount(){

        if((this.props.conexion === false) || (this.props.usuario.permisos.AdminUser === null)){
			return <Redirect to='/login' />
        }

    }

    handleDisplay = display => {
        this.setState({ display })
    }

    render() {

        if ((this.props.conexion === false) || (this.props.usuario.permisos.MedicList === null)) {
			
            return <Redirect to='/login' />

		} else {

            if (this.state.display === '') {
                return <AdminPerfilSelect handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ProfileView') {
                return <AdminPerfilView usuario={this.props.usuario} handleDisplay={this.handleDisplay}/>
            } else if (this.state.display === 'ProfileEdit') {
                return <AdminPerfilEdit usuario={this.props.usuario} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ProceduresEdit') {
                return <AdminPerfilProcedures usuario={this.props.usuario} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ProfileDocuments') {
                return <AdminPerfilDocs usuario={this.props.usuario} handleDisplay={this.handleDisplay} />
            } else {
                return null
            }

        }

    }
}

const mapStateToProps = state => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUsuarios: (usuarios) => dispatch (actions.users.fetchUsers({ usuarios })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (AdminPerfil)