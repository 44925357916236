import React, { Component } from 'react';

import { connect } from 'react-redux';

import LeftSideNavbar from './Containers/LeftSideNavbar.jsx';
import LeftSideToggler from './Containers/LeftSideToggler.jsx';
import TopNavbar from './Containers/TopNavbar.jsx';

import { NavLink } from 'react-router-dom';

import './css/Navbar-colors.min.css';
import './css/Navbar-fixed.min.css';
import './css/Navbar-global.min.css';
import './css/Navbar-static.min.css';
import './css/Navbar-toggle.min.css';
import './font-awesome/css/font-awesome.min.css'
//import '../../js/sb-admin.js'

class Navbar extends Component {
  render(){
    return (
      <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top" id="mainNav">
        {/* Navigation*/}
        <NavLink exact to="/dashboard" activeClassName="active" className="nav-link active">
          <img src="/img/Logo.png" style={{height: '2.5em', paddingLeft: '25px'}} alt="logoimg"/>
        </NavLink>
        <button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="sr-only">Toggle navigation</span>
        </button>
        {/*<button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>*/}
        <div className="collapse navbar-collapse justify-content-center justify-content-md-end" id="navbarResponsive">
  
          <LeftSideNavbar logIn={this.props.logIn}/>
  
          <LeftSideToggler />
  
          <TopNavbar />
  
        </div>
      </nav>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
      logIn: state.logIn
  }
}

export default connect(mapStateToProps)(Navbar)