let setProducts = payload => ({
    type: 'FETCH_PRODUCTS',
    payload
})

let createProduct = payload => ({
    type: 'CREATE_PRODUCT',
    payload
})

let editProduct = payload => ({
    type: 'EDIT_PRODUCT',
    payload
})

let deleteProduct = payload => ({
    type: 'DELETE_PRODUCT',
    payload
})

let changeProductPrices = payload => ({
    type: 'CHANGE_PRODUCT_PRICES',
    payload
})

module.exports = { setProducts, createProduct, editProduct, deleteProduct, changeProductPrices }