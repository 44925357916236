import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
	const message = 'Ha ocurrido un problema. Por favor, vuelve a intentarlo más tarde.'
	This.setState({ loaderClassName: 'd-none', sendButtonClassName: 'btn btn-success mt-5', sendEmailResponseClassName: 'text-danger font-weight-bold my-5', sendEmailResponseMessage: message })

	setTimeout(() => {
		This.setState({ fetchResponseClassName: 'd-none' })
	}, 3000);
}

const sendAnalysisEmail = (This, analysisId) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(analysisId)),
		credentials: 'include'
	}

	This.setState({ loaderClassName: 'd-block my-5', sendButtonClassName: 'd-none' })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( res => {

        This.setState({ 
			loaderClassName: 'd-none', 
			sendButtonClassName: 'btn btn-info my-5', 
			sendEmailResponseClassName: 'text-success font-weight-bold my-5',
			sendEmailResponseMessage: 'El correo ha sido enviado con éxito.'
		})

		setTimeout(() => {
			This.setState({ sendEmailResponseClassName: 'd-none' })
		}, 3000);

		// return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default sendAnalysisEmail