let consulta = () => {

  const string =`
    query {
      appInfo {
        productos {
          id
          nombre
          precios_TipoCliente {
            idTipoCliente
            precio
          }
        }
      }
    }
  `

    return {
      "query": string
    }

}

export default consulta