let consulta = (empresaJSON) => {

  const { metodo_pago, cuota, tipo, inicio_contrato, firma_contrato, texto_contrato, vencimiento_contrato, cantidad_afiliados_permitidos, visitas_medicas_maximas_mes, FK_Empresa } = empresaJSON

  let string = `
    mutation {
      empresaMutation {
        insertAfilacion (
          insertAfilacion: {
            metodo_pago: ${metodo_pago}
            cuota: ${cuota}
            tipo: ${tipo}
            inicio_contrato: "${inicio_contrato}"
            firma_contrato: "${firma_contrato}"
            texto_contrato: "${texto_contrato}"
            vencimiento_contrato: "${vencimiento_contrato}"
            cantidad_afiliados_permitidos: ${cantidad_afiliados_permitidos}
            visitas_medicas_maximas_mes: ${visitas_medicas_maximas_mes}
            FK_Empresa: "${FK_Empresa}"
          }
        ) {
          afiliacion {
            id
            metodo_pago
            cuota
            tipo
            inicio_contrato
            firma_contrato
            texto_contrato
            vencimiento_contrato
            cantidad_afiliados_permitidos
            visitas_medicas_maximas_mes
            FK_Empresa
          }
          mensaje
        }
      }
    }
  `
  
  return {
    "query": string
  }

}

export default consulta;