import React, { Component } from 'react'
import { connect } from 'react-redux';

import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import actions from '../../../../../actions';
import DasboardComponent from '../../../Components/DashboardComponent'
import Loader from '../../../../Components/Loader'
import FetchCreatePanel from '../Containers/fetchCreatePanel/FetchGraphQLPanel'



export class PanelCreate extends Component {

    state = {
        loaderClassName: 'd-none',
        fetchCreateResponse: 'd-none',
        fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        productos: [],
        currentItem: {
            id: '',
            nombre: ''
        },
        panel: {
            nombre: "",
            productos: []
        }
    }

    componentDidMount() {
        const productos = this.props.productos.map( element => ({ id: element.id, label: element.nombre, value: element.nombre }))
        this.setState({ productos })
    }

    createFormHandler = (e) => {
        
        e.preventDefault()

        let { nombre, productos } = this.state.panel

        let panel = { ...this.state.panel, nombre, idsProductos: productos.map( producto => producto.id ) }

        FetchCreatePanel(this, panel)

    }

    onInputChange = (event) => {
        this.setState({ 
            panel: {
                ...this.state.panel,
                [event.target.id]: event.target.value 
            }
        })
    }

    addProductHandler = () => {
        let id = this.state.currentItem.id
        let nombre = this.state.currentItem.nombre
        let newProduct = { id, nombre }

        let isItemListed = this.state.panel.productos.map( producto => producto.id ).indexOf(this.state.currentItem.id) !== -1
        let isItemSelected = this.state.currentItem.id.length !== 0
        if ( !isItemListed && isItemSelected) {
            this.setState({ panel: { ...this.state.panel, productos: [ ...this.state.panel.productos, newProduct ]} })
        }
    }

    removeProductHandler = (element, productId) => {
        let list = this.state.panel.productos
        let listedProducts = list.filter( el => el.id !== productId )
        this.setState({ panel: { ...this.state.panel, productos: listedProducts } })
    }

    render() {

        const { nombre, productos } = this.state.panel

        return (
            <DasboardComponent>

                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect" onClick={ () => this.props.handleDisplay('', {}) } className='col-12 mr-auto' >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        <h1>Crea un Panel</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='createForm' onSubmit={this.createFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className="form-row">
                                <div className="form-group col-md-6 mx-auto">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input onChange={ (event) => this.setState({ panel: { ...this.state.panel, [event.target.id]: event.target.value.toUpperCase() } }) } style={{ textTransform: 'uppercase' }} type="text" className="form-control" id="nombre" required />
                                </div>
                            </div>


                            <div className="form-row py-5">
                                <div className="form-group col-12 mx-auto">
                                    <label htmlFor="productos">Busca un Producto</label>
                                    <table className='table text-center table-bordered table-hover table-sm py-5 col-8 mx-auto' >
                                            <tbody>
                                                <tr>    
                                                    <td>
                                                        <Select 
                                                            options={this.state.productos} 
                                                            onChange={ (element) => {
                                                                
                                                                let id = element.id
                                                                let nombre = element.label
                                                                let currentItem = { ...this.state.currentItem, id, nombre }
                                                                this.setState({ currentItem }) 
                                                            }}
                                                            required
                                                        />
                                                    </td>
                                                    <td className='align-middle text-center' ><FontAwesomeIcon type='submit' onClick={ this.addProductHandler } icon="plus-circle" className='text-info' style={{ cursor: 'pointer', fontSize: '1.2em' }} title='Agregar' /></td>
                                                </tr>

                                            </tbody>
                                        </table> 
                                </div>
                            </div>

                            <table className='table text-center table-bordered table-hover table-sm' >
                                    <thead>
                                        <tr>
                                            <th>Producto</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            productos.length > 0 ? 
                                            productos.map( producto => 
                                                <tr key={producto.id} >
                                                    <td>{producto.nombre}</td>
                                                    <td>{ <button id="deleteButton" onClick={ (e) => this.removeProductHandler(e, producto.id) } type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}} > <FontAwesomeIcon icon="trash-alt" title='Eliminar Producto' /> </button> } </td>
                                                </tr> 
                                            ) : <tr><td colSpan='2' >Agrega nuevos productos a este panel.</td></tr>
                                        }
                                    </tbody>
                                </table>
                            
                            { (productos.length > 0 && nombre.length > 0) ? <button type="submit" className="btn btn-success mt-5">Crear Panel</button> : null }
                            

                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchCreateResponse} role="alert">
                                {this.state.fetchCreateMessage}
                            </div>

                        </form>

                    </div>

                </div>

            </DasboardComponent>
        )
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        createPanel: (panel) => dispatch (actions.panels.createPanel({ panel }))
    }
}

export default connect(null, mapDispatchToProps) (PanelCreate)
