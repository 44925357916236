import React, { Component } from 'react'
import { connect } from 'react-redux'

import actions from '../../../../../actions';
import DasboardComponent from '../../../Components/DashboardComponent'
import FetchCreateTipoCliente from '../Containers/fetchCreateTipoCliente/FetchGraphQLCreateTipoCliente'
import Loader from '../../../../Components/Loader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';

class ClientTypeCreate extends Component {

    state = {
        fetchCreateResponse: 'd-none',
        fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        clientTypeName: ''
    }

    createFormHandler = (e) => {
        
        e.preventDefault()

        let clientTypeName = this.state.clientTypeName

        FetchCreateTipoCliente(this, clientTypeName)

    }

    onInputChange = (event) => {

        this.setState({ [event.target.id]: event.target.value })
        
    }

    render() {

        return (
            <DasboardComponent >
            
                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', { id: '', nombre: '' }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '80px', color: '#5DACD4' }} className='mt-3' /><br/><br/>
                        <h1>Crea un Nuevo Tipo de Cliente</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='createForm' onSubmit={this.createFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className='form-row py-5' >
                                
                                <div className="form-group col-md-6 mx-auto">
                                    <label htmlFor="clientTypeName">Nombre del Tipo de Cliente</label>
                                    <input value={this.state.clientTypeName} onChange={ this.onInputChange } type="text" className="form-control" id="clientTypeName" data-test-type='input-text' required />
                                </div>

                            </div>

                            <button type="submit" className="btn btn-success mt-4">Crear</button>
                            
                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchCreateResponse} role="alert">
                                {this.state.fetchCreateMessage}
                            </div>

                        </form>

                    </div>

                </div>
            
            </DasboardComponent>
        )
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        createClientType: (clientTypeName) => dispatch (actions.clientTypes.createClientType({ clientTypeName }))
    }
}

export default connect(null, mapDispatchToProps) (ClientTypeCreate)