import React, { Component } from 'react'
import { connect } from 'react-redux';
import actions from '../../../../../actions';
import DasboardComponent from '../../../Components/DashboardComponent'
import fetchChangePrices from '../Containers/fetchChangePrices/FetchGraphQLChangePrices'

import Loader from '../../../../Components/Loader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


export class ProductPricesChange extends Component {

    state = {
        adding: false,
        editing: false,
        editingElement: {},
        loaderClassName: 'd-none',
        fetchSaveResponse: 'd-none',
        fetchSaveMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        preciosTipoCliente: [],
        newPrice: {},
        editedPrice: {}
    }

    componentDidMount() {

        let { precios_TipoCliente  } = this.props.currentProduct
        this.setState({ preciosTipoCliente: precios_TipoCliente })
        
    }

    savePricesFormHandler = (e) => {
        
        e.preventDefault()

        let precios = [ ...this.state.preciosTipoCliente ]
        let productId = this.props.currentProduct.id

        fetchChangePrices( this, precios, productId )

    }

    addNewPriceHandler = (e) => {
        e.preventDefault()
        if (this.state.adding) {
            let preciosTipoCliente = this.state.preciosTipoCliente
            let newObject = this.state.newPrice
            preciosTipoCliente.push(newObject)
            this.setState({ preciosTipoCliente, adding: false })
        } else {
            this.setState({ adding: true })
        }
    }

    editPriceHandler = (event) => {
        event.preventDefault()

        let editedPrice = { idTipoCliente: this.state.editingElement.id, precio: this.state.editingElement.precio }
        let editedPriceIndex = this.state.preciosTipoCliente.findIndex( element => element.idTipoCliente === this.state.editingElement.id ) 
        let editedArray = [...this.state.preciosTipoCliente]
        editedArray[editedPriceIndex] = editedPrice

        this.setState({ editing: false, preciosTipoCliente: editedArray })
    }

    removePriceHandler = (id) => {
        let preciosTipoCliente = this.state.preciosTipoCliente.filter( element => element.idTipoCliente !== id)
        this.setState({ preciosTipoCliente })
    }

    render() {

        let availableClientTypes = this.props.tiposCliente.filter( tipo => !this.state.preciosTipoCliente.map( element => element.idTipoCliente ).includes(tipo.id) )

        return (
            <DasboardComponent>

                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {}) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        <h1>{ this.props.currentProduct.nombre }</h1>
                        <h4>Cambio de Precios por Tipo de Cliente</h4>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        { availableClientTypes.length !== 0 ?
                            (!this.state.adding ? <div className='py-5 col-8 mx-auto' ><button onClick={ this.addNewPriceHandler } className='btn btn-info' type='button' >Nuevo Precio <FontAwesomeIcon icon="plus-circle" /></button></div> :
                                <form onSubmit={this.addNewPriceHandler} >
                                    <table className='table text-center table-bordered table-hover table-sm py-5 col-8 mx-auto' >
                                        <tbody>
                                            <tr>
                                                    <td>
                                                        <select onChange={ (event) => this.setState({ ...this.state, newPrice: { ...this.state.newPrice, idTipoCliente: event.target.value } }) } className='form-control' >
                                                            <option value='' >Tipo</option>
                                                            { availableClientTypes.map( (tipo, i) => <option key={i} value={tipo.id} >{tipo.nombre}</option>) }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type='number' placeholder='Precio' className='form-control' onChange={ (event) => this.setState({ ...this.state, newPrice: { ...this.state.newPrice, precio: parseInt(event.target.value) } }) } />
                                                    </td>
                                                    <td className='align-middle text-center' ><FontAwesomeIcon onClick={ this.addNewPriceHandler } icon="plus-circle" className='text-info' style={{ cursor: 'pointer', fontSize: '1.2em' }} title='Agregar' /></td>
                                            </tr>

                                        </tbody>
                                    </table> 
                                </form>
                            ) : null
                        }
                            
                        <div className='pb-5 col-10 col-md-8 mx-auto text-center mt-5' >
                            
                            {   this.state.preciosTipoCliente.length === 0 ? <p>No se ha encontrado ningún precio registrado.</p> :
                                <table className='table text-center table-bordered table-hover table-sm' >
                                    <thead>
                                        <tr>
                                            <th>Tipo de Cliente</th>
                                            <th>Precio</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.preciosTipoCliente.map( (tipo, i) => {
                                            
                                            return <tr key={i} >
                                                <td className='align-middle text-center' >{ this.props.tiposCliente.filter( element => element.id === tipo.idTipoCliente )[0].nombre }</td>
                                                <td className='align-middle text-center' >{ (this.state.editing && this.state.editingElement.id === tipo.idTipoCliente) ? <form onSubmit={ this.editPriceHandler } ><input type='number' placeholder='Precio' className='form-control' onChange={ (event) => this.setState({ editingElement: { ...this.state.editingElement, precio: parseInt(event.target.value) } }) } value={this.state.editingElement.precio} id='priceEditInput' required /></form> : `Q.${ tipo.precio.toFixed(2) }` }</td>
                                                <td className='align-middle text-center' >
                                                    { !this.state.editing && <FontAwesomeIcon onClick={ () => this.setState({ editing: true, editingElement: { id: tipo.idTipoCliente, precio: tipo.precio } }) } icon="edit" className='text-info' style={{ cursor: 'pointer', margin: '0px 5px' }} title='Editar' /> }
                                                    { !this.state.editing ? this.state.preciosTipoCliente.length <= 1 ? null : <FontAwesomeIcon onClick={() => this.removePriceHandler(tipo.idTipoCliente)} icon="trash-alt" className='text-info' style={{ cursor: 'pointer', margin: '0px 5px' }} title='Eliminar' /> : null } 
                                                    {/* { !this.state.editing ? tipo.idTipoCliente === '611d818c875d7d003625bc80' ? null : <FontAwesomeIcon onClick={() => this.removePriceHandler(tipo.idTipoCliente)} icon="trash-alt" className='text-info' style={{ cursor: 'pointer', margin: '0px 5px' }} title='Eliminar' /> : null }  */}
                                                    { ( this.state.editing && this.state.editingElement.id === tipo.idTipoCliente && typeof this.state.editingElement.precio === 'number' && this.state.editingElement.precio > 0 ) && <FontAwesomeIcon onClick={ this.editPriceHandler } icon="save" className='text-info' style={{ cursor: 'pointer', margin: '0px 5px' }} title='Guardar' /> }
                                                    { ( this.state.editing && this.state.editingElement.id === tipo.idTipoCliente ) && <FontAwesomeIcon onClick={ () => this.setState({ editing: false }) } icon="times" className='text-info' style={{ cursor: 'pointer', margin: '0px 5px' }} title='Guardar' /> }
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>

                            }
                        </div>
                        
                        <button onClick={ this.savePricesFormHandler } className="btn btn-success">Guardar</button>

                        <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchSaveResponse} role="alert">
                                {this.state.fetchSaveMessage}
                            </div>

                    </div>

                </div>

            </DasboardComponent>
        )
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        changeProductPrices: (productoId, precios) => dispatch (actions.products.changeProductPrices({ productoId, precios }))
    }
}

export default connect(null, mapDispatchToProps) (ProductPricesChange)
