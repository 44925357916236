import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
}

const registerMedico = (This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL()),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		const analysisNameList = res.data.appInfo.productos.map( examen => {
			let nombre = examen.nombre
			let newObject = { value: nombre, label: nombre }
			return newObject
		} )

        This.setState({ analysisNameList: [ { value: 'COVID-19', label: 'COVID-19' }, ...analysisNameList ] })
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default registerMedico