// export default async () => {
//         return fetch('https://restcountries.eu/rest/v2/all')
//         .then(res => res.json())
//         .catch( err => err )
        
// }

let fetchCountries =  () => {
        return fetch('https://restcountries.eu/rest/v2/all')
        .then(res => res.json())
        .catch( err => err )
}

export default fetchCountries