let fetchUsers = payload => ({
    type: 'FETCH_USERS',
    payload
})

let createUser = payload => ({
    type: 'CREATE_USER',
    payload
})

let editUser = payload => ({
    type: 'EDIT_USER',
    payload
})

let deleteUser = payload => ({
    type: 'DELETE_USER',
    payload
})

let updateMedicoState = payload => ({
    type: 'UPDATE_MEDICO_STATE',
    payload
})

let autoMedicEdit = payload => ({
    type: 'AUTO_MEDIC_EDIT',
    payload
})

let autoUploadMedicDocument = payload => ({
    type: 'UPLOAD_MEDIC_DOCUMENT',
    payload
})

let editAffiliatesToEmpresa = payload => ({
    type: 'EDIT_AFILIACION_TO_EMPRESA',
    payload
})

let editConsulta = payload => ({
    type: 'EDIT_CONSULTA',
    payload
})

let editProcedimientos = payload => ({
    type: 'EDIT_PROCEDIMIENTO',
    payload
})

let autoProcedimientosEdit = payload => ({
    type: 'AUTO_EDIT_PROCEDIMIENTO',
    payload
})

module.exports = { fetchUsers, createUser, editUser, deleteUser, updateMedicoState, autoMedicEdit, autoUploadMedicDocument, editAffiliatesToEmpresa, editConsulta, editProcedimientos, autoProcedimientosEdit }