import GraphQL from './ConsultaGraphQLDeleteEmpresa';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err){
	//console.log(`Request failed: ${err}`);
}

const FetchDeleteEmpresa = (This, empresa) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL( empresa )),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		let data = res.data.empresaMutation.deleteEmpresa
		let idEmpresa = data.idEmpresa
		// let mensaje = data.mensaje

		This.props.removeCompany(idEmpresa)

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default FetchDeleteEmpresa;