let consulta = (analysisId) => {

    const string =`
      mutation {
        resultadosAnalaisisMutation {
          deleteReslutadoAnalisis (
            idResultado: "${analysisId}"
          ) {
            id
            mensaje
          }
        }
      }
    `
  
      return {
        "query": string
      }
  
  }
  
  export default consulta