let consulta = () => {
    let string = `
        {
          appInfo {
            paneles {
              id
              nombre
              idsProductos
            }
          }
        }
    `
  
    return string
  
  }
  
  export default consulta;