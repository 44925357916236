import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

import actions from '../../../../../actions';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
}

const registerMedico = (This, usuarioJSON) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(usuarioJSON)),
		credentials: 'include'
	}

	This.setState({ loaderClassName: 'd-block', registerButton: 'd-none' })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		const data = res.data.userMutation.registerMedico
		const conexion = data.conexion
		const usuario = data.usuario
		const message = data.mensaje

		This.setState({ fetchCreateResponse: 'mt-4 col-8 mx-auto alert alert-success', fetchCreateMessage: message, loaderClassName: 'd-none' })

		setTimeout(() => {
			This.setState({ fetchCreateResponse: 'd-none', fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.' })
			This.props.dispatch(actions.logIn.logIn({ 
				logIn: {
				   conexion,
				   usuario
				} 
			}))
		}, 2000);


		return res
	})
	.catch(err => {
		handleError(err, This)
		This.setState({ fetchCreateResponse: 'mt-4 col-8 mx-auto alert alert-danger', loaderClassName: 'd-none', registerButton: 'btn btn-outline-info mx-auto' })
		setTimeout(() => {
			This.setState({ fetchCreateResponse: 'd-none', fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.' })
		}, 2000);
	});

	//termina fetch
}

export default registerMedico