import React from 'react';

import InicioContainer from '../../Components/Inicio.jsx';
import Nosotros from '../../Nosotros/Components/Nosotros.jsx';

import { NavHashLink } from 'react-router-hash-link';
import Slider from "react-slick";
import home1 from './assets/home1.jpeg'
import home2 from './assets/home2.jpeg'
import home3 from './assets/home3.jpeg'

import famgt from '../../../assets/famgt.png'

import './css/Home.css';

let Home = props => (
    <InicioContainer>
        <section id="inicio" className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center">Bienvenido a CDM</h1>
                    <h2 className="text-center">Contamos con servicios especializados para ti y tu familia</h2>
                </div>

                <NavHashLink className="mx-auto" exact to="/#Nosotros" activeClassName="active">
                    <button type="button" className="btn btn-info">Más de Nosotros</button>
                </NavHashLink>

            </div>
        </section>
        <div className='py-2 rounded-pill position-fixed text-info text-center grow' onClick={ () => { document.getElementById('registerModal').click() } } style={{ left: '20px', bottom: '25px', cursor: 'pointer', zIndex: 10 }} title='¡Afiliate y Beneficiate!' >
            <img src={famgt} style={{ height: '50px' }} alt='familia guatemala' />
            {/* <p className='m-0' >¡Afiliate Gratuitamente!</p> */}
        </div>
        <Nosotros />
        <section id="familia-guatemala" className="container mx-auto text-center" style={{marginTop: '25px', marginBottom: '25px'}}>

            <img src={famgt} style={{ maxHeight: '200px' }} className='img-fluid' alt='familia guatemala' />
            <p className="text-justify">Familia Guatemala es un plan de servicios médicos nuevo en el mercado ofreciendo un beneficio a sus afiliados con más de 1,000 beneficios en la industria médica; siendo una ayuda al momento de la urgencia del afiliado en donde pueda ahorrarse dinero en exámenes de diagnóstico, paquetes de maternidad, cirugías, hospitalizaciones, emergencias, medicina y consultas con médicos.</p>
            <br/>
            <p className="text-justify">Salud preventiva a tu alcance, buscamos ser parte de las familias guatemaltecas para tener acceso a la mejor atención medica brindando atención en diferentes especialidades desde medicina general hasta médicos de alta especialidad, ser un ahorro en los gastos de laboratorios, ultrasonidos, rayos-x y tomografías, brindar un apoyo al afiliado en los gastos que no están presupuestados.</p>
            <br/>
            <p className="text-justify">Un plan de servicios de salud para el acceso de la población guatemalteca que se limita al pago de altas mensualidades de seguros médicos; desde Q.38 mensuales por integrante puede ofrecerles salud de calidad a su familia, ofreciéndole tres opciones diferentes al afiliado con el fin de satisfacer sus necesidades.</p>
            
            <div className="row">
                <div className="col-12">
                    <h3 className="text-center">Adquiere tu Plan de Servicios Médicos</h3>
                </div>
                <div className="col-12 mx-auto text-center my-4" style={{marginTop: '25px'}}>
                    <Slider autoplay={true} arrows={false} dots={true} infinite={true} speed={500} >
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                            <img src={home1} alt="Examenes de Sangre" className="img-fluid rounded" />
                        </div>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                            <img src={home2} alt="Ultrasonido" className="img-fluid rounded" />
                        </div>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                            <img src={home3} alt="Examenes de Sangre" className="img-fluid rounded" />
                        </div>
                    </Slider>
                    {/* <img src="/img/jpg/familiaGuatemala.jpg" alt="Familia Guatemala" className="img-fluid" /> */}
                </div>
            </div>
        </section>
    </InicioContainer>
)

export default Home