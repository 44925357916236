let consulta = () => {
    let string = `
      {
        appInfo {
          empresas {
            id
            nombre
            direccion
            telefono
            NIT
            correo
            logo
            afiliacion {
              id
              metodo_pago
              tipo
              cuota
              inicio_contrato
              firma_contrato
              texto_contrato
              vencimiento_contrato
              cantidad_afiliados_permitidos
              visitas_medicas_maximas_mes
              FK_Empresa
            }
          }
        }
      }
    `
  
    return string
  
  }
  
  export default consulta;