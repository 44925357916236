const config = {
    envType: process.env.REACT_APP_ENVIROMENT_TYPE,
    apiUrl: process.env.REACT_APP_API_URL,
    apiWssUrl: process.env.REACT_APP_WSS_API_URL,
    redux: {
        remoteDevTools: process.env.REACT_APP_REMOTE_REDUX_DEV_TOOLS === 'true' ? true : false,
        remoteHostname: process.env.REACT_APP_REMOTE_REDUX_DEV_TOOLS_HOSTNAME,
        remotePort: parseInt(process.env.REACT_APP_REMOTE_REDUX_DEV_TOOLS_PORT)
    }
}

export default config