let consulta = (empresaJSON) => {

  const { logo, nombre, direccion, telefono, correo, NIT } = empresaJSON

  let string = `
  mutation {
    empresaMutation {
      insertEmpresa (insertEmpresa: {
        nombre: "${nombre}",
        direccion: "${direccion}",
        telefono: "${telefono}",
        NIT: "${NIT}",
        correo: "${correo}",
        logo: "${logo}"
      }) {
        empresa {
          id
          nombre
          direccion
          telefono
          NIT
          correo
          logo
          afiliacion {
            id
            metodo_pago
            tipo
            inicio_contrato
            firma_contrato
            texto_contrato
            vencimiento_contrato
            cantidad_afiliados_permitidos
            visitas_medicas_maximas_mes
          }
        }
        mensaje
      }
    }
  }
  `
  
  return {
    "query": string
  }

}

export default consulta;