let consulta = (empresa) => {

    let {id, afiliacion} = empresa
    let idAfiliacion

    if (afiliacion !== null) { idAfiliacion = afiliacion.id } else { idAfiliacion = null }
    
    let string = `
      mutation {
        empresaMutation {
          deleteEmpresa (
            idEmpresa: "${id}"
            idAfiliacion: "${idAfiliacion}"
          ) {
            idEmpresa
            mensaje
          }
        }
      }
    `

    return {
      "query": string
    }
  }
  
  export default consulta;