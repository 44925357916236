let consulta = (precios, productId) => {

  let string = `
    mutation {
      productosMutation {
        changePreciosProducto (
          idProducto: "${productId}"
          precios: [${precios.map( precio => `{idTipoCliente: "${precio.idTipoCliente}", precio: ${precio.precio}}`)}]
        ) {
          idTipoCliente
          precio
        }
      }
    }
  `

  return {
    "query": string
  }

}

export default consulta;