import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
}

const getAnalysis = (This, analysisId) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(analysisId)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		const analysis = res.data.appInfo.resultadoAnalisis
		const { nombre, apellidos, correo, telefono, nombreAnalisis, documentoAnalisis } = analysis
		

        This.setState({ nombre, apellidos, correo, telefono, nombreAnalisis, documentoAnalisis })

		// return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default getAnalysis