import React, { Component } from 'react';
import { connect } from 'react-redux'

import JoditEditor from "jodit-react";
// import parse from 'html-react-parser';

import actions from '../../../../actions';

// import loader from './assets/loader.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import FetchEditProcedimientos from '../Containers/FetchEditProcedimientos/FetchGQLEditProcedimientos'
import DasboardComponent from '../../Components/DashboardComponent'

class ProcedimientosMedico extends Component {

    state = {
        fetchEditConsultaResponse: 'd-none',
        fetchEditConsultaMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        editState: false,
        procedimientos: '',
        joditConfig: {
            readonly: true,
            toolbar: false,
            buttons: "bold,italic,underline,strikethrough,ul,ol,indent,outdent,font,fontsize",
            language: 'es',
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            enter: 'DIV'
        }
    }

    componentDidMount() {

        let data_procedimientos = this.props.usuario.medico.data_procedimientos
        let procedimientos

        if ( data_procedimientos !== null ) {
            procedimientos = data_procedimientos.html
        } else {
            procedimientos = ''
        }

        this.setState({ procedimientos })

    }

    onFormSubmit = (event) => {
        event.preventDefault()

        if (this.state.editState === true) {
            let html = this.state.procedimientos
            let medicoId = this.props.usuario.medico.id
            
            const data = { medicoId, dataProcedimientos: { html } }
    
            FetchEditProcedimientos(this, data)
        } else {
            this.setState({ editState: true, joditConfig: { ...this.state.joditConfig, readonly: false, toolbar: true } })
        }

    }

    render() {

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
        
                    <div id="showTableButton" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('', {
                        id: '',
                        usuario: '',
                        nombre: '',
                        apellidos: '',
                        correo: '',
                        fecha_de_nacimiento: new Date(),
                        password: '',
                        direccion: '',
                        nacionalidad: 'Guatemala',
                        sexo: '',
                        telefono: 0,
                        tipo: '',
                        dpi: '',
                        foto_perfil: '',
                        permisos: {
                            AdminUser: {
                                ViewUser: false,
                                CreateUser: false,
                                EditUser: false,
                                DeleteUser: false,
                                UpdateStateMedic: false
                            }
                        },
                        colaborador: {
                            id_colaborador: '',
                            nit_colaborador: '',
                            puesto: '',
                            departamento: ''
                        },
                        medico: {
                            estado: 'No Validado',
                            id_medico: '',
                            nit_medico: '',
                            numero_colegiado: '',
                            clinica_nombre: '',
                            clinica_departamento: '',
                            clinica_municipalidad: '',
                            clinica_direccion: '',
                            clinica_telefono: '',
                            clinica_correo: '',
                            clinica_direccion2: '',
                            clinica_telefono2: '',
                            clinica_correo2: '',
                            clinica_direccion3: '',
                            clinica_telefono3: '',
                            clinica_correo3: '',
                            especialidad: '',
                            sub_especialidad: '',
                            observaciones: '',
                            observaciones_emergencias: '',
                            social_media: {
                                fb_link: '',
                                ins_link: '',
                                web_link: ''
                            },
                            atencion_emergencias: '',
                            financiero: {
                                cuenta_numero: '',
                                cuenta_nombre: '',
                                cuenta_tipo: '',
                                banco_nombre: '',
                                factura_nombre: '',
                                documentos: {
                                    dpi_front: '',
                                    dpi_back: '',
                                    licencia_sanitaria: '',
                                    patente: '',
                                    recibo_colegiado: '',
                                    titulo_academico: '',
                                    constancia_desechos_solidos: '',
                                    rtu: '',
                                    factura: '',
                                }
                            },
                            horario_atencion: [
                                {
                                    dia: 'lunes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'martes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'miercoles',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'jueves',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'viernes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'sabado',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'domingo',
                                    ingreso: '',
                                    egreso: ''
                                }
                            ],
                            data_consultas: {
                                nombre: '',
                                descripcion: '',
                                precioNormal: '',
                                descuento: '',
                                precioFinal: ''
                            },
                            data_procedimientos: {
                                html: ''
                            }
                            
                        }
                    }) }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>
        
                    <h4 className='text-center mb-5' >Procedimientos</h4>

                    <JoditEditor
                        ref={ ref => this.editor = ref }
                        value={this.state.procedimientos}
                        config={this.state.joditConfig}
                        tabIndex={1} 
                        onBlur={ newContent => this.setState({ procedimientos: newContent }) }
                        onChange={ newContent => { this.setState({ procedimientos: newContent }) } }
                    />

                    <div className='mx-auto text-center my-3'>
                        <input className='bg-info rounded p-2 text-center text-white shadow border-0 mt-5' type='submit' onClick={this.onFormSubmit} value={ this.state.editState === true ? 'Guardar' : 'Editar' } />
                        <p className={this.state.fetchEditConsultaResponse} >{this.state.fetchEditConsultaMessage}</p>
                    </div>

                </div>
            </DasboardComponent>
        )
    }
} 

export const mapDispatchToProps = dispatch => {
    return {
        autoProcedimientosEdit: ( data ) => dispatch ( actions.users.autoProcedimientosEdit({ data }) )
    }
}

export default connect(null, mapDispatchToProps) (ProcedimientosMedico)