import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';

import { Redirect } from 'react-router';

import url from '../../../../apiService/ApiUrl.js';

import sessionService from '../../../../sessionService/sessionService';

import { connect } from 'react-redux';

import './css/LogIn.css';

class LogIn extends Component {

    constructor(props) {

        super(props);
    
        this.OnLogIn = this.OnLogIn.bind(this);
    
    }

    componentDidMount() {

        if(this.props.conexion === true){
                return <Redirect to='/dashboard' />
        }else {
            //console.log(this.usuario.conexion)
        }
  
    }

    sessionServiceCallback = () => {
        if(this.props.conexion === true){
          return <Redirect to='/dashboard' />
        }else{
          document.querySelector('#incorrect-data').style.display = 'block';
        }
    }
  
    OnLogIn(event){
  
        event.preventDefault();

        let pass = document.querySelector('#password').value,
        usuarioOEmail = document.querySelector('#usuarioOEmail').value
  
        let usuarioData = {
          usuarioOEmail,
          pass
        }

        sessionService.logIn(url, usuarioData, this, this.sessionServiceCallback)
        
        /*try{
        }catch{
            console.log('hubo un error y no se concreto el logIn')
        }*/
  
  
    }



    render(){

        if (this.props.conexion === true) {
            return <Redirect to='/dashboard' />
        }else{
            return(
                <div id="LogInContainer" className="bg-dark" style={{height: '100vh'}}>
                    <nav id="navLogIn" className="navbar navbar-expand">
    
                        <NavLink exact to="/#home" activeClassName="active" className="nav-link">
                            <img src="/img/Logo.png" style={{height: '2.5em', paddingLeft: '25px'}} alt="logoimg"/>
                        </NavLink>
    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
                            <ul className="navbar-nav">
                            <li className="nav-item active">
                                <NavLink exact to="/" activeClassName="active" className="nav-link" >Inicio</NavLink>
                            </li>
                            </ul>
                        </div>
                    </nav>
                    <div className="d-flex align-items-center" style={{marginTop: '80px'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-7 col-lg-5 mx-auto">
                                    <div className="card card-login mx-auto mt-5">
                                        <div className="card-header text-center">
                                        <p>Ingresa</p>
                                        </div>
                                        <div className="card-body">
                                        <form onSubmit={this.OnLogIn}>
                                            <div className="form-group">
                                            <label htmlFor="email">Correo Electrónico o Usuario</label>
                                            <input className="form-control" id="usuarioOEmail" type="text" aria-describedby="emailHelp" placeholder="Escribe tu correo electrónico o Usuario" />
                                            </div>
                                            <div className="form-group">
                                            <label htmlFor="password">Contraseña</label>
                                            <input className="form-control" id="password" type="password" placeholder="Escribe tu contraseña" />
                                            </div>
                                            {/*<div className="form-group">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                <input className="form-check-input" type="checkbox" /> Remember Password</label>
                                            </div>
                                            </div>*/}
                                            <button id="LogInButton" className="btn btn-outline-info btn-block" type="submit">Ingresa</button>
                                            <div id="incorrect-data" style={{'marginTop': '15px', 'display': 'none', 'transition': 'all 0.5s ease-in-out'}} className="alert alert-danger" role="alert">Ingresaste datos incorrectos, por favor prueba de Nuevo</div>
                                        </form>
                                        {/*<div className="text-center">
                                            <a className="d-block small mt-3" href="register.html">Register an Account</a>
                                            <a className="d-block small" href="forgot-password.html">Forgot Password?</a>
                                        </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

let mapStateToProps = (state, props) => {
    return {
      conexion: state.logIn.conexion,
      usuario: state.logIn.usuario
    }
}
  
export default connect(mapStateToProps)(LogIn)