let consulta = (usuarioOEmail, password) => {
    let string = `{ auth { logIn( usuarioOEmail: "${usuarioOEmail}" password: "${password}") { conexion,
    usuario{
      id
      usuario
      nombre
      apellidos
      correo
      fecha_de_nacimiento
      direccion
      nacionalidad
      sexo
      telefono
      tipo
      DPI
      foto_perfil
      permisos{
        AdminUser{
            ViewUser
            CreateUser
            EditUser
            DeleteUser
            UpdateStateMedic
        }
      }
      colaborador{
        id
        puesto
        departamento
        NIT
      }
      medico {
        estado
        id
        numero_colegiado
        NIT
        nombre_clinica
        departamento_clinica
        municipalidad_clinica
        direccion_clinica,
        direccion_clinica2,
        direccion_clinica3,
        telefono_clinica,
        telefono_clinica2,
        telefono_clinica3,
        correo_clinica,
        correo_clinica2,
        correo_clinica3,
        especialidad
        sub_especialidad
        atencion_emergencias
        observaciones
        observaciones_emergencias
        social_media {
          fb_link
          ins_link
          web_link
        }
        financiero {
          cuenta_numero
          cuenta_nombre
          cuenta_tipo
          banco_nombre
          factura_nombre
          documentos {
            dpi_front
            dpi_back
            licencia_sanitaria
            patente
            recibo_colegiado
            titulo_academico
            constancia_desechos_solidos
            rtu
            factura
          }
        }
        horario_atencion {
          dia
          ingreso
          egreso
        }
        data_consultas {
          precioNormal
          descuento
          precioFinal
          precioFinalPaciente
          seguro
        }
        data_procedimientos {
          html
        }
      }
      suscription{
        id
        firma_afiliado
        fecha_inicio
        fecha_expiracion
        auto_renovacion
        DPI_frontal
        DPI_posterior
        principalUser
      }
      }}}}
    `
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;