let consulta = () => {
    let string = `
        {
          appInfo {
            tiposCliente {
              id
              nombre
            }
          }
        }
    `
  
    return string
  
  }
  
  export default consulta;