// JavaScript Document
import GraphQL from './ConsultaGraphQLPanels';

import url from '../../../../../../apiService/ApiUrl';

function handleError(err){
	//console.log(`Request failed: ${err}`);
}

const deletePanel = (This, panel) => {

	// console.log('deleted', panel.id)

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(panel)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
	
		This.props.deletePanel(panel.id)
		
		// return res

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default deletePanel;