

let consulta = (companyJSON) => {

  const { id, logo, nombre, direccion, telefono, correo, NIT } = companyJSON
  
    let string = `
      mutation {
        empresaMutation {
          editEmpresa(
            editEmpresa: {
              id: "${id}"
              nombre: "${nombre}"
              direccion: "${direccion}"
              telefono: "${telefono}"
              NIT: "${NIT}"
              correo: "${correo}"
              logo: "${logo}"
            }
          ) {
            empresa {
              id
              nombre
              direccion
              telefono
              NIT
              correo
              logo
            }
            mensaje
          }
        }
      }
    `
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;