// JavaScript Document
import GraphQL from './ConsultaGraphQLProducts';

import url from '../../../../../../apiService/ApiUrl';

function handleError(err){
	// console.log(`Request failed: ${err}`);
}

const FetchEditProducto = (This, producto) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(producto)),
		credentials: 'include'
	}

	This.setState({ loaderClassName: 'd-block' })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let producto = res.data.productosMutation.editProducto.producto
		let message = res.data.productosMutation.editProducto.message

		This.props.editProduct(producto)
		This.setState({ fetchEditResponse: 'mt-4 col-8 mx-auto alert alert-success', fetchCreateMessage: message, loaderClassName: 'd-none' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchEditResponse: 'd-none', fetchEditMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.' })
		}, 1500);

	})
	.catch(err => {
		handleError(err)
		This.setState({ fetchEditResponse: 'mt-4 col-8 mx-auto alert alert-danger', loaderClassName: 'd-none' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchEditResponse: 'd-none' })
		}, 1500);
	});

	//termina fetch
}

export default FetchEditProducto;