let setClientTypes = payload => ({
    type: 'FETCH_CLIENT_TYPES',
    payload
})

let createClientType = payload => ({
    type: 'CREATE_CLIENT_TYPE',
    payload
})

let editClientType = payload => ({
    type: 'EDIT_CLIENT_TYPE',
    payload
})

let deleteClientType = payload => ({
    type: 'DELETE_CLIENT_TYPE',
    payload
})

module.exports = { setClientTypes, createClientType, editClientType, deleteClientType }