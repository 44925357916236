import React, { Component } from 'react'
import { connect } from 'react-redux'

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'
import FetchCreateUser from '../Containers/FetchCreateUser/FetchGQLCreateUser'

import fetchEspecialidadesMedico from '../Containers/FetchEspecialidades/FetchEspecialidadesMedico'
import fetchInsertEspecialidadesMedico from '../Containers/FetchInsertEspecialidades/FetchInsertEspecialidadesMedico'
import fetchCountries from '../../../Dashboard/Containers/FetchCountries/fetchCountries'
import Loader from '../../../Components/Loader'

import CreatableSelect from 'react-select/creatable'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';

class UserCreate extends Component {

    state = {
        countries: [],
        guatemala: '',
        horarios: '',
        especialidades: [],
        formMunicipalities: [],
        fetchCreateResponse: 'd-none',
        fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        usuario: {
            usuario: '',
            nombre: '',
            apellidos: '',
            correo: '',
            fecha_de_nacimiento: '',
            password: '',
            direccion: '',
            nacionalidad: 'Guatemala',
            sexo: '',
            telefono: 0,
            tipo: '',
            dpi: '',
            foto_perfil: '',
            permisos: {
                AdminUser: {
                    ViewUser: false,
                    CreateUser: false,
                    EditUser: false,
                    DeleteUser: false,
                    UpdateStateMedic: false
                }
            },
            colaborador: {
                nit_colaborador: '',
                puesto: '',
                departamento: ''
            },
            medico: {
                estado: 'Validado',
                nit_medico: '',
                numero_colegiado: '',
                clinica_nombre: '',
                clinica_departamento: '',
                clinica_municipalidad: '',
                clinica_direccion: '',
                clinica_telefono: '',
                clinica_correo: '',
                clinica_direccion2: '',
                clinica_telefono2: '',
                clinica_correo2: '',
                clinica_direccion3: '',
                clinica_telefono3: '',
                clinica_correo3: '',
                especialidad: '',
                sub_especialidad: '',
                atencion_emergencias: '',
                observaciones: '',
                observaciones_emergencias: '',
                social_media: {
                    fb_link: '',
                    ins_link: '',
                    web_link: ''
                },
                financiero: {
                    cuenta_numero: '',
                    cuenta_nombre: '',
                    cuenta_tipo: '',
                    banco_nombre: '',
                    factura_nombre: '',
                    documentos: {
                        dpi_front: '',
                        dpi_back: '',
                        licencia_sanitaria: '',
                        patente: '',
                        recibo_colegiado: '',
                        titulo_academico: '',
                        constancia_desechos_solidos: '',
                        rtu: '',
                        factura: '',
                    }
                },
                horario_atencion: [
                    {
                        dia: 'lunes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'martes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'miercoles',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'jueves',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'viernes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'sabado',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'domingo',
                        ingreso: '',
                        egreso: ''
                    }
                ]
                
            }
        }
    }

    async componentDidMount() {

        let countries = await fetchCountries()

        this.setState({ countries, guatemala: this.props.guatemala, horarios: this.props.horarios })

        fetchEspecialidadesMedico(this)

    }

    creatableSelect = (event, action, tipo) => {

        if (action.action === 'clear') {

            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    medico: {
                        ...this.state.usuario.medico,
                        [tipo]: ''
                    }
                }
            
            })

        } else if (action.action === 'create-option') {

           fetchInsertEspecialidadesMedico(this, event, tipo)

           this.setState({ 
               usuario: {
                   ...this.state.usuario,
                   medico: {
                       ...this.state.usuario.medico,
                       [tipo]: event.label
                   }
               }
            })

        } else {

           this.setState({ 
               usuario: {
                   ...this.state.usuario,
                   medico: {
                       ...this.state.usuario.medico,
                       [tipo]: event.label
                   }
               }
            })

        }

   }

    createFormHandler = (e) => {
        
        e.preventDefault()

        let usuario = {
            ...this.state.usuario,
            fecha_de_nacimiento: moment(this.state.usuario.fecha_de_nacimiento).format('YYYY-MM-DD'),
            medico: {
                ...this.state.usuario.medico,
                atencion_emergencias: this.state.usuario.medico.atencion_emergencias === 'true'
            }
        }

        FetchCreateUser(this, usuario)

    }

    onInputChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                [event.target.id]: event.target.value 
            }
        })
        
    }

    onColaboradorChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                colaborador: {
                    ...this.state.usuario.colaborador,
                    [event.target.id]: event.target.value
                }
            }
        })
        
    }

    onMedicoChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    [event.target.id]: event.target.value
                }
            }
        })
        
    }

    onMedicoSocialMediaChange = (event) => {

        this.setState({ 
            ...this.state,
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    social_media: {
                        ...this.state.usuario.medico.social_media,
                        [event.target.id]: event.target.value
                    }
                }

            }
        })

    }

    onHorarioChange = (event, time, i) => {

        let setTime = () => {
            
        let nuevo_horario_atencion = this.state.usuario.medico.horario_atencion.map( 
            
            (horario, index) => { 
                
                let new_horario

                if ( index === i ) {
                    new_horario = {
                        ...horario,
                        [time]: event.target.value
                    }
                } else {
                    new_horario = horario
                }

                return new_horario

            })
            
            return nuevo_horario_atencion

        }

        this.setState({ 
                usuario: {
                ...this.state.usuario,
                medico: {
                        ...this.state.usuario.medico,
                    horario_atencion: setTime()
                }
            }
        })

    }

    onFinancieroChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    financiero: {
                        ...this.state.usuario.medico.financiero,
                        [event.target.id]: event.target.value
                    }
                }
            }
        })

    }

    onFileUpload = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Print data in console
            files.push(base64)
        };
        // Convert data to base64
        fileReader.readAsDataURL(event.target.files[0]);

        if ( event.target.id === 'foto_perfil' ) {
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    [event.target.id]: files
                }
            })
        } else {
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    medico: {
                        ...this.state.usuario.medico,
                        financiero: {
                            ...this.state.usuario.medico.financiero,
                            documentos: {
                                ...this.state.usuario.medico.financiero.documentos,
                                [event.target.id]: files   
                            }
                        }
                    }
                }
            })
        }

    }

    onPermisoChange = (event) => {

        /* istanbul ignore next */
        if ( event.target.id === 'CreateUser' ) {
            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.CreateUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'ViewUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.ViewUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'EditUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.EditUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'DeleteUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.DeleteUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'UpdateStateMedic' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.UpdateStateMedic
                        }
                    }
                }
            })
        }
    }

    render() {

        let { usuario, nombre, apellidos, telefono, correo, dpi, fecha_de_nacimiento, sexo, password, direccion, tipo, nacionalidad, permisos, colaborador, medico } = this.state.usuario
        let { ViewUser, CreateUser, EditUser, DeleteUser, UpdateStateMedic } = permisos.AdminUser
        let { puesto, departamento, nit_colaborador } = colaborador
        let { nit_medico, numero_colegiado, clinica_nombre, clinica_departamento, clinica_municipalidad, clinica_direccion, clinica_telefono, clinica_correo, clinica_direccion2, clinica_telefono2, clinica_correo2, clinica_direccion3, clinica_telefono3, clinica_correo3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, social_media, financiero, horario_atencion } = medico
        let { fb_link, ins_link, web_link } = social_media
        let { cuenta_numero, cuenta_nombre, cuenta_tipo, banco_nombre, factura_nombre } = financiero

        return (
            <DasboardComponent >
            
                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {
                            id: '',
                            usuario: '',
                            nombre: '',
                            apellidos: '',
                            correo: '',
                            fecha_de_nacimiento: new Date(),
                            password: '',
                            direccion: '',
                            nacionalidad: '',
                            sexo: '',
                            telefono: 0,
                            tipo: '',
                            dpi: '',
                            foto_perfil: '',
                            permisos: {
                                AdminUser: {
                                    ViewUser: false,
                                    CreateUser: false,
                                    EditUser: false,
                                    DeleteUser: false,
                                },
                                AdminMedic: {
                                    ViewMedic: false,
                                    CreateMedic: false,
                                    EditMedic: false,
                                    UpdateStateMedic: false
                                }
                            },
                            colaborador: {
                                id_colaborador: '',
                                nit_colaborador: '',
                                puesto: '',
                                departamento: ''
                            },
                            medico: {
                                estado: '',
                                id_medico: '',
                                nit_medico: '',
                                numero_colegiado: '',
                                clinica_nombre: '',
                                clinica_departamento: '',
                                clinica_municipalidad: '',
                                clinica_direccion: '',
                                clinica_telefono: '',
                                clinica_correo: '',
                                especialidad: '',
                                sub_especialidad: '',
                                atencion_emergencias: false,
                                financiero: {
                                    cuenta_numero: '',
                                    cuenta_nombre: '',
                                    cuenta_tipo: '',
                                    banco_nombre: '',
                                    factura_nombre: '',
                                    documentos: {
                                        dpi_front: {
                                            file: '',
                                            ext: ''
                                        },
                                        dpi_back: {
                                            file: '',
                                            ext: ''
                                        },
                                        licencia_sanitaria: {
                                            file: '',
                                            ext: ''
                                        },
                                        patente: {
                                            file: '',
                                            ext: ''
                                        },
                                        recibo_colegiado: {
                                            file: '',
                                            ext: ''
                                        },
                                        titulo_academico: {
                                            file: '',
                                            ext: ''
                                        },
                                        constancia_desechos_solidos: {
                                            file: '',
                                            ext: ''
                                        },
                                        rtu: {
                                            file: '',
                                            ext: ''
                                        },
                                        factura: {
                                            file: '',
                                            ext: ''
                                        },
                                    }
                                },
                                horario_atencion: [
                                    {
                                        dia: 'lunes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'martes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'miercoles',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'jueves',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'viernes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'sabado',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'domingo',
                                        ingreso: '',
                                        egreso: ''
                                    },

                                ]
                                
                            }
                        }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '80px' }} className='mt-3' /><br/><br/>
                        <div className='form-group mx-auto col-10 col-md-6 col-lg-4' >
                            <label htmlFor="foto_perfil" className=''>Fotografía de Perfil</label>
                            <input 
                                onChange={ this.onFileUpload }
                                accept=".png, .jpg, .jpeg"
                                data-test-type='input-file'
                                type="file" className="form-control p-1" id="foto_perfil"
                            />
                        </div>
                        <h1>Crea un Nuevo Usuario</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='createForm' onSubmit={this.createFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className='form-row' >
                                
                                <div className="form-group col-md-6">
                                    <label htmlFor="usuario">Usuario</label>
                                    <input value={usuario} onChange={ this.onInputChange } type="text" className="form-control" id="usuario" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input value={nombre} onChange={ this.onInputChange } type="text" className="form-control" id="nombre" data-test-type='input-text' required />
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="apellidos">Apellidos</label>
                                    <input value={apellidos} onChange={ this.onInputChange } type="text" className="form-control" id="apellidos" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <input value={telefono} onChange={ this.onInputChange } type="number" className="form-control" id="telefono" data-test-type='input-text' required />
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="correo">Correo</label>
                                    <input value={correo} onChange={ this.onInputChange } type="email" className="form-control" id="correo" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="password">Password</label>
                                    <input value={ password || '' } onChange={ this.onInputChange } type="password" className="form-control" id="password" data-test-type='input-text' required />
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="dpi">DPI</label>
                                    <input value={dpi} onChange={ this.onInputChange } type="text" className="form-control" id="dpi" data-test-type='input-text' />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="fecha_de_nacimiento">Fecha de Nacimiento</label>
                                    <input value={moment(fecha_de_nacimiento).format('YYYY-MM-DD')} onChange={ this.onInputChange } type="date" className="form-control" id="fecha_de_nacimiento" data-test-type='input-text' required />
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="sexo">Sexo</label>
                                    <select value={sexo} onChange={ this.onInputChange } type="text" className="form-control" id="sexo" data-test-type='select' required >
                                        <option value='' >Selecciona un Sexo</option>
                                        <option value='Masculino' >Masculino</option>
                                        <option value='Femenino' >Femenino</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="tipo">Tipo de Usuario</label>
                                    <select value={tipo} onChange={ this.onInputChange } type="text" className="form-control" id="tipo" data-test-type='select' required >
                                        <option value='' >Selecciona un Tipo</option>
                                        <option value='cliente' >Cliente</option>
                                        <option value='colaborador' >Colaborador</option>
                                        <option value='medico' >Médico</option>
                                    </select>
                                    
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="nacionalidad">Nacionalidad</label>
                                    {/* <input value={nacionalidad} onChange={ this.onInputChange } type="text" className="form-control" id="nacionalidad" /> */}
                                    { 
                                        this.state.countries.length > 0 ? (
                                            <select id="nacionalidad" className="form-control" placeholder="Escoje tu Nacionalidad" onChange={ this.onInputChange } value={nacionalidad} data-test-type='select' >
                                                { this.state.countries.map( (country, i) => <option value={country.name} key={i}>{country.name}</option>) }
                                            </select>
                                        ) : (
                                            <select id="nacionalidad" className="form-control" placeholder="Escoje tu Nacionalidad" onChange={ this.onInputChange } value={nacionalidad} data-test-type='select' >
                                                <option value="Guatemala">Guatemala</option>
                                            </select>
                                        )
                                    }
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="direccion">Dirección</label>
                                    <input value={direccion} onChange={ this.onInputChange } type="text" className="form-control" id="direccion" data-test-type='select' />
                                </div>

                            </div>
                                           
                            {/* Colaborador */}
                            <div>
                                {
                                    this.state.usuario.tipo === 'colaborador' ?
                                        <div className='mt-4' >
                                            <h3>Colaborador</h3>
                                            <div className="form-group">
                                                <label htmlFor="puesto">Puesto</label>
                                                <input 
                                                    value={ puesto } 
                                                    onChange={ this.onColaboradorChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="puesto" required
                                                />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="departamento">Departamento</label>
                                                    <select 
                                                        value={ departamento } 
                                                        onChange={ this.onColaboradorChange }
                                                        data-test-type='select'
                                                        type="text" className="form-control" id="departamento" required
                                                    >
                                                        <option value='' >Selecciona un Departamento</option>    
                                                        <option value='Laboratorio' >Laboratorio</option>    
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="nit">NIT</label>
                                                    <input 
                                                        value={ nit_colaborador } 
                                                        onChange={ this.onColaboradorChange }
                                                        data-test-type='input-number'
                                                        type="number" className="form-control" id="nit_colaborador" required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null
                                    
                                }
                            </div>

                            {/* Médico */}
                            <div>
                                {
                                    this.state.usuario.tipo === 'medico' ?
                                        <div className='mt-4' >
                                            <h3>Médico</h3>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="numero_colegiado">Número de Colegiado</label>
                                                    <input 
                                                        value={ numero_colegiado } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-number'
                                                        type="number" className="form-control" id="numero_colegiado" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="medicoNIT">Nombre de la Clínica</label>
                                                    <input 
                                                        value={ clinica_nombre } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="clinica_nombre" required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_departamento">Departamento</label>
                                                    <select value={clinica_departamento} onChange={ (e) => { this.onMedicoChange(e); this.setState({ formMunicipalities: this.props.guatemala[e.target.value] }) } } type="text" className="form-control" id="clinica_departamento" data-test-type='select' required >
                                                        <option value='' >Selecciona un Departamento</option>
                                                        {Object.keys(this.props.guatemala).map( (department, i) => { return <option key={i} value={department} >{department}</option> })}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_municipalidad">Municipalidad</label>
                                                    <select value={clinica_municipalidad} onChange={ this.onMedicoChange } type="text" className="form-control" id="clinica_municipalidad" data-test-type='select' required >
                                                        <option value='' >Selecciona un Municipio</option>
                                                        { this.state.usuario.medico.clinica_departamento !== '' ? this.state.formMunicipalities.map( (municipalidad, i) => <option key={i} value={municipalidad} >{municipalidad}</option> ) : null }
                                                    </select>
                                                </div>
                                            </div>

                                            
                                            <h5 className='text-center mt-4' >Información de Clínica No. 01</h5>

                                            <div className="form-group">
                                                <label htmlFor="clinica_direccion">Dirección de la Clínica</label>
                                                <input 
                                                    value={ clinica_direccion } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="clinica_direccion" required
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_telefono">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ clinica_telefono } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="clinica_telefono" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_correo">Correo de la Clínica</label>
                                                    <input 
                                                        value={ clinica_correo } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="clinica_correo" required
                                                    />
                                                </div>
                                            </div>

                                            <h5 className='text-center mt-4' >Información de Clínica No. 02</h5>

                                            <div className="form-group">
                                                <label htmlFor="clinica_direccion2">Dirección de la Clínica</label>
                                                <input 
                                                    value={ clinica_direccion2 } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="clinica_direccion2"
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_telefono2">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ clinica_telefono2 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="clinica_telefono2"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_correo2">Correo de la Clínica</label>
                                                    <input 
                                                        value={ clinica_correo2 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="clinica_correo2"
                                                    />
                                                </div>
                                            </div>

                                            <h5 className='text-center mt-4' >Información de Clínica No. 03</h5>

                                            <div className="form-group">
                                                <label htmlFor="clinica_direccion3">Dirección de la Clínica</label>
                                                <input 
                                                    value={ clinica_direccion3 } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="clinica_direccion3"
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_telefono3">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ clinica_telefono3 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="clinica_telefono3"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_correo3">Correo de la Clínica</label>
                                                    <input 
                                                        value={ clinica_correo3 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="clinica_correo3"
                                                    />
                                                </div>
                                            </div>


                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="especialidad">Especialidad</label>
                                                    {/* <input 
                                                        value={ especialidad } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="especialidad" required
                                                    /> */}
                                                    <CreatableSelect
                                                        isClearable
                                                        value={ this.state.especialidades.find(specialty => specialty.label === especialidad )  }
                                                        onChange={ (event, action) => this.creatableSelect(event, action, 'especialidad') }
                                                        options={this.state.especialidades.filter( especialidad => especialidad.tipo === 'especialidad' )}
                                                    />
                                                    
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="sub_especialidad">Sub-Especialidad</label>
                                                    {/* <input 
                                                        value={ sub_especialidad } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="sub_especialidad" required
                                                    /> */}
                                                    <CreatableSelect
                                                        isClearable
                                                        value={ this.state.especialidades.find(specialty => specialty.label === sub_especialidad )  }
                                                        onChange={ (event, action) => this.creatableSelect(event, action, 'sub_especialidad') }
                                                        options={this.state.especialidades.filter( especialidad => especialidad.tipo === 'sub_especialidad' )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="atencion_emergencias">Atención a Emergencias</label>
                                                    <select value={atencion_emergencias} onChange={ this.onMedicoChange } type="text" className="form-control" id="atencion_emergencias" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='true' >Sí</option>
                                                        <option value='false' >No</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="nit_medico">NIT</label>
                                                    <input 
                                                        value={ nit_medico } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="nit_medico" required
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="fb_link">URL Perfil de Facebook</label>
                                                    <input 
                                                        value={ fb_link } 
                                                        onChange={ this.onMedicoSocialMediaChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="fb_link"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ins_link">URL Perfil de Instagram</label>
                                                    <input 
                                                        value={ ins_link } 
                                                        onChange={ this.onMedicoSocialMediaChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="ins_link"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="web_link">URL del Sitio Oficial del Médico</label>
                                                <input 
                                                    value={ web_link } 
                                                    onChange={ this.onMedicoSocialMediaChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="web_link"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="observaciones">Observaciones</label>
                                                <input 
                                                    value={ observaciones } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="observaciones"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="observaciones_emergencias">Observaciones de Emergencias</label>
                                                <input 
                                                    value={ observaciones_emergencias } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="observaciones_emergencias"
                                                />
                                            </div>

                                            <h4>Horarios de Atención</h4>

                                            <div className="form-row mx-auto">

                                                {
                                                    horario_atencion.map( (horario, i) => {

                                                            return (
                                                                <div className="form-group col-md-4 p-3 mx-auto" key={i}>
                                                                    <label htmlFor={`ingreso-${horario.dia}`} className='text-capitalize'>{horario.dia}</label>
                                                                    <select value={horario.ingreso} onChange={ (e) => this.onHorarioChange(e, 'ingreso', i) } type="text" className="form-control" id={`ingreso-${horario.dia}`} data-test-type='select' >
                                                                        <option value='' >Ingreso</option>
                                                                        { this.props.horarios.map( (horario, i) => <option key={i} value={horario} >{horario}</option> ) }
                                                                    </select>
                                                                    <select value={horario.egreso} onChange={ (e) => this.onHorarioChange(e, 'egreso', i) } type="text" className="form-control" id={`egreso-${horario.dia}`} data-test-type='select' >
                                                                        <option value='' >Egreso</option>
                                                                        { this.props.horarios.map( (horario, i) => <option key={i} value={horario} >{horario}</option> ) }
                                                                    </select>
                                                                </div>
                                                            ) 
                                                        }
                                                    )
                                                }
                                                
                                            </div>

                                            <h4>Información Financiera</h4>
                                            
                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="cuenta_numero">Número de Cuenta</label>
                                                    <input 
                                                        value={ cuenta_numero } 
                                                        onChange={ this.onFinancieroChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="cuenta_numero" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="cuenta_nombre">Nombre de la Cuenta</label>
                                                    <input 
                                                        value={ cuenta_nombre } 
                                                        onChange={ this.onFinancieroChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="cuenta_nombre" required
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="cuenta_tipo">Tipo de Cuenta</label>
                                                    <select value={cuenta_tipo} onChange={ this.onFinancieroChange } type="text" className="form-control" id="cuenta_tipo" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='Monetario' >Monetario</option>
                                                        <option value='Ahorro' >Ahorro</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="banco_nombre">Nombre del Banco</label>
                                                    <select value={banco_nombre} onChange={ this.onFinancieroChange } type="text" className="form-control" id="banco_nombre" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='BAC' >BAC</option>
                                                        <option value='Banrural' >Banrural</option>
                                                        <option value='Banco Promérica' >Banco Promérica</option>
                                                        <option value='Banco Agromercantil' >Banco Agromercantil</option>
                                                        <option value='Banco Industrial' >Banco Industrial</option>
                                                        <option value='GyT Continental' >GyT Continental</option>
                                                        <option value='Interbanco' >Interbanco</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group">
                                                <label htmlFor="factura_nombre">Nombre de Facturación</label>
                                                <input 
                                                    value={ factura_nombre } 
                                                    onChange={ this.onFinancieroChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="factura_nombre" required
                                                />
                                            </div>

                                        </div>
                                    : null
                                    
                                }
                            </div>

                            {/* Permisos */}
                            <div>
                                <div className="form-group mx-auto text-center">
                                    <div>
                                        <button id='botonPermisos' className="btn btn-primary mt-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                            Permisos de Usuario
                                        </button>
                                        <div className="collapse" id="collapseExample">
                                            <div className="card card-body mt-3 mx-auto col-12 col-md-10 col-lg-8">
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ CreateUser } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="CreateUser" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="CreateUser">Crear Usuario</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ ViewUser } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="ViewUser" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="ViewUser">Ver Usuario</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ EditUser } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="EditUser" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="EditUser">Editar Usuario</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ DeleteUser } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="DeleteUser" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="DeleteUser">Eliminar Usuario</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ UpdateStateMedic } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="UpdateStateMedic" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="UpdateStateMedic">Actualizar Estado Médico</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <button type="submit" className="btn btn-success mt-3">Crear</button>
                            
                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchCreateResponse} role="alert">
                                {this.state.fetchCreateMessage}
                            </div>

                        </form>

                    </div>

                </div>
            
            </DasboardComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        guatemala: state.appInfo.publicData.guatemala,
        horarios: state.appInfo.publicData.horarios
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        createUser: ( user ) => dispatch ( actions.users.createUser({ user }) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (UserCreate)