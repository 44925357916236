let consulta = (usuario) => {

    let {id, colaborador, medico} = usuario
    // let puesto, departamento, NIT

    // if (colaborador !== null) {
    //   puesto = colaborador.puesto
    //   departamento = colaborador.puesto
    //   NIT = colaborador.NIT
    // }
    
    let string
    
    if(colaborador !== null) {

      string = `
        mutation {
          userMutation {
            deleteUser ( 
              idUsuario: "${id}" 
              idColaborador: "${colaborador.id}"
            ) {
              idUsuario
              mensaje
            }
            
          }
        }
      `
      return {
        "query": string
      }

    } else if (medico !== null) {

      string = `
        mutation {
          userMutation {
            deleteUser ( 
              idUsuario: "${id}" 
              idMedico: "${medico.id}"
            ) {
              idUsuario
              mensaje
            }
            
          }
        }
      `

      return {
        "query": string
      }


    } else {
      
      string = `
        mutation {
          userMutation {
            deleteUser ( 
              idUsuario: "${id}" 
            ) {
              idUsuario
              mensaje
            }
            
          }
        }
      `
      
      return {
        "query": string
      }

    }
  }
  
  export default consulta;