let consulta = (clientType) => {
  let string = `
    mutation {
      productosMutation {
        deleteTipoCliente (
          id: "${clientType.id}"
        )
      }
    }
  `

  return {
    "query": string
  }

}

export default consulta;