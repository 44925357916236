import React, { Component } from 'react'

import InicioContainer from '../Components/Inicio'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

export default class Farmaceutica extends Component {
    render() {
        return (
            <InicioContainer >
                <section id="farmaceutica" className="container-fluid" style={{minHeight: '76.3vh'}} >
                    <div className='text-center mx-auto' style={{ marginTop: '100px' }} >
                        <h1>Farmaceutica</h1>
                    </div>
                </section>
            </InicioContainer>
        )
    }
}
