// JavaScript Document
import GraphQL from './ConsultaGQLEditConsulta';

import url from '../../../../../apiService/ApiUrl';

function handleError(err){
	// console.log(`Request failed: ${err}`);
}

const FetchEditConsulta = (This, data, medicoId) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	This.setState({ loaderClassName: 'd-block' })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let data = {
			medicoId: medicoId,
			data_consultas: res.data.userMutation.insertDataMedicosConsultas
		}
		
		This.props.editConsulta(data)
		This.setState({ fetchEditConsultaResponse: 'mt-4 col-8 mx-auto alert alert-success', fetchEditConsultaMessage: 'Se han guardado los cambios exitosamente.', loaderClassName: 'd-none' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchEditConsultaResponse: 'd-none', fetchEditConsultaMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.' })
		}, 1500);

	})
	.catch(err => {
		handleError(err)
		This.setState({ fetchEditConsultaResponse: 'mt-4 col-8 mx-auto alert alert-danger', loaderClassName: 'd-none' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchEditConsultaResponse: 'd-none' })
		}, 1500);
	});

	//termina fetch
}

export default FetchEditConsulta;