import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import actions from '../../../../actions'
import FetchGraphQLMedics from './FetchGetMedics/FetchGraphQLMedics'

import MedicTable from '../components/MedicTable'
import MedicView from '../components/MedicView'

class MedicList extends Component {

    state = {
        display: '',
        currentUser: {
            id: '',
            usuario: '',
            nombre: '',
            apellidos: '',
            correo: '',
            fecha_de_nacimiento: new Date(),
            direccion: '',
            nacionalidad: '',
            sexo: '',
            telefono: 0,
            tipo: '',
            DPI: '',
            permisos: {
                AdminUser: {
                    ViewUser: false,
                    CreateUser: false,
                    EditUser: false,
                    DeleteUser: false
                },
                AdminMedic: {
                    View: false,
                    Create: false,
                    Edit: false,
                    Delete: false
                }
            },
            colaborador: null,
            medico: {
                id_medico: '',
                medicoNIT: '',
                numero_colegiado: '',
                direccion_clinica: '',
                especialidad: '',
                sub_especialidad: '',
                picture: ''
            }
        }
    }

    componentDidMount(){

        if((this.props.conexion === false) || (this.props.usuario.permisos.AdminUser === null)){
			return <Redirect to='/login' />
        }
        
        FetchGraphQLMedics(this)

    }

    handleDisplay = (display, usuario) => {
        this.setState({ display, currentUser: usuario })
    }

    render() {

        if ((this.props.conexion === false) || (this.props.usuario.permisos.MedicList === null)) {
			return <Redirect to='/login' />
		} else {

            if (this.state.display === '') {
                return <MedicTable usuario={this.props.usuario} usuarios={ this.props.usuarios } handleDisplay={this.handleDisplay} handleDeleteUser={this.handleDeleteUser}/>
            } else if (this.state.display === 'UserView') {
                return <MedicView usuario={this.props.usuario} currentUser={this.state.currentUser} handleDisplay={this.handleDisplay} />
            } else {
                return null
            }

        }

        
    }
}

const mapStateToProps = (state) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        usuarios: state.appInfo.usersData.users
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUsuarios: (usuarios) => dispatch (actions.users.fetchUsers({ usuarios })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (MedicList)
