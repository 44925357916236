let consulta = (productId) => {

  let string = `
    mutation {
      productosMutation {
        deleteProducto (
          id: "${productId}"
        )
      }
    }
  `
  
  return {
    "query": string
  }

}

export default consulta;