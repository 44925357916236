// JavaScript Document
import GraphQL from './ConsultaGraphQLEmpresas'

import url from '../../../../../apiService/ApiUrl';
import urlencode from 'urlencode';
// import actions from '../../../../../actions';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchCompanies = (This) => {


	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL())}`, options)
	.then(res => res.json())
	.then( (res) => {

        let empresas = res.data.appInfo.empresas

		This.props.setCompanies(empresas)

		return res

	})
	.catch(err => handleError(err));

	//termina fetch


}

export default fetchCompanies;