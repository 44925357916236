

let consulta = (suscriptionJSON) => {

  let string = `
    mutation{
      userMutation{
        suscriptionUser(suscriptionUser: {
          idUser: ${suscriptionJSON.idUser}
          firma_afiliado: "${suscriptionJSON.firma_afiliado}"
          fecha_inicio: "${suscriptionJSON.fecha_inicio}"
          fecha_expiracion: "${suscriptionJSON.fecha_expiracion}"
          auto_renovacion: ${suscriptionJSON.auto_renovacion}
          DPI_frontal: "${suscriptionJSON.DPI_frontal}"
          DPI_posterior: "${suscriptionJSON.DPI_posterior}"
        }){
          suscription{
            id
            firma_afiliado
            fecha_inicio
            fecha_expiracion
            auto_renovacion
            DPI_frontal
            DPI_posterior
            principalUser
          }
          mensaje
        }
      }
    }
  `
  return {
    "query": string
  }
  
}

export default consulta;