import React, { Component } from 'react'
import DasboardComponent from '../../Components/DashboardComponent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faFileAlt, faList } from '@fortawesome/free-solid-svg-icons';

export default class AdminPerfilSelect extends Component {
    render() {
        return (
            <DasboardComponent>
                
                <div className='col-12 mx-auto d-flex flex-column justify-content-center align-items-center' style={{minHeight: '80vh'}} >

                    <div >
                        <button id='ViewButton' type="button" onClick={() => {
                            let display = 'ProfileView';
                            this.props.handleDisplay(display)
                        }} className="btn btn-info rounded my-4" style={{ width: '270px' }}><FontAwesomeIcon icon={faEye} style={{ fontSize: '20px' }} className='text-white mr-3' />Visualizar Perfil</button>
                    </div>

                    <div>
                        <button id='EditButton' type="button" onClick={() => {
                            let display = 'ProfileEdit';
                            this.props.handleDisplay(display)
                        }} className="btn btn-info rounded my-4" style={{ width: '270px' }}><FontAwesomeIcon icon={faEdit} style={{ fontSize: '20px' }} className='text-white mr-3' />Editar Perfil</button>
                    </div>

                    <div>
                        <button id='EditButton' type="button" onClick={() => {
                            let display = 'ProceduresEdit';
                            this.props.handleDisplay(display)
                        }} className="btn btn-info rounded my-4" style={{ width: '270px' }}><FontAwesomeIcon icon={faList} style={{ fontSize: '20px' }} className='text-white mr-3' />Editar Procedimientos</button>
                    </div>

                    <div>
                        <button id='DocumentsButton' type="button" onClick={() => {
                            let display = 'ProfileDocuments';
                            this.props.handleDisplay(display)
                        }} className="btn btn-info rounded my-4" style={{ width: '270px'}}><FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '20px' }} className='text-white mr-3' />Administrar Documentos</button>
                    </div>

                </div>

            </DasboardComponent>
        )
    }
}
