import React, { Component } from 'react'
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable'
import actions from '../../../../../actions';
import DasboardComponent from '../../../Components/DashboardComponent'
import Loader from '../../../../Components/Loader'

import FetchCreateProduct from '../Containers/fetchCreateProduct/FetchGraphQLProducts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


export class ProductCreate extends Component {

    state = {
        loaderClassName: 'd-none',
        fetchCreateResponse: 'd-none',
        fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        clasificaciones: [],
        tipos: [],
        tiposCliente: [],
        producto: {
            nombre: "",
            clasificacion: "",
            tipo: "",
            descFamGT: 0,
            conOSinMedioDeContraste: "", //optional
            observaciones: "",  //optional
            idTipoClienteDefault: "",
            precios_TipoCliente: [{
                idTipoCliente: "",
                precio: 0
            }]
        }
    }

    componentDidMount() {
        let clasificaciones = [...new Set(this.props.products.map( producto => producto.clasificacion ))].map( element => ({ label: element, value: element }) )
        let tipos = [...new Set(this.props.products.map( producto => producto.tipo ))].map( element => ({ label: element, value: element }) )
        let tiposCliente = this.props.tiposCliente

        this.setState({ clasificaciones, tipos, tiposCliente })
    }

    creatableSelect = (event, action, tipo) => {

        if (action.action === 'clear') {
            this.setState({ 
               producto: {
                   ...this.state.producto,
                   [tipo]: ''
               }
            })
        } else {
            this.setState({ 
                producto: {
                    ...this.state.producto,
                    [tipo]: event.label
                }
             })
        }

        // else if (action.action === 'create-option') {} 

   }

    createFormHandler = (e) => {
        
        e.preventDefault()

        let producto = this.state.producto

        FetchCreateProduct(this, producto)

    }

    onInputChange = (event) => {
        this.setState({ 
            producto: {
                ...this.state.producto,
                [event.target.id]: event.target.value 
            }
        })

        if (event.target.id === 'idTipoClienteDefault') {
            this.setState({
                producto: {
                    ...this.state.producto,
                    [event.target.id]: event.target.value,
                    precios_TipoCliente: [{
                        ...this.state.producto.precios_TipoCliente[0],
                        idTipoCliente: event.target.value
                    }]
                }
            })
        } 
    }

    onPrecioTipoClienteChange = (event) => {
        this.setState({
            producto: {
                ...this.state.producto,
                precios_TipoCliente: [{
                    ...this.state.producto.precios_TipoCliente[0],
                    [event.target.id]: event.target.value
                }]
            }
        })
    }

    render() {

        return (
            <DasboardComponent>

                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect" onClick={ () => this.props.handleDisplay('', {}) } className='col-12 mr-auto' >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        <h1>Crea un Producto</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='createForm' onSubmit={this.createFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input onChange={ (event) => this.setState({ producto: { ...this.state.producto, [event.target.id]: event.target.value.toUpperCase() } }) } style={{ textTransform: 'uppercase' }} type="text" className="form-control" id="nombre" required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="clasificacion">Clasificación</label>
                                    {/* <select onChange={ this.onInputChange } className="form-control" id="clasificacion" required >
                                        <option value='' >Selecciona una Opción</option>
                                        { this.state.clasificaciones.map( (clasificacion, i) => <option key={i} value={clasificacion} >{clasificacion}</option>) }
                                    </select> */}
                                    <CreatableSelect
                                        isClearable
                                        // value={ this.state.tipos.map( tipo => tipo )  }
                                        onChange={ (event, action) => this.creatableSelect(event, action, 'tipo') }
                                        options={this.state.clasificaciones}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="tipo">Tipo</label>
                                    {/* <select onChange={ this.onInputChange } className="form-control" id="tipo" required >
                                        <option value='' >Selecciona una Opción</option>
                                        { this.state.tipos.map( (tipo, i) => <option key={i} value={tipo} >{tipo}</option>) }
                                    </select> */}
                                    <CreatableSelect
                                        isClearable
                                        // value={ this.state.tipos.map( tipo => tipo )  }
                                        onChange={ (event, action) => this.creatableSelect(event, action, 'tipo') }
                                        options={this.state.tipos}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="descFamGT">Descuento FamGT</label>
                                    <input onChange={ (event) => this.setState({ producto: {...this.state.producto, [event.target.id]: (event.target.value * 0.01) } }) } type="number" className="form-control" id="descFamGT" placeholder='Ejemplo: 20 (valor en %)' required />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 mx-auto">
                                    <label htmlFor="conOSinMedioDeContraste">Con o Sin Medio de Contraste</label>
                                    <input onChange={ this.onInputChange } type="text" className="form-control" id="conOSinMedioDeContraste" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <label htmlFor="observaciones">Observaciones</label>
                                    <textarea onChange={ this.onInputChange } type="text" className="form-control" id="observaciones" />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-12 mx-auto">
                                    <label htmlFor="idTipoClienteDefault">Tipo de Cliente Predeterminado</label>
                                    <select onChange={ this.onInputChange } className="form-control col-md-6 mx-auto" id="idTipoClienteDefault" required >
                                        <option value='' >Tipo de Cliente</option>
                                        { this.state.tiposCliente.map( (tipo, i) => <option key={i} value={tipo.id} >{tipo.nombre}</option> ) }
                                    </select>
                                </div>
                            </div>

                            <div className="form-row py-5">
                                <div className="form-group col-12 col-md-6 mx-auto">
                                    <label htmlFor="precio">Precio Según Tipo de Cliente Predeterminado</label>
                                    <input onChange={ this.onPrecioTipoClienteChange } type="number" min='0' className="form-control" id="precio" />
                                </div>
                            </div>
                            
                            <button type="submit" className="btn btn-success mt-5">Crear Producto</button>

                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchCreateResponse} role="alert">
                                {this.state.fetchCreateMessage}
                            </div>

                        </form>

                    </div>

                </div>

            </DasboardComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        products: state.appInfo.productsData.products
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        createProduct: (product) => dispatch (actions.products.createProduct({ product }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ProductCreate)
