import React from 'react';

let Nosotros = props => (
    <section id="Nosotros" className="container" style={{marginTop: '50px'}}>
        <div className="row">
            <div className="col-12 mx-auto">
                <p className="text-justify">Somos un Centro Medico Especializado en Exámenes de Laboratorio Clínico, Ultrasonidos, Rayos X Digitales, Tomografías y Clínicas de Especialidades Medicas.</p>
                <br/>
                <p className="text-justify">Tenemos impacto social directo ya que ayudamos a toda la población Guatemalteca a que tengan acceso a la salud privada con precios accesibles siempre cuidando LA EXCELENCIA Y PROFESIONALISMO en todas las áreas.</p>
                <br/>
                <p className="text-justify">CDM se fundó desde 2015 expandiéndose con los años en los diferentes servicios médicos especializados atendiendo hasta el día de hoy a mas de 600,000 personas.</p>
                <hr className='my-5' />
            </div>
        </div>
    </section>
)

export default Nosotros