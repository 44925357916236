let consulta = (data) => {

  let { medicoId, dataProcedimientos } = data
  let { html } = dataProcedimientos

  const string =
    `mutation {
      userMutation {
        insertDataMedicosProcedimientos (
          medicoId: "${medicoId}"
          dataProcedimientos: {
            html: "${html}"
          }
        ) {
          html
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta