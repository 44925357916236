import React, { Component } from 'react'
import { connect } from 'react-redux'

import actions from '../../../../actions';
import FetchUpdateStateMedic from '../Containers/FetchUpdateStateMedic/FetchUpdateStateMedic'

import DashboardComponent from '../../Components/DashboardComponent'
import UserFilter from './UserFilter'

import Modal from 'react-bootstrap4-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class UserTable extends Component {

  state = {
    nombre: '',
    apellidos: '',
    tipo: '',
    currentMedicId: '',
    currentMedicState: '',
    UpdateMedicStateModal: false,
    DeleteUserValidation: false,
    fetchUpdateStateMedic: 'd-none',
    fetchUpdateStateMedicFail: 'd-none',
    usuario: ''
  }

  setFilter = (type, event) => {
      if ( type === 'set' ) {
          this.setState({ [event.target.id]: event.target.value })
      } else {
          this.setState({ nombre: '', apellidos: '', tipo: '' })
      }
  }

  handleUpdateStateMedic = (id, update) => {
    FetchUpdateStateMedic(this, id, update)
  }

  render() {

    const userFilter = this.props.usuarios.filter( usuario =>
      usuario.nombre.toLowerCase().indexOf(this.state.nombre.toLowerCase()) !== -1 &&
      usuario.apellidos.toLowerCase().indexOf(this.state.apellidos.toLowerCase()) !== -1 &&
      usuario.tipo.toLowerCase().indexOf(this.state.tipo.toLowerCase()) !== -1 
    )

    return (
      <DashboardComponent>
      <div className="container" style={{marginTop: '50px', minHeight: '75vh'}}>

        <div className='mb-5' >
            <UserFilter setFilter={this.setFilter} usuarios={this.props.usuarios} />
        </div>

        <div className="table-responsive"> 
          {
            this.props.usuario.permisos.AdminUser.CreateUser === true ? (
              <div className="justify-content-end">
                  <button id="createButton"
                    onClick={() => {
                      let display = 'UserCreate';
                      this.props.handleDisplay(display)
                    }}
                    type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color' : '#5DACD4'}}>
                    Nuevo Usuario <FontAwesomeIcon icon="user-plus" />
                  </button>
              </div>
            ) : null
          }
          {
            ((this.props.usuario.permisos.AdminUser.ViewUser === true) || (this.props.usuario.permisos.AdminUser.EditUser === true) || (this.props.usuario.permisos.AdminUser.DeleteUser === true)) ? (
              <table className="table text-center table-bordered table-hover table-sm">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellidos</th>
                    <th scope="col" className="d-none d-md-table-cell" >Teléfono</th>
                    <th scope="col" className="d-none d-lg-table-cell" >Correo</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    //props.usuarios.map( usuario => (
                    userFilter.map( usuario => (
                        <tr key={usuario.id}>
                          {/*<th scope="row">{usuario.nombre}</th>*/}
                          <td>{usuario.nombre}</td>
                          <td>{usuario.apellidos}</td>
                          <td className="d-none d-md-table-cell" >{usuario.telefono}</td>
                          <td className="d-none d-lg-table-cell" >{usuario.correo}</td>
                          <td>
                            {
                              this.props.usuario.permisos.AdminUser.ViewUser === true ? (
                                <button id="viewButton" type="button" onClick={() => {
                                    let display = 'UserView';
                                    this.props.handleDisplay(display, usuario)
                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}>
                                  <FontAwesomeIcon icon="eye" title='Visualizar Usuario' />
                                </button>
                              ) : null
                            }

                            {
                              this.props.usuario.permisos.AdminUser.EditUser === true ? (
                                <button id="editButton" type="button" onClick={() => {
                                    let display = 'UserEdit';
                                    this.props.handleDisplay(display, usuario);
                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}>
                                  <FontAwesomeIcon icon="edit" title='Editar Usuario' />
                                </button>
                              ) : null
                            }

                            {
                              this.props.usuario.permisos.AdminUser.DeleteUser === true ? (
                                <button id="deleteButton" 
                                  // onClick={ () => { this.props.handleDeleteUser(usuario) }} 
                                  onClick={ () => { this.setState({ usuario, DeleteUserValidation: true }) }} 
                                  type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}
                                >
                                  <FontAwesomeIcon icon="trash-alt" title='Eliminar Usuario' />
                                </button>
                                
                              ) : null
                            }

                            {
                              //( usuario.tipo === 'medico' && usuario.medico.estado === 'No Validado' && this.props.usuario.permisos.AdminUser.UpdateStateMedic === true ) ? (
                              ( usuario.tipo === 'medico' && this.props.usuario.permisos.AdminUser.UpdateStateMedic === true ) ? (
                                <button id="valitdateMedicButton" 
                                  onClick={ () => { this.setState({ currentMedicId: usuario.medico.id, currentMedicState: usuario.medico.estado, UpdateMedicStateModal: true }) }} 
                                  type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}
                                >
                                  <FontAwesomeIcon icon="file-medical" title='Cambiar Estado de Médico' />
                                  { usuario.medico.estado === 'No Validado' ? <span className='font-weight-bold text-danger ml-2' style={{ fontSize: '1.2em' }} title='Médico No Validado' >!</span> : null }
                                </button>
                                
                              ) : null
                            }

                          </td>
                        </tr>
                      )
                      
                    )
                  }
                </tbody>
              </table>
            ) : null
          }
        </div>

        <Modal id='UpdateMedicStateModal' visible={this.state.UpdateMedicStateModal} fade onClickBackdrop={ () => this.setState({ UpdateMedicStateModal: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >

            <div className="modal-body text-center py-4">
              <button id='registerClose' type="button" className="close" onClick={ () => this.setState({ UpdateMedicStateModal: false }) }>
                  <span aria-hidden="true">&times;</span>
              </button>
              <div className='mx-auto col-10 mb-4 py-4' >
                  <h5 className='mb-5' >Actualiza el Estado del Perfil Médico:</h5>
                  <select value={this.state.currentMedicState} onChange={ (e) => { this.handleUpdateStateMedic(this.state.currentMedicId, e.target.value) } } type="text" className="form-control" id="currentMedicState" data-test-type='select' required >
                      <option value='Validado' >Validado</option>
                      <option value='No Validado' >No Validado</option>
                  </select>
                  <p className={this.state.fetchUpdateStateMedic} >El cambio de estado ha sido efectivo</p>
                  <p className={this.state.fetchUpdateStateMedicFail} >Ha habido un problema en la conexión, inténtalo de nuevo.</p>
              </div>
            </div>
            
        </Modal>
        
        <Modal id='DeleteUserValidation' visible={this.state.DeleteUserValidation} fade onClickBackdrop={ () => this.setState({ DeleteUserValidation: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >

            <div className="modal-body text-center py-4">
              <button id='registerClose' type="button" className="close" onClick={ () => this.setState({ UpdateMedicStateModal: false }) }>
                  <span aria-hidden="true">&times;</span>
              </button>
              <div className='mx-auto col-10 mb-4 py-4' >
                  <h5 className='mb-5' >¿En verdad deseas eliminar este usuario?</h5>
                  <div className='d-flex justify-content-center align-items-center flex-column' >
                    <button onClick={ () => { this.props.handleDeleteUser(this.state.usuario); this.setState({ DeleteUserValidation: false }) } } className='btn btn-danger mx-auto mb-4' >Eliminar</button>
                    <button onClick={ () => { this.setState({ DeleteUserValidation: false }) } } className='btn btn-secondary mx-auto' >Cancelar</button>
                  </div>
              </div>
            </div>
            
        </Modal>
        
      </div>
    </DashboardComponent>
    )
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
      dispatch,
      setUsuarios: (usuarios) => dispatch (actions.users.fetchUsers({ usuarios })),
      removeUser: (userId) => dispatch (actions.users.deleteUser({ userId })),
      setMedicoState: (medicoId, newState) => dispatch (actions.users.updateMedicoState({ medicoId, newState }))
  }
}

export default connect(null, mapDispatchToProps)(UserTable)