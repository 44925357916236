import React, { Component } from 'react';

import InicioContainer from '../../Components/Inicio.jsx';

import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faVial, faVials } from '@fortawesome/free-solid-svg-icons';
import XRay from './assets/xray.svg'
import Tomography from './assets/tomography.svg'
import Ultrasound from './assets/ultrasound.svg'

import FetchExamenes from './ExamenesFetch/FetchExamenes'

import './css/Examenes.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class Examenes extends Component {

    state = {
        productos: [],
        searchBar: '',
        clasificacion: ''
    }

    componentDidMount() {

        FetchExamenes(this)
        
    }
    
    render() {

        const examFilter = this.state.productos.filter( examen =>
            examen.nombre.toLowerCase().indexOf(this.state.searchBar.toLowerCase()) !== -1
        )
        
        return (
            <InicioContainer>
                <section id="examenes" className="container-fluid" style={{minHeight: '76.3vh'}}>
        
                    <div id='filtro' className='col-11 mx-auto border shadow py-4 text-center' style={{ marginTop: '100px' }} >

                        <div className='navbar navbar-expand-lg col-lg-11 mx-auto'>

                            <div className='row d-lg-flex align-items-lg-center mx-auto mx-lg-0 mr-auto' >

                                <div id="icono-filtro" className="col-12 col-lg-6 mx-0 px-0">
                                    <FontAwesomeIcon icon={faVials} style={{color: '#5DACD4', fontSize: '40px'}} />
                                </div>

                                <div className="col-12 col-lg-6 mx-0 px-0">
                                    <label className='d-none d-xl-block mb-0' style={{minWidth: '100px'}}>Filtro de Búsqueda</label>
                                    <div id='toggler' className='mx-auto mx-lg-0'>
                                        <button className="navbar-toggler x collapsed border-dark btn btn-outline-secondary" type="button" data-toggle="collapse" data-target="#filterToggle" aria-controls="filterToggle" aria-expanded="false" aria-label="Toggle navigation">Filtro de Búsqueda</button>
                                    </div>
                                </div>

                            </div>

                            
                        
                            <div id="filterToggle" className="collapse navbar-collapse justify-content-center justify-content-md-end" >

                                <ul className="navbar-nav">
                                    <li className="nav-item ml-lg-3 mt-3 mt-lg-0 text-center text-lg-right d-lg-none">
                                        <FontAwesomeIcon icon={faArrowCircleDown} style={{color: '#5DACD4', fontSize: '25px'}} className='mt-3' />
                                    </li>
                                    {/* <li className="nav-item ml-lg-3 mt-3 mt-lg-0 text-center text-lg-right">
                                        <select id="selectTipoExamen" className='py-1 px-2 btn border border-dark' value={this.state.clasificacion} onChange={ (event) => this.setState({ clasificacion: event.target.value }) } >
                                            <option value='' >Selecciona un Tipo de Examen</option>
                                            <option value='Sangre' >Examenes de Sangre</option>
                                            <option value='Ultrasonido' >Ultrasonidos</option>
                                            <option value='RayosX' >Rayos X</option>
                                            <option value='Tomografia' >Tomografías</option>
                                        </select>
                                    </li> */}
                                    <li className="nav-item ml-lg-3 mt-3 mt-lg-0 text-center text-lg-right">
                                        <input id="inputTextNombreExamen" type='text' className='py-1 px-2 btn border border-dark' value={this.state.searchBar} placeholder='Nombre del Producto' onChange={ (event) => this.setState({ searchBar: event.target.value }) } />
                                    </li>
                                    <li className="nav-item ml-lg-3 mt-3 mt-lg-0 text-center text-lg-right">
                                        <input id="resetFilterExam" type="reset" onClick={ () => this.setState({ searchBar: '', examType: '' }) } value='Eliminar Filtros' className="py-1 px-2 btn btn-secondary rounded" />
                                    </li>    
                                </ul>
                                
                            </div>

                        </div>

                    </div>

                    <div id='publicidad' className='text-center my-4' >
                        <Slider autoplay={true} arrows={false} dots={true} infinite={true} speed={500} >
                            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                <img src="/img/jpg/examSangre.jpg" alt="Examenes de Sangre" className="img-fluid" />
                            </div>
                            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                <img src="/img/jpg/ultrasonidos.jpg" alt="Ultrasonido" className="img-fluid" />
                            </div>
                            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                <img src="/img/png/rayosX.png" alt="Examenes de Sangre" className="img-fluid" />
                            </div>
                            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                <img src="/img/jpg/tomografia.jpg" alt="Examenes de Sangre" className="img-fluid" />
                            </div>
                        </Slider>
                    </div>
        
                    <div id='examenes-card' className='row' >
                        { 
                            examFilter.length > 0 ?
                                examFilter.map( (examen, i) => {
                                    return <div key={i} className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: '25px', marginBottom: '25px'}}>
                                        <div className="card">
                                            <div className="card-body text-center">
                                                {/* <FontAwesomeIcon icon={"vials"} style={{color: '#5DACD4', fontSize: '50px'}}/> */}
                                                { examen.clasificacion === 'Sangre' && <FontAwesomeIcon icon={faVial} style={{color: '#5DACD4', fontSize: '35px'}}/> }
                                                { examen.clasificacion === 'Tomografia' && <img style={{ height: '35px' }} src={Tomography} alt='rayos x' /> }
                                                { examen.clasificacion === 'RayosX' && <img style={{ height: '35px' }} src={XRay} alt='rayos x' /> }
                                                { examen.clasificacion === 'Ultrasonido' && <img style={{ height: '35px' }} src={Ultrasound} alt='rayos x' /> }
                                                <h5 className="text-center" style={{marginTop: '15px'}}>{examen.nombre}</h5>
                                                { (examen.conOSinMedioDeContraste === typeof 'string' && examen.conOSinMedioDeContraste.length !== 0) ? <p className="text-center"><b>Método de Contraste:</b> {examen.conOSinMedioDeContraste}</p> : null }
                                                <p className="text-center"><b>Precio:</b> Q.{examen.precios_TipoCliente.filter( el => examen.idTipoClienteDefault === el.idTipoCliente)[0].precio}</p>
                                            </div>
                                        </div> 
                                    </div>
                                } ) : null
                        }
                    </div>

                    <div>
                        {/* <div className="row" style={{marginTop: '75px'}}>
                            
                            {
                                examenesSangre.length > 0 ? (
                                    <div className="col-12" style={{marginTop: '25px'}}>
                                        <h2 className="text-center">Examenes de Sangre</h2>
                                        <div className="row">
                                            <div className="col-12 col-sm-8 col-md-6 col-lg-4 mx-auto">
                                                <img src="/img/jpg/examSangre.jpg" alt="Examnes de Sangre" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                examenesSangre.map( (examen, i) => (
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" key={i} style={{marginTop: '25px', marginBottom: '25px'}}>
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <FontAwesomeIcon icon={"vials"} style={{color: '#5DACD4', fontSize: '50px'}}/>
                                                                <h5 className="text-center" style={{marginTop: '15px'}}>{examen.nombre}</h5>
                                                                <p className="text-center"><b>Precio:</b> Q.{examen.precio}</p>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : null    
                            }
                            {
                                ultrasonidos.length > 0 ? (
                                    <div className="col-12" style={{marginTop: '25px'}}>
                                        <h2 className="text-center">Ultrasonidos</h2>
                                        <div className="row">
                                            <div className="col-12 col-sm-8 col-md-6 col-lg-4 mx-auto">
                                                <img src="/img/jpg/ultrasonidos.jpg" alt="Ultrasonido" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                ultrasonidos.map( (ultrasonido, i) => (
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" key={i} style={{marginTop: '25px', marginBottom: '25px'}}>
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <FontAwesomeIcon icon={"vials"} style={{color: '#5DACD4', fontSize: '50px'}}/>
                                                                <h5 className="text-center" style={{marginTop: '15px'}}>{ultrasonido.nombre}</h5>
                                                                <p className="text-center"><b>Precio:</b> Q.{ultrasonido.precio}</p>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : null    
                            }{
                                rayosX.length > 0 ? (
                                    <div className="col-12" style={{marginTop: '25px'}}>
                                        <h2 className="text-center">Rayos X Digitales</h2>
                                        <div className="row">
                                            <div className="col-12 col-sm-8 col-md-6 col-lg-4 mx-auto">
                                                <img src="/img/png/rayosX.png" alt="Examnes de Sangre" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                rayosX.map( (rayoX, i) => (
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" key={i} style={{marginTop: '25px', marginBottom: '25px'}}>
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <FontAwesomeIcon icon={"vials"} style={{color: '#5DACD4', fontSize: '50px'}}/>
                                                                <h5 className="text-center" style={{marginTop: '15px'}}>{rayoX.nombre}</h5>
                                                                {rayoX.conOSinMedioDeContraste !== undefined ? (<p className="text-center"><b>Método de Contraste:</b> {rayoX.conOSinMedioDeContraste}</p>) : null }
                                                                <p className="text-center"><b>Precio:</b> Q.{rayoX.precio}</p>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : null    
                            }
                            {
                                tomografias.length > 0 ? (
                                    <div className="col-12" style={{marginTop: '25px'}}>
                                        <h2 className="text-center">Tomografías</h2>
                                        <div className="row">
                                            <div className="col-12 col-sm-8 col-md-6 col-lg-4 mx-auto">
                                                <img src="/img/jpg/tomografia.jpg" alt="Examnes de Sangre" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                rayosX.map( (tomografia, i) => (
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" key={i} style={{marginTop: '25px', marginBottom: '25px'}}>
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <FontAwesomeIcon icon={"vials"} style={{color: '#5DACD4', fontSize: '50px'}}/>
                                                                <h5 className="text-center" style={{marginTop: '15px'}}>{tomografia.nombre}</h5>
                                                                {tomografia.conOSinMedioDeContraste !== undefined ? (<p className="text-center"><b>Método de Contraste:</b> {tomografia.conOSinMedioDeContraste}</p>) : null }
                                                                <p className="text-center"><b>Precio:</b> Q.{tomografia.precio}</p>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : null    
                            }
            
                        </div> */}
                    </div>

                </section>
            </InicioContainer>
        )

    }

} 

export default Examenes