import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'
import FetchEditUser from '../Containers/FetchEditUser/FetchGraphQLEditUser'
import fetchCountries from '../../../Dashboard/Containers/FetchCountries/fetchCountries'

import fetchEspecialidadesMedico from '../Containers/FetchEspecialidades/FetchEspecialidadesMedico'
import fetchInsertEspecialidadesMedico from '../Containers/FetchInsertEspecialidades/FetchInsertEspecialidadesMedico'

import CreatableSelect from 'react-select/creatable'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';

class UserEdit extends Component {

    state = {
        countries: [],
        guatemala: [],
        currentDepartment: '',
        formMunicipalities: [],
        especialidades: [],
        fetchEditResponse: 'd-none',
        fetchEditMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        current_foto_perfil: '',
        usuario: {
            id: '',
            usuario: '',
            nombre: '',
            apellidos: '',
            correo: '',
            fecha_de_nacimiento: '',
            direccion: '',
            nacionalidad: 'Guatemala',
            sexo: '',
            telefono: 0,
            tipo: '',
            DPI: '',
            foto_perfil: '',
            permisos: {
                AdminUser: {
                    ViewUser: false,
                    CreateUser: false,
                    EditUser: false,
                    DeleteUser: false,
                    UpdateStateMedic: false
                }
            },
            colaborador: {
                id_colaborador: '',
                nit_colaborador: '',
                puesto: '',
                departamento: ''
            },
            medico: {
                id_medico: '',
                estado: 'Validado',
                nit_medico: '',
                numero_colegiado: '',
                clinica_nombre: '',
                clinica_departamento: '',
                clinica_municipalidad: '',
                clinica_direccion: '',
                clinica_telefono: '',
                clinica_correo: '',
                clinica_direccion2: '',
                clinica_telefono2: '',
                clinica_correo2: '',
                clinica_direccion3: '',
                clinica_telefono3: '',
                clinica_correo3: '',
                especialidad: '',
                sub_especialidad: '',
                observaciones: '',
                observaciones_emergencias: '',
                atencion_emergencias: false,
                social_media: {
                    fb_link: '',
                    ins_link: '',
                    web_link: ''
                },
                financiero: {
                    cuenta_numero: '',
                    cuenta_nombre: '',
                    cuenta_tipo: '',
                    banco_nombre: '',
                    factura_nombre: ''
                },
                horario_atencion: [
                    {
                        dia: 'lunes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'martes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'miercoles',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'jueves',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'viernes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'sabado',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'domingo',
                        ingreso: '',
                        egreso: ''
                    }
                ]
            }
        }
    }

    async componentDidMount() {

        let { id, usuario, nombre, apellidos, telefono, correo, DPI, fecha_de_nacimiento, sexo, direccion, tipo, nacionalidad, foto_perfil, permisos, colaborador, medico } = this.props.currentUser
        let ViewUser, CreateUser, EditUser, DeleteUser, UpdateStateMedic
        let id_colaborador, puesto, departamento, nit_colaborador
        let id_medico, nit_medico, numero_colegiado, clinica_nombre, clinica_departamento, clinica_municipalidad, clinica_direccion, clinica_telefono, clinica_correo, clinica_direccion2, clinica_telefono2, clinica_correo2, clinica_direccion3, clinica_telefono3, clinica_correo3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, horario_atencion
        let fb_link, ins_link, web_link
        let cuenta_numero, cuenta_nombre, cuenta_tipo, banco_nombre, factura_nombre

        /* istanbul ignore next */
        if (permisos.AdminUser !== null) {
            ViewUser = permisos.AdminUser.ViewUser
            CreateUser = permisos.AdminUser.CreateUser
            EditUser = permisos.AdminUser.EditUser
            DeleteUser = permisos.AdminUser.DeleteUser
            UpdateStateMedic = permisos.AdminUser.UpdateStateMedic
        } else {
            ViewUser = false
            CreateUser = false
            EditUser = false
            DeleteUser = false
            UpdateStateMedic = false
        }

        /* istanbul ignore next */
        if (colaborador !== null) {
            id_colaborador = colaborador.id
            puesto = colaborador.puesto
            departamento = colaborador.departamento
            nit_colaborador = colaborador.NIT
        } else {
            id_colaborador = ''
            puesto = ''
            departamento = ''
            nit_colaborador = ''
        }

        /* istanbul ignore next */
        if (medico !== null) {
            id_medico = medico.id
            nit_medico = medico.NIT
            numero_colegiado = medico.numero_colegiado
            clinica_nombre = medico.nombre_clinica
            clinica_departamento = medico.departamento_clinica
            clinica_municipalidad = medico.municipalidad_clinica
            clinica_direccion = medico.direccion_clinica
            clinica_telefono = medico.telefono_clinica
            clinica_correo = medico.correo_clinica
            medico.direccion_clinica2 === null ? clinica_direccion2 = '' : clinica_direccion2 = medico.direccion_clinica2
            medico.telefono_clinica2 === null ? clinica_telefono2 = '' : clinica_telefono2 = medico.telefono_clinica2
            medico.correo_clinica2 === null ? clinica_correo2 = '' : clinica_correo2 = medico.correo_clinica2
            medico.direccion_clinica3 === null ? clinica_direccion3 = '' : clinica_direccion3 = medico.direccion_clinica3
            medico.telefono_clinica3 === null ? clinica_telefono3 = '' : clinica_telefono3 = medico.telefono_clinica3
            medico.correo_clinica3 === null ? clinica_correo3 = '' : clinica_correo3 = medico.correo_clinica3
            especialidad = medico.especialidad
            sub_especialidad = medico.sub_especialidad
            atencion_emergencias = medico.atencion_emergencias
            observaciones = medico.observaciones
            observaciones_emergencias = medico.observaciones_emergencias
            fb_link = medico.social_media.fb_link
            ins_link = medico.social_media.ins_link
            web_link = medico.social_media.web_link
            horario_atencion = medico.horario_atencion
            cuenta_numero = medico.financiero.cuenta_numero
            cuenta_nombre = medico.financiero.cuenta_numero
            cuenta_tipo = medico.financiero.cuenta_tipo
            banco_nombre = medico.financiero.banco_nombre
            factura_nombre = medico.financiero.factura_nombre
        } else {
            id_medico = ''
            nit_medico = ''
            numero_colegiado = ''
            clinica_nombre = ''
            clinica_departamento = ''
            clinica_municipalidad = ''
            clinica_direccion = ''
            clinica_telefono = ''
            clinica_correo = ''
            clinica_direccion2 = ''
            clinica_telefono2 = ''
            clinica_correo2 = ''
            clinica_direccion3 = ''
            clinica_telefono3 = ''
            clinica_correo3 = ''
            especialidad = ''
            sub_especialidad = ''
            atencion_emergencias = ''
            observaciones = ''
            observaciones_emergencias = ''
            fb_link = ''
            ins_link = ''
            web_link = ''
            horario_atencion = [
                {
                    dia: 'lunes',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'martes',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'miercoles',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'jueves',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'viernes',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'sabado',
                    ingreso: '',
                    egreso: ''
                },
                {
                    dia: 'domingo',
                    ingreso: '',
                    egreso: ''
                }
            ]
            cuenta_numero = ''
            cuenta_nombre = ''
            cuenta_tipo = ''
            banco_nombre = ''
            factura_nombre = ''
        }

        this.setState({
            ...this.state,
            currentDepartment: clinica_departamento,
            usuario: {
                ...this.state.usuario,
                id: id,
                usuario: usuario,
                nombre: nombre,
                apellidos: apellidos,
                correo: correo,
                fecha_de_nacimiento: fecha_de_nacimiento,
                direccion: direccion,
                nacionalidad: nacionalidad,
                sexo: sexo,
                telefono: telefono,
                tipo: tipo,
                DPI: DPI,
                foto_perfil: foto_perfil,
                permisos: {
                    AdminUser: {
                        ViewUser: ViewUser,
                        CreateUser: CreateUser,
                        EditUser: EditUser,
                        DeleteUser: DeleteUser,
                        UpdateStateMedic: UpdateStateMedic
                    }
                },
                colaborador: {
                    id_colaborador: id_colaborador,
                    puesto: puesto,
                    departamento: departamento,
                    nit_colaborador: nit_colaborador
                },
                medico: {
                    ...this.state.usuario.medico,
                    id_medico: id_medico,
                    nit_medico: nit_medico,
                    numero_colegiado: numero_colegiado,
                    clinica_nombre: clinica_nombre,
                    clinica_departamento: clinica_departamento,
                    clinica_municipalidad: clinica_municipalidad,
                    clinica_direccion: clinica_direccion,
                    clinica_telefono: clinica_telefono,
                    clinica_correo: clinica_correo,
                    clinica_direccion2: clinica_direccion2,
                    clinica_telefono2: clinica_telefono2,
                    clinica_correo2: clinica_correo2,
                    clinica_direccion3: clinica_direccion3,
                    clinica_telefono3: clinica_telefono3,
                    clinica_correo3: clinica_correo3,
                    especialidad: especialidad,
                    sub_especialidad: sub_especialidad,
                    atencion_emergencias: atencion_emergencias,
                    observaciones: observaciones,
                    observaciones_emergencias: observaciones_emergencias,
                    social_media: {
                        fb_link: fb_link,
                        ins_link: ins_link,
                        web_link: web_link
                    },
                    horario_atencion: horario_atencion,
                    financiero: {
                        cuenta_numero: cuenta_numero,
                        cuenta_nombre: cuenta_nombre,
                        cuenta_tipo: cuenta_tipo,
                        banco_nombre: banco_nombre,
                        factura_nombre: factura_nombre
                    }
                }
            },
            current_foto_perfil: foto_perfil
        })
        
        let countries = await fetchCountries()
        
        this.setState({ countries, guatemala: this.props.guatemala, horarios: this.props.horarios })
        
        fetchEspecialidadesMedico(this)

        if ( this.state.currentDepartment !== '' ) { this.setState({ formMunicipalities: this.props.guatemala[this.state.currentDepartment] }) }

    }

    creatableSelect = (event, action, tipo) => {

        if (action.action === 'clear') {

            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    medico: {
                        ...this.state.usuario.medico,
                        [tipo]: ''
                    }
                }
            
            })

        } else if (action.action === 'create-option') {

           fetchInsertEspecialidadesMedico(this, event, tipo)

           this.setState({ 
               usuario: {
                   ...this.state.usuario,
                   medico: {
                       ...this.state.usuario.medico,
                       [tipo]: event.label
                   }
               }
            })

        } else {

           this.setState({ 
               usuario: {
                   ...this.state.usuario,
                   medico: {
                       ...this.state.usuario.medico,
                       [tipo]: event.label
                   }
               }
            })

        }

    }

    editFormHandler = (e) => {
        
        e.preventDefault()

        let usuario = {
            ...this.state.usuario,
            fecha_de_nacimiento: moment(this.state.usuario.fecha_de_nacimiento).format('YYYY-MM-DD'),
            colaborador: {
                ...this.state.usuario.colaborador,
                id_colaborador: this.state.usuario.colaborador.id_colaborador
            }
        }

        FetchEditUser(this, usuario)

    }

    onInputChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                [event.target.id]: event.target.value 
            }
        })
        
    }

    onInputChangeTipo = (event) => {
        if (event.target.value === 'cliente') {
            this.setState({
                ...this.state,
                usuario: {
                    ...this.state.usuario,
                    tipo: event.target.value,
                    colaborador: {
                        id_colaborador: '',
                        puesto: '',
                        departamento: '',
                        nit_colaborador: ''
                    },
                    medico: {
                        id_medico: '',
                        estado: 'No Validado',
                        nit_medico: '',
                        numero_colegiado: '',
                        clinica_nombre: '',
                        clinica_departamento: '',
                        clinica_municipalidad: '',
                        clinica_direccion: '',
                        clinica_telefono: '',
                        clinica_correo: '',
                        clinica_direccion2: '',
                        clinica_telefono2: '',
                        clinica_correo2: '',
                        clinica_direccion3: '',
                        clinica_telefono3: '',
                        clinica_correo3: '',
                        especialidad: '',
                        sub_especialidad: '',
                        observaciones: '',
                        observaciones_emergencias: '',
                        atencion_emergencias: false,
                        social_media: {
                            fb_link: '',
                            ins_link: '',
                            web_link: ''
                        },
                        financiero: {
                            cuenta_numero: '',
                            cuenta_nombre: '',
                            cuenta_tipo: '',
                            banco_nombre: '',
                            factura_nombre: ''
                        },
                        horario_atencion: [
                            {
                                dia: 'lunes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'martes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'miercoles',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'jueves',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'viernes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'sabado',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'domingo',
                                ingreso: '',
                                egreso: ''
                            }
                        ]
                    }
                }
            })
        } else if (event.target.value === 'colaborador') {
            this.setState({
                ...this.state,
                usuario: {
                    ...this.state.usuario,
                    tipo: event.target.value,
                    medico: {
                        id_medico: '',
                        estado: 'Validado',
                        nit_medico: '',
                        numero_colegiado: '',
                        clinica_nombre: '',
                        clinica_departamento: '',
                        clinica_municipalidad: '',
                        clinica_direccion: '',
                        clinica_telefono: '',
                        clinica_correo: '',
                        clinica_direccion2: '',
                        clinica_telefono2: '',
                        clinica_correo2: '',
                        clinica_direccion3: '',
                        clinica_telefono3: '',
                        clinica_correo3: '',
                        especialidad: '',
                        sub_especialidad: '',
                        observaciones: '',
                        observaciones_emergencias: '',
                        atencion_emergencias: false,
                        social_media: {
                            fb_link: '',
                            ins_link: '',
                            web_link: ''
                        },
                        financiero: {
                            cuenta_numero: '',
                            cuenta_nombre: '',
                            cuenta_tipo: '',
                            banco_nombre: '',
                            factura_nombre: ''
                        },
                        horario_atencion: [
                            {
                                dia: 'lunes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'martes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'miercoles',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'jueves',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'viernes',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'sabado',
                                ingreso: '',
                                egreso: ''
                            },
                            {
                                dia: 'domingo',
                                ingreso: '',
                                egreso: ''
                            }
                        ]
                    }
                }
            })
        } else if (event.target.value === 'medico') {
            this.setState({
                ...this.state,
                usuario: {
                    ...this.state.usuario,
                    tipo: event.target.value,
                    colaborador: {
                        id_colaborador: '',
                        puesto: '',
                        departamento: '',
                        nit_colaborador: ''
                    }
                }
            })
        } else {
            this.setState({
                ...this.state,
                usuario: {
                    ...this.state.usuario,
                    [event.target.id]: event.target.value,
                }
            })
        }
    }

    onColaboradorChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                colaborador: {
                    ...this.state.usuario.colaborador,
                    [event.target.id]: event.target.value
                }
            }
        })
        
    }

    onMedicoChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    [event.target.id]: event.target.value
                }
            }
        })
        
    }

    onMedicoSocialMediaChange = (event) => {

        this.setState({ 
            ...this.state,
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    social_media: {
                        ...this.state.usuario.medico.social_media,
                        [event.target.id]: event.target.value
                    }
                }

            }
        })

    }

    onFinancieroChange = (event) => {

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                medico: {
                    ...this.state.usuario.medico,
                    financiero: {
                        ...this.state.usuario.medico.financiero,
                        [event.target.id]: event.target.value
                    }
                }
            }
        })

    }

    onFileUpload = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Print data in console
            files.push(base64)
        };
        // Convert data to base64
        fileReader.readAsDataURL(event.target.files[0]);

        this.setState({ 
            usuario: {
                ...this.state.usuario,
                [event.target.id]: files
            }
        })

    }

    onHorarioChange = (event, time, i) => {

        let setTime = () => {
            
        let nuevo_horario_atencion = this.state.usuario.medico.horario_atencion.map( 
            
            (horario, index) => { 
                
                let new_horario

                if ( index === i ) {
                    new_horario = {
                        ...horario,
                        [time]: event.target.value
                    }
                } else {
                    new_horario = horario
                }

                return new_horario

            })
            
            return nuevo_horario_atencion

        }

        this.setState({ 
                usuario: {
                ...this.state.usuario,
                medico: {
                        ...this.state.usuario.medico,
                    horario_atencion: setTime()
                }
            }
        })

    }

    onPermisoChange = (event) => {

        /* istanbul ignore next */
        if ( event.target.id === 'CreateUser' ) {
            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.CreateUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'ViewUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.ViewUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'EditUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.EditUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'DeleteUser' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.DeleteUser
                        }
                    }
                }
            })
            /* istanbul ignore next */
        } else if ( event.target.id === 'UpdateStateMedic' ) {

            /* istanbul ignore next */
            this.setState({ 
                usuario: {
                    ...this.state.usuario,
                    permisos: {
                        ...this.state.usuario.permisos,
                        AdminUser: {
                            ...this.state.usuario.permisos.AdminUser,
                            [event.target.id]: !this.state.usuario.permisos.AdminUser.UpdateStateMedic
                        }
                    }
                }
            })

        }

    }
 
    render() {

        let current_foto_perfil = this.state.current_foto_perfil
        let { usuario, nombre, apellidos, telefono, correo, DPI, fecha_de_nacimiento, sexo, direccion, tipo, nacionalidad, permisos, colaborador, medico } = this.state.usuario
        let { ViewUser, CreateUser, EditUser, DeleteUser, UpdateStateMedic } = permisos.AdminUser
        let { puesto, departamento, nit_colaborador } = colaborador
        let { nit_medico, numero_colegiado, clinica_nombre, clinica_departamento, clinica_municipalidad, clinica_direccion, clinica_telefono, clinica_correo, clinica_direccion2, clinica_telefono2, clinica_correo2, clinica_direccion3, clinica_telefono3, clinica_correo3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, social_media, financiero, horario_atencion } = medico
        let { fb_link, ins_link, web_link } = social_media
        let { cuenta_numero, cuenta_nombre, cuenta_tipo, banco_nombre, factura_nombre } = financiero

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {
                            id: '',
                            usuario: '',
                            nombre: '',
                            apellidos: '',
                            correo: '',
                            fecha_de_nacimiento: new Date(),
                            direccion: '',
                            nacionalidad: '',
                            sexo: '',
                            telefono: 0,
                            tipo: '',
                            dpi: '',
                            foto_perfil: '',
                            permisos: {
                                AdminUser: {
                                    ViewUser: false,
                                    CreateUser: false,
                                    EditUser: false,
                                    DeleteUser: false,
                                },
                                AdminMedic: {
                                    ViewMedic: false,
                                    CreateMedic: false,
                                    EditMedic: false,
                                    UpdateStateMedic: false
                                }
                            },
                            colaborador: {
                                id_colaborador: '',
                                nit_colaborador: '',
                                puesto: '',
                                departamento: ''
                            },
                            medico: {
                                id_medico: '',
                                estado: 'No Validado',
                                nit_medico: '',
                                numero_colegiado: '',
                                clinica_nombre: '',
                                clinica_departamento: '',
                                clinica_municipalidad: '',
                                clinica_direccion: '',
                                clinica_telefono: '',
                                clinica_correo: '',
                                especialidad: '',
                                sub_especialidad: '',
                                atencion_emergencias: '',
                                financiero: {
                                    cuenta_numero: '',
                                    cuenta_nombre: '',
                                    cuenta_tipo: '',
                                    banco_nombre: '',
                                    factura_nombre: '',
                                    documentos: {
                                        dpi_front: '',
                                        dpi_back: '',
                                        licencia_sanitaria: '',
                                        patente: '',
                                        recibo_colegiado: '',
                                        titulo_academico: '',
                                        constancia_desechos_solidos: '',
                                        rtu: '',
                                        factura: '',
                                    }
                                },
                                horario_atencion: [
                                    {
                                        dia: 'lunes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'martes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'miercoles',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'jueves',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'viernes',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'sabado',
                                        ingreso: '',
                                        egreso: ''
                                    },
                                    {
                                        dia: 'domingo',
                                        ingreso: '',
                                        egreso: ''
                                    }
                                ]
                            }
                        }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        { current_foto_perfil !== null ? <img src={current_foto_perfil} alt={nombre} style={{ height: '150px', borderRadius: '50%' }} /> : <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '80px' }} className='mt-3' /> }<br/><br/>
                        <div className='form-group mx-auto col-10 col-md-6 col-lg-4 mb-4' >
                            <label htmlFor="foto_perfil" className=''>Fotografía de Perfil</label>
                            <input 
                                onChange={ this.onFileUpload }
                                accept=".png, .jpg, .jpeg"
                                data-test-type='input-file'
                                type="file" className="form-control p-1" id="foto_perfil"
                            />
                        </div>
                        <h1>{ this.props.currentUser.nombre } { this.props.currentUser.apellidos }</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='editForm' onSubmit={this.editFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="usuario">Usuario</label>
                                    <input value={usuario} onChange={ this.onInputChange } type="text" className="form-control" id="usuario" required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input value={nombre} onChange={ this.onInputChange } type="text" className="form-control" id="nombre" required />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="apellidos">Apellidos</label>
                                    <input value={apellidos} onChange={ this.onInputChange } type="text" className="form-control" id="apellidos" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <input value={telefono} onChange={ this.onInputChange } type="number" className="form-control" id="telefono" data-test-type='input-number' required />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="correo">Correo</label>
                                    <input value={correo} onChange={ this.onInputChange } type="emial" className="form-control" id="correo" data-test-type='input-email' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="dpi">DPI</label>
                                    <input value={DPI} onChange={ this.onInputChange } type="text" className="form-control" id="DPI" data-test-type='input-text' />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="fecha_de_nacimiento">Fecha de Nacimiento</label>
                                    <input value={moment(fecha_de_nacimiento).format('YYYY-MM-DD')} onChange={ this.onInputChange } type="date" className="form-control" id="fecha_de_nacimiento" data-test-type='input-date' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="sexo">Sexo</label>
                                    <select value={sexo} onChange={ this.onInputChange } type="text" className="form-control" id="sexo" data-test-type='input-text' required >
                                        <option value='' >Selecciona un Sexo</option>
                                        <option value='Masculino' >Masculino</option>
                                        <option value='Femenino' >Femenino</option>
                                    </select>

                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="tipo">Tipo de Usuario</label>
                                    <select id="tipo" value={tipo} onChange={ this.onInputChangeTipo } type="text" className="form-control" data-test-type='input-text' required >
                                        <option value='' >Selecciona un Tipo</option>
                                        <option value='cliente' >Cliente</option>
                                        <option value='colaborador' >Colaborador</option>
                                        <option value='medico' >Médico</option>
                                    </select>
                                    
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="nacionalidad">Nacionalidad</label>
                                    {/* <input value={nacionalidad} onChange={ this.onInputChange } type="text" className="form-control" id="nacionalidad" /> */}
                                    { 
                                        this.state.countries.length > 0 ? (
                                            <select id="nacionalidad" className="form-control" placeholder="Escoje tu Nacionalidad" onChange={ this.onInputChange } value={nacionalidad} >
                                                { this.state.countries.map( (country, i) => <option value={country.name} key={i}>{country.name}</option>) }
                                            </select>
                                        ) : (
                                            <select id="nacionalidad" className="form-control" placeholder="Escoje tu Nacionalidad" onChange={ this.onInputChange } value={nacionalidad} >
                                                <option value="Guatemala">Guatemala</option>
                                            </select>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="direccion">Dirección</label>
                                <input value={direccion} onChange={ this.onInputChange } type="text" className="form-control" id="direccion" data-test-type='input-text' />
                            </div>
                            
                            {/* Colaborador */}
                            <div>
                                {
                                    this.state.usuario.tipo === 'colaborador' ?
                                        <div>
                                            <h3>Colaborador</h3>
                                            <div className="form-group">
                                                <label htmlFor="puesto">Puesto</label>
                                                <input 
                                                    value={ puesto } 
                                                    onChange={ this.onColaboradorChange }
                                                    type="text" className="form-control" id="puesto" data-test-type='input-text' required
                                                />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="departamento">Departamento</label>
                                                    <select 
                                                        value={ departamento } 
                                                        onChange={ this.onColaboradorChange }
                                                        type="text" className="form-control" id="departamento" data-test-type='input-text' required
                                                    >
                                                        <option value='' >Selecciona un Departamento</option>    
                                                        <option value='Laboratorio' >Laboratorio</option>    
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="nit_colaborador">NIT</label>
                                                    <input 
                                                        value={ nit_colaborador } 
                                                        onChange={ this.onColaboradorChange }
                                                        type="text" className="form-control" id="nit_colaborador" data-test-type='input-text' required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null
                                    
                                }
                            </div>

                            {/* Medico */}
                            <div>
                                {
                                    this.state.usuario.tipo === 'medico' ?
                                        <div className='mt-4' >
                                            <h3>Médico</h3>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="numero_colegiado">Número de Colegiado</label>
                                                    <input 
                                                        value={ numero_colegiado } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="numero_colegiado" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="medicoNIT">Nombre de la Clínica</label>
                                                    <input 
                                                        value={ clinica_nombre } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="clinica_nombre" required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_departamento">Departamento</label>
                                                    <select value={clinica_departamento} onChange={ (e) => { this.onMedicoChange(e); this.setState({ currentDepartment: e.target.value, formMunicipalities: this.props.guatemala[e.target.value] }) } } type="text" className="form-control" id="clinica_departamento" data-test-type='select' required >
                                                        <option value='' >Selecciona un Departamento</option>
                                                        {Object.keys(this.props.guatemala).map( (department, i) => { return <option key={i} value={department} >{department}</option> })}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_municipalidad">Municipalidad</label>
                                                    <select value={clinica_municipalidad} onChange={ this.onMedicoChange } type="text" className="form-control" id="clinica_municipalidad" data-test-type='select' required >
                                                        <option value='' >Selecciona un Municipio</option>
                                                        { this.state.usuario.medico.clinica_departamento !== '' ? this.state.formMunicipalities.map( (municipalidad, i) => <option key={i} value={municipalidad} >{municipalidad}</option> ) : null }
                                                    </select>
                                                </div>
                                            </div>

                                            
                                            <h5 className='text-center mt-4' >Información de Clínica No. 01</h5>

                                            <div className="form-group">
                                                <label htmlFor="clinica_direccion">Dirección de la Clínica</label>
                                                <input 
                                                    value={ clinica_direccion } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="clinica_direccion" required
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_telefono">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ clinica_telefono } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="clinica_telefono" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_correo">Correo de la Clínica</label>
                                                    <input 
                                                        value={ clinica_correo } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="clinica_correo" required
                                                    />
                                                </div>
                                            </div>

                                            <h5 className='text-center mt-4' >Información de Clínica No. 02</h5>

                                            <div className="form-group">
                                                <label htmlFor="clinica_direccion2">Dirección de la Clínica</label>
                                                <input 
                                                    value={ clinica_direccion2 } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="clinica_direccion2"
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_telefono2">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ clinica_telefono2 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="clinica_telefono2"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_correo2">Correo de la Clínica</label>
                                                    <input 
                                                        value={ clinica_correo2 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="clinica_correo2"
                                                    />
                                                </div>
                                            </div>

                                            <h5 className='text-center mt-4' >Información de Clínica No. 03</h5>

                                            <div className="form-group">
                                                <label htmlFor="clinica_direccion3">Dirección de la Clínica</label>
                                                <input 
                                                    value={ clinica_direccion3 } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="clinica_direccion3"
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="clinica_telefono3">Número Telefónico de la Clinica</label>
                                                    <input 
                                                        value={ clinica_telefono3 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="number" className="form-control" id="clinica_telefono3"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="clinica_correo3">Correo de la Clínica</label>
                                                    <input 
                                                        value={ clinica_correo3 } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="email" className="form-control" id="clinica_correo3"
                                                    />
                                                </div>
                                            </div>


                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="especialidad">Especialidad</label>
                                                    <CreatableSelect
                                                        isClearable
                                                        value={ this.state.especialidades.find(specialty => specialty.label === especialidad )  }
                                                        onChange={ (event, action) => this.creatableSelect(event, action, 'especialidad') }
                                                        options={this.state.especialidades.filter( especialidad => especialidad.tipo === 'especialidad' )}
                                                    />
                                                    
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="sub_especialidad">Sub-Especialidad</label>
                                                    <CreatableSelect
                                                        isClearable
                                                        value={ this.state.especialidades.find(specialty => specialty.label === sub_especialidad )  }
                                                        onChange={ (event, action) => this.creatableSelect(event, action, 'sub_especialidad') }
                                                        options={this.state.especialidades.filter( especialidad => especialidad.tipo === 'sub_especialidad' )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="atencion_emergencias">Atención a Emergencias</label>
                                                    <select value={atencion_emergencias} onChange={ this.onMedicoChange } type="text" className="form-control" id="atencion_emergencias" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='true' >Sí</option>
                                                        <option value='false' >No</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="nit_medico">NIT</label>
                                                    <input 
                                                        value={ nit_medico } 
                                                        onChange={ this.onMedicoChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="nit_medico" required
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="fb_link">URL Perfil de Facebook</label>
                                                    <input 
                                                        value={ fb_link } 
                                                        onChange={ this.onMedicoSocialMediaChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="fb_link"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ins_link">URL Perfil de Instagram</label>
                                                    <input 
                                                        value={ ins_link } 
                                                        onChange={ this.onMedicoSocialMediaChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="ins_link"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="web_link">URL del Sitio Oficial del Médico</label>
                                                <input 
                                                    value={ web_link } 
                                                    onChange={ this.onMedicoSocialMediaChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="web_link"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="observaciones">Observaciones</label>
                                                <input 
                                                    value={ observaciones } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="observaciones"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="observaciones_emergencias">Observaciones de Emergencias</label>
                                                <input 
                                                    value={ observaciones_emergencias } 
                                                    onChange={ this.onMedicoChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="observaciones_emergencias"
                                                />
                                            </div>

                                            <h4>Horarios de Atención</h4>

                                            <div className="form-row mx-auto">

                                                {
                                                    horario_atencion.map( (horario, i) => {

                                                            return (
                                                                <div className="form-group col-md-4 p-3 mx-auto" key={i}>
                                                                    <label htmlFor={`ingreso-${horario.dia}`} className='text-capitalize'>{horario.dia}</label>
                                                                    <select value={horario.ingreso} onChange={ (e) => this.onHorarioChange(e, 'ingreso', i) } type="text" className="form-control" id={`ingreso-${horario.dia}`} data-test-type='select' >
                                                                        <option value='' >Ingreso</option>
                                                                        { this.props.horarios.map( (horario, i) => <option key={i} value={horario} >{horario}</option> ) }
                                                                    </select>
                                                                    <select value={horario.egreso} onChange={ (e) => this.onHorarioChange(e, 'egreso', i) } type="text" className="form-control" id={`egreso-${horario.dia}`} data-test-type='select' >
                                                                        <option value='' >Egreso</option>
                                                                        { this.props.horarios.map( (horario, i) => <option key={i} value={horario} >{horario}</option> ) }
                                                                    </select>
                                                                </div>
                                                            ) 
                                                        }
                                                    )
                                                }
                                                
                                            </div>

                                            <h4>Información Financiera</h4>
                                            
                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="cuenta_numero">Número de Cuenta</label>
                                                    <input 
                                                        value={ cuenta_numero } 
                                                        onChange={ this.onFinancieroChange }
                                                        data-test-type='input-number'
                                                        type="number" className="form-control" id="cuenta_numero" required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="cuenta_nombre">Nombre de la Cuenta</label>
                                                    <input 
                                                        value={ cuenta_nombre } 
                                                        onChange={ this.onFinancieroChange }
                                                        data-test-type='input-text'
                                                        type="text" className="form-control" id="cuenta_nombre" required
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="form-row">
                                                <div className='form-group col-md-6' >
                                                    <label htmlFor="cuenta_tipo">Tipo de Cuenta</label>
                                                    <select value={cuenta_tipo} onChange={ this.onFinancieroChange } type="text" className="form-control" id="cuenta_tipo" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='Monetario' >Monetario</option>
                                                        <option value='Ahorro' >Ahorro</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="banco_nombre">Nombre del Banco</label>
                                                    <select value={banco_nombre} onChange={ this.onFinancieroChange } type="text" className="form-control" id="banco_nombre" data-test-type='select' required >
                                                        <option value='' >Selecciona una Respuesta</option>
                                                        <option value='Banrural' >Banrural</option>
                                                        <option value='Banco Agromercantil' >Banco Agromercantil</option>
                                                        <option value='BAC' >BAC</option>
                                                        <option value='Banco Industrial' >Banco Industrial</option>
                                                        <option value='GyT Continental' >GyT Continental</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group">
                                                <label htmlFor="factura_nombre">Nombre de Facturación</label>
                                                <input 
                                                    value={ factura_nombre } 
                                                    onChange={ this.onFinancieroChange }
                                                    data-test-type='input-text'
                                                    type="text" className="form-control" id="factura_nombre" required
                                                />
                                            </div>

                                        </div>
                                    : null
                                    
                                }
                            </div>

                            {/* Permisos */}
                            <div>
                                <div className="form-group mx-auto text-center">
                                    <div>
                                        <button id='botonPermisos' className="btn btn-primary mt-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                            Permisos de Usuario
                                        </button>
                                        <div className="collapse" id="collapseExample">
                                            <div className="card card-body mt-3 mx-auto col-12 col-md-10 col-lg-8">
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ CreateUser } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="CreateUser" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="CreateUser">Crear Usuario</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ ViewUser } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="ViewUser" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="ViewUser">Ver Usuario</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ EditUser } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="EditUser" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="EditUser">Editar Usuario</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input checked={ DeleteUser } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="DeleteUser" data-test-type='input-checkbox' />
                                                    <label className="custom-control-label" htmlFor="DeleteUser">Eliminar Usuario</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                        <input checked={ UpdateStateMedic } onChange={ this.onPermisoChange } type="checkbox" className="custom-control-input" id="UpdateStateMedic" data-test-type='input-checkbox' />
                                                        <label className="custom-control-label" htmlFor="UpdateStateMedic">Actualizar Estado de Médico</label>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            
                            <button type="submit" className="btn btn-success">Editar</button>

                            <div className={this.state.fetchEditResponse} role="alert">
                                {this.state.fetchEditMessage}
                            </div>

                        </form>


                        <div className='row mt-5'>
                            
                            {
                                tipo !== 'medico' ? null :
                                <div id="tableViewRedirect"
                                    onClick={ () => this.props.handleDisplay('MedicDocumentsEdit', this.props.currentUser) } 
                                    className='col-12 col-md-4 mx-auto' 
                                >
                                    <button id='botonDocumentos' className="btn btn-info mt-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                        Documentos
                                    </button>
                                </div>
                            }

                            {
                                tipo !== 'medico' && this.props.usuario.tipo === 'colaborador' ? null :
                                <div id="tableViewRedirect"
                                    onClick={ () => this.props.handleDisplay('ConsultaMedico', this.props.currentUser) } 
                                    className='col-12 col-md-4 mx-auto' 
                                >
                                    <button id='botonConsulta' className="btn btn-info mt-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                        Consulta
                                    </button>
                                </div>
                            }

                            {
                                tipo !== 'medico' && this.props.usuario.tipo === 'colaborador' ? null :
                                <div id="tableViewRedirect"
                                    onClick={ () => this.props.handleDisplay('ProcedimientosMedico', this.props.currentUser) } 
                                    className='col-12 col-md-4 mx-auto' 
                                >
                                    <button id='botonProcedimientos' className="btn btn-info mt-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                        Procedimientos
                                    </button>
                                </div>
                            }
                        </div>

                    </div>

                </div>
            </DasboardComponent>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        guatemala: state.appInfo.publicData.guatemala,
        horarios: state.appInfo.publicData.horarios
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        editUser: ( user ) => dispatch ( actions.users.editUser({ user }) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (UserEdit)