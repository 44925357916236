import React, { Component } from 'react'
import { connect } from 'react-redux'

import QRCode from "qrcode.react";
import printEl from "print-el";
import Modal from 'react-bootstrap4-modal';
import PDFViewer from '../../../Components/PDFViewer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileDownload } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../../Components/Loader'
import DasboardComponent from '../../Components/DashboardComponent'
import FetchAnalysis from '../containers/fetchGetAnalysis/FetchGetAnalysis'
import FetchSendAnalysisEmail from '../containers/fetchSendAnalysisEmail/FetchSendAnalysisEmail'



class AnalysisReport extends Component {

    state = {
        loaderClassName: 'd-none',
        sendButtonClassName: 'btn btn-info rounded my-5',
        sendEmailResponseClassName: 'd-none',
        sendEmailResponseMessage: '',
        nombre: '',
        apellidos: '',
        correo: '',
        telefono: '',
        nombreAnalisis: '',
        documentoAnalisis: ''
    }

    componentDidMount() {

        FetchAnalysis(this, this.props.match.match.params.idAnalisis)
    }

    onfilDownload = (event, nombre, apellidos, nombreAnalisis, documentoAnalisis) => {
        var link
        link = document.getElementById("downloadButton")
        link.href = documentoAnalisis
        link.download = `${nombre}_${apellidos}_${nombreAnalisis}.pdf`
        link.target = "_blank"
    }

    onPrint = () => {

        let { nombre, apellidos, correo, telefono, nombreAnalisis } = this.state

        var QRCanvas = document.querySelector('#QRCanvas').childNodes[0].toDataURL()
        let content = `
            <div style="size: 7in 9.25in; margin: 27mm 16mm 27mm 16mm;" >
                <div id="AnalisisContainer" class='mx-auto text-center' >
                    <img src='/img/Logo.png' class='mb-5' style="height:75px;" alt='CDM' />
                    <h2>Resultados de Análisis</h2>
                    <h3>${nombreAnalisis}</h3>
                    
                    
                    <div class='my-5' >
                        <h4>${nombre} ${apellidos}</h4>
                        <h5>${correo}</h5>
                        <h5>${telefono}</h5>
                    </div>
                    <img src='${QRCanvas}' style='display: block; margin-left: auto; margin-right: auto; width: 150px;' />
                </div>
                <div id='footer' style='position: fixed; left: 0; bottom: 0; width: 100%; background-color: #17a2b8; color: white; text-align: center;' >
                    <p >Dirección: 9 Calle "A" 00-75 zona 1 9 Calle "A" 00-75 zona 1-Guatemala, Guatemala.</p>
                    <p >Teléfono: +502 2509-6969</p>
                </div>
            </div>
        `

        printEl(content, {
            useGlobalStyle: true,
        });
        return true
    }
    
    render() {

        let { nombre, apellidos, correo, telefono, nombreAnalisis, documentoAnalisis } = this.state

        if ( this.props.conexion && this.props.usuario.tipo === 'colaborador' ) {
            return (
                <DasboardComponent>
                    <div className="col-11 mx-auto py-5" style={{marginTop: '50px', minHeight: '75vh'}}>

                        <div id="AnalisisContainer" className='mx-auto text-center' >

                            <h2>Resultados de Análisis</h2>
                            <h3>{nombreAnalisis}</h3>
                            
                            
                            <div className='my-5' >
                                <h4>{nombre} {apellidos}</h4>
                                <h5>{correo}</h5>
                                <h5>{telefono}</h5>

                            </div>

                            <div id="QRCanvas" className='my-5 d-print-none' >
                                <QRCode value={`https://cdmsalud.com/analisis/${this.props.match.match.params.idAnalisis}`} />
                            </div>


                            <div className='my-5' >
                                { documentoAnalisis === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: documentoAnalisis }) } icon={faEye} style={{ fontSize: '40px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-4 d-print-none' title='Visualizar Documento' /> }
                                { documentoAnalisis === null ? null : <a href={documentoAnalisis} download={documentoAnalisis} onClick={ (event) => this.onfilDownload(event, nombre, apellidos, nombreAnalisis, documentoAnalisis) } className='mx-4 d-print-none' id='downloadButton' ><FontAwesomeIcon icon={faFileDownload} style={{ fontSize: '40px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} title='Descargar Documento' /></a> }
                            </div>

                            <div className="d-print-none">
                                <button className={this.state.sendButtonClassName} onClick={ () => FetchSendAnalysisEmail(this, this.props.match.match.params.idAnalisis ) } >Reenviar Correo</button>
                                
                                <div className={this.state.loaderClassName} >
                                    <Loader />
                                </div>

                                <p className={this.state.sendEmailResponseClassName} >{this.state.sendEmailResponseMessage}</p>
                            </div>

                            {
                                ( this.props.conexion && this.props.usuario.tipo === 'colaborador' ) ? (
                                    <div className="d-print-none">
                                        <button className={this.state.sendButtonClassName} onClick={this.onPrint}>Imprimir Código QR de Análisis</button>
                                    </div>
                                ) : null
                            }



                        </div>

                        {
                            documentoAnalisis.length !== 0 ?
                            <Modal id='documentModal' visible={this.state.documentModal} fade onClickBackdrop={ () => this.setState({ documentModal: false, currentDocument: '' })} className='modal' dialogClassName='modal-dialog modal-lg' >
                        
                                <div className="modal-body text-center py-4">
                                    <PDFViewer file={documentoAnalisis} />
                                </div>

                            </Modal> : null
                        }

                    </div>
                </DasboardComponent>
            )
        } else {
            return (
                <div className='py-3' >

                    <div className="col-11 col-md-8 col-lg-6 mx-auto text-center py-5 border rounded shadow-lg" >

                        <img src='/img/Logo.png' className='my-5' style={{ height: '75px' }} alt='CDM' />

                        <h2>Resultados de Análisis</h2>
                        <h3>{nombreAnalisis}</h3>


                        <div className='my-5' >
                            <h4>{nombre} {apellidos}</h4>
                            <h5>{correo}</h5>
                            <h5>{telefono}</h5>

                        </div>

                        <div className='my-5' >
                            <QRCode value={`https://cdmsalud.com/analisis/${this.props.match.match.params.idAnalisis}`} />
                        </div>


                        <div className='my-5 d-print-none' >
                            { documentoAnalisis === null ? null : <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: documentoAnalisis }) } icon={faEye} style={{ fontSize: '40px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} className='mx-4' title='Visualizar Documento' /> }
                            { documentoAnalisis === null ? null : <a href={documentoAnalisis} download={documentoAnalisis} onClick={ (event) => this.onfilDownload(event, nombre, apellidos, nombreAnalisis, documentoAnalisis) } className='mx-4' id='downloadButton' ><FontAwesomeIcon icon={faFileDownload} style={{ fontSize: '40px', 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', color: '#17a2b8' }} title='Descargar Documento' /></a> }
                        </div>


                        {
                            documentoAnalisis.length !== 0 ?
                            <Modal id='documentModal' visible={this.state.documentModal} fade onClickBackdrop={ () => this.setState({ documentModal: false, currentDocument: '' })} className='' dialogClassName='modal-lg' >

                                <div className="modal-body text-center py-4">
                                    <PDFViewer file={documentoAnalisis} />
                                </div>

                            </Modal> : null
                        }

                    </div>

                </div>
            )
        }
    
    }
}

const mapStateToProps = state => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
    }
}

export default connect(mapStateToProps) (AnalysisReport)