let consulta = (id, update) => {

  let string = `
    mutation {
      userMutation {
        updateMedicoState (
          medicoId: "${id}"
          newState: "${update}"
        ) {
          medicoId
          newState
          mensaje
        }
      }
    }
  `

  return {
    "query": string
  }

}

export default consulta;