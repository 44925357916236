import React, { Component } from 'react'
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable'
import actions from '../../../../../actions';
import DasboardComponent from '../../../Components/DashboardComponent'

import FetchEditProduct from '../Containers/fetchEditProduct/FetchGraphQLProducts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


export class ProductEdit extends Component {

    state = {
        loaderClassName: 'd-none',
        fetchEditResponse: 'd-none',
        fetchEditMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        clasificaciones: [],
        tipos: [],
        tiposCliente: [],
        producto: {
            id: "",
            nombre: "",
            clasificacion: "",
            tipo: "",
            descFamGT: 0,
            conOSinMedioDeContraste: "", //optional
            observaciones: "",  //optional
            idTipoClienteDefault: "",
            precios_TipoCliente: []
        }
    }

    componentDidMount() {

        let clasificaciones = [...new Set(this.props.products.map( producto => producto.clasificacion ))].map( element => ({ label: element, value: element }) )
        let tipos = [...new Set(this.props.products.map( producto => producto.tipo ))].map( element => ({ label: element, value: element }) )
        let tiposCliente = this.props.tiposCliente

        let { id, nombre, tipo, clasificacion, descFamGT, conOSinMedioDeContraste, observaciones, idTipoClienteDefault, precios_TipoCliente } = this.props.currentProduct

        if(descFamGT === null || descFamGT === undefined) descFamGT = 0
        if(conOSinMedioDeContraste === null || conOSinMedioDeContraste === undefined) conOSinMedioDeContraste = ''
        if(observaciones === null || observaciones === undefined) observaciones = ''
        
        let producto = { ...this.state.producto, id, nombre, tipo, clasificacion, descFamGT, conOSinMedioDeContraste, observaciones, idTipoClienteDefault, precios_TipoCliente }

        this.setState({ clasificaciones, tipos, tiposCliente, producto })



    }

    creatableSelect = (event, action, tipo) => {

        if (action.action === 'clear') {
            this.setState({ 
               producto: {
                   ...this.state.producto,
                   [tipo]: ''
               }
            })
        } else {
            this.setState({ 
                producto: {
                    ...this.state.producto,
                    [tipo]: event.label
                }
             })
        }

        // else if (action.action === 'create-option') {} 

   }

    editFormHandler = (e) => {
        
        e.preventDefault()

        let producto = this.state.producto

        FetchEditProduct(this, producto)

    }

    onInputChange = (event) => {
        this.setState({ 
            producto: {
                ...this.state.producto,
                [event.target.id]: event.target.value 
            }
        })
    }

    render() {

        let { nombre, tipo, clasificacion, descFamGT, conOSinMedioDeContraste, observaciones, idTipoClienteDefault } = this.state.producto

        return (
            <DasboardComponent>

                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {}) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        <h1>{ this.props.currentProduct.nombre }</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='editForm' onSubmit={this.editFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className="form-row">
                            <div className="form-group col-md-6">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input value={nombre} onChange={ (event) => this.setState({ producto: { ...this.state.producto, [event.target.id]: event.target.value.toUpperCase() } }) } style={{ textTransform: 'uppercase' }} type="text" className="form-control" id="nombre" required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="clasificacion">Clasificación</label>
                                    {/* <input value={clasificacion} onChange={ this.onInputChange } type="text" className="form-control" id="clasificacion" required /> */}
                                    <CreatableSelect
                                        isClearable
                                        value={ this.state.clasificaciones.find( el => el.label === clasificacion )  }
                                        onChange={ (event, action) => this.creatableSelect(event, action, 'clasificacion') }
                                        options={this.state.clasificaciones}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="tipo">Tipo</label>
                                    {/* <input value={tipo} onChange={ this.onInputChange } type="text" className="form-control" id="tipo" required /> */}
                                    <CreatableSelect
                                        isClearable
                                        value={ this.state.tipos.find( el => el.label === tipo )  }
                                        onChange={ (event, action) => this.creatableSelect(event, action, 'tipo') }
                                        options={this.state.tipos}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="descFamGT">Descuento FamGT</label>
                                    <input value={descFamGT*100} onChange={ (event) => this.setState({ producto: { ...this.state.producto, [event.target.id]: (event.target.value * 0.01) } }) } type="text" className="form-control" id="descFamGT" required />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6 mx-auto">
                                    <label htmlFor="conOSinMedioDeContraste">Con o Sin Medio de Contraste</label>
                                    <input value={conOSinMedioDeContraste} onChange={ this.onInputChange } type="text" className="form-control" id="conOSinMedioDeContraste" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <label htmlFor="observaciones">Observaciones</label>
                                    <textarea value={observaciones} onChange={ this.onInputChange } type="text" className="form-control" id="observaciones" />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-12 mx-auto">
                                    <label htmlFor="idTipoClienteDefault">Tipo de Cliente Predeterminado</label>
                                    <select onChange={ this.onInputChange } value={idTipoClienteDefault} className="form-control col-md-6 mx-auto" id="idTipoClienteDefault" required >
                                        <option value='' >Tipo de Cliente</option>
                                        { this.state.tiposCliente.filter( tipo => this.state.producto.precios_TipoCliente.map( tipo => tipo.idTipoCliente).includes(tipo.id)).map( (tipo, i) => <option key={i} value={tipo.id} >{tipo.nombre}</option> ) }
                                    </select>
                                </div>
                            </div>
                            
                            <button type="submit" className="btn btn-success mt-5">Editar</button>

                            <div className={this.state.fetchEditResponse} role="alert">
                                {this.state.fetchEditMessage}
                            </div>

                        </form>

                    </div>

                </div>

            </DasboardComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        products: state.appInfo.productsData.products
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        editProduct: (product) => dispatch (actions.products.editProduct({ product }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ProductEdit)