const initialState = {
    panels: []
}

let panels = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_PANELS':
            return {
                ...state,
                panels: action.payload.panels
            }
        case 'CREATE_PANEL': 
            let newPanel = [ action.payload.panel ]
            let statePanels = [...state.panels]
            let newPanelsArr = statePanels.concat(newPanel)
            return {
                ...state,
                panels: newPanelsArr
            }
        case 'EDIT_PANEL': 
            let panelIndex = state.panels.findIndex(panel => panel.id === action.payload.panel.id)
            let editedArray = [ ...state.panels ]
            editedArray[panelIndex] = action.payload.panel
            return {
                ...state,
                panels: editedArray
            }  
        case 'DELETE_PANEL': 
            let filteredArray = state.panels.filter((panel) => panel.id !== action.payload.panelId)
            return {
                ...state,
                panels: filteredArray
            }
            //break;
        default:
            return state

    }
}
  
  export default panels