import React, { Component } from 'react'
import { connect } from 'react-redux'

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'
import Loader from '../../../Components/Loader'

import FetchCreateEmpresa from '../containers/FetchCreateEmpresa/FetchGQLCreateEmpresa'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


class EmpresaCreate extends Component {

    state = {
        
        fetchCreateResponse: 'd-none',
        fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        empresa: {
            logo: '',
            nombre: '',
            direccion: '',
            telefono: '',
            correo: '',
            NIT: ''
        }
        
    }

    createFormHandler = (e) => {
        
        e.preventDefault()

        FetchCreateEmpresa(this, this.state.empresa)

    }

    onInputChange = (event) => {

        this.setState({ 
            empresa: {
                ...this.state.empresa,
                [event.target.id]: event.target.value 
            }
        })
        
    }

    onFileUpload = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Print data in console
            files.push(base64)
        };
        // Convert data to base64
        fileReader.readAsDataURL(event.target.files[0]);

        if ( event.target.id === 'logo' ) {
            this.setState({ 
                empresa: {
                    ...this.state.empresa,
                    [event.target.id]: files
                }
            })
        }

    }

    render() {

        return (
            <DasboardComponent >
            
                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {
                            id: '',
                            logo: '',
                            nombre: '',
                            direccion: '',
                            telefono: '',
                            correo: '',
                            NIT: '',
                            FK_Afiliacion: ''
                        }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <form id='createForm' onSubmit={this.createFormHandler} className='mx-auto text-center mt-5' >

                        <div className='mx-auto text-center' >
                            <FontAwesomeIcon icon={'building'} style={{ fontSize: '80px' }} className='mt-3' /><br/><br/>
                            <div className='form-group mx-auto custom-file col-10 col-md-6 col-lg-4' >
                                <label htmlFor="logo" className='custom-file-label'>Logotipo</label>
                                <input 
                                    onChange={ this.onFileUpload }
                                    accept=".png, .jpg, .jpeg"
                                    data-test-type='input-file'
                                    type="file" className="form-control custom-file-input" id="logo" required
                                />
                            </div>
                            <h1>Crea un Nueva Empresa</h1>
                        </div>


                        <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >
                            
                            <div className='form-row' >
                                <div className="form-group col-md-6">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input onChange={ this.onInputChange } type="text" className="form-control" id="nombre" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <input onChange={ this.onInputChange } type="text" className="form-control" id="telefono" data-test-type='input-text' required />
                                </div>
                            </div>

                            <div className='form-row' >
                                <div className="form-group col-md-6">
                                    <label htmlFor="correo">Correo Electrónico</label>
                                    <input onChange={ this.onInputChange } type="text" className="form-control" id="correo" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="NIT">NIT</label>
                                    <input onChange={ this.onInputChange } type="text" className="form-control" id="NIT" data-test-type='input-text' required />
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <label htmlFor="direccion">Dirección</label>
                                <input onChange={ this.onInputChange } type="text" className="form-control" id="direccion" data-test-type='select' />
                            </div>
                            
                            <button type="submit" className="btn btn-success mt-3">Crear</button>
                            
                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchCreateResponse} role="alert">
                                {this.state.fetchCreateMessage}
                            </div>

                        </div>

                    </form>

                </div>
            
            </DasboardComponent>
        )
    }
}


export const mapDispatchToProps = dispatch => {
    return {
        createCompany: ( company ) => dispatch ( actions.companies.createCompany({ company }) )
    }
}

export default connect(null, mapDispatchToProps) (EmpresaCreate)