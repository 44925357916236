import React, { Component } from 'react'
import DashboardComponent from '../../../Components/DashboardComponent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

export class ProductView extends Component {

    state = {
        tiposCliente: [],
        preciosTipoCliente: [],
        defaultTipoClienteNombre: ''
    }

    async componentDidMount() {
        
        let tiposCliente = await this.props.tiposCliente
        this.setState({ tiposCliente })

        let preciosTipoCliente = this.props.currentProduct.precios_TipoCliente.map( obj => {
            let nombre = this.state.tiposCliente.find( tipo => tipo.id === obj.idTipoCliente).nombre
            let precio = obj.precio
            return { nombre, precio }
        })

        let defaultTipoClienteNombre = this.state.tiposCliente.find( tipo => tipo.id === this.props.currentProduct.idTipoClienteDefault).nombre

        this.setState({ preciosTipoCliente, defaultTipoClienteNombre })
    }

    render() {

        let { id, nombre, tipo, clasificacion, descFamGT, conOSinMedioDeContraste, observaciones } = this.props.currentProduct

        return (
            <DashboardComponent>

                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
                        
                        <div id="showProfileSelect" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('') }>
                            <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                        </div>

                        <div className='mx-auto text-center' >
                            <h1>{ nombre }</h1>
                        </div>

                        <div className='mx-auto text-center mt-5 pt-5' >

                            <p><span className='font-weight-bold' >ID:</span> { id }</p>
                            <p><span className='font-weight-bold' >Tipo:</span> { tipo }</p>
                            <p><span className='font-weight-bold' >Clasificación:</span> { clasificacion }</p>
                            { descFamGT !== null ? <p><span className='font-weight-bold' >Descuento Familia GT:</span> { descFamGT * 100 }%</p> : null }
                            { ( typeof conOSinMedioDeContraste === 'string' && conOSinMedioDeContraste.length > 0 ) ? <p><span className='font-weight-bold' >Con o sin Medio de Contraste:</span> { conOSinMedioDeContraste }</p> : null }
                            { ( typeof observaciones === 'string' && observaciones.length > 0 ) ? 
                                <div className='py-4' >
                                    <p><span className='font-weight-bold' >Observaciones:</span></p>
                                    <p className='col-8 mx-auto' >{observaciones}</p> 
                                </div> : null 
                            }
                            <p><span className='font-weight-bold' >Tipo de Cliente Predeterminado:</span> { this.state.defaultTipoClienteNombre }</p>

                            <div className='py-5 col-10 col-md-8 col-lg-6 mx-auto' >
                                {/* <p><span className='font-weight-bold' >Precios Según Tipo de Cliente:</span></p> */}
                                <table className='table text-center table-bordered table-hover table-sm' >
                                    <thead>
                                        <tr>
                                            <th>Tipo de Cliente</th>
                                            <th>Precio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.preciosTipoCliente.map( (tipo, i) => 
                                            <tr key={i} >
                                                <td>{tipo.nombre}</td>
                                                <td>Q.{ tipo.precio.toFixed(2) }</td>
                                            </tr>  
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <button id='botonTiposClientes' onClick={() => this.props.handleDisplay('ChangePreciosProducto', this.props.currentProduct )} className="btn btn-info mt-5" type="button">Modificar Precios</button>

                        </div>
                        
                </div>

            </DashboardComponent>
        )
    }
}

export default ProductView
