let consulta = (clientType) => {

  let { id, nombre } = clientType

    let string = `
      mutation {
        productosMutation {
          editTipoCliente (
            id: "${id}"
            nombre: "${nombre}"
          ) {
            tipoCliente {
              id
              nombre
            }
            mensaje
          }
        }
      }
    `
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;