import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
	const message = 'Ha ocurrido un problema. Por favor, vuelve a intentarlo más tarde.'
	This.setState({ loaderClassName: 'd-none', submitButtonClassName: 'btn btn-success mt-5', fetchResponseClassName: 'text-danger', fetchCreateMessage: message })

	setTimeout(() => {
		This.setState({ fetchResponseClassName: 'd-none' })
	}, 3000);
}

const createAnalysis = (This, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	This.setState({ loaderClassName: 'd-block my-5', submitButtonClassName: 'd-none', showQR: false  })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		const data = res.data.resultadosAnalaisisMutation.insertResultadoAnalisis
		const analysisId = data.id

		const message = 'Se ha creado exitosamente el análisis.'

        This.setState({ 
			loaderClassName: 'd-none', 
			submitButtonClassName: 'btn btn-success my-5', 
			fetchResponseClassName: 'text-success font-weight-bold mb-5', 
			fetchCreateMessage: message, 
			analysisURL: `https://cdmsalud.com/analisis/${analysisId}`, 
			showQR: true 
		})
		document.getElementById('createAnalysisForm').reset()

		setTimeout(() => {
			This.setState({ fetchResponseClassName: 'd-none' })
		}, 3000);

		// return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default createAnalysis