import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

const ProductFilter = (props) => {

    
    return (
        <div id='filtroDoctores' className='col-12 mx-auto border shadow py-4 text-center rounded' style={{ marginTop: '100px' }} >

            <div className='navbar navbar-expand-lg col-lg-12 mx-auto'>

                <div className='row d-lg-flex align-items-lg-center mx-auto mx-lg-0 mr-auto' >

                    <div id="icono-filtro" className="col-12 col-lg-6 mx-0 px-0">
                        <FontAwesomeIcon icon={"user"} style={{color: '#5DACD4', fontSize: '40px'}} />
                    </div>

                    <div className="col-12 col-lg-6 mx-0 px-0">
                        {/* <label className='d-none d-xl-block mb-0' style={{minWidth: '100px'}}>Filtro de Búsqueda</label> */}
                        <div id='toggler' className='mx-auto mx-lg-0'>
                            <button className="navbar-toggler x collapsed border-dark btn btn-outline-secondary mt-3 px-4 py-2" type="button" data-toggle="collapse" data-target="#filterToggle" aria-controls="filterToggle" aria-expanded="false" aria-label="Toggle navigation">Filtro de Búsqueda</button>
                        </div>
                    </div>

                </div>
        
                <div id="filterToggle" className="collapse navbar-collapse justify-content-center justify-content-md-end" >

                    <form >
                        <ul className="navbar-nav d-flex flex-wrap">
                            <li className="nav-item mx-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right d-lg-none">
                                <FontAwesomeIcon icon={faArrowCircleDown} style={{color: '#5DACD4', fontSize: '25px'}} className='mt-3' />
                            </li>
                            <li className="nav-item ml-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right">
                                <input id="id" type='text' className='py-1 px-2 btn border border-dark' style={{ width: '185px' }} placeholder='ID' onChange={ (event) => props.setFilter('set', event) } />
                            </li>
                            <li className="nav-item ml-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right">
                                <input id="nombre" type='text' className='py-1 px-2 btn border border-dark' style={{ width: '185px' }} placeholder='Tipo' onChange={ (event) => props.setFilter('set', event) } />
                            </li>
                        </ul>
                    </form>
                    
                </div>

            </div>

        </div>
    )

}

export default ProductFilter