import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import '../css/Dashboard.css';

import SignaturePad from 'react-signature-canvas';

import Loader from '../../../Components/Loader.jsx';
import DashboardComponent from '../../Components/DashboardComponent.jsx';

import FetchAffiliateUser from './FetchAfiliateUser/FetchGraphQLAffiliateUser';

import Modal from 'react-bootstrap4-modal';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

class Dashboard extends Component {

  constructor(props){

    super(props)

    this.state = {
      invitationModal: false,
      affiliateModal: true,
      finalitationModal: false,
      autoRenew: false,
      agreement: false,
      frontDPI: '',
      backDPI: '',
      firma_afiliado: '',
      hideAlertMessage: false,
      loading: false
    }

    this.sigCanvas = React.createRef();
    this.onBoxChange = this.onBoxChange.bind(this);
    this.clearSign = this.clearSign.bind(this);
    this.onSignatureEndStroke = this.onSignatureEndStroke.bind(this);

  }

  componentDidMount(){

    if (this.props.conexion === false) {
      return <Redirect to='/login' />
    }

    if ( (this.props.usuario.suscription === null || this.props.usuario.FK_Afiliado === null) && (this.props.usuario.tipo !== 'colaborador') ) {
      this.setState({ invitationModal: true, affiliateModal: false })
    }else{
      this.setState({ invitationModal: false, affiliateModal: false })
    }

  }
  
  sessionServiceCallback = () => {
    
    if(this.props.conexion === true){
      return <Loader />
    }else{
      return <Redirect to='/login' />
    }
    
  }

  affiliateForm = (event) => {

    event.preventDefault()

    if(this.sigCanvas.current.isEmpty() === false){

      let date = new Date()

      let data = {
        idUser: this.props.usuario.id,
        firma_afiliado: this.state.firma_afiliado,
        fecha_inicio: moment(new Date()).format('YYYY-MM-DD'),
        fecha_expiracion: moment(date.setDate(date.getDate() + 365)).format('YYYY-MM-DD'),
        deacuerdo_con_terminos: this.state.agreement,
        auto_renovacion: this.state.autoRenew,
        DPI_frontal: this.state.frontDPI,
        DPI_posterior: this.state.backDPI
      }

      //console.log(data)
      FetchAffiliateUser(this, data)

      this.setState({
        loading: true
      })

    }else{

      this.setState({
        hideAlertMessage: true
      })

      /* istanbul ignore next */
      setTimeout( () => {
        this.setState({ hideAlertMessage: false })
      }, 3000)

    }


  }

  imageToBase64 = (event) => {
    const id = event.target.id
    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0])

    reader.onload = () => {
      this.setState({ [id]: reader.result })
    }
  }

  onBoxChange = (event) => {

    let state

    if ( event.target.id === 'agreement' ) {

      state = this.state.agreement
      this.setState({ 
        [event.target.id]: !state
      })

    } else if ( event.target.id === 'autoRenew' ) {

      state = this.state.autoRenew
      this.setState({ 
        [event.target.id]: !state
      })
      
    }

  }

  clearSign = () => {

    this.sigCanvas.current.clear();

    this.setState({
      firma_afiliado: ""
    })

  }

  onSignatureEndStroke(){

    let firma_afiliado = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")

    this.setState({
      firma_afiliado
    })

  }

  render(){

    const usuario = this.props.usuario

    if (this.props.conexion === false) {

      return <Redirect to='/login' />

    }else{
      return(
        <div className="container-fluid" style={{marginLeft: '0px', marginRight: '0px', paddingLeft: '0px', paddingRight: '0px'}}>

          <DashboardComponent>
            <div id='componentContainer' className="col-12 text-center" style={{minHeight: '75vh'}}>

              <h1 className="py-5 mb-5">Bienvenid{usuario.sexo === 'Masculino' ? 'o' : 'a'} {usuario.nombre}</h1>

              <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: '510px' }} >

                { (this.props.usuario.suscription === null || this.props.usuario.FK_Afiliado === null) ? <button id='affiliateButton' type="button" onClick={() => this.setState({ affiliateModal: true })} className="btn btn-info my-5" style={{ width: '200px' }}>Afiliate</button> : null }

                { (this.props.usuario.tipo === 'cliente') ? <Link to='/dashboard/medicos' ><button id='medicosButton' type="button" className="btn btn-info my-5" style={{ width: '200px' }} >Busca un Médico</button></Link> : null }

              </div>


              <Modal id='invitationModal' visible={this.state.invitationModal} fade onClickBackdrop={ () => this.setState({ invitationModal: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >
                <div className="modal-body text-center py-4">
                  <button id='invitationClose' type="button" className="close" onClick={ () => this.setState({ invitationModal: false }) }>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className='mx-auto col-10 mb-4' >
                    <p>Te invitamos a afiliarte para que obtengas los mejores beneficios de nuestra plataforma.</p>
                    <p>¡Hazlo Ahora!</p>
                  </div>
                  <button id='affiliate' onClick={ () => this.setState({ invitationModal: false, affiliateModal: true }) } type="button" className="btn btn-success mx-auto">Afiliate</button>
                </div>
  
              </Modal>
              
              <Modal id='affiliateModal' visible={this.state.affiliateModal} fade onClickBackdrop={ () => this.setState({ affiliateModal: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >
                <div className="modal-body text-center py-4">
                  <button id='affiliateClose' type="button" className="close" onClick={ () => this.setState({ affiliateModal: false }) }>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className='mx-auto col-12 col-md-10 mb-4' >
                    <h3 className='my-5 pb-4' >Formulario de Afiliación</h3>
                    <form id='affiliateForm' onSubmit={this.affiliateForm} className='mx-auto text-center mt-5' >

                          <div className="form-group custom-file mb-3">
                            <input onChange={ this.imageToBase64 } type="file" className="custom-file-input" id="frontDPI" required />
                            <label className="custom-file-label" htmlFor="frontDPI">Fotografía DPI Frontal</label>
                          </div>   
                          
                          <div className="form-group custom-file mb-3">
                            <input onChange={ this.imageToBase64 } type="file" className="custom-file-input" id="backDPI" required />
                            <label className="custom-file-label" htmlFor="backDPI">Fotografía DPI Posterior</label>
                          </div>

                          <div className="form-group">
                            <label htmlFor='autoRenew'>Activar Auto Renovación</label><br/>
                            <Toggle
                              id='autoRenew' 
                              defaultChecked={this.state.autoRenew}
                              onChange={this.onBoxChange}   
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor='agreement'>Estoy de Acuerdo</label><br/>
                            <Toggle
                              id='agreement'
                              defaultChecked={this.state.agreement}
                              onChange={this.onBoxChange}
                              required   
                            />
                          </div>

                          <div className="form-group">
                              <SignaturePad 
                                ref={this.sigCanvas}
                                canvasProps={{className: "signatureCanvas"}}
                                onEnd={this.onSignatureEndStroke} 
                              />
                              <p className="text-center">f)__________________________</p>
                              <button id="clearSignButton" type="button" className="btn btn-info" onClick={this.clearSign}>Limpiar Firma</button>
                              <div id="alert-signature" className="alert alert-danger mt-2" role="alert" style={{display: this.state.hideAlertMessage ? 'block' : 'none'}}>
                                  Hace falta la firma. Por favor firma en el recuadro.
                              </div>
                          </div>

                          {
                            this.state.loading === true ? (
                              <div className="col-12">
                                <Loader />                            
                              </div>
                            ) : null
                          }
                          
                          <button type="submit" className="btn btn-success mt-5">Completar Afiliación</button><br/>
                          <input id='affiliateCloseButton' type='button' onClick={ () => this.setState({ affiliateModal: false }) } className="btn btn-secondary mt-4" value='Cancelar' />

                          <div className={this.state.fetchEditResponse} role="alert">
                              {this.state.fetchEditMessage}
                          </div>

                      </form>
                  </div>
                </div>
  
              </Modal>

              <Modal id='finalitationModal' visible={this.state.finalitationModal} fade onClickBackdrop={ () => this.setState({ finalitationModal: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >

                <div className="modal-body text-center py-4">
                  <button id='finalitationClose' type="button" className="close" onClick={ () => this.setState({ finalitationModal: false }) }>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className='mx-auto col-10 mb-4' >
                    <p className="alert alert-success" role="alert">Te has afiliado satisfactoriamente</p>
                  </div>
                  <button id='finalitationModalButton' onClick={ () => this.setState({ finalitationModal: false }) } type="button" className="btn btn-success mx-auto">Cerrar</button>
                </div>
  
              </Modal>

            </div>
          </DashboardComponent>

        </div>
      )
    }
  }

}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(Dashboard)