let consulta = (analysisId) => {

    const string =`
      query {
        appInfo {
          resultadoAnalisis (
            idResultado: "${analysisId}"
          ) {
            id
            nombre
            apellidos
            correo
            telefono
            nombreAnalisis
            documentoAnalisis
          }
        }
      }
    `
  
      return {
        "query": string
      }
  
  }
  
  export default consulta