import React, { Component } from 'react'

import DashboardComponent from '../../Components/DashboardComponent'
import EmpresaFilter from './EmpresaFilter'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class EmpresasTable extends Component {

  state = {
    nombre: '',
    NIT: '',
  }

  setFilter = (type, event) => {
      if ( type === 'set' ) {
          this.setState({ [event.target.id]: event.target.value })
      } else {
          this.setState({ nombre: '', NIT: '' })
      }
  }

  render() {

    const companyFilter = this.props.empresas.filter( empresa =>
      empresa.nombre.toLowerCase().indexOf(this.state.nombre.toLowerCase()) !== -1 &&
      empresa.NIT.indexOf(this.state.NIT) !== -1
    )

    return (
      <DashboardComponent>
      <div className="container" style={{marginTop: '50px', minHeight: '75vh'}}>

        <div className='mb-5' >
            <EmpresaFilter setFilter={this.setFilter} usuarios={this.props.usuarios} />
        </div>

        <div className="table-responsive"> 
          {
            this.props.usuario.permisos.AdminUser.CreateUser === true ? (
              <div className="justify-content-end">
                  <button id="createButton"
                    onClick={() => {
                      let display = 'EmpresaCreate';
                      this.props.handleDisplay(display)
                    }}
                    type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color' : '#5DACD4'}}>
                    Nueva Empresa <FontAwesomeIcon icon="building" /> +
                  </button>
              </div>
            ) : null
          }
          {
            ((this.props.usuario.permisos.AdminUser.ViewUser === true) || (this.props.usuario.permisos.AdminUser.EditUser === true) || (this.props.usuario.permisos.AdminUser.DeleteUser === true)) ? (
                <table className="table text-center table-bordered table-hover table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" className="d-none d-md-table-cell" >Logotipo</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">NIT</th>
                        <th scope="col" className="d-none d-md-table-cell" >Dirección</th>
                        <th scope="col">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        //props.usuarios.map( usuario => (
                        companyFilter.map( empresa => (
                            <tr key={empresa.id}>
                            {/*<th scope="row">{usuario.nombre}</th>*/}
                            <td className="d-none d-md-table-cell" ><img src={empresa.logo} alt='logo' style={{ maxHeight:'75px' }} /></td>
                            <td className='align-middle text-center' >{empresa.nombre}</td>
                            <td className="align-middle text-center" >{empresa.NIT}</td>
                            <td className="d-none d-md-table-cell align-middle text-center" >{empresa.direccion}</td>
                            <td className='align-middle text-center' >
                                {
                                this.props.usuario.permisos.AdminUser.ViewUser === true ? (
                                    <button id="viewButton" type="button" onClick={() => {
                                        let display = 'EmpresaView';
                                        this.props.handleDisplay(display, empresa)
                                    }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}>
                                    <FontAwesomeIcon icon="eye" title='Visualizar Empresa' />
                                    </button>
                                ) : null
                                }

                                {
                                this.props.usuario.permisos.AdminUser.EditUser === true ? (
                                    <button id="editButton" type="button" onClick={() => {
                                        let display = 'EmpresaEdit';
                                        this.props.handleDisplay(display, empresa)
                                    }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}>
                                    <FontAwesomeIcon icon="edit" title='Editar Empresa' />
                                    </button>
                                ) : null
                                }

                                {
                                this.props.usuario.permisos.AdminUser.DeleteUser === true ? (
                                    <button id="deleteButton" 
                                      onClick={ () => { this.props.handleDeleteCompany(empresa) }} 
                                      type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}
                                    >
                                    <FontAwesomeIcon icon="trash-alt" title='Eliminar Empresa' />
                                    </button>
                                    
                                ) : null
                                }

                                {
                                    empresa.afiliacion !== null ? null : (
                                        <button id="empresaAffiliate" 
                                            onClick={ () => { let display = 'EmpresaAffiliate'; this.props.handleDisplay(display, empresa) }} 
                                            type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}
                                        >
                                            <FontAwesomeIcon icon="clipboard-check" title='Afiliar Empresa' />
                                        </button>
                                        
                                    )
                                }

                                {
                                    empresa.afiliacion === null ? null : (
                                        <button id="manageAffiliates" 
                                            onClick={ () => { let display = 'ManageAffiliates'; this.props.handleDisplay(display, empresa) }} 
                                            type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#5DACD4'}}
                                        >
                                            <FontAwesomeIcon icon="list-ul" title='Gestionar Afiliados' />
                                        </button>
                                        
                                    )
                                }

                            </td>
                            </tr>
                        )
                        
                        )
                    }
                    </tbody>
                </table>
            ) : null
          }
        </div>
        
      </div>
    </DashboardComponent>
    )
  }
}


export default EmpresasTable