import React, { Component } from 'react';

import { Redirect } from 'react-router';

import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

import url from '../../../../apiService/ApiUrl';

import sessionService from '../../../../sessionService/sessionService';

import Loader from '../../../Components/Loader.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TopNavbar extends Component{

  constructor(props) {
    super(props);

    this.OnLogOut = this.OnLogOut.bind(this);

  }

  /* istanbul ignore next */
  OnLogOut() {

    let sessionServiceCallback = () => {
      
      /* istanbul ignore next */
      if(this.props.conexion === true){
        /* istanbul ignore next */
        return <Loader />
      }else{
        /* istanbul ignore next */
        return <Redirect to='/login' />
      }
      
    }

    /* istanbul ignore next */
    sessionService.logOut(url, this, sessionServiceCallback)

    /*sessionService.logOut(url, this)
    .then( () => {
       return <Redirect to='/login' />
    })*/

    /*this.props.dispatch({
      type: 'LOGIN',
      payload: {
        logIn: sessionService.deleteSession()//conexionSessionJson
      }
    })*/

  }

  render(){
    return(
      <ul className="navbar-nav ml-auto">
        {/*<li className="nav-item dropdown text-center text-md-right">
          <a className="nav-link dropdown-toggle mr-lg-2" id="messagesDropdown" href="#link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-fw fa-envelope"></i>
            <span className="d-md-none">Messages
              <span className="badge badge-pill badge-primary">12 New</span>
            </span>
            <span className="indicator text-primary d-none d-md-block">
              <i className="fa fa-fw fa-circle"></i>
            </span>
          </a>
          <div className="dropdown-menu" aria-labelledby="messagesDropdown">
            <h6 className="dropdown-header">New Messages:</h6>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#link">
              <strong>David Miller</strong>
              <span className="small float-right text-muted">11:21 AM</span>
              <div className="dropdown-message small">Hey there! This new version of SB Admin is pretty awesome! These messages clip off when they reach the end of the box so they don't overflow over to the sides!</div>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#link">
              <strong>Jane Smith</strong>
              <span className="small float-right text-muted">11:21 AM</span>
              <div className="dropdown-message small">I was wondering if you could meet for an appointment at 3:00 instead of 4:00. Thanks!</div>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#link">
              <strong>John Doe</strong>
              <span className="small float-right text-muted">11:21 AM</span>
              <div className="dropdown-message small">I've sent the final files over to you for review. When you're able to sign off of them let me know and we can discuss distribution.</div>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item small" href="#link">View all messages</a>
          </div>
        </li>
        <li className="nav-item dropdown text-center text-md-right">
          <a className="nav-link dropdown-toggle mr-lg-2" id="alertsDropdown" href="#link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-fw fa-bell"></i>
            <span className="d-md-none">Alerts
              <span className="badge badge-pill badge-warning">6 New</span>
            </span>
            <span className="indicator text-warning d-none d-md-block">
              <i className="fa fa-fw fa-circle"></i>
            </span>
          </a>
          <div className="dropdown-menu" aria-labelledby="alertsDropdown">
            <h6 className="dropdown-header">New Alerts:</h6>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#link">
              <span className="text-success">
                <strong>
                  <i className="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
              </span>
              <span className="small float-right text-muted">11:21 AM</span>
              <div className="dropdown-message small">This is an automated server response message. All systems are online.</div>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#link">
              <span className="text-danger">
                <strong>
                  <i className="fa fa-long-arrow-down fa-fw"></i>Status Update</strong>
              </span>
              <span className="small float-right text-muted">11:21 AM</span>
              <div className="dropdown-message small">This is an automated server response message. All systems are online.</div>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#link">
              <span className="text-success">
                <strong>
                  <i className="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
              </span>
              <span className="small float-right text-muted">11:21 AM</span>
              <div className="dropdown-message small">This is an automated server response message. All systems are online.</div>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item small" href="#link">View all alerts</a>
          </div>
        </li>*/}
        {/*<li className="nav-item text-center text-md-right">
          <form className="form-inline my-2 my-lg-0 mr-lg-2">
            <div className="input-group">
              <input className="form-control" type="text" placeholder="Search for..." />
              <span className="input-group-append">
                <button className="btn btn-primary" type="button">
                  <i className="fa fa-search"></i>
                </button>
              </span>
            </div>
          </form>
        </li>*/}
        <li id="logOut" className="nav-item text-center text-md-right">
          <NavLink onClick={this.OnLogOut} exact to="/login" activeClassName="active" className="nav-link"><span className="nav-link-text"><FontAwesomeIcon icon={"sign-out-alt"} style={{color: 'rgba(0,0,0,.5)'}}/> Logout</span></NavLink>
        </li>
      </ul>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(TopNavbar)