let fetchCompanies = payload => ({
    type: 'FETCH_COMPANIES',
    payload
})

let createCompany = payload => ({
    type: 'CREATE_COMPANY',
    payload
})

let editCompany = payload => ({
    type: 'EDIT_COMPANY',
    payload
})

let deleteCompany = payload => ({
    type: 'DELETE_COMPANY',
    payload
})

let insertAfiliacion = payload => ({
    type: 'INSERT_AFILIACION',
    payload
})

let editAfiliacion = payload => ({
    type: 'EDIT_AFILIACION',
    payload
})

module.exports = { fetchCompanies, createCompany, editCompany, deleteCompany, insertAfiliacion, editAfiliacion }