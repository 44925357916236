import publicData from './publicData'
import usersData from './usersData'
import companiesData from './companiesData'
import productsData from './productsData'
import panelsData from './panelsData'
import clientTypesData from './clientTypesData'
import { combineReducers } from 'redux'

const appInfo = combineReducers({
  publicData,
  usersData,
  companiesData,
  productsData,
  panelsData,
  clientTypesData
})

export default appInfo