import React from 'react';
import './css/dashboardComponent.min.css';

import Navbar from '../Navbar/Navbar.jsx';

import Footer from '../../Components/Footer.jsx';


let DashboardComponent = props => (
  <div className="body fixed-nav sticky-footer container-fluid" id="page-top" style={{paddingLeft: '0px', paddingRight: '0px'}}>
    <Navbar />
    <div className="content-wrapper" style={{minHeight: '80vh'}}>
      {props.children}
    <Footer />
    </div>
  </div>
)

export default DashboardComponent