import React from 'react';

//Components
import AppLayout from './AppLayout.jsx';

//Import PaginaWeb
//Import Inicio
import Inicio from '../WebPage/Home/Components/Home.jsx';
import Examenes from '../WebPage/Examenes/Components/Examenes.jsx';
import Farmaceutica from '../WebPage/Farmaceutica/Farmaceutica';

//Import Dashboard
import LogIn from '../Dashboard/LogIn/Containers/LogIn.jsx';
import RegisterUser from '../Dashboard/Register/Containers/RegisterUser.tsx';
import RegisterMedico from '../Dashboard/Register/Containers/RegisterMedico';
import Dashboard from '../Dashboard/Dashboard/Containers/Dashboard.jsx';
import AdminPerfil from '../Dashboard/AdminPerfil/Containers/AdminPerfil.jsx';
import AdminUser from '../Dashboard/AdminUser/Containers/AdminUser.tsx';
import MedicList from '../Dashboard/MedicList/containers/MedicList.jsx';
import PublicMedicList from '../WebPage/MedicList/containers/MedicList.jsx';
import Empresas from '../Dashboard/Empresas/containers/Empresas.jsx';
import Productos from '../Dashboard/Beneficios/components/Productos.jsx';
import AnalysisCreate from '../Dashboard/Analysis/components/AnalysisCreate'
import AnalysisList from '../Dashboard/Analysis/components/AnalysisList'
import AnalysisReport from '../Dashboard/Analysis/components/AnalysisReport'
import AdminProductos from '../Dashboard/AdminProductos/Productos/Containers/AdminProductos.jsx';
import AdminPaneles from '../Dashboard/AdminProductos/Paneles/Containers/AdminPaneles.jsx';
import AdminTiposCliente from '../Dashboard/AdminProductos/TipoCliente/Containers/AdminTiposCliente.jsx';
import Cotizaciones from '../Dashboard/Ventas/Cotizaciones/Containers/Cotizaciones.jsx';
import OrdenesVenta from '../Dashboard/Ventas/OrdenesVenta/Containers/OrdenesVenta.jsx';

//Router
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom'; //Redirect: agregarlo después

// import examenes from '../WebPage/Examenes/Components/examenes.json';

const PrincipalRoutes = props => (
	<AppLayout>
		<Router>
            <Switch>

                {/*Route Inicio*/}
                <Route path="/" exact render={
                    ({ location }) => <Inicio path={location.pathname} />
                }/>
                {/*Route Examenes*/}
                <Route path="/examenes" exact component={ Examenes }/>

                {/*Route Farmaceutica*/}
                <Route path="/farmaceutica" exact component={ Farmaceutica }/>

                {/*Route LogIn*/}
                <Route path="/login" exact component={LogIn}/>

                {/*Route Register User*/}
                <Route path="/register/user" exact component={RegisterUser}/>
                
                {/* Route Register Medico */}
                <Route path="/register/medico" exact component={RegisterMedico}/>

                {/*Route Dashboard*/}
                <Route path="/dashboard" exact component={Dashboard}/>

                {/*Route Admin Perfil*/}
                <Route path="/dashboard/admin/perfil" exact component={AdminPerfil}/>

                {/*Route Admin User*/}
                <Route path="/dashboard/admin/usuarios" exact component={AdminUser}/>

                {/*Route Dashboard MedicList*/}
                <Route path="/dashboard/medicos" exact component={MedicList}/>

                {/*Route Public MedicList*/}
                <Route path="/medicos" exact component={PublicMedicList}/>

                {/*Route Dashboard Admin Empresas*/}
                <Route path="/dashboard/admin/empresas" exact component={Empresas}/>

                {/*Route Dashboard Beneficios Productos*/}
                <Route path="/dashboard/beneficios/productos" exact component={Productos}/>

                {/*Route Dashboard Analisis Crear*/}
                <Route path="/dashboard/analisis/crear" exact component={AnalysisCreate}/>
                
                {/*Route Dashboard Analisis Listado*/}
                <Route path="/dashboard/analisis/" exact component={AnalysisList}/>

                {/*Route Dashboard Analisis ID*/}
                <Route path="/analisis/:idAnalisis" exact render={ (match) => <AnalysisReport match={match}/> }/>

                {/*Route Dashboard Productos*/}
                <Route path="/dashboard/productos" exact component={AdminProductos} />
                                
                {/*Route Dashboard Paneles*/}
                <Route path="/dashboard/paneles" exact component={AdminPaneles} />
                
                {/*Route Dashboard Tipos de Cliente*/}
                <Route path="/dashboard/tipos-de-cliente" exact component={AdminTiposCliente} />
                
                {/*Route Dashboard Cotizaciones*/}
                <Route path="/dashboard/cotizaciones" exact component={Cotizaciones} />
                                
                {/*Route Dashboard Ordenes de Venta*/}
                <Route path="/dashboard/ordenes-de-venta" exact component={OrdenesVenta} />
            
            </Switch>
        </Router>
	</AppLayout>
)

export default PrincipalRoutes;