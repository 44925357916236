let consulta = (event, tipo) => {
    const string =`
      mutation {
        userMutation {
          insertEspecialidadMedico (
            nombre: "${event.value}"
            tipo: "${tipo}"
          ) {
            id
            nombre
            tipo
          }
        }
      }
    `
  
    return {
      "query": string
    }
  
  }
  
  export default consulta