const initialState = {
  users: []
}
  
  let users = (state = initialState, action) => {
    switch (action.type) {

      case 'FETCH_USERS':
        return {
          ...state,
          users: action.payload.usuarios
        }
        case 'CREATE_USER': 
        let newUser = [ action.payload.user ]
        let stateUsers = [...state.users]
        let newUsersArr = stateUsers.concat(newUser)
        return {
          ...state,
          users: newUsersArr
        }
      case 'EDIT_USER': 
        let userIndex = state.users.findIndex(user => user.id === action.payload.user.id)
        let editedArray = [ ...state.users ]
        editedArray[userIndex] = action.payload.user
        return {
          ...state,
          users: editedArray
        }  
      case 'DELETE_USER': 
        let filteredArray = state.users.filter((user) => user.id !== action.payload.userId)
        return {
          ...state,
          users: filteredArray
        }
      case 'UPDATE_MEDICO_STATE':
        let medico = state.users.filter( user => (user.tipo === 'medico' && user.medico.id === action.payload.medicoId) )
        let medicoIndex = state.users.findIndex( user => user.id === medico[0].id )
        let newStateMedicoArray = [ ...state.users ]
        newStateMedicoArray[medicoIndex] = { ...newStateMedicoArray[medicoIndex], medico: { ...newStateMedicoArray[medicoIndex].medico, estado: action.payload.newState } }
        return {
          ...state,
          users: newStateMedicoArray
        }
      case 'EDIT_AFILIACION_TO_EMPRESA':
        let newArray = [ ...state.users ]
        let currentCompanyId = action.payload.currentCompanyId
        let newAfiliados = action.payload.newAfiliados
        let initialAfiliados = action.payload.initialAfiliados
        let addedAfiliados = newAfiliados.filter( afiliado => !initialAfiliados.includes(afiliado) )
        let removedAfiliados = initialAfiliados.filter( afiliado => !newAfiliados.includes(afiliado) )
        let addedUsers = state.users.filter( user => addedAfiliados.includes(user.FK_Afiliado) )
        let removedUsers = state.users.filter( user => removedAfiliados.includes(user.FK_Afiliado) )
        let addedUsersIndex = addedUsers.map( addedUser => state.users.findIndex( user => user.id === addedUser.id ) )
        let removedUsersIndex = removedUsers.map( removedUser => state.users.findIndex( user => user.id === removedUser.id ) )
        addedUsersIndex.map( addedUserIndex => newArray[addedUserIndex] = { ...newArray[addedUserIndex], afiliacion: { ...newArray[addedUserIndex].afiliacion, FK_Empresa: currentCompanyId } } )
        removedUsersIndex.map( removedUserIndex => newArray[removedUserIndex] = { ...newArray[removedUserIndex], afiliacion: { ...newArray[removedUserIndex].afiliacion, FK_Empresa: null } } )
        return {
            ...state,
            users: newArray
        }
      case 'EDIT_CONSULTA':
        let medicoConsulta = state.users.filter( user => (user.tipo === 'medico' && user.medico.id === action.payload.data.medicoId) )
        let medicoIndexConsulta = state.users.findIndex( user => user.id === medicoConsulta[0].id )
        let medicoConsultasArray = [ ...state.users ]
        medicoConsultasArray[medicoIndexConsulta] = { ...medicoConsultasArray[medicoIndexConsulta], medico: { ...medicoConsultasArray[medicoIndexConsulta].medico, data_consultas: action.payload.data.data_consultas } }
        return {
          ...state,
          users: medicoConsultasArray
        }
      case 'EDIT_PROCEDIMIENTO':
        let medicoProcedimiento = state.users.filter( user => (user.tipo === 'medico' && user.medico.id === action.payload.data.medicoId) )
        let medicoIndexProcedimiento = state.users.findIndex( user => user.id === medicoProcedimiento[0].id )
        let medicoProcedimientosArray = [ ...state.users ]
        medicoProcedimientosArray[medicoIndexProcedimiento] = { ...medicoProcedimientosArray[medicoIndexProcedimiento], medico: { ...medicoProcedimientosArray[medicoIndexProcedimiento].medico, data_procedimientos: action.payload.data.data_procedimientos } }
        return {
          ...state,
          users: medicoProcedimientosArray
        }
        //break;
      default:
        return state
    }
  }
  
  export default users