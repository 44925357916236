import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import fetchRegisterUser from './RegisterUserFetch/FetchRegisterUser.js';
import fetchCountries from '../../../Dashboard/Containers/FetchCountries/fetchCountries'

import './css/RegisterUser.css';

type Country = {
    name: string
}

type FormData = {
    nombre: string;
    apellidos: string;
    usuario: string;
    email: string;
    fechaDeNacimiento: string;
    direccion: string;
    nacionalidad: string;
    sexo: string;
    telefono: string;
    DPI: string;
    password: string;
    passwordValidator: string;
}

interface IProps {
    conexion: boolean;
}

interface IState {
    countries: Country[];
    formData: FormData;
}

class RegisterUser extends Component<IProps, IState> {

    constructor(props: IProps){
        super(props)

        this.state = {
            countries: [],
            formData: {
                nombre: '',
                apellidos: '',
                usuario: '',
                email: '',
                fechaDeNacimiento: '',
                direccion: '',
                nacionalidad: 'Guatemala',
                sexo: 'Masculino',
                telefono: '',
                DPI: '',
                password: '',
                passwordValidator: ''
            }
        }

        this.OnInputChange = this.OnInputChange.bind(this);
        this.OnRegisterUser = this.OnRegisterUser.bind(this);

    }

    async componentDidMount() {

        if(this.props.conexion === true){
                return <Redirect to='/dashboard' />
        }

        let countries = await fetchCountries()

        this.setState({ 
            countries
         })
  
    }

    OnInputChange(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>){

        /* istanbul ignore else */
        /* istanbul ignore next */ 
        if(event.target.id === 'nombre'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    /* istanbul ignore next */ 
                    ...this.state.formData,
                    nombre: event.target.value
                }
            })
        }else if(event.target.id === 'apellidos'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    /* istanbul ignore next */ 
                    ...this.state.formData,
                    apellidos: event.target.value
                }
            })
        }else if(event.target.id === 'usuario'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    /* istanbul ignore next */ 
                    ...this.state.formData,
                    usuario: event.target.value
                }
            }) 
        }else if(event.target.id === 'email'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    /* istanbul ignore next */ 
                    ...this.state.formData,
                    email: event.target.value
                }
            }) 
        }else if(event.target.id === 'fechaDeNacimiento'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    /* istanbul ignore next */ 
                    ...this.state.formData,
                    fechaDeNacimiento: event.target.value
                }
            })
        }else if(event.target.id === 'direccion'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    /* istanbul ignore next */ 
                    ...this.state.formData,
                    direccion: event.target.value
                }
            })
        }else if(event.target.id === 'nacionalidad'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    /* istanbul ignore next */ 
                    ...this.state.formData,
                    nacionalidad: event.target.value
                }
            })
        }else if(event.target.id === 'sexo'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    /* istanbul ignore next */ 
                    ...this.state.formData,
                    sexo: event.target.value
                }
            })
        }else if(event.target.id === 'telefono'){
            /* istanbul ignore next */ 
            const re = /^[0-9\b]+$/;
            /* istanbul ignore next */ 
            if (event.target.value === '' || re.test(event.target.value)) {
                /* istanbul ignore next */ 
                this.setState({
                    formData: {
                        ...this.state.formData,
                        telefono: event.target.value
                    }
                })
            }
        }else if(event.target.id === 'DPI'){
            /* istanbul ignore next */ 
            const re = /^[0-9\b]+$/;
            /* istanbul ignore next */ 
            if (event.target.value === '' || re.test(event.target.value)) {
                /* istanbul ignore next */ 
                this.setState({
                    formData: {
                        ...this.state.formData,
                        DPI: event.target.value
                    }
                })
            }
        }else if(event.target.id === 'password'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    ...this.state.formData,
                    password: event.target.value
                }
            })
        }else if(event.target.id === 'repiteTuPassword'){
            /* istanbul ignore next */ 
            this.setState({
                formData: {
                    ...this.state.formData,
                    passwordValidator: event.target.value
                }
            })
        }
    }

    OnRegisterUser(event: React.FormEvent){
  
        event.preventDefault();

        if((this.state.formData.password !== '') && (this.state.formData.password === this.state.formData.passwordValidator)){

            let newUser = {
                nombre: this.state.formData.nombre,
                apellidos: this.state.formData.apellidos,
                usuario: this.state.formData.usuario,
                correo: this.state.formData.email,
                fecha_de_nacimiento: this.state.formData.fechaDeNacimiento,
                direccion: this.state.formData.direccion,
                nacionalidad: this.state.formData.nacionalidad,
                sexo: this.state.formData.sexo,
                telefono: this.state.formData.telefono,
                DPI: this.state.formData.DPI,
                tipo: 'cliente',
                password: this.state.formData.password
            }

            fetchRegisterUser(this, newUser)

        }
  
  
    }

    render(){
        if (this.props.conexion === true) {
            return <Redirect to='/dashboard' />
        }else{
            return(
                <div id="RegisterUserContainer">
                    <nav id="navLogIn" className="navbar navbar-expand fixed-top">
    
                        <NavLink exact to="/#home" activeClassName="active" className="nav-link">
                            <img src="/img/Logo.png" style={{height: '2.5em', paddingLeft: '25px'}} alt="logoimg"/>
                        </NavLink>
    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
                            <ul className="navbar-nav">
                            <li className="nav-item active">
                                <NavLink exact to="/" activeClassName="active" className="nav-link" >Inicio</NavLink>
                            </li>
                            </ul>
                        </div>

                    </nav>
                    <div className="d-flex align-items-center">
                        <div className="container"  style={{marginTop: '50px'}}>
                            <div className="row">
                                <div className="col-12 mx-auto">
                                    <div className="card card-register-user mx-auto mt-5">
                                        <div className="card-header text-center">
                                            <p>Registro de Usuario</p>
                                        </div>
                                        <div className="card-body">
                                            <form className="row" onSubmit={this.OnRegisterUser}>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input className="form-control" id="nombre" type="text" aria-describedby="nombreHelp" placeholder="Escribe tu Nombre" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.nombre}/>
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="apellidos">Apellidos</label>
                                                    <input className="form-control" id="apellidos" type="text" aria-describedby="apellidosHelp" placeholder="Escribe tus Apellidos" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.apellidos}/>
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="usuario">Usuario</label>
                                                    <input className="form-control" id="usuario" type="text" aria-describedby="usuarioHelp" placeholder="Escribe tu Usuario" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.usuario}/>
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="email">Correo Electrónico</label>
                                                    <input className="form-control" id="email" type="email" aria-describedby="emailHelp" placeholder="Escribe tu correo Electrónico" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.email} />
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="fechaDeNacimiento">Fecha de Nacimiento</label>
                                                    <input className="form-control" id="fechaDeNacimiento" type="date" aria-describedby="fechaDeNacimientoHelp" placeholder="Escribe tu Fecha de nacimiento" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.fechaDeNacimiento}/>
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="direccion">Dirección</label>
                                                    <input className="form-control" id="direccion" type="text" aria-describedby="direccionHelp" placeholder="Escribe tu Dirección" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.direccion} />
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="nacionalidad">Nacionalidad</label>
                                                    {
                                                            this.state.countries.length > 0 ? (
                                                                <select className="form-control" id="nacionalidad" aria-describedby="nacionalidadHelp" placeholder="Escoje tu Nacionalidad" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.nacionalidad}>
                                                                    {
                                                                        this.state.countries.map( (country, i) => <option value={country.name} key={i}>{country.name}</option>)
                                                                    }
                                                                </select>
                                                            ) : (
                                                                <select className="form-control" id="nacionalidad" aria-describedby="nacionalidadHelp" placeholder="Escoje tu Nacionalidad" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.nacionalidad}>
                                                                    <option value="Guatemala">Guatemala</option>
                                                                </select>
                                                            )
                                                    }
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="sexo">Sexo</label>    
                                                    <select className="form-control" id="sexo" aria-describedby="sexoHelp" placeholder="Escoje tu Sexo" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.sexo}>
                                                        <option>Masculino</option>
                                                        <option>Femenino</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="telefono">Teléfono</label>
                                                    <input className="form-control" id="telefono" type="text" aria-describedby="telefonoHelp" placeholder="Escribe tu Teléfono" onChange={ (e)=> this.OnInputChange(e) } pattern="[0-9]*" value={this.state.formData.telefono}/>
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="DPI">DPI</label>
                                                    <input className="form-control" id="DPI" type="text" aria-describedby="DPIHelp" placeholder="Escribe tu DPI" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.DPI} />
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="password">Contraseña</label>
                                                    <input className="form-control" id="password" type="password" placeholder="Escribe tu contraseña" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.password}/>
                                                </div>
                                                <div className="form-group col-12 col-md-6 mx-auto">
                                                    <label htmlFor="repiteTuPassword"> Repite tu Contraseña</label>
                                                    <input className="form-control" id="repiteTuPassword" type="password" placeholder="Repite tu contraseña" onChange={ (e)=> this.OnInputChange(e) } value={this.state.formData.passwordValidator}/>
                                                </div>
                                                <div className="form-group col-12">
                                                    <div className="row">
                                                        <button id="RegisterUserButton" className="btn btn-outline-info mx-auto" type="submit">Regístrate</button>
                                                    </div>
                                                </div> 
                                                {
                                                    (this.state.formData.password !== '') && (this.state.formData.password !== this.state.formData.passwordValidator) ? <div id="incorrect-data" style={{'marginTop': '15px', 'transition': 'all 0.5s ease-in-out'}} className="alert alert-danger mx-auto" role="alert">Las contraseñas no coinciden.</div> : null
                                                }
                                                {/*<div id="incorrect-data" style={{'marginTop': '15px', 'display': 'none', 'transition': 'all 0.5s ease-in-out'}} className="alert alert-danger" role="alert">Ingresaste datos incorrectos, por favor prueba de Nuevo</div>*/}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

let mapStateToProps = (state: any, props: any) => {
    return {
      conexion: state.logIn.conexion
    }
}
  
export default connect(mapStateToProps)(RegisterUser)