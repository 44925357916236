let setPanels = payload => ({
    type: 'FETCH_PANELS',
    payload
})

let createPanel = payload => ({
    type: 'CREATE_PANEL',
    payload
})

let editPanel = payload => ({
    type: 'EDIT_PANEL',
    payload
})

let deletePanel = payload => ({
    type: 'DELETE_PANEL',
    payload
})

module.exports = { setPanels, createPanel, editPanel, deletePanel }