const initialState = {
    companies: []
}

let companies = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_COMPANIES':
            return {
                ...state,
                companies: action.payload.empresas
            }
        case 'CREATE_COMPANY': 
            let newCompany = [ action.payload.company ]
            let stateCompanies = [...state.companies]
            let newCompaniesArr = stateCompanies.concat(newCompany)
            return {
                ...state,
                companies: newCompaniesArr
            }
        case 'EDIT_COMPANY': 
            let userIndex = state.companies.findIndex(company => company.id === action.payload.company.id)
            let editedArray = [ ...state.companies ]
            editedArray[userIndex] = action.payload.company
            return {
                ...state,
                companies: editedArray
            }  
        case 'DELETE_COMPANY': 
            let filteredArray = state.companies.filter((company) => company.id !== action.payload.idEmpresa)
            return {
                ...state,
                companies: filteredArray
            }
        case 'INSERT_AFILIACION': 
            let userIndex1 = state.companies.findIndex(company => company.id === action.payload.afiliacion.FK_Empresa)
            let editedArray1 = [ ...state.companies ]
            editedArray1[userIndex1] = { ...editedArray1[userIndex1], afiliacion: action.payload.afiliacion }
            return {
                ...state,
                companies: editedArray1
            }
        case 'EDIT_AFILIACION': 
            console.log(action.payload.afiliacion)
            let userIndex2 = state.companies.findIndex(company => company.id === action.payload.afiliacion.FK_Empresa)
            let editedArray2 = [ ...state.companies ]
            editedArray2[userIndex2] = { ...editedArray2[userIndex2], afiliacion: action.payload.afiliacion }
            return {
                ...state,
                companies: editedArray2
            }
            //break;
        default:
            return state

    }
}
  
  export default companies