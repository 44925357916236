// JavaScript Document
import GraphQL from './ConsultaGraphQLUsuario';

import url from '../../../../../apiService/ApiUrl';
import urlencode from 'urlencode';
// import actions from '../../../../../actions';

function handleError(err){
	//console.log(`Request failed: ${err}`);
}

const fetchUser = (This, userId) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL(userId))}`, options)
	.then(res => res.json())
	.then( (res) => {

		
		let usuario = res.data.appInfo.usuario,
        usuarioConFecha = {
			...usuario,
			fecha_de_nacimiento: new Date(parseInt(usuario.fecha_de_nacimiento))
		}
		
		This.setState({
			apiResponse: true,
			usuario: usuarioConFecha
		})

		// return res

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchUser;