import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

const MedicFilter = (props) => {

    const especialidades = new Set(props.medicos.map(usuario => usuario.medico.especialidad))
    const especialidadesArray = [...especialidades]

    const subEspecialidades = new Set(props.medicos.map(usuario => usuario.medico.sub_especialidad))
    const subEspecialidadesArray = [...subEspecialidades]
    
    return (
        <div id='filtroDoctores' className='col-12 mx-auto border shadow py-4 text-center rounded' style={{ marginTop: '100px' }} >

            <div className='navbar navbar-expand-lg col-lg-12 mx-auto'>

                <div className='row d-lg-flex align-items-lg-center mx-auto mx-lg-0 mr-auto' >

                    <div id="icono-filtro" className="col-12 col-lg-6 mx-0 px-0">
                        <FontAwesomeIcon icon={"vials"} style={{color: '#5DACD4', fontSize: '40px'}} />
                    </div>

                    <div className="col-12 col-lg-6 mx-0 px-0">
                        {/* <label className='d-none d-xl-block mb-0' style={{minWidth: '100px'}}>Filtro de Búsqueda</label> */}
                        <div id='toggler' className='mx-auto mx-lg-0'>
                            <button className="navbar-toggler x collapsed border-dark btn btn-outline-secondary mt-3 px-4 py-2" type="button" data-toggle="collapse" data-target="#filterToggle" aria-controls="filterToggle" aria-expanded="false" aria-label="Toggle navigation">Filtro de Búsqueda</button>
                        </div>
                    </div>

                </div>
        
                <div id="filterToggle" className="collapse navbar-collapse justify-content-center justify-content-md-end" >

                    <form >
                        <ul className="navbar-nav d-flex flex-wrap">
                            <li className="nav-item mx-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right d-lg-none">
                                <FontAwesomeIcon icon={faArrowCircleDown} style={{color: '#5DACD4', fontSize: '25px'}} className='mt-3' />
                            </li>
                            <li className="nav-item ml-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right">
                                <input id="nombre" type='text' className='py-1 px-2 btn border border-dark' style={{ width: '185px' }} placeholder='Nombre' onChange={ (event) => props.setFilter('set', event) } />
                            </li>
                            <li className="nav-item ml-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right">
                                <input id="apellidos" type='text' className='py-1 px-2 btn border border-dark' style={{ width: '185px' }} placeholder='Apellidos' onChange={ (event) => props.setFilter('set', event) } />
                            </li>
                            <li className="nav-item ml-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right">
                                <select id="especialidad" type='text' className='py-1 px-2 btn border border-dark' style={{ width: '185px' }} onChange={ (event) => props.setFilter('set', event) } >
                                    <option className='text-capitalize' value='' >Especialidad</option>
                                    { especialidadesArray.map((especialidad, key) => <option className='text-capitalize' key={key} value={especialidad} >{especialidad}</option> ) }
                                    {/* { especialidadesArray.map((usuario, key) => <option key={key} value={usuario.medico.especialidad} >{usuario.medico.especialidad}</option> ) } */}
                                </select>
                                {/* <input id="especialidad" type='text' className='py-1 px-2 btn border border-dark' placeholder='Especialidad' onChange={ (event) => props.setFilter('set', event) } /> */}
                            </li>
                            <li className="nav-item ml-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right">
                                <select id="sub_especialidad" type='text' className='py-1 px-2 btn border border-dark' style={{ width: '185px' }} onChange={ (event) => props.setFilter('set', event) } >
                                    <option className='text-capitalize' value='' >Sub-Especialidad</option>
                                    { subEspecialidadesArray.map((subEspecialidad, key) => <option className='text-capitalize' key={key} value={subEspecialidad} >{subEspecialidad}</option> ) }
                                    {/* { props.medicos.map((usuario, key) => <option key={key} value={usuario.medico.sub_especialidad} >{usuario.medico.sub_especialidad}</option> ) } */}
                                </select>
                                {/* <input id="sub_especialidad" type='text' className='py-1 px-2 btn border border-dark' placeholder='Sub-Especialidad' onChange={ (event) => props.setFilter('set', event) } /> */}
                            </li>
                            <li className="nav-item ml-lg-3 mt-3 mt-lg-0 my-lg-2 text-center text-lg-right">
                                <input id="resetMedicFilter" type="reset" onClick={ () => props.setFilter('reset') } value='Eliminar Filtros' className="py-1 px-2 btn btn-secondary rounded" />
                            </li>    
                        </ul>
                    </form>
                    
                </div>

            </div>

        </div>
    )

}

export default MedicFilter