

let consulta = (userJSON) => {

  const { id, nombre, apellidos, usuario, correo, fecha_de_nacimiento, direccion, nacionalidad, sexo, telefono, DPI, tipo, foto_perfil, permisos, medico } = userJSON
  const { ViewUser, CreateUser, EditUser, DeleteUser, UpdateStateMedic } = permisos.AdminUser
  const { estado, numero_colegiado, nit_medico, clinica_nombre, clinica_departamento, clinica_municipalidad, direccion_clinica, telefono_clinica, correo_clinica, direccion_clinica2, telefono_clinica2, correo_clinica2, direccion_clinica3, telefono_clinica3, correo_clinica3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, social_media, financiero, horario_atencion } = medico
  const { fb_link, ins_link, web_link } = social_media
  const { cuenta_numero, cuenta_nombre, cuenta_tipo, banco_nombre, factura_nombre } = financiero

    let string = `
      mutation {
        userMutation {
          editUser( editUser: {
            id: "${id}"
            nombre: "${nombre}"
            apellidos: "${apellidos}"
            usuario: "${usuario}"
            correo: "${correo}"
            fecha_de_nacimiento: "${fecha_de_nacimiento}"
            direccion: "${direccion}"
            nacionalidad: "${nacionalidad}"
            sexo: "${sexo}"
            telefono: ${telefono}
            DPI: "${DPI}"
            tipo: "${tipo}"
            foto_perfil: "${foto_perfil}"
            permisos: {
              AdminUser: {
                ViewUser: ${ViewUser}
                CreateUser: ${CreateUser}
                EditUser: ${EditUser}
                DeleteUser: ${DeleteUser}
                UpdateStateMedic: ${UpdateStateMedic}
              }
            }
            medico: {
              estado: "${estado}"
              numero_colegiado: "${numero_colegiado}"
              NIT: "${nit_medico}"
              nombre_clinica: "${clinica_nombre}"
              departamento_clinica: "${clinica_departamento}"
              municipalidad_clinica: "${clinica_municipalidad}"
              direccion_clinica: "${direccion_clinica}"
              telefono_clinica: "${telefono_clinica}"
              correo_clinica: "${correo_clinica}"
              direccion_clinica2: "${direccion_clinica2}"
              telefono_clinica2: "${telefono_clinica2}"
              correo_clinica2: "${correo_clinica2}"
              direccion_clinica3: "${direccion_clinica3}"
              telefono_clinica3: "${telefono_clinica3}"
              correo_clinica3: "${correo_clinica3}"
              especialidad: "${especialidad}"
              sub_especialidad: "${sub_especialidad}"
              atencion_emergencias: ${atencion_emergencias}
              observaciones: "${observaciones}"
              observaciones_emergencias: "${observaciones_emergencias}"
              social_media: {
                fb_link: "${fb_link}"
                ins_link: "${ins_link}"
                web_link: "${web_link}"
              }
              financiero: {
                cuenta_numero: "${cuenta_numero}"
                cuenta_nombre: "${cuenta_nombre}"
                cuenta_tipo: "${cuenta_tipo}"
                banco_nombre: "${banco_nombre}"
                factura_nombre: "${factura_nombre}"
              }
              horario_atencion: [
                {
                  dia: "${horario_atencion[0].dia}"
                  ingreso: "${horario_atencion[0].ingreso}"
                  egreso: "${horario_atencion[0].egreso}"
                }
                {
                  dia: "${horario_atencion[1].dia}"
                  ingreso: "${horario_atencion[1].ingreso}"
                  egreso: "${horario_atencion[1].egreso}"
                }
                {
                  dia: "${horario_atencion[2].dia}"
                  ingreso: "${horario_atencion[2].ingreso}"
                  egreso: "${horario_atencion[2].egreso}"
                }
                {
                  dia: "${horario_atencion[3].dia}"
                  ingreso: "${horario_atencion[3].ingreso}"
                  egreso: "${horario_atencion[3].egreso}"
                }
                {
                  dia: "${horario_atencion[4].dia}"
                  ingreso: "${horario_atencion[4].ingreso}"
                  egreso: "${horario_atencion[4].egreso}"
                }
                {
                  dia: "${horario_atencion[5].dia}"
                  ingreso: "${horario_atencion[5].ingreso}"
                  egreso: "${horario_atencion[5].egreso}"
                }
                {
                  dia: "${horario_atencion[6].dia}"
                  ingreso: "${horario_atencion[6].ingreso}"
                  egreso: "${horario_atencion[6].egreso}"
                }
              ]
            }
          }) {
            usuario {
              id
              usuario
              nombre
              apellidos
              correo
              fecha_de_nacimiento
              direccion
              nacionalidad
              sexo
              telefono
              tipo
              DPI
              foto_perfil
              permisos {
                AdminUser {
                  ViewUser
                  CreateUser
                  EditUser
                  DeleteUser
                  UpdateStateMedic
                }
              }
              colaborador {
                id
                puesto
                departamento
                NIT
              }
              medico {
                estado
                id
                numero_colegiado
                NIT
                nombre_clinica
                departamento_clinica
                municipalidad_clinica
                direccion_clinica
                telefono_clinica
                correo_clinica
                direccion_clinica2
                telefono_clinica2
                correo_clinica2
                direccion_clinica3
                telefono_clinica3
                correo_clinica3
                especialidad
                sub_especialidad
                atencion_emergencias
                observaciones
                observaciones_emergencias
                social_media {
                  fb_link
                  ins_link
                  web_link
                }
                financiero {
                  cuenta_numero
                  cuenta_nombre
                  cuenta_tipo
                  banco_nombre
                  factura_nombre
                  documentos {
                    dpi_front
                    dpi_back
                    licencia_sanitaria
                    patente
                    recibo_colegiado
                    constancia_desechos_solidos
                    titulo_academico
                    rtu
                    factura
                  }
                }
                horario_atencion {
                  dia
                  ingreso
                  egreso
                }
              }
              suscription{
                id
                firma_afiliado
                fecha_inicio
                fecha_expiracion
                auto_renovacion
                DPI_frontal
                DPI_posterior
                principalUser
              }
            }
            mensaje
          }
        }
      }
    `

    return {
      "query": string
    }
  
  }
  
  export default consulta;