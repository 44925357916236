// JavaScript Document
import GraphQL from './ConsultaGraphQLPanels';
import ProductsGQL from '../fetchGetProducts/ConsultaGraphQLProducts';

import url from '../../../../../../apiService/ApiUrl';
import urlencode from 'urlencode';
// import actions from '../../../../../actions';

function handleError(err){
	//console.log(`Request failed: ${err}`);
}

const FetchPanels = async (This) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL())}`, options)
	.then(res => res.json())
	.then( async (res) => {

		const panels = res.data.appInfo.paneles

		const options = {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(ProductsGQL()),
			credentials: 'include'
		}
		
		let fetchedProducts = await fetch(url, options)
		.then(res => res.json())
		.then( (res) => res.data.appInfo.productos )
		.catch(err => console.log(err))
	
		const newPanels = panels.map( panel => {
			let products = panel.idsProductos.map( productId => { 
				return fetchedProducts.find( product => product.id === productId )
			})
	
			let newPanel = {
				id: panel.id,
				label: panel.nombre,
				nombre: panel.nombre,
				productos: products
			}
	
			return newPanel
		})
	
		This.setState({ fetchedProducts, panels: newPanels })
	
		// return res

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default FetchPanels;