import React, { Component } from 'react';
//Router
import { BrowserRouter as Router } from 'react-router-dom';

import { connect } from 'react-redux';

import sessionService from '../../sessionService/sessionService';
import url from '../../apiService/ApiUrl.js';

//Components
import PrincipalRoutes from '../Components/PrincipalRoutes.jsx';

//Importando Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//importando fontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTachometerAlt, faIdCard, faEye, faTrashAlt, faUser, faSave, faPlusCircle, faUsers, faUserPlus, faEdit, faVials, faBars, faTimes, faPhone, faBriefcaseMedical, faFileMedical, faBuilding, faListUl, faClipboardCheck, faBox, faBoxes } from '@fortawesome/free-solid-svg-icons';

import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(faSignOutAlt, faTachometerAlt, faIdCard, fab, faEye, faTrashAlt, faUser, faSave, faPlusCircle, faUsers, faUserPlus, faEdit, faVials, faBars, faTimes, faPhone, faBriefcaseMedical, faFileMedical, faBuilding, faListUl, faClipboardCheck, faBox, faBoxes );

class App extends Component {

  componentDidMount(){

		sessionService.getLogIn(url, this)

	}

  render(){
    return (
      <Router>
				<PrincipalRoutes />
			</Router>
    );
  }

}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(App);
