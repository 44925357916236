let consulta = (getAllAnalysisData) => {

  let {cursor, limit, nombre, apellidos, analysisName} = getAllAnalysisData

    const string =`
      query {
        appInfo {
          resultadosAnalisis (
            limit: ${limit}
            cursor: ${cursor}
            filter: {
              nombre: "${nombre}"
              apellidos: "${apellidos}"
              nombreAnalisis: "${analysisName}"
            }
          ) {
            totalResultados
            resultados {
              id
              nombre
              apellidos
              correo
              telefono
              nombreAnalisis
              documentoAnalisis  
            }
          }
        }
      }
    `
  
      return {
        "query": string
      }
  
  }
  
  export default consulta