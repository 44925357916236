import React, { Component } from 'react'

import QRCode from "qrcode.react";
import Select from 'react-select'

import FetchCreateAnalysis from '../containers/fetchCreateAnalysis/FetchCreateAnalysis'

import DasboardComponent from '../../Components/DashboardComponent'
import Loader from '../../../Components/Loader'
import FetchGetAnalysisNames from '../containers/fetchGetAnalysisNames/FetchAnalysisNames'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowCircleDown, faVial, faVials } from '@fortawesome/free-solid-svg-icons';

class AnalysisCreate extends Component {

    state = {
        loaderClassName: 'd-none',
        fetchResponseClassName: 'd-none',
        fetchCreateMessage: '',
        submitButtonClassName: 'btn btn-success mt-5',
        requireAnalysisNameMessage: 'd-none',
        showQR: false,
        nombre: '', 
        apellidos: '', 
        correo: '', 
        teléfono: '', 
        analysisName: '',
        analysisDocument: '',
        analysisURL: '',
        analysisNameList: []
    }

    componentDidMount() {

        FetchGetAnalysisNames(this)

    }

    createAnalysisHandler = (event) => {
        
        event.preventDefault()

        if ( this.state.analysisName !== '' ) {
            
            let { nombre, apellidos, correo, telefono, analysisName, analysisDocument } = this.state
            let data = { nombre, apellidos, correo, telefono, analysisName, analysisDocument }
            
            FetchCreateAnalysis(this, data)

        } else {
            this.setState({ requireAnalysisNameMessage: 'text-warning font-weight-bold mt-5' })
            setTimeout(() => {
                this.setState({ requireAnalysisNameMessage: 'd-none' })
            }, 3000);
        }


    }

    onInputChange = (event) => this.setState({ [event.target.id]: event.target.value })

    onFileLoad = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        if (event.target.files[0] !== undefined) {
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                files.push(base64)
            };
            // Convert data to base64
            fileReader.readAsDataURL(event.target.files[0]);
            // Onload of file read the file content
            this.setState({ [event.target.id]: files })
        } else {
            this.setState({ [event.target.id]: '' })
        }



    }
    
    render() {
        return (
            <DasboardComponent>

                <div className="col-11 mx-auto py-5" style={{marginTop: '50px', minHeight: '75vh'}}>

                    <h1 className='mx-auto text-center' >Crear Análisis</h1>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='createAnalysisForm' onSubmit={this.createAnalysisHandler} className='mx-auto text-center my-5 py-5' >
                            
                            <div className='form-row' >
                                
                                <div className="form-group col-md-6">
                                    <label htmlFor="nombre">Nombre</label>
                                    <input onChange={ this.onInputChange } type="text" className="form-control" id="nombre" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="apellidos">Apellidos</label>
                                    <input onChange={ this.onInputChange } type="text" className="form-control" id="apellidos" data-test-type='input-text' required />
                                </div>

                            </div>

                            <div className='form-row' >
                                
                                <div className="form-group col-md-6">
                                    <label htmlFor="correo">Correo Electrónico</label>
                                    <input onChange={ this.onInputChange } type="email" className="form-control" id="correo" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <input onChange={ this.onInputChange } type="text" className="form-control" id="telefono" data-test-type='input-text' required />
                                </div>

                            </div>

                            <div className='form-row' >
                                
                                <div className="form-group col-md-6">
                                    <label htmlFor="analysisName">Nombre del Análisis</label>
                                    {/* <input onChange={ this.onInputChange } type="text" className="form-control" id="analysisName" data-test-type='input-text' required /> */}
                                    <Select
                                        options={this.state.analysisNameList}
                                        onChange={ (event) => { this.setState({ analysisName: event.label }) } }
                                        isSearchable={true}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="analysisDocument">Documento del Análisis</label>
                                    <input onChange={ this.onFileLoad } type="file" accept=".pdf" className="form-control py-1" id="analysisDocument" data-test-type='input-file' required />
                                </div>

                            </div>

                            <button type="submit" className={this.state.submitButtonClassName}>Crear</button>

                            <p className={this.state.requireAnalysisNameMessage} >Por favor elige el análisis que deseas crear.</p>
                            
                            <div className={this.state.loaderClassName} >
                                <Loader />
                            </div>

                            <div className={this.state.fetchResponseClassName} role="alert">
                                {this.state.fetchCreateMessage}
                            </div>

                            <div>
                                {this.state.showQR === true && 
                                    <div>
                                        <QRCode value={this.state.analysisURL} />
                                        <div>
                                            <a className='text-decoration-none text-info font-weigth-bold' href={this.state.analysisURL} rel="noreferrer" target="_blank" >Visualizar Análisis</a>
                                        </div>
                                    </div>
                                }
                            </div>

                        </form>

                    </div>

                </div>
           

            </DasboardComponent>
        )
    }
}

export default AnalysisCreate