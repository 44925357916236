import GraphQL from './ConsultaGraphQLDeleteUser';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err){
	//console.log(`Request failed: ${err}`);
}

const FetchDeleteUser = (This, usuario) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL( usuario )),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let data = res.data.userMutation.deleteUser
		let idUsuario = data.idUsuario
		// let mensaje = data.mensaje

		This.props.removeUser(idUsuario)

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default FetchDeleteUser;