import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

import actions from '../../../../../actions';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
}

const registerUser = (This, usuarioJSON) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(usuarioJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		const data = res.data.userMutation.registerUser
		const conexion = data.conexion
		const usuario = data.usuario

        This.props.dispatch(actions.logIn.logIn({ 
            logIn: {
               conexion,
               usuario
            } 
        }))

		return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default registerUser;