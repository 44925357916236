// JavaScript Document
import GraphQL from './ConsultaGraphQLEditDocuments';

import url from '../../../../../apiService/ApiUrl';

function handleError(err){
	// console.log(`Request failed: ${err}`);
}

const FetchEditDocuments = (This, idMedico, tipo, documento) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(idMedico, tipo, documento)),
		credentials: 'include'
	}

	let loader = `${tipo}_loaderClassName`
	let upLoader = `${tipo}_fileUploader`
	let success = `${tipo}_success`
	let failure = `${tipo}_failure`

	This.setState({ [success]: 'd-none', [failure]: 'd-none', [loader]: 'd-inline mx-2', [upLoader]: 'd-none' })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let data = res.data.userMutation.addDocumentMedico
		let documento = data.documento
		// let message = data.mensaje

		document.getElementById(tipo).value = ''
		This.setState({ [loader]: 'd-none', [upLoader]: 'mx-2 text-warning', [success]: 'd-inline mx-2', [tipo]: '', userDocuments: { ...This.state.userDocuments, [tipo]: documento } })

		This.props.autoUploadMedicDocument(idMedico, tipo, documento)

	})
	.catch(err => {
		handleError(err)
		document.getElementById(tipo).value = ''
		This.setState({ [loader]: 'd-none', [upLoader]: 'mx-2 text-warning', [failure]: 'd-inline mx-2', [tipo]: '' })
	});

	//termina fetch
}

export default FetchEditDocuments;