let consulta = (analysis) => {

  let { nombre, apellidos, correo, telefono, analysisName, analysisDocument } = analysis

  const string =`
    mutation {
      resultadosAnalaisisMutation {
        insertResultadoAnalisis (resultadoAnalisis: {
          nombre: "${nombre}"
          apellidos: "${apellidos}"
          correo: "${correo}"
          telefono: "${telefono}"
          nombreAnalisis: "${analysisName}"
          documentoAnalisis: "${analysisDocument}"
        }) {
          id
          nombre
          apellidos
          correo
          telefono
          nombre
          documentoAnalisis
        }
      }
    }
  `

    return {
      "query": string
    }

}
  
  export default consulta