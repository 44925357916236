import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import actions from '../../../../../actions';

import ProductsTable from '../Components/ProductsTable'
import ProductView from '../Components/ProductView'
import ProductCreate from '../Components/ProductCreate'
import ProductEdit from '../Components/ProductEdit'
import ProductPricesChange from '../Components/ProductPricesChange';

import fetchTiposCliente from '../Containers/fetchGetTiposCliente/FetchGraphQLTipoCliente'
import fetchGraphQLProducts from './fetchGetProducts/FetchGraphQLProducts'
import FetchDeleteProduct from './fetchDeleteProduct/FetchGraphQLProducts'

export class AdminProductos extends Component {

    state = {
        display: '',
        showLoader: 'd-none',
        currentProduct: '',
        tiposCliente: []
    }

    componentDidMount() {

        fetchGraphQLProducts(this)
        fetchTiposCliente(this)

    }

    handleDisplay = (display, product) => {
        this.setState({ display, currentProduct: product })
    }

    handleDeleteProduct = (product) => {
        FetchDeleteProduct(this, product)
    }

    render() {

        if ( this.props.conexion === false ) {
			return <Redirect to='/login' />
		} else {

            if (this.state.display === '') {
                return <ProductsTable usuario={this.props.usuario} products={this.props.products} handleDisplay={this.handleDisplay} handleDeleteProduct={this.handleDeleteProduct} showLoader={this.state.showLoader} />
            } else if (this.state.display === 'ProductView') {
                return <ProductView usuario={this.props.usuario} currentProduct={this.state.currentProduct} tiposCliente={this.state.tiposCliente} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ProductCreate') {
                return <ProductCreate usuario={this.props.usuario} products={this.props.products} tiposCliente={this.state.tiposCliente} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ProductEdit') {
                return <ProductEdit usuario={this.props.usuario} tiposCliente={this.state.tiposCliente} currentProduct={this.state.currentProduct} handleDisplay={this.handleDisplay} />
            } else if (this.state.display === 'ChangePreciosProducto') {
                return <ProductPricesChange usuario={this.props.usuario} tiposCliente={this.state.tiposCliente} currentProduct={this.state.currentProduct} handleDisplay={this.handleDisplay} />
            } else {
                return null
            }

        }
    }
}

const mapStateToProps = (state) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        products: state.appInfo.productsData.products
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setProducts: (products) => dispatch (actions.products.setProducts({ products }) ),
        deleteProduct: (productId) => dispatch(actions.products.deleteProduct({ productId }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (AdminProductos)
