// JavaScript Document
import GraphQL from './ConsultaGraphQLCreateTipoCliente';

import url from '../../../../../../apiService/ApiUrl';

function handleError(err){
	// console.log(`Request failed: ${err}`);
}

const FetchCreateTipoCliente = (This, nombreTipoCliente) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(nombreTipoCliente)),
		credentials: 'include'
	}

	This.setState({ loaderClassName: 'd-block' })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let clientType = res.data.productosMutation.insertTipoCliente
		let message = 'Se ha hecho la inserción de un nuevo tipo de cliente.'

		This.props.createClientType(clientType)
		This.setState({ fetchCreateResponse: 'mt-4 col-8 mx-auto alert alert-success', fetchCreateMessage: message, loaderClassName: 'd-none' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchCreateResponse: 'd-none', fetchCreateMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.' })
		}, 1500);

	})
	.catch(err => {
		handleError(err)
		This.setState({ fetchCreateResponse: 'mt-4 col-8 mx-auto alert alert-danger', loaderClassName: 'd-none' })
		/* istanbul ignore next */
		setTimeout(() => {
			This.setState({ fetchCreateResponse: 'd-none' })
		}, 1500);
	});

	//termina fetch
}

export default FetchCreateTipoCliente;