// JavaScript Document
import GraphQL from './ConsultaGraphQLUsuarios';

import url from '../../../../../apiService/ApiUrl';
import urlencode from 'urlencode';
// import actions from '../../../../../actions';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchUsers = (This) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL())}`, options)
	.then(res => res.json())
	.then( (res) => {

        let usuarios = res.data.appInfo.usuarios,
        usuariosConFecha = usuarios.map( usuario => { 
			usuario.fecha_de_nacimiento = new Date(parseInt(usuario.fecha_de_nacimiento))
			
            return usuario
		})

		let medicos = usuariosConFecha.filter( usuario => usuario.tipo === 'medico' && usuario.medico.estado === 'Validado' )

		This.props.setUsuarios(medicos)

		return res

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchUsers;