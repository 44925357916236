import React, { Component } from 'react';

import DasboardComponent from '../../Components/DashboardComponent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';

export default class EmpresaView extends Component {
    
    render() {

        let { id, logo, nombre, direccion, telefono, correo, NIT, afiliacion } = this.props.currentCompany
        let firma_contrato, inicio_contrato, metodo_pago, texto_contrato, tipo, cuota, vencimiento_contrato, cantidad_afiliados_permitidos, visitas_medicas_maximas_mes
        if ( afiliacion !== null ) {
            firma_contrato = afiliacion.firma_contrato
            inicio_contrato = afiliacion.inicio_contrato
            metodo_pago = afiliacion.metodo_pago
            texto_contrato = afiliacion.texto_contrato
            tipo = afiliacion.tipo
            cuota = afiliacion.cuota
            vencimiento_contrato = afiliacion.vencimiento_contrato
            cantidad_afiliados_permitidos = afiliacion.cantidad_afiliados_permitidos
            visitas_medicas_maximas_mes= afiliacion.visitas_medicas_maximas_mes
        }
        

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
        
                    <div id="showTableButton" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('', {
                        id: '',
                        logo: '',
                        nombre: '',
                        direccion: '',
                        telefono: '',
                        correo: '',
                        NIT: '',
                        FK_Afiliado: '',
                        afiliacion: {
                            id: '',
                            inicio_contrato: '',
                            firma_contrato: '',
                            metodo_pago: '',
                            texto_contrato: '',
                            vencimiento_contrato: '',
                            cantidad_afiliados_permitidos: '',
                            visitas_medicas_maximas_mes: '',
                            FK_Empresa: ''
                        },
                    }) }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        { (logo !== null && logo !== undefined) ? <img  src={logo} alt='medico' style={{ height: '150px' }}  /> : <FontAwesomeIcon icon={faUserCircle} style={{color: '#5DACD4', fontSize: '100px'}} className='mt-3' /> }
                        <h1>{ nombre }</h1>
                    </div>

                    <div className='mx-auto text-center mt-5 pt-5' >

                        <div className='form-row' >
                            <div className='col-12 col-md-6' >
                                <p><span className='font-weight-bold' >ID:</span> { id } </p>
                            </div>
                            <div className='col-12 col-md-6' >
                                <p><span className='font-weight-bold' >Nombres:</span> { nombre } </p>
                            </div>
                        </div>

                        <div className='form-row' >
                            <div className='col-12 col-md-6' >
                                <p><span className='font-weight-bold' >Teléfono:</span> { telefono } </p>
                            </div>
                            <div className='col-12 col-md-6' >
                                <p><span className='font-weight-bold' >Dirección:</span> { direccion } </p>
                            </div>
                        </div>

                        <div className='form-row' >
                            <div className='col-12 col-md-6' >
                                <p><span className='font-weight-bold' >Correo:</span> { correo } </p>
                            </div>
                            <div className='col-12 col-md-6' >
                                <p><span className='font-weight-bold' >NIT:</span> { NIT } </p>
                            </div>
                        </div>

                        {
                            afiliacion === null ? null : 
                            <div className="form-group col-12">
                                <p><span className='font-weight-bold' >Afiliación:</span> { afiliacion.id } </p>
                            </div>
                        }

                        {
                            afiliacion === null ? null :
                            <div className='mt-5 pt-5' >
                                
                                <h3>Información de la Afiliación</h3>

                                <div className='form-row' >
                                    <div className='col-12 col-md-6' >
                                        <p><span className='font-weight-bold' >Método de Pago:</span> { metodo_pago } </p>
                                    </div>
                                    <div className='col-12 col-md-6' >
                                        <p><span className='font-weight-bold' >Tipo de Pago:</span> { tipo === 'Paga_Empresa' ? 'Paga la Empresa' : 'Paga el Usuario' } </p>
                                    </div>
                                </div>

                                <div className='form-row' >
                                    <div className='col-12 col-md-6' >
                                        <p><span className='font-weight-bold' >Fecha de Contratación:</span> { inicio_contrato } </p>
                                    </div>
                                    <div className='col-12 col-md-6' >
                                        <p><span className='font-weight-bold' >Fecha de Vencimiento:</span> { vencimiento_contrato } </p>
                                    </div>
                                </div>

                                <div className='form-row' >
                                    <div className='col-12 col-md-6' >
                                        <p><span className='font-weight-bold' >Límite Máximo de Afiliados:</span> { cantidad_afiliados_permitidos } </p>
                                    </div>
                                    <div className='col-12 col-md-6' >
                                        <p><span className='font-weight-bold' >Límite Máximo de Visitas Médicas al Mes:</span> { visitas_medicas_maximas_mes } </p>
                                    </div>
                                </div>

                                {
                                    tipo !== 'Paga_Empresa' ? null :
                                    <div className="form-group col-12">
                                        <p><span className='font-weight-bold' >Cuota a Pagar:</span> Q.{ cuota } </p>
                                    </div>
                                }

                                <div className="form-group col-12 mt-5">
                                    <p className='font-weight-bold' >Contenido de Contrato</p>
                                    <textarea className='col-12' defaultValue={texto_contrato} disabled />
                                </div>

                                <div className="form-group col-12 mt-5">
                                    <p className='font-weight-bold' >Firma de Contrato</p>
                                    <img src={firma_contrato} alt='cdm' style={{ maxHeight: '100px' }} />
                                </div>

                            </div>
                        }

                        {
                            afiliacion === null ? null :
                            <button className='btn btn-info' style={{ marginTop: '120px' }} onClick={ () => { let display = 'ManageAffiliates'; this.props.handleDisplay(display, this.props.currentCompany) } }>Gestionar Afiliados</button>
                        }


                    </div>
                    
                </div>
            </DasboardComponent>
        )
    }
} 