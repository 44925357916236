import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err, This){
	//console.log(`Request failed: ${err}`);
}

const getAllAnalysis = (This, getAllAnalysisData) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(getAllAnalysisData)),
		credentials: 'include'
	}

	// //llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		const analysis = res.data.appInfo.resultadosAnalisis
		const resultados = analysis.resultados
		const totalResultados = analysis.totalResultados
        This.setState({ analysis: resultados, totalResultados  })

		// return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default getAllAnalysis