import React from 'react';

import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faTags, faUser, faBoxes, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

let toggleSideOff = (e) => {

    e.preventDefault();
    document.querySelector(".body").classList.remove("sidenav-toggled");
    document.querySelector("body").classList.remove("sidenav-toggled");

}

let LeftSideNavbar = props => (
  <ul className="navbar-nav navbar-sidenav" id="leftMenu" onClick={toggleSideOff}>

    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Dashboard">
      <NavLink className="nav-link" to="/dashboard" activeClassName="active">
        <FontAwesomeIcon icon={"id-card"} style={{color: '#5DACD4'}}/>
        <span className="nav-link-text"> Panel Principal</span>
      </NavLink>
    </li>

    {
        (props.logIn.conexion === true) && (props.logIn.usuario.tipo === 'medico') ? (
        <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Administración de Perfil">
          <NavLink className="nav-link" to="/dashboard/admin/perfil" >
            <FontAwesomeIcon icon={faUser} style={{color: '#5DACD4'}}/>
            <span className="nav-link-text"> Adiministración de Perfil</span>
          </NavLink>
        </li>
      ) : null
    }

    {
      //(props.logIn.conexion === false) || (props.logIn.usuario.permisos.AdminUser !== null) ? (
      (props.logIn.conexion === true) && (props.logIn.usuario.tipo === 'colaborador') && (props.logIn.usuario.permisos.AdminUser !== null) ? (
        <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Administración de Usuarios">
          <NavLink className="nav-link" to="/dashboard/admin/usuarios" >
            <FontAwesomeIcon icon={"users"} style={{color: '#5DACD4'}}/>
            <span className="nav-link-text"> Adiministración de Usuarios</span>
          </NavLink>
        </li>
      ) : null
    }

    {
      //(props.logIn.conexion === false) || (props.logIn.usuario.permisos.AdminUser !== null) ? (
      ((props.logIn.conexion === true) && (props.logIn.usuario.tipo === 'colaborador')) ? (
        <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Empresas">
          <NavLink className="nav-link" to="/dashboard/admin/empresas" >
            <FontAwesomeIcon icon={"building"} style={{color: '#5DACD4'}}/>
            <span className="nav-link-text"> Empresas</span>
          </NavLink>
        </li>
      ) : null
    }

    {
      //(props.logIn.conexion === false) || (props.logIn.usuario.permisos.AdminUser !== null) ? (
      ((props.logIn.conexion === true) && (props.logIn.usuario.tipo !== 'colaborador')) ? (
        <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Médicos Afiliados">
          <NavLink className="nav-link" to="/dashboard/medicos" >
            <FontAwesomeIcon icon={"briefcase-medical"} style={{color: '#5DACD4'}}/>
            <span className="nav-link-text"> Médicos Afiliados</span>
          </NavLink>
        </li>
      ) : null
    }

    {
      <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Beneficios">
        <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#beneficios" data-parent="#leftMenu" onClick={toggleSideOff}>
          <FontAwesomeIcon icon={faTags} style={{color: '#5DACD4'}}/>
          <span className="nav-link-text"> Beneficios</span>
        </a>
        <ul className="sidenav-second-level collapse" id="beneficios" style={{ backgroundColor: '#fbfbfb' }}>
          <li>
            <NavLink to="/dashboard/beneficios/productos">Centro de Diagnóstico</NavLink>
          </li>
        </ul>
      </li>
    }

    {
      ((props.logIn.conexion === true) && (props.logIn.usuario.tipo === 'colaborador')) ? (
        <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Análisis">
          <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#analisis" data-parent="#leftMenu" onClick={toggleSideOff}>
            <FontAwesomeIcon icon={faChartPie} style={{color: '#5DACD4'}}/>
            <span className="nav-link-text"> Análisis</span>
          </a>
          <ul className="sidenav-second-level collapse" id="analisis" style={{ backgroundColor: '#fbfbfb' }}>
            <li>
              <NavLink to="/dashboard/analisis/crear">Crear</NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/analisis/">Visualizar</NavLink>
            </li>
          </ul>
        </li>
        ) : null
    }

    {
      ((props.logIn.conexion === true) && ((props.logIn.usuario.tipo === 'colaborador') )) ? (
        <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Análisis">
          <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#productos" data-parent="#leftMenu" onClick={toggleSideOff}>
            <FontAwesomeIcon icon={faBoxes} style={{color: '#5DACD4'}}/>
            <span className="nav-link-text"> Productos</span>
          </a>
          <ul className="sidenav-second-level collapse" id="productos" style={{ backgroundColor: '#fbfbfb' }}>
            <li>
              <NavLink to="/dashboard/productos/">Productos</NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/paneles/">Paneles</NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/tipos-de-cliente/">Tipo de Cliente</NavLink>
            </li>
          </ul>
        </li>
        ) : null
    }
    
    {
      ((props.logIn.conexion === true) && ((props.logIn.usuario.tipo === 'colaborador'))) ? (
        <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Análisis">
          <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#ventas" data-parent="#leftMenu" onClick={toggleSideOff}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} style={{color: '#5DACD4'}}/>
            <span className="nav-link-text"> Ventas</span>
          </a>
          <ul className="sidenav-second-level collapse" id="ventas" style={{ backgroundColor: '#fbfbfb' }}>
            <li>
              <NavLink to="/dashboard/cotizaciones/">Cotizaciones</NavLink>
            </li>
            {/* <li>
              <NavLink to="/dashboard/ordenes-de-venta/">Órdenes de Venta</NavLink>
            </li> */}
          </ul>
        </li>
        ) : null
    }

    
  </ul>
)

export default LeftSideNavbar