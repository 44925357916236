let consulta = (producto) => {

  let { nombre, clasificacion, tipo, descFamGT, conOSinMedioDeContraste, observaciones, idTipoClienteDefault, precios_TipoCliente } = producto
  let { idTipoCliente, precio } = precios_TipoCliente[0]

  let string = `
    mutation {
      productosMutation {
        insertProducto (
          producto: {
            tipo: "${tipo}"
            clasificacion: "${clasificacion}"
            precios_TipoCliente: [{
              idTipoCliente: "${idTipoCliente}"
              precio: ${precio}
            }]
            descFamGT: ${descFamGT}
            nombre: "${nombre}"
            conOSinMedioDeContraste: "${conOSinMedioDeContraste}"
            observaciones: "${observaciones}"
            idTipoClienteDefault: "${idTipoClienteDefault}"
          }
        ) {
          id
          tipo
          clasificacion
          precios_TipoCliente {
            idTipoCliente
            precio
          }
          descFamGT
          nombre
          conOSinMedioDeContraste
          observaciones
          idTipoClienteDefault
        }
      }
    }
  `
  console.log(string)

  return {
    "query": string
  }

}

export default consulta;