let consulta = (analysisId) => {

  const string =`
    mutation {
      resultadosAnalaisisMutation {
        sendMailResultadoAnalisis (
          idResultado: "${analysisId}"
        )
      }
    }
  `

    return {
      "query": string
    }

}
  
  export default consulta