let consulta = (panel) => {

  let { id, nombre, productos } = panel

  let string = `
    mutation {
      productosMutation {
        editPanel (
          id: "${id}"
          nombre: "${nombre}"
          idsProductos: [${productos.map( producto => `"${producto.id}"`)}]
        ) {
          panel {
            id
            nombre
            idsProductos
          }
          mensaje
        }
      }
    }
  `

  return {
    "query": string
  }

}

export default consulta;