import React from 'react';

//Importando Componentes
import Navbar from '../Navbar/Containers/Navbar.jsx';
import Footer from '../../Components/Footer.jsx';
import MenuCMR from '../../Components/MenuCMR.jsx';

//Importando Estilos CSS
//import './css/Inicio.css';

//Import Jquery
//import $ from 'jquery';

let Inicio = props => {
	//console.log(props.path)
	return(
		<div id="inicio-container" className="container-fluid sin-padding">
			<Navbar path={props.path}/>
			{props.children}
			<MenuCMR />
			<Footer />
		</div>
	)
}

export default Inicio