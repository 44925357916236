import React, { Component } from 'react';

import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle, faEye, faFileDownload, faColumns } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap4-modal';
import PDFViewer from '../../../Components/PDFViewer'

import DasboardComponent from '../../Components/DashboardComponent'
import parse from 'html-react-parser';

import fetchUser from '../Containers/FetchGetUser/FetchGraphQLUsuario'

import Loader from '../../../Components/Loader'
import famgt from '../../../assets/famgt.png'

export default class UserView extends Component {

    state = {
        apiResponse: false,
        documentModal: false,
        currentDocument: '',
        usuario: {
            id: '',
            usuario: '',
            nombre: '',
            apellidos: '',
            correo: '',
            fecha_de_nacimiento: '',
            direccion: '',
            nacionalidad: 'Guatemala',
            sexo: '',
            telefono: 0,
            tipo: '',
            DPI: '',
            foto_perfil: '',
            permisos: {
                AdminUser: {
                    ViewUser: false,
                    CreateUser: false,
                    EditUser: false,
                    DeleteUser: false,
                    UpdateStateMedic: false
                }
            },
            colaborador: {
                id_colaborador: '',
                nit_colaborador: '',
                puesto: '',
                departamento: ''
            },
            medico: {
                id_medico: '',
                estado: 'Validado',
                nit_medico: '',
                numero_colegiado: '',
                clinica_nombre: '',
                clinica_departamento: '',
                clinica_municipalidad: '',
                clinica_direccion: '',
                clinica_telefono: '',
                clinica_correo: '',
                clinica_direccion2: '',
                clinica_telefono2: '',
                clinica_correo2: '',
                clinica_direccion3: '',
                clinica_telefono3: '',
                clinica_correo3: '',
                especialidad: '',
                sub_especialidad: '',
                observaciones: '',
                observaciones_emergencias: '',
                atencion_emergencias: false,
                social_media: {
                    fb_link: '',
                    ins_link: '',
                    web_link: ''
                },
                data_consultas: {
                    precioNormal: '',
                    descuento: '',
                    precioFinalPaciente: ''
                },
                data_procedimientos: {
                    html: ''
                },
                financiero: {
                    cuenta_numero: '',
                    cuenta_nombre: '',
                    cuenta_tipo: '',
                    banco_nombre: '',
                    factura_nombre: '',
                    documentos: {
                        dpi_front: null,
                        dpi_back: null,
                        licencia_sanitaria: null,
                        patente: null,
                        recibo_colegiado: null,
                        titulo_academico: null,
                        constancia_desechos_solidos: null,
                        rtu: null,
                        factura: null
                    }
                },
                horario_atencion: [
                    {
                        dia: 'lunes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'martes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'miercoles',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'jueves',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'viernes',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'sabado',
                        ingreso: '',
                        egreso: ''
                    },
                    {
                        dia: 'domingo',
                        ingreso: '',
                        egreso: ''
                    }
                ]
            }
        }
    }

    componentDidMount() {

        fetchUser(this, this.props.currentUser.id)

    }
    
    render() {

        let { usuario, nombre, apellidos, telefono, correo, DPI, fecha_de_nacimiento, sexo, direccion, tipo, nacionalidad, foto_perfil, colaborador, medico } = this.state.usuario
        let id_colaborador, puesto, departamento, nit_colaborador
        let id_medico, nit_medico, numero_colegiado, clinica_nombre, clinica_departamento, clinica_municipalidad, direccion_clinica, telefono_clinica, correo_clinica, direccion_clinica2, telefono_clinica2, correo_clinica2, direccion_clinica3, telefono_clinica3, correo_clinica3, especialidad, sub_especialidad, atencion_emergencias, observaciones, observaciones_emergencias, horario_atencion
        let precioNormal, descuento, precioFinalPaciente
        let html
        let fb_link, ins_link, web_link
        let cuenta_numero, cuenta_nombre, cuenta_tipo, banco_nombre, factura_nombre
        let dpi_front, dpi_back, licencia_sanitaria, patente, recibo_colegiado, titulo_academico, constancia_desechos_solidos, rtu, factura

        let social_media, data_consultas, data_procedimientos

        if (colaborador !== null) {
            id_colaborador = colaborador.id
            puesto = colaborador.puesto
            departamento = colaborador.departamento
            nit_colaborador = colaborador.NIT
        }

        // if ((medico !== null) && (medico.id !== null)) {
        if ( medico !== null ) {
            id_medico = medico.id
            numero_colegiado = medico.numero_colegiado
            nit_medico = medico.NIT
            clinica_nombre = medico.nombre_clinica
            clinica_departamento = medico.departamento_clinica
            clinica_municipalidad = medico.municipalidad_clinica
            direccion_clinica = medico.direccion_clinica
            telefono_clinica = medico.telefono_clinica
            correo_clinica = medico.correo_clinica
            direccion_clinica2 = medico.direccion_clinica2
            telefono_clinica2 = medico.telefono_clinica2
            correo_clinica2 = medico.correo_clinica2
            direccion_clinica3 = medico.direccion_clinica3
            telefono_clinica3 = medico.telefono_clinica3
            correo_clinica3 = medico.correo_clinica3
            especialidad = medico.especialidad
            sub_especialidad = medico.sub_especialidad
            atencion_emergencias = medico.atencion_emergencias
            observaciones = medico.observaciones
            observaciones_emergencias = medico.observaciones_emergencias
            horario_atencion = medico.horario_atencion
            cuenta_numero = medico.financiero.cuenta_numero
            cuenta_nombre = medico.financiero.cuenta_nombre
            cuenta_tipo = medico.financiero.cuenta_tipo
            banco_nombre = medico.financiero.banco_nombre
            factura_nombre = medico.financiero.factura_nombre
            dpi_front = medico.financiero.documentos.dpi_front
            dpi_back = medico.financiero.documentos.dpi_back
            licencia_sanitaria = medico.financiero.documentos.licencia_sanitaria
            patente = medico.financiero.documentos.patente
            recibo_colegiado = medico.financiero.documentos.recibo_colegiado
            titulo_academico = medico.financiero.documentos.titulo_academico
            constancia_desechos_solidos = medico.financiero.documentos.constancia_desechos_solidos
            rtu = medico.financiero.documentos.rtu
            factura = medico.financiero.documentos.factura
            social_media = medico.social_media
            data_consultas = medico.data_consultas
            data_procedimientos = medico.data_procedimientos
        } else {
            id_medico = ''
            numero_colegiado = ''
            nit_medico = ''
            clinica_nombre = ''
            clinica_departamento = ''
            clinica_municipalidad = ''
            direccion_clinica = ''
            telefono_clinica = ''
            correo_clinica = ''
            direccion_clinica2 = ''
            telefono_clinica2 = ''
            correo_clinica2 = ''
            direccion_clinica3 = ''
            telefono_clinica3 = ''
            correo_clinica3 = ''
            especialidad = ''
            sub_especialidad = ''
            atencion_emergencias = ''
            observaciones = ''
            observaciones_emergencias = ''
            horario_atencion = ''
            cuenta_numero = ''
            cuenta_nombre = ''
            cuenta_tipo = ''
            banco_nombre = ''
            factura_nombre = ''
            dpi_front = ''
            dpi_back = ''
            licencia_sanitaria = ''
            patente = ''
            recibo_colegiado = ''
            titulo_academico = ''
            constancia_desechos_solidos = ''
            rtu = ''
            factura = ''
            social_media = null
            data_consultas = null
            data_procedimientos = null
        }

        if (social_media !== null ) {
            fb_link = social_media.fb_link
            ins_link = social_media.ins_link
            web_link = social_media.web_link
        } else {
            fb_link = ''
            ins_link = ''
            web_link = ''
        }

        if (data_consultas !== null) {
            precioNormal = data_consultas.precioNormal
            descuento = data_consultas.descuento
            precioFinalPaciente = data_consultas.precioFinalPaciente
        } else {
            precioNormal = ''
            descuento = ''
            precioFinalPaciente = ''
        }

        if (data_procedimientos !== null) {
            html = data_procedimientos.html
        } else {
            html = ''
        }

        let documents = [ 
            {name: 'DPI Frontal', content: dpi_front}, 
            {name: 'DPI Trasero', content: dpi_back}, 
            {name: 'Licencia Sanitaria', content: licencia_sanitaria}, 
            {name: 'Patente', content: patente}, 
            {name: 'Recibo de Colegiado', content: recibo_colegiado}, 
            {name: 'Título Académico', content: titulo_academico}, 
            {name: 'Constancia de Desechos Sólidos', content: constancia_desechos_solidos}, 
            {name: 'RTU', content: rtu}, 
            {name: 'Factura', content: factura} 
        ]

        let docs = documents.every( doc => doc.content === null )

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{margin: '50px 0px 75px 0px', minHeight: '75vh'}}>
        
                    <div id="showTableButton" className='col-12 mr-auto' onClick={ () => this.props.handleDisplay('', {
                        id: '',
                        usuario: '',
                        nombre: '',
                        apellidos: '',
                        correo: '',
                        fecha_de_nacimiento: new Date(),
                        password: '',
                        direccion: '',
                        nacionalidad: 'Guatemala',
                        sexo: '',
                        telefono: 0,
                        tipo: '',
                        dpi: '',
                        foto_perfil: '',
                        permisos: {
                            AdminUser: {
                                ViewUser: false,
                                CreateUser: false,
                                EditUser: false,
                                DeleteUser: false,
                                UpdateStateMedic: false
                            }
                        },
                        colaborador: {
                            id_colaborador: '',
                            nit_colaborador: '',
                            puesto: '',
                            departamento: ''
                        },
                        medico: {
                            estado: 'No Validado',
                            id_medico: '',
                            nit_medico: '',
                            numero_colegiado: '',
                            clinica_nombre: '',
                            clinica_departamento: '',
                            clinica_municipalidad: '',
                            clinica_direccion: '',
                            clinica_telefono: '',
                            clinica_correo: '',
                            clinica_direccion2: '',
                            clinica_telefono2: '',
                            clinica_correo2: '',
                            clinica_direccion3: '',
                            clinica_telefono3: '',
                            clinica_correo3: '',
                            especialidad: '',
                            sub_especialidad: '',
                            observaciones: '',
                            observaciones_emergencias: '',
                            social_media: {
                                fb_link: '',
                                ins_link: '',
                                web_link: ''
                            },
                            atencion_emergencias: '',
                            financiero: {
                                cuenta_numero: '',
                                cuenta_nombre: '',
                                cuenta_tipo: '',
                                banco_nombre: '',
                                factura_nombre: '',
                                documentos: {
                                    dpi_front: '',
                                    dpi_back: '',
                                    licencia_sanitaria: '',
                                    patente: '',
                                    recibo_colegiado: '',
                                    titulo_academico: '',
                                    constancia_desechos_solidos: '',
                                    rtu: '',
                                    factura: '',
                                }
                            },
                            horario_atencion: [
                                {
                                    dia: 'lunes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'martes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'miercoles',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'jueves',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'viernes',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'sabado',
                                    ingreso: '',
                                    egreso: ''
                                },
                                {
                                    dia: 'domingo',
                                    ingreso: '',
                                    egreso: ''
                                }
                            ]
                            
                        }
                    }) }>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>
        
                    {
                        this.state.apiResponse === false ?
                        <div>
                            <Loader />
                        </div> :
                        <div>
                            
                            <div className='mx-auto text-center' >
                            { (foto_perfil !== null && foto_perfil !== undefined) ? <img  src={foto_perfil} alt='medico' style={{ height: '150px', borderRadius: '50%' }}  /> : <FontAwesomeIcon icon={faUserCircle} style={{color: '#5DACD4', fontSize: '100px'}} className='mt-3' /> }
                            <h1>{ nombre } { apellidos }</h1>
                        </div>
            
                            <div className='mx-auto text-center mt-5 pt-5' >
                
                                <div className='row' >
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Usuario:</span> { usuario } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Nombres:</span> { nombre } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Apellidos:</span> { apellidos } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Teléfono:</span> { telefono } </p>
                                    </div>
                                </div>
                                <div className='row' >
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Correo:</span> { correo } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >DPI:</span> { DPI } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Fecha de Nacimiento:</span> { moment(fecha_de_nacimiento).format('YYYY/MM/DD') } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Dirección:</span> { direccion } </p>
                                    </div>
                                </div>
                                <div className='row' >
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Sexo:</span> { sexo } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Tipo:</span> { tipo } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        <p><span className='font-weight-bold' >Nacionalidad:</span> { nacionalidad } </p>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-3' >
                                        
                                    </div>
                                </div>
                
                                {
                                    //colaborador !== null ?
                                    tipo === 'colaborador' ?
                                        <div>
                                            <h4 className='py-5 mt-2' >Información de Colaborador</h4>
                                            <div className='row' >
                                                <div className='col-12 col-md-6 col-lg-3' >
                                                    <p><span className='font-weight-bold' >ID de Colaborador:</span> { id_colaborador } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3' >
                                                    <p><span className='font-weight-bold' >Puesto:</span> { puesto } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3' >
                                                    <p><span className='font-weight-bold' >Departamento:</span> { departamento } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3' >
                                                    <p><span className='font-weight-bold' >NIT:</span> { nit_colaborador } </p>
                                                </div>
                                            </div>
                                        </div>
                                    : null
                
                                }

                                {
                                    //medico !== null ?
                                    tipo === 'medico' ?
                                        <div>
                                            
                                            <h4 className='py-5 mt-2' >Información de Médico</h4>
                                            
                                            <div className='row' >
                                                <div className='col-12 col-md-6 col-lg-3 mx-auto text-center' >
                                                    <p><span className='font-weight-bold' >ID de Médico:</span> { id_medico } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3 mx-auto text-center' >
                                                    <p><span className='font-weight-bold' >NIT:</span> { nit_medico } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3 mx-auto text-center' >
                                                    <p><span className='font-weight-bold' >Número de Colegiado:</span> { numero_colegiado } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3 mx-auto text-center' >
                                                    <p><span className='font-weight-bold' >Nombre de la Clínica:</span> { clinica_nombre } </p>
                                                </div>
                                                
                                                <div className='col-12 col-md-6 col-lg-3 mx-auto text-center' >
                                                    <p><span className='font-weight-bold' >Departamento:</span> { clinica_departamento } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3 mx-auto text-center' >
                                                    <p><span className='font-weight-bold' >Municipalidad:</span> { clinica_municipalidad } </p>
                                                </div>
                                                
                                                {
                                                    direccion_clinica === null && telefono_clinica === null && correo_clinica === null ? null :
                                                    <div className='text-center mx-auto'>
                                                        <h5 className='mt-5 py-4' >Información de la Clínica No. 1</h5>
                                                        
                                                        <div className='row mt-5' >

                                                            {
                                                                direccion_clinica === null ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Dirección de la Clínica:</span> { direccion_clinica } </p>
                                                                </div>
                                                            }
                                                            {
                                                                telefono_clinica === null ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Teléfono de la Clínica:</span> { telefono_clinica } </p>
                                                                </div>
                                                            }
                                                            {
                                                                correo_clinica === null ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Correo Electrónico de la Clínica:</span> { correo_clinica } </p>
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                }


                                                {
                                                    direccion_clinica2 === '' && telefono_clinica2 === '' && correo_clinica2 === '' ? null :
                                                    <div className='text-center mx-auto'>
                                                        <h5 className='mt-5 py-4' >Información de la Clínica No. 2</h5>
                                                        
                                                        <div className='row mt-5' >

                                                            {
                                                                direccion_clinica2 === '' ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Dirección de la Clínica:</span> { direccion_clinica2 } </p>
                                                                </div>
                                                            }
                                                            {
                                                                telefono_clinica2 === '' ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Teléfono de la Clínica:</span> { telefono_clinica2 } </p>
                                                                </div>
                                                            }
                                                            {
                                                                correo_clinica2 === '' ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Correo Electrónico de la Clínica:</span> { correo_clinica2 } </p>
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    direccion_clinica3 === '' && telefono_clinica3 === '' && correo_clinica3 === '' ? null :
                                                    <div className='text-center mx-auto '>
                                                        <h5 className='mt-5 py-4' >Información de la Clínica No. 3</h5>
                                                        
                                                        <div className='row mt-5' >

                                                            {
                                                                direccion_clinica3 === '' ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Dirección de la Clínica:</span> { direccion_clinica3 } </p>
                                                                </div>
                                                            }
                                                            {
                                                                telefono_clinica3 === '' ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Teléfono de la Clínica:</span> { telefono_clinica3 } </p>
                                                                </div>
                                                            }
                                                            {
                                                                correo_clinica3 === '' ? null :
                                                                <div className='col-12 col-md-6 col-lg-4 mx-auto text-center' >
                                                                    <p><span className='font-weight-bold' >Correo Electrónico de la Clínica:</span> { correo_clinica3 } </p>
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                }

                                                <div className='pt-5 w-100'></div>
                                                    
                                                <div className='col-12 col-md-6 col-lg-3 text-center mx-auto' >
                                                    <p><span className='font-weight-bold' >Especialidad:</span> { especialidad } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3 text-center mx-auto' >
                                                    <p><span className='font-weight-bold' >Sub-Especialidad:</span> { sub_especialidad } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3 text-center mx-auto' >
                                                    <p><span className='font-weight-bold' >Atención a Emergencias:</span> { atencion_emergencias ? 'Sí' : 'No' } </p>
                                                </div>

                                            </div>
                                            
                                            <div className='row my-4' >
                                                <div className='col-12 col-md-6' >
                                                    <p className='font-weight-bold' >Observaciones:</p>
                                                    <p>{ observaciones }</p>
                                                </div>
                                                <div className='col-12 col-md-6' >
                                                    <p className='font-weight-bold' >Obervaciones de Emergencias:</p>
                                                    <p>{ observaciones_emergencias }</p>
                                                </div>
                                            </div>

                                            <h4 className='py-5 mt-2' >Horario de Atención</h4>
                                            
                                            <div className='table-responsive' >
                                                <table className='table text-center table-bordered table-hover table-sm' >
                                                    <thead>
                                                        <tr>
                                                            {
                                                                horario_atencion !== undefined ? horario_atencion.map( (horario, i) => <td key={i} className='text-capitalize' >{horario.dia}</td> ) : null
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {
                                                                horario_atencion !== undefined ? horario_atencion.map( (horario, i) => 
                                                                    (horario.ingreso === '' || horario.egreso === '') ? <td key={i} className='' >-</td> : <td key={i} className='' >{horario.ingreso} - {horario.egreso}</td>
                                                                ) : null
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            
                                            {/* Consulta Médica */}
                                            {
                                                medico.data_consultas !== null  ?
                                                <div>
                                                    <h4 className='py-5 mt-2' >Consulta Médica</h4>

                                                    <div className='py-4 text-center mx-auto' >

                                                        <div className='col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3 border rounded shadow-sm p-5 mx-auto' >
                                                            <img src={famgt} className='img-fluid' style={{ maxHeight: '80px' }} alt='familia guatemala' />
                                                            <p>Precio de consulta médica preferencial para afiliados</p>
                                                            <p className='font-weight-bold mb-0' style={{ fontSize: '2em', color: '#21a5d7' }} >Q.{parseFloat(precioFinalPaciente).toFixed(2)} <span className='font-weight-normal text-dark' style={{ fontSize: '.4em' }} >/ consulta*</span></p>
                                                            <p style={{ fontSize: '0.8em' }} >Normalmente <del className='text-danger' >Q.{parseFloat(precioNormal).toFixed(2)}</del></p>
                                                            <p className='text-white bg-success font-weight-bold border border-success py-1 shadow' style={{ borderRadius: '15px' }} >{descuento.toFixed(2) * 100}% <span style={{ fontSize: '.8em' }} >de descuento</span></p>
                                                            <button className='grow btn btn-info mt-3' type="button" ><a href={`tel:${telefono_clinica}`} title={telefono_clinica} className='text-white text-decoration-none' >Llamar</a></button>
                                                        </div>

                                                    </div>

                                                </div> : null
                                            }


                                            {/* Procedimiento Médico */}
                                            {
                                                medico.data_procedimientos !== null  ?
                                                <div>
                                                    <h4 className='py-5 mt-2' >Procedimientos</h4>

                                                    <div className='col-12 col-md-6 mx-auto  py-4 text-justify border rounded shadow-sm' >

                                                        { parse(html) }

                                                    </div>

                                                </div> : null
                                            }

                                            {/* Medios Sociales */}
                                            {
                                                fb_link || ins_link || web_link ?
                                                <div>
                                                    <h4 className='py-5 mt-2' >Medios Sociales</h4>

                                                    <div className='row' >
                                                        {
                                                            fb_link ?
                                                            <div className='col-4 mx-auto' >
                                                                <a href={fb_link} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={['fab', 'facebook']} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                            </div> : null
                                                        }
                                                        {
                                                            ins_link ?
                                                            <div className='col-4 mx-auto' >
                                                                <a href={ins_link} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={['fab', 'instagram']} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                            </div> : null
                                                        }
                                                        {
                                                            web_link ?
                                                            <div className='col-4 mx-auto' >
                                                                <a href={web_link} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faColumns} style={{color: '#5DACD4', fontSize: '50px'}} className='mx-3' /></a>
                                                            </div> : null
                                                        }
                                                    </div>
                                                </div> : null
                                            }


                                            <h4 className='py-5 mt-2' >Información Financiera</h4>

                                            <div className='row' >
                                                <div className='col-12 col-md-6 col-lg-3' >
                                                    <p><span className='font-weight-bold' >Número de Cuenta:</span> { cuenta_numero } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3' >
                                                    <p><span className='font-weight-bold' >Nombre de la Cuenta:</span> { cuenta_nombre } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3' >
                                                    <p><span className='font-weight-bold' >Tipo de Cuenta:</span> { cuenta_tipo } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3' >
                                                    <p><span className='font-weight-bold' >Nombre del Banco:</span> { banco_nombre } </p>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-3 mx-auto' >
                                                    <p><span className='font-weight-bold' >Facturación a Nombre de:</span> { factura_nombre } </p>
                                                </div>
                                            </div>
                                            
                                            {
                                                docs === true ? null :
                                                <button id='botonDocumentos' className="btn btn-info mt-5" type="button" data-toggle="collapse" data-target="#documentsCollapse" aria-expanded="false" aria-controls="docuemtsCollapse">
                                                    Documentación de Médico
                                                </button>
                                            }

                                            
                                            <div className="collapse" id="documentsCollapse">
                                                <div className='row' >

                                                    {
                                                        documents.map( (document, i) => {return (
                                                            <div className='col-12 col-md-6 col-lg-3 mx-auto text-center' key={i} >
                                                                
                                                                {
                                                                    document.content === null ? null :
                                                                    <div>
                                                                        <p className='mt-5 mb-1 font-weight-bold' >{document.name}</p>
                                                                        <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true, currentDocument: document.content }) } icon={faEye} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mr-3' />
                                                                        {/* <FontAwesomeIcon onClick={ () => this.setState({ documentModal: true }) } icon={faEye} style={{color: '#5DACD4', fontSize: '25px'}} className='mr-3' /> */}
                                                                        <a href={document.content} download={`${nombre}-${apellidos}-${document.name}.pdf`} ><FontAwesomeIcon icon={faFileDownload} style={{color: '#5DACD4', fontSize: '25px'}} className='mt-3' /></a>
                                                                    </div>
                                                                }


                                                            </div>
                                                        )})
                                                    }

                                                    {
                                                        this.state.currentDocument.length !== 0 ?
                                                        <Modal id='documentModal' visible={this.state.documentModal} fade onClickBackdrop={ () => this.setState({ documentModal: false, currentDocument: '' })} className='modal' dialogClassName='modal-dialog modal-lg' >
                                                    
                                                            <div className="modal-body text-center py-4">
                                                                <PDFViewer file={this.state.currentDocument} />
                                                            </div>

                                                        </Modal> : null
                                                    }


                                                </div>
                                            </div>

                                        </div>
                                    : null
                
                                }
                
                
                            </div>
                            
                        </div>
                    }

                    
        
        
                </div>
            </DasboardComponent>
        )
    }
} 