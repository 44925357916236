import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '../../../../actions';
import DasboardComponent from '../../Components/DashboardComponent'
import FetchEditCompany from '../containers/FetchEditCompany/FetchGraphQLEditCompany'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';

class CompanyEdit extends Component {

    state = {
        fetchEditResponse: 'd-none',
        fetchEditMessage: 'Ha ocurrido un problema, por favor vuelve a intentarlo luego.',
        loaderClassName: 'd-none',
        empresa: {
            id: '',
            logo: '',
            nombre: '',
            direccion: '',
            telefono: '',
            correo: '',
            NIT: '',
            afiliacion: ''
        }
    }

    async componentDidMount() {

        let { id, logo, nombre, direccion, telefono, correo, NIT, afiliacion } = this.props.currentCompany

        this.setState({
            ...this.state,
            empresa: {
                id: id,
                logo: logo,
                nombre: nombre,
                direccion: direccion,
                telefono: telefono,
                correo: correo,
                NIT: NIT,
                afiliacion: afiliacion,
            }
        })
        
    }

    editFormHandler = (e) => {
        
        e.preventDefault()

        FetchEditCompany(this, this.state.empresa)

    }

    onInputChange = (event) => {

        this.setState({ 
            empresa: {
                ...this.state.empresa,
                [event.target.id]: event.target.value 
            }
        })
        
    }

    onFileUpload = (event) => {

        let files = []

        let fileReader = new FileReader();
        let base64;

        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Print data in console
            files.push(base64)
        };
        // Convert data to base64
        fileReader.readAsDataURL(event.target.files[0]);

        if ( event.target.id === 'logo' ) {
            this.setState({ 
                empresa: {
                    ...this.state.empresa,
                    [event.target.id]: files
                }
            })
        } 

    }

    render() {

        let { logo, nombre, direccion, telefono, correo, NIT, afiliacion } = this.state.empresa

        return (
            <DasboardComponent>
                <div className="col-11 mx-auto" style={{marginTop: '50px', minHeight: '75vh'}}>
                    
                    <div id="tableViewRedirect"
                        onClick={ () => this.props.handleDisplay('', {
                            id: '',
                            logo: '',
                            nombre: '',
                            direccion: '',
                            telefono: '',
                            correo: '',
                            NIT: '',
                            FK_Afiliacion: ''
                        }) } 
                        className='col-12 mr-auto' 
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: '#5DACD4', fontSize: '25px', cursor: 'pointer'}} className='mt-3' />
                    </div>

                    <div className='mx-auto text-center' >
                        { logo !== null ? <img src={logo} alt={nombre} style={{ height: '150px' }} /> : <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '80px' }} className='mt-3' /> }<br/><br/>
                        <div className='form-group mx-auto custom-file col-10 col-md-6 col-lg-4 mb-4' >
                            <label htmlFor="logo" className='custom-file-label'>Logotipo</label>
                            <input 
                                onChange={ this.onFileUpload }
                                accept=".png, .jpg, .jpeg"
                                data-test-type='input-file'
                                type="file" className="form-control custom-file-input" id="logo"
                            />
                        </div>
                        <h1>{ this.props.currentCompany.nombre }</h1>
                    </div>

                    <div className='mx-auto text-center my-5 pb-5 col-12 col-md-10 col-lg-8' >

                        <form id='editForm' onSubmit={this.editFormHandler} className='mx-auto text-center mt-5' >
                            
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="usuario">Nombre</label>
                                    <input value={nombre} onChange={ this.onInputChange } type="text" className="form-control" id="nombre" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <input value={telefono} onChange={ this.onInputChange } type="text" className="form-control" id="telefono" data-test-type='input-text' required />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="correo">Correo Electrónico</label>
                                    <input value={correo} onChange={ this.onInputChange } type="text" className="form-control" id="correo" data-test-type='input-text' required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="NIT">NIT</label>
                                    <input value={NIT} onChange={ this.onInputChange } type="text" className="form-control" id="NIT" data-test-type='input-text' required />
                                </div>
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="direccion">Dirección</label>
                                <input value={direccion} onChange={ this.onInputChange } type="text" className="form-control" id="direccion" data-test-type='select' />
                            </div>

                            <button type="submit" className="btn btn-success">Editar</button>

                            <div className={this.state.fetchEditResponse} role="alert">
                                {this.state.fetchEditMessage}
                            </div>

                        </form>

                        { afiliacion === null ? null : <button type="button" className="btn btn-info mt-5" onClick={ () => this.props.handleDisplay('EmpresaAffiliateEdit', this.props.currentCompany) }  >Editar Afiliación</button> }

                    </div>

                </div>
            </DasboardComponent>
        )
    }

}

export const mapDispatchToProps = dispatch => {
    return {
        editCompany: ( company ) => dispatch ( actions.companies.editCompany({ company }) )
    }
}

export default connect(null, mapDispatchToProps) (CompanyEdit)