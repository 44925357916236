// JavaScript Document
import GraphQL from './ConsultaGraphQLProducts';

import url from '../../../../../../apiService/ApiUrl';

function handleError(err){
	// console.log(`Request failed: ${err}`);
}

const FetchDeleteProducto = (This, productId) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(productId)),
		credentials: 'include'
	}

	This.setState({ loaderClassName: 'd-block' })

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		This.props.deleteProduct(productId)

	})
	.catch(err => {
		handleError(err)
	});

	//termina fetch
}

export default FetchDeleteProducto;