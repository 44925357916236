let consulta = (nombreTipoCliente) => {
    let string = `
      mutation {
        productosMutation {
          insertTipoCliente (
            nombre: "${nombreTipoCliente}"
          ) {
            id
            nombre
          }
        }
      }
    `
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;